import "./ChecklistsTable.scss";

import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import k from "i18n/keys";

import { ITemplateChecklistReportFilterState } from "../../api/ITemplateChecklistReportFilterState";
import {
    useOnlyStoppableChecklistsTotalCount,
    usePauseAllMutation,
    useSearchTemplateListQuery,
} from "../../api/hooks";
import DeleteMessage from "../messages/DeleteMessage";
import HeaderFilters from "./HeaderFilters";
import ReportTable from "./ReportTable";
import ReportTableWrapper from "./ReportTableWrapper";
import { useQueryClient } from "@tanstack/react-query";
import IChanged from "common/IChanged";
import { IValueLabelItem } from "common/IValueLabelItem";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import IDictionary from "common/viewModels/IDictionary";
import { ChecklistReportSortBy } from "components/template-checklist-report/api/ChecklistReportSortBy";
import IChecklistReportFilter from "components/template-checklist-report/api/IChecklistReportFilter";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

const thirtyDaysDuration = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
const centerTime = new Date().getTime(); 
const halfDuration = thirtyDaysDuration / 2;

export const defaultTimelineTimeStart = new Date(centerTime - halfDuration);
export const defaultTimelineTimeEnd = new Date(centerTime + halfDuration);

interface IChecklistsTableProps {
    selectedTemplateVersionId?: string;
    filterState?: ITemplateChecklistReportFilterState;
    customListItemIds?: string[];
    noRedirectOnTemplateChange?: boolean;
    enableSync?: boolean;
}

export enum ViewMode {
    TableView = "tableView",
    TableViewPerTemplate = "tableViewPerTemplate",
    TimelineView = "timelineView",
    TimelineViewPerTemplate = "timelineViewPerTemplate",
    ChecklistTimelineView = "checklistTimelineView",
    ChecklistTimelineViewPerTemplate = "checklistTimelineViewPerTemplate",
}

const getInitialStatus = (state?: ITemplateChecklistReportFilterState) => {
    let result: ChecklistDynamicStatus[] | undefined;

    if (state) {
        result = [];

        if (state.done) {
            result.push(ChecklistDynamicStatus.Finalized);
        }

        if (state.active) {
            result.push(ChecklistDynamicStatus.OnTrack);
            result.push(ChecklistDynamicStatus.Behind);
        }
    }

    return result;
};

const ChecklistsTable: React.FC<
    React.PropsWithChildren<IChecklistsTableProps>
> = (props) => {
    const {
        selectedTemplateVersionId,
        customListItemIds,
        filterState,
        enableSync,
    } = props;

    const appFeatures = useSelector(
        (state: IAppState) => state.authViewState.profile?.appFeatures,
    );

    const isAdministrator = useSelector(
        (state: IAppState) => state.authViewState.roles?.ADMINISTRATOR ?? false,
    );

    const timelineFeatureEnabled = useSelector(
        (state: IAppState) =>
            state.authViewState.profile?.appFeatures
                ?.checklistReportTimelineView,
    );

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const modalManagerContext = useContext(ModalManagerContext);

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const getInitialFilter = (templateIds?: string[], search?: string) => {
        const templateVersionIds =
            templateIds ??
            (selectedTemplateVersionId
                ? [selectedTemplateVersionId]
                : undefined);
        const keywordSearch = search ?? filterState?.search?.trim();

        const initialValue: IChanged<IChecklistReportFilter> = {
            value: {
                templateVersionIds,
                keywordSearch,
                status: getInitialStatus(filterState),
                customListItemIds,
            },
        };

        initialValue.isChanged =
            selectedTemplateVersionId !== undefined ||
            Boolean(keywordSearch) ||
            (initialValue.value.status?.length ?? 0) > 0;

        return initialValue;
    };

    const [currentFilter, setCurrentFilter] =
        useState<IChanged<IChecklistReportFilter>>(getInitialFilter());

    const [viewMode, setViewMode] = useState<ViewMode>(
        !selectedTemplateVersionId
            ? ViewMode.TableView
            : ViewMode.TableViewPerTemplate,
    );

    const updateFiltersForNewMode = (
        newFilter: IChanged<IChecklistReportFilter>,
        newViewMode?: ViewMode,
    ) => {
        if (
            newViewMode === ViewMode.TableView ||
            newViewMode === ViewMode.TableViewPerTemplate
        ) {
            return getInitialFilter(
                newFilter.value.templateVersionIds,
                newFilter.value.keywordSearch,
            );
        } else {
            const initialStatus = getInitialStatus(filterState);

            const newRequestFilter: IChecklistReportFilter = {
                pageSize: undefined,
                page: 1,
                sortBy:
                    newViewMode === ViewMode.TimelineView ||
                    newViewMode === ViewMode.TimelineViewPerTemplate
                        ? ChecklistReportSortBy.None
                        : ChecklistReportSortBy.StartDate,
                sortAsc:
                    newViewMode === ViewMode.TimelineView ||
                    newViewMode === ViewMode.TimelineViewPerTemplate
                        ? undefined
                        : true,
                startDate: defaultTimelineTimeStart,
                endDate: defaultTimelineTimeEnd,
                status: [
                    ...(initialStatus ?? []),
                    ChecklistDynamicStatus.Scheduled,
                ],
            };

            return {
                ...newFilter,
                value: { ...newFilter.value, ...newRequestFilter },
            };
        }
    };

    const pauseAllMutation = usePauseAllMutation();

    const [templates, setTemplates] = useState<IDictionary<string>>();

    const splitPerTemplate = viewMode.endsWith("PerTemplate");

    const shouldUseSearchTemplateList =
        splitPerTemplate &&
        (currentFilter.value.templateVersionIds?.length ?? 0) === 0;

    const { data: searchTemplateVersionIdList } = useSearchTemplateListQuery(
        { enabled: shouldUseSearchTemplateList, syncData: enableSync },
        currentFilter.value,
    );

    const templateVersionIdList = shouldUseSearchTemplateList
        ? (searchTemplateVersionIdList ?? [])
        : (currentFilter.value.templateVersionIds ?? []);

    const { data: totalCountToStop } = useOnlyStoppableChecklistsTotalCount(
        currentFilter.value,
        templateVersionIdList,
        viewMode,
    );

    const handleStop = async (newFilter: IChecklistReportFilter) => {
        const confirmMsg: IShowConfirmArgs = {
            message: <DeleteMessage />,
            yesButtonVariant: "danger",
            yesLabel: (
                <React.Fragment>
                    {t(k.STOP)} ({totalCountToStop})
                </React.Fragment>
            ),
            title: t(k.STOP_MATCHING_CHECKLISTS_QUESTION),
        };

        if (await showConfirmNoThunk(dispatch, confirmMsg)) {
            await pauseAllMutation.mutateAsync(newFilter);

            queryClient.setQueryData(
                [
                    "useTotalChecklistsCountToPause",
                    currentFilter.value,
                    templateVersionIdList,
                ],
                undefined,
            );

            queryClient.invalidateQueries({
                queryKey: ["useChecklistReportItems"],
            });
        }
    };

    const handleFetchTemplates = (
        options: Array<IValueLabelItem<string, string>>,
    ) => {
        setTemplates(
            options.reduce<typeof templates>((map, currValue) => {
                if (map) {
                    map[currValue.value] = currValue.label;
                }

                return map;
            }, {}),
        );
    };

    const handleOnBarcodeSearch = (barcode: string) => {
        setCurrentFilter((prev) => ({
            ...prev,
            isChanged: true,
            value: {
                ...prev.value,
                keywordSearch: barcode,
            },
        }));
    };

    const handleShowWorkDetails = (checklistId: string) => {
        const payload: IShowModalPayload = {
            id: checklistId,
            type: ModalTypes.checklist,
            callerAddress: location.pathname,
            options: { showAll: true },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowRecurring = (recurringId: string) => {
        const payload: IShowModalPayload = {
            id: recurringId,
            type: ModalTypes.recurrence,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowImprovements = async (checklistId: string) => {
        const payload: IShowModalPayload = {
            modalFor: { type: ModalTypes.checklist, id: checklistId },
            type: ModalTypes.multiple_improvements,
            callerAddress: location.pathname,
            options: { impFilter: { checklist: true } },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleShowChecklistTemplate = (templateId: string) => {
        const payload: IShowModalPayload = {
            id: templateId,
            type: ModalTypes.template,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnChangeTemplates = (newValues: string[]) => {
        const selectedId = newValues.length === 1 ? newValues[0] : undefined;

        if (selectedId) {
            navigate(`/checklists-report/${selectedId}`);
        } else {
            navigate("/checklists-report");
        }
    };

    const getViewModeByTemplate = (hasTemplatesSelected?: boolean) => {
        if (hasTemplatesSelected) {
            if (!timelineFeatureEnabled) {
                return ViewMode.TableViewPerTemplate;
            }
            // If per-template version exists, switch to it
            switch (viewMode) {
                case ViewMode.TableView:
                    return ViewMode.TableViewPerTemplate;
                case ViewMode.TimelineView:
                    return ViewMode.TimelineViewPerTemplate;
                case ViewMode.ChecklistTimelineView:
                    return ViewMode.ChecklistTimelineViewPerTemplate;
                default:
                    return viewMode;
            }
        } else {
            if (!timelineFeatureEnabled) {
                return ViewMode.TableView;
            }
            // No templates selected, switch to non-per-template version
            switch (viewMode) {
                case ViewMode.TableViewPerTemplate:
                    return ViewMode.TableView;
                case ViewMode.TimelineViewPerTemplate:
                    return ViewMode.TimelineView;
                case ViewMode.ChecklistTimelineViewPerTemplate:
                    return ViewMode.ChecklistTimelineView;
                default:
                    return viewMode;
            }
        }
    };

    const handleOnChangeFilter = (
        newFilter: IChanged<IChecklistReportFilter>,
    ) => {
        if (!props.noRedirectOnTemplateChange) {
            handleOnChangeTemplates(newFilter.value.templateVersionIds ?? []);
        }

        const hasTemplatesSelected =
            !!newFilter.value.templateVersionIds?.length;
        const newViewMode = getViewModeByTemplate(hasTemplatesSelected);

        if (
            newFilter.value.templateVersionIds !==
            currentFilter.value.templateVersionIds
        ) {
            setViewMode(newViewMode);
        }
        setCurrentFilter(updateFiltersForNewMode(newFilter, newViewMode));
    };

    const handleOnChangeViewMode = (newViewMode: ViewMode) => {
        setViewMode(newViewMode);

        setCurrentFilter((prev) => updateFiltersForNewMode(prev, newViewMode));
    };

    return (
        <React.Fragment>
            <HeaderFilters
                canStopMultipleChecklists={appFeatures?.stopMultipleChecklists}
                totalCountToStop={totalCountToStop}
                viewMode={viewMode}
                value={currentFilter.value}
                onChange={handleOnChangeFilter}
                isPausingAll={pauseAllMutation.isPending}
                onPauseAll={handleStop}
                onFetchTemplateList={handleFetchTemplates}
                onViewModeChange={handleOnChangeViewMode}
            />
            <div className="report-table__list">
                {splitPerTemplate && templateVersionIdList.length > 0 ? (
                    templateVersionIdList.map((templateVersionId) => (
                        <ReportTableWrapper
                            key={templateVersionId}
                            templateName={
                                templates && templates[templateVersionId]
                            }
                        >
                            <ReportTable
                                templateVersionId={templateVersionId}
                                isAdministrator={isAdministrator}
                                filter={currentFilter}
                                syncData={enableSync}
                                viewMode={viewMode}
                                onBarcodeSearch={handleOnBarcodeSearch}
                                onShowChecklistDetails={handleShowWorkDetails}
                                onShowRecurrence={handleShowRecurring}
                                onShowImprovements={handleShowImprovements}
                                onShowChecklistTemplate={
                                    handleShowChecklistTemplate
                                }
                            />
                        </ReportTableWrapper>
                    ))
                ) : (
                    <ReportTable
                        isAdministrator={isAdministrator}
                        filter={currentFilter}
                        syncData={enableSync}
                        viewMode={viewMode}
                        onShowChecklistDetails={handleShowWorkDetails}
                        onShowRecurrence={handleShowRecurring}
                        onShowImprovements={handleShowImprovements}
                        onShowChecklistTemplate={handleShowChecklistTemplate}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default ChecklistsTable;
