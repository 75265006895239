import "./UserForm.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import TeamRoleBadges from "./TeamRoleBadges";
import { SaveHandler } from "./UserForm";
import { IValueParamItem } from "common/IValueParamItem";
import { Alert } from "common/components/alerts/Alert";
import Button from "common/components/buttons/Button";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputWrapper from "common/components/input-components/InputWrapper";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import PositionsList from "common/components/positions/PositionsList";
import IValidationMessages from "common/viewModels/IValidationMessages";
import LabelWithError from "components/auth/components/LabelWithError";
import SelectRoles from "components/common/selectDropdowns/SelectRoles";
import SelectTeams from "components/common/selectDropdowns/SelectTeams";
import SelectUsers from "components/common/selectDropdowns/SelectUsers";
import { IUserPositionItem } from "components/positions/api/IUserPositionItem";
import IUserRowDTO from "components/users/api/IUserRowDTO";
import ITableSet from "http/ITableSet";

interface IProps {
    isLoading?: boolean;
    item: IUserRowDTO;
    userRoles: IValueParamItem<string, boolean>[];
    userTeams: IValueParamItem<string, boolean>[];
    teamRoles: ITableSet<string[]> | undefined;
    errors?: IValidationMessages;
    canEdit: boolean;
    positionsFeatureEnabled?: boolean;
    handleChange: (e: IInputChangeEvent<string>) => void;
    handleManagerChange: (userIds: string[]) => void;
    handleRolesChange: (roleIds: string[]) => void;
    handleTeamsChange: (teamIds: string[]) => void;
    handlePositionChange: (newItem: IUserPositionItem) => void;
    handlePositionDelete: (itemId: string, undo?: boolean) => void;
    handlePositionAddNew: () => void;
    handlePositionEditMode: (id: string, newMode: boolean) => void;
    onHaveChanges?: (haveChanges: boolean) => void;

    registerChildSaveHandler?: (
        itemId: string,
        saveHandler: SaveHandler,
    ) => void;
    unregisterChildSaveHandler?: (itemId: string) => void;
}

const UserEditForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        item,
        isLoading,
        errors,
        canEdit,
        userRoles,
        teamRoles,
        userTeams,
        positionsFeatureEnabled,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <TextfieldComplex
                hideIcon
                type="email"
                id="email"
                value={item.email ?? ""}
                placeholder={t(k.ENTER_HERE)}
                size="large"
                bold
                innerLabelContent={
                    <LabelWithError
                        label={
                            <React.Fragment>
                                {t(k.EMAIL)}
                                &nbsp;
                                <span className="text--danger">*</span>
                            </React.Fragment>
                        }
                        error={errors?.email}
                    />
                }
                invalid={Boolean(errors?.email)}
                disabled={isLoading}
                disableUserDataPrefill
                onChange={props.handleChange}
            />

            <TextfieldComplex
                hideIcon
                id="userName"
                value={item.userName ?? ""}
                placeholder={t(k.ENTER_HERE)}
                size="large"
                bold
                innerLabelContent={
                    <LabelWithError
                        label={
                            <React.Fragment>
                                {t(k.NAME)}
                                &nbsp;
                                <span className="text--danger">*</span>
                            </React.Fragment>
                        }
                        error={errors?.userName}
                    />
                }
                invalid={Boolean(errors?.userName)}
                disabled={isLoading}
                disableUserDataPrefill
                onChange={props.handleChange}
            />

            <TextfieldComplex
                hideIcon
                id="password"
                type="password"
                value={item.password ?? ""}
                placeholder={t(k.ENTER_HERE)}
                size="large"
                bold
                innerLabelContent={
                    <LabelWithError
                        label={
                            <React.Fragment>
                                {t(k.NEW_PASSWORD)}
                                &nbsp;
                                <span className="text--danger">*</span>
                            </React.Fragment>
                        }
                        error={errors?.password}
                    />
                }
                invalid={Boolean(errors?.password)}
                disabled={isLoading}
                disableUserDataPrefill
                onChange={props.handleChange}
            />

            <TextfieldComplex
                hideIcon
                id="password2"
                type="password"
                value={item.password2 ?? ""}
                placeholder={t(k.ENTER_HERE)}
                size="large"
                bold
                innerLabelContent={
                    <LabelWithError
                        label={
                            <React.Fragment>
                                {t(k.CONFIRM_NEW_PASSWORD)}
                                &nbsp;
                                <span className="text--danger">*</span>
                            </React.Fragment>
                        }
                        error={errors?.password2}
                    />
                }
                invalid={Boolean(errors?.password2)}
                disabled={isLoading}
                disableUserDataPrefill
                onChange={props.handleChange}
            />
            <TextfieldComplex
                hideIcon
                id="bio"
                value={item.bio ?? ""}
                placeholder={t(k.ENTER_HERE)}
                size="large"
                bold
                innerLabelContent={
                    <LabelWithError
                        label={
                            <React.Fragment>
                                {t(k.BIO)}
                                &nbsp;
                            </React.Fragment>
                        }
                        error={errors?.bio}
                    />
                }
                invalid={Boolean(errors?.bio)}
                disabled={isLoading}
                onChange={props.handleChange}
                multiline
            />
            <SelectUsers
                id="myManager"
                selectedUsers={item.myManager ? [item.myManager] : undefined}
                testId="selectManagerUserInput"
                boldLabel
                wrapperLabel={t(k.MANAGER)}
                filterIds={[item.id].concat(item.reportingUsers ?? [])}
                onChange={props.handleManagerChange}
            />

            <SelectRoles
                id="userRoles"
                selectedRoles={userRoles}
                multi
                testId="selectRolesUserInput"
                boldLabel
                onChange={props.handleRolesChange}
            />

            {teamRoles && teamRoles.ids.length > 0 && (
                <InputWrapper
                    htmlFor="teamRoles"
                    wrapperLabel={t(k.TEAM_ROLES)}
                    boldLabel
                >
                    <TeamRoleBadges roles={teamRoles} />
                </InputWrapper>
            )}

            <SelectTeams
                id="userTeams"
                selectedTeams={userTeams}
                multi
                testId="selectTeamsUserInput"
                onChange={props.handleTeamsChange}
                wrapperLabel={t(k.TEAMS)}
                boldLabel
            />

            {positionsFeatureEnabled && (
                <InputWrapper
                    wrapperInputTestId="userPositions"
                    wrapperLabel={t(k.POSITIONS)}
                    htmlFor="userPositions"
                    boldLabel
                >
                    <div className="user-form--positions">
                        <PositionsList
                            userPositions={item.userPositions}
                            canEdit={canEdit}
                            errors={errors}
                            onSave={props.handlePositionChange}
                            onDelete={props.handlePositionDelete}
                            onChangeEditMode={props.handlePositionEditMode}
                            onHaveChanges={props.onHaveChanges}
                            registerChildSaveHandler={
                                props.registerChildSaveHandler
                            }
                            unregisterChildSaveHandler={
                                props.unregisterChildSaveHandler
                            }
                        />

                        {canEdit && (
                            <div className="user-form--positions--add">
                                <Button
                                    testId="userForm_addPosition_btn"
                                    transparent
                                    onClick={props.handlePositionAddNew}
                                >
                                    {t(k.ADD_NEW)}
                                </Button>
                            </div>
                        )}
                    </div>
                </InputWrapper>
            )}

            <Alert>
                {t(k.THE_USER_WONT_BE_NOTIFIED_ABOUT_THEIR_ACCOUNT_INFORMATION)}
                <br />
                {t(k.PLEASE_FORWARD_LOGIN_DETAILS_TO_USER_AFTER_SAVING)}
            </Alert>
        </>
    );
};

export default UserEditForm;
