import "./AdressBarButton.scss";

import React from "react";

import classNames from "classnames";

import { LinkWrapper } from "components/common/link-wrapper";

interface IProps {
    onClick?: () => void;
    to?: string;
    active?: boolean;
}
const AdressBarButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const className = classNames("adress-bar-button", {
        clickable: props.to || props.onClick,
        active: props.active,
    });

    return (
        <LinkWrapper to={props.to}>
            <div className={className} onClick={props.onClick}>
                {props.children}
            </div>
        </LinkWrapper>
    );
};

export default AdressBarButton;
