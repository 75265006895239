import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import DarkBadge, { IDarkBadgeProps } from "common/components/badges/base-badges/badge-dark/DarkBadge";

interface INotActionableTakenBadgeProps extends IDarkBadgeProps {
    label?: string;
}

const NotActionableTakenBadge = (props: INotActionableTakenBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();
    
    return <DarkBadge {...rest}>{label ?? t(k.NOT_ACTIONABLE)}</DarkBadge>;
};

export default NotActionableTakenBadge;
