import "./Icon.scss";

import { IconType } from "react-icons/lib";

import classNames from "classnames";
import Tooltip, { TooltipProps } from "../tooltip/Tooltip";

export type IconSize = "small" | "medium" | "large";
export type IconColor = "black" | "white";

export interface IBaseIconProps {
    color?: IconColor;
    className?: string;
    disabled?: boolean;
    size?: IconSize;

    /**
     * ```ts
     * id: string;
     * message: React.ReactNode;
     * placement?: TooltipPlacement;
     * textEllipsis?: boolean;
     * variant?: "inline" | "no-flex";
     * alignSelf?: "center";
     * tooltipClassName?: string;
     * truncate?: boolean;
     * ```
     */
    tooltip?: TooltipProps;
}

interface IIconProps extends IBaseIconProps {
    icon: IconType;
}

const Icon = (props: IIconProps) => {
    const { size = "medium", icon: IconComponent, tooltip } = props;

    const className = classNames("icon", props.className, {
        [`icon--${size}`]: size,
        "icon--disabled": props.disabled,
    });

    const renderIcon = <IconComponent className={className} />;

    if (tooltip) {
        return <Tooltip {...tooltip}>{renderIcon}</Tooltip>;
    }

    return renderIcon;
};

export default Icon;
