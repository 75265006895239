import "./SelectUsersDropdown.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdPersonAdd } from "react-icons/md";

import k from "i18n/keys";

import DropdownButton from "../dropdown/DropdownButton";
import {
    IDropdownGroupOption,
    IDropdownOption,
} from "../dropdown/api/IDropdownOption";
import { IValueLabelItem } from "common/IValueLabelItem";

interface IProps {
    users: Array<IValueLabelItem<string, string>>;

    selectedUserIds: string[];

    disabled?: boolean;

    className?: string;

    color?: string;

    testId?: string;

    onSelect: (userId: string) => void;
    shouldPreventClickEventPropagation?: boolean;
}

const SelectUsersDropdown: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { t } = useTranslation();

    const {
        users,
        selectedUserIds,
        disabled,
        className,
        color,
        testId,
        shouldPreventClickEventPropagation,
    } = props;

    const options = useMemo(() => {
        const x: IDropdownGroupOption = {
            value: "allUsers",
            label: t(k.AVAILABLE_USERS),
            options: users.map((x) => {
                const result: IDropdownOption = {
                    value: x.value,
                    label: x.label,
                    selected: selectedUserIds.includes(x.value),
                };
                return result;
            }),
        };

        return [x];
    }, [users, selectedUserIds.length]);

    return (
        <DropdownButton
            icon={<MdPersonAdd color={color ?? "#396aff"} size={24} />}
            options={options}
            className={className}
            disabled={disabled}
            buttonTestId={testId}
            onChange={props.onSelect}
            shouldPreventClickEventPropagation={
                shouldPreventClickEventPropagation
            }
        />
    );
};

export default SelectUsersDropdown;
