import "./InfoBadge.scss";

import Badge, { IBaseBadgeProps } from "../Badge";
import { PropsWithChildren } from "react";
import classNames from "classnames";

export interface IInfoBadgeProps extends IBaseBadgeProps {
    outline?: boolean;
}

const InfoBadge = (props: PropsWithChildren<IInfoBadgeProps>) => {
    const { children, outline, ...rest } = props;

    const className = classNames("info-badge", {
        "info-badge--outline": outline,
    });

    return (
        <Badge className={className} {...rest}>
            {children}
        </Badge>
    );
};

export default InfoBadge;
