import "./CustomListForm.scss";

import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { ICustomListPropertyTemplateValue } from "../api/ICustomListPropertyTemplateValue";
import ICustomListRowDTO from "../api/ICustomListRowDTO";
import {
    useArchiveCustomListMutation,
    useCustomListById,
    useCustomListPermissions,
    useDeleteCustomListMutation,
    usePropertiesByCustomListId,
    useSaveCustomListMutation,
} from "../api/hooks";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import Textfield from "common/components/input-components/textfield/Textfield";
import ModalContent from "common/components/modal-content/ModalContent";
import { IShowModalPayload } from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import IValidationMessages from "common/viewModels/IValidationMessages";
import SharedPropertiesForm from "components/common/shared-properties/SharedPropertiesForm";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { scrollToTopErrorById } from "components/common/validation/ScrollToError";
import ArchiveMessage from "components/custom-list-item-page/components/messages/ArchiveMessage";
import DeleteMessage from "components/custom-list-item-page/components/messages/DeleteMessage";
import UnarchiveMessage from "components/custom-list-item-page/components/messages/UnarchiveMessage";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    id?: string;

    createNew?: boolean;

    haveChanges?: boolean;

    onShowModal: (payload: IShowModalPayload) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

function getInitialState() {
    const id = uuidv4();

    const result: ICustomListRowDTO = {
        id,
        parentId: id,
        name: "",
        description: "",
        isArchived: false,
        properties: [],
    };

    return result;
}

const CustomListForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        id: customListId,

        createNew = false,
        haveChanges = false,
    } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const isAdmin = useSelector(
        (appState: IAppState) => appState.authViewState.roles.ADMINISTRATOR,
    );

    const { data: permissions, isLoading: isLoadingPermissions } =
        useCustomListPermissions(customListId);

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [properties, setProperties] =
        useState<ICustomListPropertyTemplateValue[]>();

    const isAtLeastOneVisibleInvalid = useMemo(() => {
        if (errors._error?.includes("AT_LEAST_ONE_PROP_VISIBLE")) {
            return properties?.some((x) => x.showInListView) === false;
        }

        return false;
    }, [errors, properties]);

    const propertiesRequired = useMemo(() => {
        if (errors._error?.includes("PROPERTY_REQUIRED")) {
            if (Boolean(properties?.length) === false) {
                return true;
            } else if (properties?.every((x) => x.isDeleted)) {
                return true;
            }
        }
        return false;
    }, [errors, properties]);

    const saveMutation = useSaveCustomListMutation();
    const archiveMutation = useArchiveCustomListMutation();
    const deleteMutation = useDeleteCustomListMutation();

    const isSaving = saveMutation.isPending;

    const { data: listItem, isLoading: isLoadingList } =
        useCustomListById(customListId);

    const [editedItem, setEditedItem] = useState<ICustomListRowDTO>(
        createNew ? getInitialState() : ({} as ICustomListRowDTO),
    );

    const item = { ...listItem, ...editedItem };

    const { data: selectedProperties, isLoading: isLoadingProperties } =
        usePropertiesByCustomListId(customListId);

    const handleChange = (value: string, id?: string, name?: string) => {
        if (name && item?.id) {
            props.onHaveChanges(true);

            setEditedItem((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleSave = async () => {
        if (item?.id) {
            const newProperties =
                properties ??
                selectedProperties?.ids.reduce<
                    ICustomListPropertyTemplateValue[]
                >((acc, x) => {
                    const value = selectedProperties.values[x];

                    if (value) {
                        acc.push(value);
                    }

                    return acc;
                }, []);
            const result = await saveMutation.mutateAsync({
                isCreate: createNew,
                value: {
                    ...item,
                    properties: newProperties ?? [],
                },
            });

            if (result.Succeeded) {
                props.onClose(true);
            } else {
                setErrors(result.Messages);

                if (result.Messages._error) {
                    const errorMessage = result.Messages._error[0]?.toString();
                    if (errorMessage) {
                        toast.dismiss();
                        toast.error(t(k[errorMessage as keyof typeof k]), {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }

                const errorKeys = Object.keys(result.Messages);

                const ids = errorKeys.reduce<string[]>((map, key) => {
                    map.push(`form.${key}`);
                    return map;
                }, []);

                scrollToTopErrorById(ids);
            }
        }
    };

    const handleArchive = async () => {
        if (item?.id) {
            const archive = item.isArchived ? false : true;
            const confirmOk = await showConfirmNoThunk(
                dispatch,
                archive ? <ArchiveMessage /> : <UnarchiveMessage />,
            );

            if (confirmOk) {
                await archiveMutation.mutateAsync({
                    id: item.id,
                    request: { id: item.parentId, isArchive: archive },
                });
            }
        }
    };

    const handleDelete = async () => {
        const confirmMsg: IShowConfirmArgs = {
            message: <DeleteMessage />,
            yesButtonVariant: "danger",
            yesLabel: t(k.DELETE),
        };

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        if (confirmOk) {
            const result = await deleteMutation.mutateAsync({
                parentId: item.parentId,
            });

            if (result.Succeeded) {
                props.onClose(true);
            } else {
                toast.dismiss();
                toast.error(result.Messages._error, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const isSaveDisabled = item.isArchived || isSaving || !haveChanges;

    const handleChangeProperties = (
        values: ICustomListPropertyTemplateValue[],
        isInitialization: boolean,
    ) => {
        setProperties(values);

        props.onHaveChanges(isInitialization ? haveChanges : true);
    };

    const isLoading =
        isLoadingPermissions || isLoadingList || isLoadingProperties;

    if (isLoading) {
        return <AnimatedSpinner position="center" aligned="center" isVisible />;
    }

    if (!(isAdmin || permissions?.isOwner) || (!createNew && !listItem)) {
        return (
            <NotAvailableModal
                onClose={handleCancel}
                message={t(k.NO_AVAILABLE_CUSTOMLIST_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    return (
        <ModalContent>
            <ModalContent.Header
                onClose={handleCancel}
                title={createNew ? t(k.ADD_CUSTOM_LIST) : t(k.EDIT)}
            />

            <ModalContent.Body>
                <div className="custom-list-form--body">
                    <Textfield
                        id="form.Name"
                        name="name"
                        value={item.name ?? ""}
                        invalid={Boolean(errors.Name)}
                        placeholder={t(k.ENTER_HERE)}
                        size="large"
                        labelContent={
                            <React.Fragment>
                                {t(k.NAME)}
                                &nbsp;
                                <span className="text--danger">*</span>
                            </React.Fragment>
                        }
                        disabled={isSaving}
                        testId={`custom-list--label--${customListId}`}
                        onChange={handleChange}
                        helperText={
                            <Textfield.Error>
                                {t(k[errors.Name as keyof typeof k])}
                            </Textfield.Error>
                        }
                    />

                    <Textfield
                        id="form.Description"
                        name="description"
                        value={item.description ?? ""}
                        invalid={Boolean(errors.Description)}
                        placeholder={t(k.ENTER_HERE)}
                        size="large"
                        labelContent={t(k.DESCRIPTION)}
                        disabled={isSaving}
                        testId={`custom-list--description--${customListId}`}
                        onChange={handleChange}
                        helperText={
                            <Textfield.Error>
                                {t(k[errors.Description as keyof typeof k])}
                            </Textfield.Error>
                        }
                        multiline={true}
                        minRows={3}
                    />

                    {customListId === undefined && (
                        <SharedPropertiesForm
                            isCustomList
                            disabled={isSaving}
                            excludeCustomListParentId={null}
                            selectedProperties={null}
                            errors={errors}
                            isAtLeastOnePropertyRequiredInvalid={
                                propertiesRequired
                            }
                            isAtLeastOnePropertyShouldBeVisibleInvalid={
                                isAtLeastOneVisibleInvalid
                            }
                            onChange={handleChangeProperties}
                        />
                    )}

                    {customListId && selectedProperties && (
                        <SharedPropertiesForm
                            isCustomList
                            disabled={isSaving}
                            excludeCustomListParentId={item.parentId}
                            selectedProperties={selectedProperties}
                            errors={errors}
                            isAtLeastOnePropertyRequiredInvalid={
                                propertiesRequired
                            }
                            isAtLeastOnePropertyShouldBeVisibleInvalid={
                                isAtLeastOneVisibleInvalid
                            }
                            onChange={handleChangeProperties}
                        />
                    )}
                </div>
            </ModalContent.Body>

            <ModalContent.Footer>
                <ButtonGroup className="custom-list-form--footer">
                    <Button
                        variant="danger"
                        transparent
                        onClick={handleCancel}
                        disabled={isSaving}
                    >
                        {t(k.CANCEL)}
                    </Button>
                    <ButtonGroup>
                        {createNew === false && (
                            <React.Fragment>
                                {listItem?.isArchived ? (
                                    <React.Fragment>
                                        <Button
                                            variant="warning"
                                            onClick={handleArchive}
                                            disabled={isSaving}
                                        >
                                            {t(k.UN_ARCHIVE)}
                                        </Button>

                                        <Button
                                            variant="danger"
                                            onClick={handleDelete}
                                            disabled={isSaving}
                                        >
                                            {t(k.DELETE)}
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Button
                                        variant="gray"
                                        transparent
                                        onClick={handleArchive}
                                        disabled={isSaving}
                                    >
                                        {t(k.ARCHIVE)}
                                    </Button>
                                )}
                            </React.Fragment>
                        )}

                        <Button
                            variant="success"
                            onClick={handleSave}
                            disabled={isSaveDisabled}
                            isBusy={isSaving}
                            testId="customListSaveBtn"
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ButtonGroup>
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default CustomListForm;
