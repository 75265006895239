import { useMemo } from "react";

import axios from "axios";

import { HistoryOwnerEnum } from "./HistoryOwnerEnum";
import IHistoryDTO from "./IHistoryDTO";
import IHistoryRequest from "./IHistoryRequest";
import { useQuery } from "@tanstack/react-query";
import {
    useManualDataSourceValuesByKpiId,
    useTargetValuesByKpiId,
} from "common/components/modal-manager-content/kpi-form/api/hooks";
import {
    convertToDate,
    getMonthAndYearAsTranslationKeysArray,
} from "common/utils/time";
import IDictionary from "common/viewModels/IDictionary";
import {
    useStepInstancesActivitiesByChecklistId,
    useStepInstancesByChecklistId,
} from "components/checklists/api/hooks";
import {
    useStepInstancesActivitiesByImprovementId,
    useStepInstancesByImprovementId,
} from "components/improvements/api/hooks";

const historyUrl = `/api/history`;
const GET_HISTORY_BY_ID = "GET_HISTORY_BY_ID";

export const useHistoryById = (
    id?: string,
    type?: HistoryOwnerEnum,
    showAll?: boolean,
) => {
    return useQuery({
        queryKey: [GET_HISTORY_BY_ID, id],

        queryFn: async (context) => {
            const url = `${historyUrl}/list-by-id`;

            const request: IHistoryRequest = {
                ownerId: id,
                ownerType: type ?? HistoryOwnerEnum.None,
                showAll,
            };

            const response = await axios.post<IHistoryDTO[]>(url, request, {
                signal: context.signal,
            });
            for (const item in response.data) {
                response.data[item].createdAt = convertToDate(
                    response.data[item].createdAt,
                );
            }
            return response.data;
        },

        enabled: true,

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useStepInstanceNames = (
    id?: string,
    type?: HistoryOwnerEnum,
    showAll?: boolean,
): IDictionary<string> => {
    if (type === HistoryOwnerEnum.Checklist) {
        const { data, isLoading } = useStepInstancesByChecklistId(
            false,
            showAll ?? false,
            id,
        );

        const stepInstanceNames = useMemo(() => {
            const result: IDictionary<string> = {};
            if (data) {
                for (const key in data.values) {
                    const value = data.values[key];

                    if (value) {
                        result[key] = value.name;
                    }
                }
            }
            return result;
        }, [data]);

        return isLoading ? {} : stepInstanceNames;
    } else if (type === HistoryOwnerEnum.Improvement) {
        const { data, isLoading } = useStepInstancesByImprovementId(
            false,
            showAll ?? false,
            id,
        );

        const stepInstanceNames = useMemo(() => {
            const result: IDictionary<string> = {};
            if (data) {
                for (const key in data.values) {
                    const value = data.values[key];

                    if (value) {
                        result[key] = value.name;
                    }
                }
            }
            return result;
        }, [data]);
        return isLoading ? {} : stepInstanceNames;
    }
    return {};
};

export const useActivityNames = (
    id?: string,
    type?: HistoryOwnerEnum,
    showAll?: boolean,
): {
    aisNames: IDictionary<string>;
    aiNames: IDictionary<string>;
    aiSetIdsByAiIds: IDictionary<string>;
} => {
    if (type === HistoryOwnerEnum.Checklist) {
        const { data, isLoading } = useStepInstancesActivitiesByChecklistId(
            false,
            showAll ?? false,
            id,
        );

        const names = useMemo(() => {
            const result: [
                IDictionary<string>,
                IDictionary<string>,
                IDictionary<string>,
            ] = [{}, {}, {}];
            if (data) {
                for (const key in data.activityInstanceSets) {
                    result[0][key] =
                        data.activityInstanceSets.values?.[key]?.title ?? "";
                }
                for (const key in data.activityInstances) {
                    result[1][key] = data.activityInstances[key].label;
                    result[2][key] =
                        data.activityInstances[key].activityInstanceSetId;
                }
            }
            return result;
        }, [data]);

        return isLoading
            ? { aisNames: {}, aiNames: {}, aiSetIdsByAiIds: {} }
            : {
                  aisNames: names[0],
                  aiNames: names[1],
                  aiSetIdsByAiIds: names[2],
              };
    } else if (type === HistoryOwnerEnum.Improvement) {
        const { data, isLoading } = useStepInstancesActivitiesByImprovementId(
            false,
            showAll ?? false,
            id,
        );

        const names = useMemo(() => {
            const result: [
                IDictionary<string>,
                IDictionary<string>,
                IDictionary<string>,
            ] = [{}, {}, {}];

            if (data) {
                for (const key in data.activityInstanceSets) {
                    result[0][key] =
                        data.activityInstanceSets.values?.[key]?.title ?? "";
                }

                for (const key in data.activityInstances) {
                    result[1][key] = data.activityInstances[key].label;
                    result[2][key] =
                        data.activityInstances[key].activityInstanceSetId;
                }
            }

            return result;
        }, [data]);

        return isLoading
            ? { aisNames: {}, aiNames: {}, aiSetIdsByAiIds: {} }
            : {
                  aisNames: names[0],
                  aiNames: names[1],
                  aiSetIdsByAiIds: names[2],
              };
    }
    return { aisNames: {}, aiNames: {}, aiSetIdsByAiIds: {} };
};

export const useKpiTargetValuesByKpiId = (
    id: string,
    enabled?: boolean,
): {
    targetValues: IDictionary<string[]>;
    isLoading: boolean;
} => {
    if (enabled) {
        const { data, isLoading } = useTargetValuesByKpiId(id);

        const names = useMemo(() => {
            const result: IDictionary<string[]> = {};

            if (data) {
                for (var key in data) {
                    var monthAndYear = getMonthAndYearAsTranslationKeysArray(
                        convertToDate(data?.[key]?.date),
                    );
                    if (monthAndYear) {
                        result[key] = [...monthAndYear];
                    }
                }
            }

            return result;
        }, [data]);

        return { targetValues: names, isLoading };
    } else {
        return { targetValues: {}, isLoading: false };
    }
};

export const useKpiManualDataSourceValuesByKpiId = (
    id: string,
    enabled?: boolean,
): {
    manualValues: IDictionary<string[]>;
    isLoading: boolean;
} => {
    if (enabled) {
        const { data, isLoading } = useManualDataSourceValuesByKpiId(id);

        const names = useMemo(() => {
            const result: IDictionary<string[]> = {};

            if (data) {
                for (var key in data) {
                    var monthAndYear = getMonthAndYearAsTranslationKeysArray(
                        convertToDate(data?.[key]?.date),
                    );
                    if (monthAndYear) {
                        result[key] = [...monthAndYear];
                    }
                }
            }

            return result;
        }, [data]);

        return { manualValues: names, isLoading };
    } else {
        return { manualValues: {}, isLoading: false };
    }
};
