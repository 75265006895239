import { Action, Reducer, UnknownAction } from "redux";

export function reduceReducers<S, A extends Action = UnknownAction>(
    ...reducers: Array<Reducer<S, A>>
) {
    return (prevState: S, value: A) => {
        return reducers.reduce(
            (newState, reducer) => reducer(newState, value),
            prevState,
        );
    };
}
