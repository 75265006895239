import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import DarkBadge, { IDarkBadgeProps } from "common/components/badges/base-badges/badge-dark/DarkBadge";

interface IResolvedFullyBadgeProps extends IDarkBadgeProps {
    label?: string;
}

const ResolvedFullyBadge = (props: IResolvedFullyBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();
    
    return <DarkBadge {...rest}>{label ?? t(k.RESOLVED_FULLY)}</DarkBadge>;
};

export default ResolvedFullyBadge;
