import axios from "axios";

import ICustomerContactFilter from "./ICustomerContactFilter";
import ICustomerContactRowDTO from "./ICustomerContactRowDTO";
import ICustomerFilter from "./ICustomerFilter";
import ICustomerRowDTO from "./ICustomerRowDTO";
import { ICustomerWithContactsRowDTO } from "./ICustomerWithContactsRowDTO";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { ITableList } from "http/ITableList";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const baseUrl = "/api/customer";
const contactsUrl = `${baseUrl}/contacts`;

const customerKeys = {
    all: ["customers"] as const,
    customerList: (filter?: ICustomerFilter) =>
        [...customerKeys.all, "list", filter] as const,
    customerOptions: () => [...customerKeys.all, "options"] as const,
    customerById: (id?: string) =>
        [...customerKeys.customerList(), "byId", id] as const,
    customerContacts: () => [...customerKeys.all, "contacts"] as const,
    customerContactsList: (filter?: ICustomerContactFilter) =>
        [...customerKeys.customerContacts(), "list", filter] as const,
    customerContactById: (id?: string) =>
        [...customerKeys.customerContacts(), "byId", id] as const,
};
export const useCustomerList = (filter?: ICustomerFilter) => {
    const url = `${baseUrl}/list`;

    return useQuery({
        queryKey: customerKeys.customerList(filter),
        queryFn: async () => {
            const response = await axios.post<ITableList<ICustomerRowDTO>>(
                url,
                filter,
            );

            return response.data;
        },
        enabled: filter !== undefined,
    });
};

export const useCustomerOptions = () => {
    const url = `${baseUrl}/options`;

    return useQuery({
        queryKey: customerKeys.customerOptions(),
        queryFn: async (context) => {
            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
    });
};

export const useCustomerById = (id?: string) => {
    const url = `${baseUrl}/${id}`;
    return useQuery({
        queryKey: customerKeys.customerById(id),
        queryFn: async (context) => {
            const response = await axios.get<ICustomerRowDTO>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        enabled: id !== undefined && id !== "",
    });
};

export const useCustomerContactById = (id?: string) => {
    const url = `${contactsUrl}/${id}`;

    return useQuery({
        queryKey: customerKeys.customerContactById(id),
        queryFn: async (context) => {
            const response = await axios.get<ICustomerContactRowDTO>(url, {
                signal: context.signal,
            });
            return response.data;
        },
        enabled: id !== undefined && id !== "",
    });
};

export const useArchiveCustomerMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        onSuccess: (variables) => {
            queryClient.invalidateQueries({
                queryKey: customerKeys.customerList(),
            });
        },
    });
};

export const useArchiveCustomerContactMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive-contact`,
                variables.request,
            ),

        onSuccess: (variables) => {
            queryClient.invalidateQueries({
                queryKey: customerKeys.customerContacts(),
            });
        },
    });
};

export const useDeleteCustomerMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { id: string }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.id}`);

                return true;
            } catch (ex) {}

            return false;
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: customerKeys.customerById(variables.id),
            });
        },
    });
};

export const useDeleteCustomerContactMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { id: string }) => {
            try {
                await axios.delete(`${contactsUrl}/${variables.id}`);

                return true;
            } catch (ex) {}

            return false;
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: customerKeys.customerContacts(),
            });
        },
    });
};

export const useSaveCustomerMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: ICustomerRowDTO;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(baseUrl, value);

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: customerKeys.customerById(variables.value.id),
            });
        },
    });
};

export const useSaveCustomerContactMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: ICustomerContactRowDTO;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(
                        `${baseUrl}/contacts`,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/contacts`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.refetchQueries({
                queryKey: customerKeys.customerContacts(),
            });
        },
    });
};
export const useCustomerContactsList = (filter?: ICustomerContactFilter) => {
    const url = `${baseUrl}/contacts-list`;

    return useQuery({
        queryKey: customerKeys.customerContactsList(filter),
        queryFn: async (context) => {
            const response = await axios.post<
                ITableList<ICustomerContactRowDTO>
            >(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },
        enabled: filter !== undefined,
    });
};

export async function importCustomersMutation(
    data: ICustomerWithContactsRowDTO[],
) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
