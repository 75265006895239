import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IGradingBadgeBaseProps } from "../IGradingBadgeBaseProps";
import ScoreBadge from "common/components/badges/base-badges/badge-score/ScoreBadge";

type INoGradeBadgeProps = IGradingBadgeBaseProps;

const NoGradeBadge = (props: INoGradeBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <ScoreBadge label={label ?? (props.short ? 0 : t(k.NONE))} {...rest} />
    );
};

export default NoGradeBadge;
