import "./GoalForm.scss";

import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SortEnd } from "react-sortable-hoc";
import { toast } from "react-toastify";

import { LocaleId } from "AppLocale";
import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { EGoalGrading } from "../api/EGoalGrading";
import { IGoalDTO } from "../api/IGoalDTO";
import {
    IClientGoalPageFilter,
    IServerGoalPageFilter,
} from "../api/IGoalPageFilters";
import { IGoalRequest } from "../api/IGoalRequest";
import { IParentGoalDTO } from "../api/IParentGoalDTO";
import {
    useArchiveGoalMutation,
    useChangeGoalStatusCommentMutation,
    useChangeGoalStatusMutation,
    useDeleteGoalMutation,
    useGoalAccessRole,
    useGoalById,
    useGoalTableSet,
    useGoalViewAsAccessRole,
    useSaveGoalMutation,
    useSortGoalsMutation,
    useSortMeasurementsMutation,
} from "../api/hooks";
import {
    decomposeGoalAccessRole,
    filterGoalsByStatus,
    filterMeasurementsByStatus,
    sortGoalsOptionByIndex,
} from "../utils/GoalTools";
import InfoCard from "./InfoCard";
import { SortableGoalsTable } from "./SortableGoalTable/SortableGoalsTable";
import { IValueLabelItem } from "common/IValueLabelItem";
import ActivityInstanceCard from "common/components/activity-instance-card/ActivityInstanceCard";
import { ButtonGroup } from "common/components/buttons";
import Button from "common/components/buttons/Button";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import FormattedDueDate from "common/components/formattedDate/FormattedDueDate";
import GoalStatusComment from "common/components/goal-status-comment/GoalStatusComment";
import { RequiredStarIcon } from "common/components/icons";
import ImpactGrading from "common/components/impact-gradings/ImpactGrading";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import GoalStatusSelectionRow from "common/components/input-components/goal-status-selection-row/GoalStatusSelectionRow";
import ModalContent from "common/components/modal-content/ModalContent";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IActiveTransactionItem,
    IActiveTransactionsPayload,
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import ParentGoalList from "common/components/parent-goal-list/ParentGoalList";
import ProgressBarPercentValue from "common/components/progress-bar/ProgressBarPercentValue";
import SelectUsersAvatarListEditable from "common/components/select-users/SelectUsersAvatarListEditable";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import TeamsListEditable from "common/components/team-list/TeamsListEditable";
import { arrayMoveTo } from "common/utils/arrayUtils";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import UserMultiselect from "components/common/multiselect-dropdowns/UserMultiselect";
import { getBeforeAndAfterItemIds } from "components/common/sortableLists/utility/utils";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";
import ValidationWrapper from "components/common/validation/ValidationWrapper";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import HistoryButton from "components/history/components/HistoryButton";
import {
    ImpactGradingEnum,
    getImpactGradingOptions,
} from "components/impact-grading-page/api/ImpactGradingEnum";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import ImpactGradingCard from "components/impact-grading-page/components/inputs/ImpactGradingCard";
import MeasurementFormLocal from "components/measurement/MeasurementFormLocal";
import { IMeasurementDTO } from "components/measurement/api/IMeasurementDTO";
import { IMeasurementRequest } from "components/measurement/api/IMeasurementRequest";
import { useMeasurementTableSetByIds } from "components/measurement/api/hooks";
import MeasurementsSortableList from "components/measurement/components/MeasurementsSortableList";
import { TaskOriginType } from "components/tasks-page/api/TaskOriginType";
import { usePublishedUserOptions } from "components/users/api/hooks";
import ITableSet from "http/ITableSet";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { ActivityType } from "models/enums/ActivityType";
import { ETransactionStatus } from "models/enums/TransactionStatus";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    id?: string;
    parentId?: string;

    haveChanges: boolean;
    serverFilters?: IServerGoalPageFilter;
    clientFilters?: IClientGoalPageFilter;

    createNew?: boolean;

    syncData: boolean;
    isSubGoal?: boolean;
    edit?: boolean;

    onSave?: (value: string) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
    onUpdateModal: (id: string) => void;

    onUpdateActiveTransactions?: (payload: IActiveTransactionsPayload) => void;
}

const GoalForm = (props: IProps) => {
    const {
        createNew,
        edit,
        haveChanges,
        syncData,
        serverFilters,
        clientFilters,
    } = props;

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const modalManagerContext = useContext(ModalManagerContext);

    const [editMode, setEditMode] = useState(createNew ?? edit ?? false);
    const [errors, setErrors] = useState<IValidationMessages>({});
    const [activeTransactions, setActiveTransactions] = useState<
        IDictionary<IActiveTransactionItem>
    >({});

    const statusCommentRef = useRef<
        HTMLInputElement | HTMLTextAreaElement | null
    >(null);
    const [shouldStatusCommentFocus, setShouldStatusCommentFocus] =
        useState(false);
    const [removedGoalsIds, setRemovedGoalsIds] = useState<string[]>([]);

    const { data: impactAreas, isLoading: impactAreasLoading } =
        useImpactGradingOptions(
            syncData ? (i18n.language as LocaleId) : undefined,
        );

    const saveMutation = useSaveGoalMutation();
    const archiveMutation = useArchiveGoalMutation();
    const deleteMutation = useDeleteGoalMutation();
    const sortMeasurementsMutation = useSortMeasurementsMutation();
    const sortSubGoalsMutation = useSortGoalsMutation(true);
    const changeStatusMutation = useChangeGoalStatusMutation();
    const changeStatusCommentMutation = useChangeGoalStatusCommentMutation();

    const canAccessGoals = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR ||
                roles.GOALS_OWNER ||
                roles.GOALS_EDITOR ||
                roles.GOALS_USER) ??
            false
        );
    });

    const _canCreate = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR || roles.GOALS_OWNER || roles.GOALS_EDITOR) ??
            false
        );
    });

    const _canDelete = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return roles.ADMINISTRATOR || roles.GOALS_OWNER;
    });

    const { data: goalAccessRole, isLoading: isLoadingGoalAccessRole } =
        useGoalAccessRole(!createNew && canAccessGoals ? props.id : undefined);

    const goalAccessPermissions = decomposeGoalAccessRole(goalAccessRole);

    const {
        data: goalViewAsAccessRole,
        isLoading: isLoadingGoalViewAsAccessRole,
    } = useGoalViewAsAccessRole(
        !createNew && canAccessGoals ? props.id : undefined,
        serverFilters,
    );

    const goalViewAsAccessPermissions =
        decomposeGoalAccessRole(goalViewAsAccessRole);

    const isFilterEmpty =
        serverFilters === undefined ||
        !Object.values(serverFilters).some((value) => value !== undefined) ||
        goalViewAsAccessRole === undefined;

    const canAccess = !isFilterEmpty
        ? goalAccessPermissions.canView && goalViewAsAccessPermissions.canView
        : _canCreate || goalAccessPermissions.canView;
    const canEdit = !isFilterEmpty
        ? goalAccessPermissions.canEdit && goalViewAsAccessPermissions.canEdit
        : _canCreate || goalAccessPermissions.canEdit;
    const canEditInPreview = !isFilterEmpty
        ? goalAccessPermissions.canEditInPreview &&
          goalViewAsAccessPermissions.canEditInPreview
        : _canCreate || goalAccessPermissions.canEditInPreview;
    const canCreate = !isFilterEmpty
        ? _canCreate && goalViewAsAccessPermissions.canCreate
        : _canCreate;
    const canDelete = !isFilterEmpty
        ? _canDelete && goalViewAsAccessPermissions.canEverything
        : _canDelete;

    const impactGradingsFeature = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.impactGradings === true
        );
    });

    const {
        data: goalData,
        isLoading: goalLoading,
        isError: isGoalByIdResponseError,
    } = useGoalById(syncData && !createNew && canAccess ? props.id : undefined);

    const { data: allUsers, isLoading: isLoadingUsers } =
        usePublishedUserOptions();

    const impactList = useMemo(
        () => getImpactGradingOptions(i18n),
        [i18n.language],
    );

    const [goal, setGoal] = useState<IGoalDTO>({
        id: createNew ? uuidv4() : "noData",
        name: "",
        responsibleUsersIds: [],
        status: EGoalGrading.OnTrack,
        impactGradings: {},
        involvedTeamsIds: [],
        measurementsIds: [],
        statusComment: "",
        subGoalsIds: [],
    });

    const responsibleUsersData = allUsers?.options?.filter((x) =>
        goal?.responsibleUsersIds?.includes(x.value ?? ""),
    );

    const isSubGoal =
        props.isSubGoal ||
        (goal.parentGoalsIds?.length && goal.parentGoalsIds?.length > 0);

    const { data: goalsTableSet, isLoading: isLoadingTableSet } =
        useGoalTableSet(
            {
                enabled: canAccessGoals && goal.id !== "noData",
                syncData,
            },
            {
                viewAs: serverFilters?.viewAs,
            },
        );
    const {
        data: measurmentData,
        isLoading: loadingMeasurement,
        refetch: refetchMeasurementData,
    } = useMeasurementTableSetByIds(
        {
            measurementsIds: goal.measurementsIds,
            viewAs: serverFilters?.viewAs,
        },
        syncData && canAccess && !!goal.measurementsIds?.length && !createNew,
    );

    const [showLocalMeasurement, setShowLocalMeasurement] = useState<string>();

    const [localMeasurements, setLocalMeasurements] = useState<
        ITableSet<IMeasurementDTO>
    >({ ids: [], values: {} });

    useEffect(() => {
        if (goalData) {
            if (editMode) {
                if (
                    JSON.stringify(goalData.measurementsIds) !==
                    JSON.stringify(goal.measurementsIds)
                ) {
                    setGoal((prev) => {
                        prev.measurementsIds = prev.measurementsIds?.filter(
                            (item) => goalData.measurementsIds?.includes(item),
                        );

                        goalData.measurementsIds?.forEach((item) => {
                            if (!prev.measurementsIds?.includes(item)) {
                                prev.measurementsIds?.push(item);
                            }
                        });

                        return prev;
                    });

                    refetchMeasurementData();
                }
            } else {
                setGoal(goalData);
            }
        }
    }, [goalData, editMode, isGoalByIdResponseError]);

    const [filteredGoalsTableSet, setFilteredGoalsTableSet] = useState<
        ITableSetWithOptions<IGoalDTO, string>
    >(
        goalsTableSet ?? {
            options: [],
            ids: [],
            values: {},
        },
    );

    const filteredSubGoalsIds = goal.subGoalsIds?.filter(
        (x) => filteredGoalsTableSet.values[x],
    );

    useEffect(() => {
        if (goalsTableSet) {
            const { filteredGoals } = filterGoalsByStatus(
                goalsTableSet,
                clientFilters?.statuses,
            );

            setFilteredGoalsTableSet(filteredGoals);
        }
    }, [goalsTableSet, clientFilters]);

    const filteredMeasurementData = useMemo(() => {
        if (createNew) {
            return localMeasurements;
        }

        const { filteredMeasurements } = filterMeasurementsByStatus(
            {
                ids: goal.measurementsIds ?? [],
                values: measurmentData?.values ?? {},
            },
            clientFilters?.statuses,
        );
        return filteredMeasurements;
    }, [measurmentData, localMeasurements, goal.measurementsIds]);

    useEffect(() => {
        if (shouldStatusCommentFocus && statusCommentRef.current) {
            statusCommentRef.current.focus();
            setShouldStatusCommentFocus(false);
        }
    }, [shouldStatusCommentFocus, statusCommentRef]);

    useEffect(() => {
        const isLoading = Object.values(activeTransactions).some(
            (x) => x.status === ETransactionStatus.Pending,
        );

        const isError = Object.values(activeTransactions).some(
            (x) => x.status === ETransactionStatus.Error,
        );

        const isSuccess = Object.values(activeTransactions).every(
            (x) => x.status === ETransactionStatus.Success,
        );

        if (isLoading) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Pending,
            });
        } else if (isError) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Error,
                error: { modalTitle: goal.name },
            });
        } else if (isSuccess) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Success,
            });
        }
    }, [activeTransactions]);

    const handleShowHistory = () => {
        const payload: IShowModalPayload = {
            id: goal.id,
            type: ModalTypes.history,
            callerAddress: location.pathname,

            options: {
                historyOwnerType: HistoryOwnerEnum.Goal,
            },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleSaveSubGoal = (subGoalId: string) => {
        setGoal((prev) => ({
            ...prev,
            subGoalsIds: prev.subGoalsIds
                ? [...prev.subGoalsIds, subGoalId]
                : [subGoalId],
        }));
        if (createNew) {
            props.onHaveChanges(true);
        }
    };

    const handleCreateSubGoal = () => {
        const payload: IShowModalPayload = {
            type: ModalTypes.goal_form,
            callerAddress: location.pathname,
            modalFor: { type: ModalTypes.goal_form, id: goal.id },
            options: {
                createNew: true,
                parentId: createNew ? undefined : goal.id,
            },
            callbacks: { onSave: handleSaveSubGoal },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleViewSubGoal = (id: string, parentId?: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.goal_form,
            callerAddress: location.pathname,
            options: {
                goalServerFilter: serverFilters,
                goalClientFilter: clientFilters,
            },
        };

        if (!createNew) {
            payload.options = {
                ...payload.options,
                parentId: parentId ?? goal.id,
            };
        }

        modalManagerContext.onShowModal(payload);
    };

    const handleViewParentGoal = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.goal_form,
            callerAddress: location.pathname,
            options: { goalServerFilter: serverFilters },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnRemoveSubGoal = (id: string) => {
        setRemovedGoalsIds((prev) => [...prev, id]);
        props.onHaveChanges(true);
    };

    const handleSaveMeasurement = (itemId: string) => {
        setGoal((prev) => ({
            ...prev,
            saveDraft: true,
            measurementsIds: prev.measurementsIds
                ? [...prev.measurementsIds, itemId]
                : [itemId],
        }));
    };

    const handleOnSortMeasurements = async (params: SortEnd) => {
        const { oldIndex, newIndex } = params;

        if (newIndex === oldIndex) return;

        if (createNew) {
            const sortedIds = arrayMoveTo(
                localMeasurements.ids,
                oldIndex,
                newIndex,
            );

            setLocalMeasurements((prev) => ({ ...prev, ids: sortedIds }));

            props.onHaveChanges(true);
        } else if (editMode && filteredMeasurementData.ids) {
            const sortedIds = arrayMoveTo(
                filteredMeasurementData.ids,
                oldIndex,
                newIndex,
            );

            setGoal((prev) => ({ ...prev, measurementsIds: sortedIds }));

            props.onHaveChanges(true);
        } else if (filteredMeasurementData.ids) {
            const sortedIds = arrayMoveTo(
                filteredMeasurementData.ids,
                oldIndex,
                newIndex,
            );

            setGoal((prev) => ({ ...prev, measurementsIds: sortedIds }));

            setActiveTransactions((prev) => ({
                ...prev,
                sortMeasurements: { status: ETransactionStatus.Pending },
            }));

            const request = await sortMeasurementsMutation.mutateAsync({
                request: {
                    parentId: goal.id,
                    sortedIds,
                },
            });

            setActiveTransactions((prev) => ({
                ...prev,
                sortMeasurements: request.Succeeded
                    ? { status: ETransactionStatus.Success }
                    : { status: ETransactionStatus.Error },
            }));

            if (request.Invalid) {
                setGoal((prev) => ({
                    ...prev,
                    measurementsIds: filteredMeasurementData.ids,
                }));
            }
        }
    };

    const handleOnSortSubGoals = async (params: SortEnd, listId?: string) => {
        const { oldIndex, newIndex } = params;

        if (newIndex === oldIndex) return;

        if (editMode && filteredSubGoalsIds) {
            const sortedIds = arrayMoveTo(
                filteredSubGoalsIds ?? [],
                oldIndex,
                newIndex,
            );

            setGoal((prev) => ({ ...prev, subGoalsIds: sortedIds }));

            props.onHaveChanges(true);
        } else if (filteredSubGoalsIds) {
            setActiveTransactions((prev) => ({
                ...prev,
                sortSubGoals: { status: ETransactionStatus.Pending },
            }));

            const sortedIds = arrayMoveTo(
                filteredSubGoalsIds,
                oldIndex,
                newIndex,
            );

            setGoal((prev) => ({
                ...prev,
                subGoalsIds: sortedIds,
            }));

            const request = await sortSubGoalsMutation.mutateAsync({
                request: {
                    parentId: goal.id,
                    sortedIds,
                },
            });

            setActiveTransactions((prev) => ({
                ...prev,
                sortSubGoals: request.Succeeded
                    ? { status: ETransactionStatus.Success }
                    : { status: ETransactionStatus.Error },
            }));

            if (request.Invalid) {
                setGoal((prev) => ({
                    ...prev,
                    subGoalsIds: filteredSubGoalsIds,
                }));
            }
        }
    };

    const handleAddMeasurement = () => {
        if (createNew) {
            setShowLocalMeasurement("createNew");
        } else {
            const payload: IShowModalPayload = {
                type: ModalTypes.measurement_form,
                callerAddress: location.pathname,
                options: {
                    createNew: true,
                    parentId: createNew ? undefined : goal.id,
                    goalServerFilter: serverFilters,
                },
                callbacks: { onSave: handleSaveMeasurement },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    const handleViewMeasurement = (id: string, localMeasurement?: boolean) => {
        if (localMeasurement) {
            setShowLocalMeasurement(id);
        } else {
            const payload: IShowModalPayload = {
                id,
                type: ModalTypes.measurement_form,
                callerAddress: location.pathname,
                options: {
                    parentId: createNew ? undefined : goal.id,
                    goalServerFilter: serverFilters,
                },
            };

            modalManagerContext.onShowModal(payload);
        }
    };

    const handleOnSaveLocalMeasurement = (value: IMeasurementDTO) => {
        setShowLocalMeasurement(undefined);

        setLocalMeasurements((prev) => ({
            ids: prev.ids.some((id) => id == value.id)
                ? prev.ids
                : [...prev.ids, value.id],
            values: { ...prev.values, [value.id]: value },
        }));

        props.onHaveChanges(true);
    };

    const handleOnDeleteLocalMeasurement = (id: string) => {
        setLocalMeasurements((prev) => ({
            ids: prev.ids.filter((x) => x !== id),
            values: {
                ...prev.values,
                [id]: undefined,
            },
        }));

        setShowLocalMeasurement(undefined);

        props.onHaveChanges(true);
    };

    const handleOnCloseLocalMeasurement = () => {
        setShowLocalMeasurement(undefined);
    };

    const handleArchive = async () => {
        if (goal.id) {
            const isArchive = goal.archivedAt ? false : true;

            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: !isArchive ? "blue" : "danger",
                yesLabel: !isArchive ? t(k.UN_ARCHIVE) : t(k.ARCHIVE),
                title: !isArchive
                    ? t(k.UNARCHIVE_THIS_GOAL)
                    : t(k.ARCHIVE_THIS_GOAL),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                setActiveTransactions((prev) => ({
                    ...prev,
                    archiveGoal: { status: ETransactionStatus.Pending },
                }));

                const request = await archiveMutation.mutateAsync({
                    id: goal.id,
                    isArchive: !goal.archivedAt,
                });

                setActiveTransactions((prev) => ({
                    ...prev,
                    archiveGoal: request.Succeeded
                        ? { status: ETransactionStatus.Success }
                        : { status: ETransactionStatus.Error },
                }));
            }
        }
    };

    const handleSave = async () => {
        const measurementRequests: IMeasurementRequest[] =
            localMeasurements.ids.reduce((acc, id) => {
                const measurement = localMeasurements.values[id];

                if (measurement) {
                    const request: IMeasurementRequest = {
                        ...measurement,
                    };
                    acc.push(request);
                }
                return acc;
            }, [] as IMeasurementRequest[]);

        let request: IGoalRequest = {
            ...goal,
            removedGoalsIds,
            parentGoalId: props.parentId,
            localMeasurementsRequests: createNew
                ? measurementRequests
                : undefined,
        };

        setActiveTransactions((prev) => ({
            ...prev,
            saveGoal: { status: ETransactionStatus.Pending },
        }));

        const result = await saveMutation.mutateAsync({
            isCreate: !!createNew,
            value: request,
        });

        setActiveTransactions((prev) => ({
            ...prev,
            saveGoal: result.Succeeded
                ? { status: ETransactionStatus.Success }
                : { status: ETransactionStatus.Error },
        }));

        if (result.Succeeded) {
            const goalId = result.Data;

            props.onHaveChanges(false);
            setErrors({});

            setEditMode(false);

            if (goalId) {
                props.onSave?.(goalId);

                if (createNew) {
                    props.onUpdateModal(goalId);
                }
            }
        } else if (result.Invalid) {
            setErrors(result.Messages ?? {});
            scrollToTopErrorById(getIdsFromValidationMessages(result.Messages));
        }
    };

    const handleDelete = async () => {
        if (goal.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
                title: t(k.DELETE_THIS_GOAL),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                setActiveTransactions((prev) => ({
                    ...prev,
                    deleteGoal: { status: ETransactionStatus.Pending },
                }));
                const result = await deleteMutation.mutateAsync(goal.id);

                setActiveTransactions((prev) => ({
                    ...prev,
                    deleteGoal: result.Succeeded
                        ? { status: ETransactionStatus.Success }
                        : { status: ETransactionStatus.Error },
                }));

                if (result.Succeeded) {
                    toast.success(t(k.GOAL_DELETED), {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        autoClose: 2000,
                    });

                    props.onClose(true);
                }
            }
        }
    };

    const handleOnChangeImpactItem = (e: IInputChangeEvent<string>) => {
        setGoal((prev) => ({
            ...prev,
            impact: parseInt(e.value) as ImpactGradingEnum,
        }));
    };

    const handleOnChangeStatus = async (status: EGoalGrading) => {
        const oldGoal = goal;
        setGoal((prev) => ({
            ...prev,
            status: status,
            statusComment: "",
        }));

        if (status === EGoalGrading.AtRisk || status === EGoalGrading.Blocked) {
            setShouldStatusCommentFocus(true);
        }

        if (editMode) {
            props.onHaveChanges(true);
        } else {
            setActiveTransactions((prev) => ({
                ...prev,
                statusGoal: { status: ETransactionStatus.Pending },
            }));
            const result = await changeStatusMutation.mutateAsync({
                goalId: goal.id,
                status,
                statusComment: "",
            });

            setActiveTransactions((prev) => ({
                ...prev,
                statusGoal: result.Succeeded
                    ? { status: ETransactionStatus.Success }
                    : { status: ETransactionStatus.Error },
            }));

            if (result.Invalid) {
                setGoal(oldGoal);
            }
        }
    };

    const handleOnChangeUsers = (userIds?: string[]) => {
        setGoal((prev) => ({
            ...prev,
            responsibleUsersIds: userIds,
        }));

        props.onHaveChanges(true);
    };

    const handleOnChangeUsersCanView = (userId: string) => {
        setGoal((prev) => ({
            ...prev,
            usersIdsCanView: prev.usersIdsCanView?.includes(userId)
                ? prev.usersIdsCanView?.filter((x) => x !== userId)
                : [...(prev.usersIdsCanView ?? []), userId],
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeInvolvedTeams = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setGoal((prev) => ({
                ...prev,
                isTeamEveryone: !remove,
            }));
        } else {
            setGoal((prev) => ({
                ...prev,
                involvedTeamsIds: remove
                    ? prev.involvedTeamsIds?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.involvedTeamsIds ?? []), team.value],
            }));
        }
        props.onHaveChanges(true);
    };

    const handleOnChangeTeamsCanView = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setGoal((prev) => ({
                ...prev,
                isTeamEveryoneCanView: !remove,
            }));
        } else {
            setGoal((prev) => ({
                ...prev,
                teamsIdsCanView: remove
                    ? prev.teamsIdsCanView?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.teamsIdsCanView ?? []), team.value],
            }));
        }
        props.onHaveChanges(true);
    };

    const handleOnChangeName = (
        e: IInputActionChangeEvent<string | undefined>,
    ) => {
        setGoal((prev) => ({
            ...prev,
            name: e.value ?? "",
        }));
        props.onHaveChanges(true);
    };

    const handleAddExistingGoal = (id: string) => {
        if (!goal.subGoalsIds?.includes(id)) {
            setGoal((prev) => ({
                ...prev,
                subGoalsIds: prev.subGoalsIds
                    ? [...prev.subGoalsIds, id]
                    : [id],
            }));
        } else {
            setGoal((prev) => ({
                ...prev,
                subGoalsIds: prev.subGoalsIds?.filter((sgId) => sgId !== id),
            }));

            if (
                goalData?.subGoalsIds?.includes(id) &&
                !removedGoalsIds?.includes(id)
            ) {
                setRemovedGoalsIds((prev) => [...prev, id]);
            }
        }
        props.onHaveChanges(true);
    };

    const handleOnChangeDescription = (
        e: IInputChangeEvent<string | undefined>,
    ) => {
        setGoal((prev) => ({
            ...prev,
            description: e.value ?? "",
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeStatusComment = (
        e: IInputActionChangeEvent<string | undefined>,
    ) => {
        setGoal((prev) => ({
            ...prev,
            statusComment: e.value ?? "",
        }));

        if (editMode) {
            props.onHaveChanges(true);
        }
    };

    const handleSaveStatusComment = async () => {
        setActiveTransactions((prev) => ({
            ...prev,
            statusCommentGoal: { status: ETransactionStatus.Pending },
        }));
        const result = await changeStatusCommentMutation.mutateAsync({
            goalId: goal.id,
            statusComment: goal.statusComment ?? "",
        });

        setActiveTransactions((prev) => ({
            ...prev,
            statusCommentGoal: result.Succeeded
                ? { status: ETransactionStatus.Success }
                : { status: ETransactionStatus.Error },
        }));
    };

    const handleOnChangeStartDate = (
        e: IInputChangeEvent<Date | undefined>,
    ) => {
        setGoal((prev) => ({
            ...prev,
            startDate: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeEndDate = (e: IInputChangeEvent<Date | undefined>) => {
        setGoal((prev) => ({
            ...prev,
            endDate: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeImpact = (
        list: IDictionary<ImpactGradingEnum>,
        impactMax: ImpactGradingEnum,
    ) => {
        setGoal((prev) => ({
            ...prev,
            impactGradings: list,
        }));
        props.onHaveChanges(true);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleOnClose = () => {
        props.onHaveChanges(false);
        props.onClose();
    };

    const handleOnCancel = async () => {
        if (haveChanges) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE_UNSAVED_CHANGES)}</>,
                yesButtonVariant: "danger",
                title: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                if (goalData) {
                    setGoal(goalData);
                }

                setEditMode(false);

                props.onHaveChanges(false);
            }
        } else {
            setEditMode(false);
        }
    };

    const useDefaultImpact =
        !impactGradingsFeature ||
        (impactAreasLoading === false &&
            impactAreas &&
            impactAreas.length === 0);

    if (isLoadingGoalAccessRole || isLoadingGoalViewAsAccessRole) {
        return <AnimatedSpinner isVisible aligned="center" position="center" />;
    }

    if ((!createNew && !canAccess) || (createNew && !canCreate)) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(k.NO_AVAILABLE_GOALS_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }

    const showParentGoalsList =
        filteredGoalsTableSet &&
        responsibleUsersData &&
        goal.parentGoalsIds &&
        goal.parentGoalsIds.length > 0 &&
        goal.parentGoalsIds.some(
            (id) => filteredGoalsTableSet.values[id] !== undefined,
        );

    const showInvolvedTeamEditableList =
        (goal.involvedTeamsIds && goal.involvedTeamsIds.length > 0) ||
        goal.isTeamEveryone ||
        editMode;

    const showTeamsAndUsersEditableListCanView =
        (goal.usersIdsCanView && goal.usersIdsCanView.length > 0) ||
        (goal.teamsIdsCanView && goal.teamsIdsCanView.length > 0) ||
        goal.isTeamEveryoneCanView ||
        editMode;

    const showDescriptionCard =
        editMode || goal.description || goal.startDate || goal.endDate;

    const showSortableGoalsTable =
        (goal.subGoalsIds && goal.subGoalsIds.length > 0) || canCreate;

    const showSortableMeasurementsList =
        (measurmentData && measurmentData.ids.length > 0) ||
        localMeasurements.ids.length > 0 ||
        canCreate;

    return (
        <>
            {showLocalMeasurement && (
                <MeasurementFormLocal
                    canEdit={canEdit}
                    allUsers={allUsers?.options}
                    data={localMeasurements.values[showLocalMeasurement]}
                    parentGoal={{
                        id: goal.id,
                        name: goal.name,
                        responsibleUsersIds: goal.responsibleUsersIds ?? [],
                    }}
                    onClose={handleOnCloseLocalMeasurement}
                    onSave={handleOnSaveLocalMeasurement}
                    onDelete={handleOnDeleteLocalMeasurement}
                />
            )}
            <ModalContent maxWidth="xl">
                <ModalContent.Header
                    title={
                        !editMode
                            ? isSubGoal
                                ? t(k.VIEW_SUB_GOAL)
                                : t(k.VIEW_GOAL)
                            : createNew
                              ? isSubGoal
                                  ? t(k.NEW_SUB_GOAL)
                                  : t(k.NEW_GOAL)
                              : isSubGoal
                                ? t(k.EDIT_SUB_GOAL)
                                : t(k.EDIT_GOAL)
                    }
                    onClose={handleOnClose}
                    actions={
                        <div className="goal-form--header-actions">
                            {!createNew && goal.id !== "noData" && (
                                <>
                                    <div
                                        className="last-modified"
                                        onClick={handleShowHistory}
                                    >
                                        {t(k.LAST_MODIFIED)}:{" "}
                                        <FormattedDate
                                            date={goal.modifiedAt}
                                            showMonthName
                                            shortMonthName
                                        />{" "}
                                        {t(k.BY_)}{" "}
                                        {goal.modifiedByName ??
                                            goal.createdByName}
                                    </div>

                                    <HistoryButton
                                        id={goal.id}
                                        type={HistoryOwnerEnum.Goal}
                                    />
                                </>
                            )}
                        </div>
                    }
                />
                <ModalContent.Body className="goal-form--body">
                    {!createNew && (goalLoading || goal.id === "noData") ? (
                        <AnimatedSpinner
                            isVisible
                            aligned="center"
                            position="center"
                        />
                    ) : (
                        <>
                            <div className="measurement-form--body--first-row">
                                <div
                                    className={`goal-form--body--first-row--name ${
                                        !showParentGoalsList
                                            ? "no-parent-goals"
                                            : ""
                                    }`}
                                >
                                    <ActionTextField
                                        id={`goal.${goal.id}.name`}
                                        label={editMode ? t(k.NAME) : null}
                                        required
                                        multiline
                                        minRows={1}
                                        editMode={editMode}
                                        editable={editMode && canEditInPreview}
                                        boldLabel
                                        variant={
                                            !editMode ? "title" : undefined
                                        }
                                        noPadding={!editMode}
                                        inputSize="large"
                                        value={goal.name ?? ""}
                                        onChange={handleOnChangeName}
                                        testId="goal-form--name"
                                        placeholder={t(k.ENTER_HERE)}
                                        error={getLocaleString(
                                            t,
                                            errors?.[`goal.${goal.id}.name`],
                                        )}
                                        invalid={
                                            !!errors?.[`goal.${goal.id}.name`]
                                        }
                                    />

                                    {!editMode ? (
                                        <div className="status-responsible-row">
                                            {responsibleUsersData &&
                                            responsibleUsersData.length > 0 ? (
                                                <SelectedUsersAvatarList
                                                    maxShownAvatars={5}
                                                    size={24}
                                                    users={responsibleUsersData}
                                                />
                                            ) : (
                                                t(
                                                    k.NO_USERS_ASSIGNED_TO_THIS_GOAL,
                                                )
                                            )}
                                            <GoalStatusSelectionRow
                                                disabled={
                                                    !!goal.archivedAt ||
                                                    !canEditInPreview
                                                }
                                                status={
                                                    goal.status ??
                                                    EGoalGrading.OnTrack
                                                }
                                                hideDraft={!canEdit}
                                                onClick={handleOnChangeStatus}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <UserMultiselect
                                                className="responsible"
                                                label={t(k.RESPONSIBLE)}
                                                defaultValues={
                                                    goal.responsibleUsersIds ??
                                                    []
                                                }
                                                onChange={handleOnChangeUsers}
                                            />
                                            <InputWrapper
                                                wrapperLabel={t(k.STATUS)}
                                                boldLabel
                                            >
                                                <GoalStatusSelectionRow
                                                    status={
                                                        goal.status ??
                                                        EGoalGrading.OnTrack
                                                    }
                                                    onClick={
                                                        handleOnChangeStatus
                                                    }
                                                />
                                            </InputWrapper>
                                        </>
                                    )}

                                    <GoalStatusComment
                                        inputRef={statusCommentRef}
                                        status={goal.status}
                                        comment={goal.statusComment}
                                        editMode={editMode}
                                        editable={canEditInPreview}
                                        canEdit={
                                            canAccessGoals && !goal.archivedAt
                                        }
                                        onChangeStatusComment={
                                            handleOnChangeStatusComment
                                        }
                                        onSaveStatusComment={
                                            handleSaveStatusComment
                                        }
                                    />
                                </div>
                                {showParentGoalsList && (
                                    <ParentGoalList
                                        parentGoals={
                                            goal.parentGoalsIds?.reduce(
                                                (acc, id) => {
                                                    const _goal =
                                                        filteredGoalsTableSet
                                                            .values[id];
                                                    if (_goal) {
                                                        acc.push({
                                                            id: id,
                                                            name: _goal.name,
                                                            responsibleUsersIds:
                                                                _goal.responsibleUsersIds ??
                                                                [],
                                                        });
                                                    }
                                                    return acc;
                                                },
                                                [] as IParentGoalDTO[],
                                            ) ?? []
                                        }
                                        onClick={handleViewParentGoal}
                                    />
                                )}
                            </div>
                            <div className="goal-form--body--progress-row">
                                {!editMode && (
                                    <>
                                        <div className="progress-column">
                                            <InputWrapper
                                                wrapperLabel={t(k.PROGRESS)}
                                                boldLabel
                                            >
                                                <ProgressBarPercentValue
                                                    now={goal?.progress}
                                                    max={1}
                                                />
                                            </InputWrapper>
                                            <FormattedDueDate
                                                date={goal.endDate}
                                            />
                                        </div>
                                        <InputWrapper
                                            wrapperLabel={t(k.IMPACT)}
                                            boldLabel
                                        >
                                            <ImpactGrading
                                                impactList={goal.impactGradings}
                                            />
                                        </InputWrapper>
                                    </>
                                )}
                                <InputWrapper
                                    wrapperLabel={t(k.INVOLVED_TEAMS)}
                                    boldLabel
                                    fullWidth
                                >
                                    {showInvolvedTeamEditableList ? (
                                        <TeamsListEditable
                                            editMode={editMode}
                                            icon={null}
                                            clearable
                                            teamIds={goal.involvedTeamsIds}
                                            isTeamEveryone={goal.isTeamEveryone}
                                            onChange={
                                                handleOnChangeInvolvedTeams
                                            }
                                        />
                                    ) : (
                                        t(k.NO_ONE_ASSIGNED)
                                    )}
                                </InputWrapper>
                                <InputWrapper
                                    wrapperLabel={t(k.CAN_VIEW)}
                                    boldLabel
                                    fullWidth
                                >
                                    {showTeamsAndUsersEditableListCanView ? (
                                        <div className="goal-form--body--progress-row--item vertical">
                                            <TeamsListEditable
                                                editMode={editMode}
                                                icon={null}
                                                clearable
                                                teamIds={goal.teamsIdsCanView}
                                                isTeamEveryone={
                                                    goal.isTeamEveryoneCanView
                                                }
                                                onChange={
                                                    handleOnChangeTeamsCanView
                                                }
                                            />
                                            <SelectUsersAvatarListEditable
                                                maxShownAvatars={10}
                                                selectedUserIds={
                                                    goal.usersIdsCanView
                                                }
                                                editMode={editMode}
                                                onChangeUser={
                                                    handleOnChangeUsersCanView
                                                }
                                                allUsers={allUsers?.options}
                                            />
                                        </div>
                                    ) : (
                                        t(k.NO_ONE_ASSIGNED)
                                    )}
                                </InputWrapper>
                            </div>
                            {useDefaultImpact ? (
                                <>
                                    {editMode && (
                                        <div className="impact-grading">
                                            <div className="impact-grading-card--preview">
                                                <h6 className="activity-instance--title">
                                                    <strong>
                                                        {t(k.IMPACT)}
                                                    </strong>
                                                    <RequiredStarIcon />
                                                </h6>
                                                <ActivityInstanceCard
                                                    type={
                                                        ActivityType.Selection
                                                    }
                                                    id={`goal.${goal.id}.impactGradings`}
                                                    value={String(goal.impact)}
                                                    options={impactList}
                                                    label={t(k.IMPACT)}
                                                    completed={
                                                        goal.impact !==
                                                        ImpactGradingEnum.None
                                                    }
                                                    required
                                                    onChange={
                                                        handleOnChangeImpactItem
                                                    }
                                                    originType={
                                                        TaskOriginType.Goal
                                                    }
                                                    invalid={
                                                        !!errors?.[
                                                            `goal.${goal.id}.impactGradings`
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {editMode && (
                                        <InfoCard
                                            label={t(k.IMPACT)}
                                            className="impact-grading--edit"
                                        >
                                            {impactAreasLoading ? (
                                                <AnimatedSpinner
                                                    isVisible
                                                    aligned="center"
                                                    position="center"
                                                />
                                            ) : (
                                                <>
                                                    <ValidationWrapper
                                                        errors={getLocaleString(
                                                            t,
                                                            errors?.[
                                                                `goal.${goal.id}.impactGradings`
                                                            ],
                                                        )}
                                                    >
                                                        <ImpactGradingCard
                                                            impactId={`goal.${goal.id}.impactGradings`}
                                                            disableBorder
                                                            invalid={
                                                                !!errors?.[
                                                                    `goal.${goal.id}.impactGradings`
                                                                ]
                                                            }
                                                            required
                                                            preview={!editMode}
                                                            disabled={
                                                                !canEdit &&
                                                                !canCreate
                                                            }
                                                            impactList={
                                                                goal.impactGradings ??
                                                                null
                                                            }
                                                            impactAreas={
                                                                impactAreas ??
                                                                []
                                                            }
                                                            onChange={
                                                                handleOnChangeImpact
                                                            }
                                                        />
                                                    </ValidationWrapper>
                                                </>
                                            )}
                                        </InfoCard>
                                    )}
                                </>
                            )}

                            {showDescriptionCard && (
                                <InfoCard
                                    label={t(k.DESCRIPTION)}
                                    childrenClassName="goal-form--body--description"
                                >
                                    <div className="timeframe">
                                        {editMode ? (
                                            <>
                                                <InputComponent
                                                    id={`goal.${goal.id}.startDate`}
                                                    testId="goal_startDate_input"
                                                    inputType={
                                                        EActivityType.DateTime
                                                    }
                                                    wrapperLabel={t(k.START)}
                                                    value={goal.startDate}
                                                    name="startDate"
                                                    showTimeSelect={false}
                                                    placeholder={t(k.START)}
                                                    hideIcon
                                                    noMinWidth
                                                    shouldCloseOnSelect
                                                    onChange={
                                                        handleOnChangeStartDate
                                                    }
                                                    invalid={
                                                        !!errors?.[
                                                            `goal.${goal.id}.startDate`
                                                        ]
                                                    }
                                                    errors={getLocaleString(
                                                        t,
                                                        errors?.[
                                                            `goal.${goal.id}.startDate`
                                                        ],
                                                    )}
                                                />

                                                <InputComponent
                                                    testId="goal_endDate_input"
                                                    inputType={
                                                        EActivityType.DateTime
                                                    }
                                                    value={goal.endDate}
                                                    name="endDate"
                                                    showTimeSelect={false}
                                                    wrapperLabel={t(k.DUE)}
                                                    placeholder={t(k.DUE)}
                                                    hideIcon
                                                    noMinWidth
                                                    shouldCloseOnSelect
                                                    onChange={
                                                        handleOnChangeEndDate
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {goal.startDate && (
                                                    <div className="timeframe--unit">
                                                        <div className="timeframe--unit--label">
                                                            {t(k.START)}:{" "}
                                                        </div>
                                                        <FormattedDate
                                                            onlyDate
                                                            showMonthName
                                                            shortMonthName
                                                            date={
                                                                goal.startDate
                                                            }
                                                        />{" "}
                                                    </div>
                                                )}
                                                {goal.endDate && (
                                                    <div className="timeframe--unit">
                                                        <div className="timeframe--unit--label">
                                                            {t(k.END)}:{" "}
                                                        </div>
                                                        <FormattedDate
                                                            onlyDate
                                                            showMonthName
                                                            shortMonthName
                                                            date={goal.endDate}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <InputComponent
                                        inputType={EActivityType.Textfield}
                                        boldLabel
                                        hideIcon
                                        preview={!editMode}
                                        value={
                                            !editMode
                                                ? (goal.description ??
                                                  t(k.NO_DESCRIPTION_PROVIDED))
                                                : (goal.description ?? "")
                                        }
                                        onChange={handleOnChangeDescription}
                                        multiline
                                        minRows={5}
                                        testId="goal-form--description"
                                        placeholder={t(k.ENTER_HERE)}
                                    />
                                </InfoCard>
                            )}
                            {showSortableMeasurementsList && (
                                <MeasurementsSortableList
                                    editMode={editMode}
                                    canCreate={canCreate}
                                    disabled={
                                        !!goal.archivedAt || !canEditInPreview
                                    }
                                    isLoading={loadingMeasurement}
                                    measurements={filteredMeasurementData}
                                    onAddMeasurement={handleAddMeasurement}
                                    onSorted={handleOnSortMeasurements}
                                    onEditMeasurement={handleViewMeasurement}
                                />
                            )}

                            {showSortableGoalsTable && (
                                <SortableGoalsTable
                                    goal={goal}
                                    editMode={editMode}
                                    canCreate={canCreate}
                                    disabled={
                                        !!goal.archivedAt || !canEditInPreview
                                    }
                                    createNew={createNew}
                                    syncData={
                                        syncData &&
                                        canCreate &&
                                        goal.id !== "noData"
                                    }
                                    goalsTableSet={filteredGoalsTableSet}
                                    isLoadingTableSet={isLoadingTableSet}
                                    subGoalsIds={filteredSubGoalsIds}
                                    onCreateSubGoal={handleCreateSubGoal}
                                    onSorted={handleOnSortSubGoals}
                                    onViewGoal={handleViewSubGoal}
                                    onAddExistingGoal={handleAddExistingGoal}
                                    onRemoveSubGoal={handleOnRemoveSubGoal}
                                />
                            )}
                        </>
                    )}
                </ModalContent.Body>
                <ModalContent.Footer className="goal-form--footer">
                    {editMode ? (
                        <>
                            {createNew ? (
                                <Button
                                    onClick={handleOnClose}
                                    variant="danger"
                                    transparent
                                >
                                    {t(k.CLOSE)}
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleOnCancel}
                                    variant="danger"
                                    transparent
                                >
                                    {t(k.CANCEL)}
                                </Button>
                            )}

                            <ButtonGroup className="buttons">
                                <Button
                                    onClick={handleSave}
                                    variant="success"
                                    isBusy={saveMutation.isPending}
                                    disabled={saveMutation.isPending}
                                >
                                    {t(k.SAVE)}
                                </Button>
                            </ButtonGroup>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={handleOnClose}
                                variant="danger"
                                transparent
                            >
                                {t(k.CLOSE)}
                            </Button>
                            <ButtonGroup className="buttons">
                                {!createNew && canEdit && (
                                    <Button
                                        onClick={handleArchive}
                                        variant="bordered"
                                        transparent
                                    >
                                        {goal.archivedAt
                                            ? t(k.UN_ARCHIVE)
                                            : t(k.ARCHIVE)}
                                    </Button>
                                )}
                                {goal.archivedAt && canDelete && (
                                    <Button
                                        onClick={handleDelete}
                                        variant="danger"
                                    >
                                        {t(k.DELETE)}
                                    </Button>
                                )}
                                {!goal.archivedAt && canEdit && (
                                    <Button onClick={handleEdit}>
                                        {t(k.EDIT)}
                                    </Button>
                                )}
                            </ButtonGroup>
                        </>
                    )}
                </ModalContent.Footer>
            </ModalContent>
        </>
    );
};

export default GoalForm;
