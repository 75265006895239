import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IGradingBadgeBaseProps } from "../IGradingBadgeBaseProps";
import ScoreBadge from "common/components/badges/base-badges/badge-score/ScoreBadge";

type IModerateGradeBadgeProps = IGradingBadgeBaseProps;

const ModerateGradeBadge = (props: IModerateGradeBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <ScoreBadge
            score="md"
            label={label ?? (props.short ? 3 : t(k.IMPACT_SCORE3_LABEL))}
            {...rest}
        />
    );
};

export default ModerateGradeBadge;
