import "./KPITableCell.scss";

import React from "react";

import { formatDecimalWithLocale } from "AppLocale";

import TooltipMessage from "../tooltip-message/TooltipMessage";
import KpiProgressBar from "common/components/kpi-progress-bar/KpiProgressBar";
import { IKpiFormulaCalculatedValue } from "common/components/modal-manager-content/kpi-form/api/IKpiFormulaCalculations";
import { TableCell } from "common/components/table";
import Tooltip from "common/components/tooltip/Tooltip";

interface IProps {
    value?: IKpiFormulaCalculatedValue;
    kpiUnit?: string;
    decimalPlaces?: number;
    timeUnitKey: string;
    year: number;
    error?: string | null;
    overIsGood: boolean;
    overThreshold?: number;
    underThreshold?: number;
}

const KPITableCell = (props: IProps) => {
    const {
        year,
        timeUnitKey,
        value,
        overIsGood,
        kpiUnit,
        decimalPlaces,
        error,
    } = props;

    const overThreshold =
        value?.targetValue !== undefined && value?.targetValue !== null
            ? value.targetValue > 0
                ? props.overThreshold
                : props.underThreshold
            : undefined;
    const underThreshold =
        value?.targetValue !== undefined && value?.targetValue !== null
            ? value?.targetValue > 0
                ? props.underThreshold
                : props.overThreshold
            : undefined;

    return (
        <TableCell className="kpi-table-cell">
            <Tooltip
                id="kpi-table-cell-tooltip"
                message={
                    <TooltipMessage
                        year={year}
                        timeUnitKey={timeUnitKey}
                        target={
                            <FormatedValue
                                value={value?.targetValue}
                                kpiUnitName={kpiUnit}
                                decimalPlaces={decimalPlaces}
                            />
                        }
                        value={
                            error ?? (
                                <FormatedValue
                                    value={value?.calculatedValue}
                                    kpiUnitName={kpiUnit}
                                    decimalPlaces={decimalPlaces}
                                />
                            )
                        }
                    />
                }
            >
                <div className="kpi-table-cell--data">
                    <KpiProgressBar
                        invalid={!!error}
                        targetValue={value?.targetValue}
                        calculatedValue={value?.calculatedValue}
                        overThreshold={overThreshold}
                        underThreshold={underThreshold}
                        overIsGood={overIsGood}
                        decimalPlaces={decimalPlaces}
                    />
                </div>
            </Tooltip>
        </TableCell>
    );
};

KPITableCell.FirstCellWithLegend = (props: {
    year: number;
    kpiUnitName?: string;
}) => {
    const { year } = props;

    return (
        <TableCell className="kpi-table-cell kpi-table-cell__legend">
            <div className="legend__wrapper">
                <div className="year-cell">
                    <div className="year-cell--content">{year}</div>
                </div>
            </div>
        </TableCell>
    );
};

const FormatedValue = (props: {
    value?: number | null;
    kpiUnitName?: string;
    decimalPlaces?: number;
}) => {
    const { value, kpiUnitName, decimalPlaces } = props;

    const noDecimal =
        decimalPlaces === undefined || decimalPlaces < 0 || decimalPlaces > 20;

    const formattedValue =
        value !== undefined && value !== null
            ? formatDecimalWithLocale(parseFloat(value.toFixed(decimalPlaces)))
            : undefined;

    return (
        <React.Fragment>
            {noDecimal ? (value ?? "-") : (formattedValue ?? "-")}
            <KpiUnitNameLabel
                label={(value ?? "-") !== "-" ? kpiUnitName : undefined}
            />
        </React.Fragment>
    );
};

const KpiUnitNameLabel = (props: { label?: string; braces?: boolean }) => {
    const { label, braces } = props;

    return label ? (
        <span className="text--muted kpi-unit-title">
            {braces && "("}
            {label}
            {braces && ")"}
        </span>
    ) : null;
};

export default KPITableCell;
