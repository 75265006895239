import "./MeasurementCard.scss";

import { PropsWithRef } from "react";

import { useTranslation } from "react-i18next";
import { FaTasks } from "react-icons/fa";

import k from "i18n/keys";

import { EMeasurementGrading } from "../api/EMeasurementGrading";
import { IMeasurementDTO } from "../api/IMeasurementDTO";
import { getMeasurementProgress } from "../utils/MeasurementTools";
import MeasurementStatusBadge from "common/components/badges/measurement-status-badge/MeasurementStatusBagde";
import ArchivedBadge from "common/components/badges/simple-badges/badge-archived/ArchivedBadge";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import { Position } from "common/components/progress-bar/ProgressBar";
import ProgressBarPercentValue from "common/components/progress-bar/ProgressBarPercentValue";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import TeamsListById from "common/components/team-list/TeamsListById";
import TextHighlightWrapper from "common/components/text-highlight-wrapper/TextHighlightWrapper";
import IUserDTO from "components/users/api/IUserDTO";
import { ITableSetWithOptions } from "http/ITableSetWithOptions";

interface IProps {
    matchedSearchText?: string;
    measurement: IMeasurementDTO;
    usersData?: ITableSetWithOptions<IUserDTO, IUserDTO>;
    onClick: () => void;
}

const MeasurementCard = (props: PropsWithRef<IProps>) => {
    const { measurement, matchedSearchText } = props;

    const userData = measurement.responsibleUsersIds?.reduce<IUserDTO[]>(
        (acc, userId) => {
            const user = props.usersData?.values[userId];

            if (user) {
                acc.push(user);
            }

            return acc;
        },
        [],
    );

    const { t } = useTranslation();

    return (
        <div
            key={measurement.id}
            onClick={props.onClick}
            className="measurement-card"
        >
            <div className="measurement-card--left-content">
                <ProgressBarPercentValue
                    type="circle"
                    now={getMeasurementProgress(measurement) ?? 0}
                    contentPos={Position.Center}
                />

                <FormattedDate
                    onlyDate
                    showMonthName
                    shortMonthName
                    date={measurement.endDate}
                />
            </div>
            <div className="measurement-card--right-content">
                <div className="measurement-card--name">
                    <TextHighlightWrapper
                        text={measurement?.name}
                        textToReplace={matchedSearchText}
                    />
                </div>
                <div className="measurement-card--unit">
                    {measurement?.currentValue ?? measurement?.startValue ?? 0}{" "}
                    / {measurement?.endValue} {measurement.unit?.name}
                </div>
                <div className="measurement-card--status-users">
                    {userData && userData.length > 0 ? (
                        <SelectedUsersAvatarList
                            maxShownAvatars={2}
                            size={24}
                            users={userData}
                        />
                    ) : (
                        t(k.NO_USERS_ASSIGNED_TO_THIS_MEASUREMENT)
                    )}
                    {measurement.archivedAt ? (
                        <ArchivedBadge />
                    ) : (
                        <MeasurementStatusBadge
                            status={
                                measurement.status ??
                                EMeasurementGrading.OnTrack
                            }
                            hideOnTrack
                            iconTooltip={
                                measurement?.statusComment
                                    ? {
                                          id: measurement.id,
                                          message: measurement?.statusComment,
                                      }
                                    : undefined
                            }
                        />
                    )}
                </div>
                {measurement?.taskCount ? (
                    <div className="measurement-card--assigned-tasks">
                        <FaTasks /> {measurement?.taskCount}{" "}
                        {t(k.TASKS_ASSIGNED_TO_YOU)}
                    </div>
                ) : null}
                {((measurement.involvedTeamsIds &&
                    measurement.involvedTeamsIds.length > 0) ||
                    measurement.isTeamEveryone) && (
                    <TeamsListById
                        icon={null}
                        showTitle
                        haveWordWrap
                        fullWidth
                        teamIds={measurement.involvedTeamsIds}
                        isTeamEveryone={measurement.isTeamEveryone}
                    />
                )}
            </div>
        </div>
    );
};

export default MeasurementCard;
