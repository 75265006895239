import axios from "axios";

import IItemTypeRowDTO from "./IItemTypeRowDTO";
import { ItemCategoryType } from "./ItemCategoryType";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import ExecutionResult from "common/viewModels/ExecutionResult";

const baseUrl = "/api/itemtype";

const itemKeys = {
    all: ["item-list"] as const,
    published: ["published"] as const,
    publishedUnits: ["published-units"] as const,
    publishedTypes: ["published-types"] as const,
};

export const usePublishedItemTypeList = () => {
    const url = `${baseUrl}/published`;

    return useQuery({
        queryKey: itemKeys.published,
        queryFn: async () => {
            const response =
                await axios.get<
                    Array<IValueLabelItem<string, string, ItemCategoryType>>
                >(url);

            return response.data;
        },
    });
};

export const usePublishedUnitsList = () => {
    const url = `${baseUrl}/published-units`;

    return useQuery({
        queryKey: itemKeys.publishedUnits,

        queryFn: async () => {
            const response =
                await axios.get<
                    Array<IValueLabelItem<string, string, ItemCategoryType>>
                >(url);

            return response.data;
        },
    });
};
export const usePublishedTypesList = () => {
    const url = `${baseUrl}/published-types`;

    return useQuery({
        queryKey: itemKeys.publishedTypes,

        queryFn: async () => {
            const response =
                await axios.get<
                    Array<IValueLabelItem<string, string, ItemCategoryType>>
                >(url);

            return response.data;
        },
    });
};

export const useSaveItemMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IItemTypeRowDTO;
        }) => {
            try {
                let result: IItemTypeRowDTO;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<IItemTypeRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<IItemTypeRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<IItemTypeRowDTO>(result);
            } catch (error) {
                return ExecutionResult.Failed<IItemTypeRowDTO>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: itemKeys.all });
            queryClient.refetchQueries({ queryKey: itemKeys.publishedUnits });
        },
    });
};
