import { Reducer } from "redux";

import {
    PROCESS_CHART_REMOVE_NODE_BY_ID,
    PROCESS_CHART_SHOW_PUBLISHED_VIEW,
    ProcessChartActionTypes,
} from "./types";

export interface IProcessChartState {
    showPublished: boolean;
    removingNodeByIds: {
        [key: string]: boolean;
    };
}

const initialState: IProcessChartState = {
    showPublished: false,
    removingNodeByIds: {},
};

export const ProcessChartReducer = (
    state = initialState,
    action: ProcessChartActionTypes,
): IProcessChartState => {
    switch (action.type) {
        case PROCESS_CHART_SHOW_PUBLISHED_VIEW: {
            return {
                ...state,
                showPublished: action.payload,
            };
        }

        case PROCESS_CHART_REMOVE_NODE_BY_ID: {
            const removingNodeByIds = action.payload.reduce<
                typeof state.removingNodeByIds
            >((map, value) => {
                map[value] = true;

                return map;
            }, {});

            return { ...state, removingNodeByIds };
        }

        default:
            return state;
    }
};
