import "./LevelOneReportDetailsHeader.scss";

import React from "react";

import SeparateTablesCell from "../report-table/SeparateTablesCell";
import { TableCell } from "common/components/table";
import IChecklistReportTableMatrixResponse from "components/template-checklist-report/components/report-table-details/api/IChecklistReportResponse";

interface IProps {
    tableMatrix: IChecklistReportTableMatrixResponse;
}

export const LevelOneReportDetailsHeader = (props: IProps) => {
    const { stepInstanceIds, colSpanByStepInstanceId, labelByStepInstanceId } =
        props.tableMatrix.headerFirst;

    return (
        <React.Fragment>
            <SeparateTablesCell />

            {stepInstanceIds.map((id) => (
                <TableCell
                    key={id}
                    isHighlighted
                    colSpan={colSpanByStepInstanceId?.[id]}
                    className="top-level-header"
                >
                    {labelByStepInstanceId[id]}
                </TableCell>
            ))}
        </React.Fragment>
    );
};
