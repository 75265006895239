import "./UserForm.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { RiTeamLine } from "react-icons/ri";

import k from "i18n/keys";

import TeamRoleBadges from "./TeamRoleBadges";
import UserRoleBadges from "./UserRoleBadges";
import { IValueParamItem } from "common/IValueParamItem";
import TeamRoleIcon from "common/components/icons/icon-team-role/TeamRoleIcon";
import UserManagerIcon from "common/components/icons/icon-user-manager/UserManagerIcon";
import InputWrapper from "common/components/input-components/InputWrapper";
import PositionsList from "common/components/positions/PositionsList";
import TeamsListById from "common/components/team-list/TeamsListById";
import UserList from "components/process-chart/components/users/UserList";
import IUserRowDTO from "components/users/api/IUserRowDTO";

interface IProps {
    item: IUserRowDTO;
    userRoles: IValueParamItem<string, boolean>[];
    positionsFeatureEnabled?: boolean;
}

const UserForm: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { item, userRoles, positionsFeatureEnabled } = props;

    const { t } = useTranslation();

    return (
        <div className="user-details--body">
            <div className="user-details--body--user">
                <div className="user-details--body--user--name">
                    {item.userName}
                </div>
                <div className="user-details--body--user--email">
                    {item.email}
                </div>
            </div>
            <div className="user-details--body--details">
                <InputWrapper
                    boldLabel
                    iconInLine
                    wrapperLabel={t(k.BIO)}
                    wrapperIcon={<RiTeamLine />}
                >
                    {item.bio ? (
                        <span> {item.bio}</span>
                    ) : (
                        <span>{t(k.NO_BIO_AVAILABLE)}</span>
                    )}
                </InputWrapper>
                <InputWrapper
                    boldLabel
                    iconInLine
                    wrapperLabel={t(k.MANAGER)}
                    wrapperIcon={<UserManagerIcon />}
                >
                    {item.myManager ? (
                        <UserList userIds={[item.myManager]} size={33} />
                    ) : (
                        t(k.NONE_ASSIGNED)
                    )}
                </InputWrapper>

                {item.reportingUsers && item.reportingUsers.length > 0 && (
                    <InputWrapper
                        boldLabel
                        iconInLine
                        wrapperLabel={t(k.DIRECT_REPORTS)}
                        wrapperIcon={<UserManagerIcon />}
                    >
                        <UserList userIds={item.reportingUsers} size={33} />
                    </InputWrapper>
                )}

                <InputWrapper
                    boldLabel
                    iconInLine
                    wrapperLabel={t(k.USER_ROLES)}
                    wrapperIcon={<AiOutlineUserSwitch />}
                >
                    <UserRoleBadges roles={userRoles.map((x) => x.value)} />
                </InputWrapper>

                {item.teamRoles && (
                    <InputWrapper
                        boldLabel
                        iconInLine
                        wrapperLabel={t(k.TEAM_ROLES)}
                        wrapperIcon={<TeamRoleIcon />}
                    >
                        <TeamRoleBadges roles={item.teamRoles} />
                    </InputWrapper>
                )}

                <InputWrapper
                    boldLabel
                    iconInLine
                    wrapperLabel={t(k.TEAMS)}
                    wrapperIcon={<RiTeamLine />}
                >
                    <TeamsListById
                        isTeamEveryone
                        icon={null}
                        teamIds={item.teamIds}
                    />
                </InputWrapper>

                {positionsFeatureEnabled && (
                    <InputWrapper
                        boldLabel
                        iconInLine
                        wrapperLabel={t(k.POSITIONS)}
                        wrapperIcon={<RiTeamLine />}
                    >
                        <PositionsList
                            userPositions={item.userPositions}
                            canEdit={false}
                        />
                    </InputWrapper>
                )}
            </div>
        </div>
    );
};

export default UserForm;
