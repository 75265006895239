import { useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";

import { formatDecimalWithLocale } from "AppLocale";

import k from "i18n/keys";

import { MonthlyValue } from "../../api/MonthlyValue";
import {
    formulaNumberRegEx,
    validNumberRegEx,
} from "common/components/modal-manager-content/kpi-form/utility/FormulaTools";
import { TableCell, TableRow } from "common/components/table";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import { MonthsOrder } from "models/enums/Months";

interface IProps {
    ids: string[];
    values: Record<string, MonthlyValue | undefined>;

    disabled?: boolean;
    allowNegativeNumbers?: boolean;
    fillCellsBetweenValues?: boolean;
    onChange: (value: string, id?: string) => void;
}

const DataYearRow = (props: IProps) => {
    const {
        ids,
        values,
        allowNegativeNumbers,
        fillCellsBetweenValues = true,
        disabled,
    } = props;

    const { t } = useTranslation();

    const resetFunctionsRef = useRef<Map<string, (value?: string) => void>>(
        new Map(),
    );

    const handleResetDebounce = useCallback(
        (id: string, resetFunction: (value?: string) => void) => {
            resetFunctionsRef.current.set(id, resetFunction);
        },
        [],
    );

    const resetDebounceForId = (id: string, value?: string) => {
        const resetFn = resetFunctionsRef.current.get(id);
        if (resetFn) {
            resetFn(value);
        }
    };

    return (
        <TableRow>
            {ids.length !== MonthsOrder.length ? (
                <TableCell colSpan={MonthsOrder.length}>
                    {t(k.NOT_AVAILABLE_MESSAGE)}
                </TableCell>
            ) : (
                ids.map((id) => {
                    const item = values[id];

                    if (item) {
                        const value = item.quantityTarget ?? item.quantity;

                        const formattedValue =
                            value !== undefined
                                ? formatDecimalWithLocale(value)
                                : "";

                        const displayValue =
                            item.hasQuantityTarget || item.hasQuantity
                                ? formattedValue
                                : "";

                        const handleOnChange = (
                            e: IInputActionChangeEvent<string>,
                        ) => {
                            const { value: newValue, id } = e;

                            const validNumberOnlyMatch =
                                newValue.match(validNumberRegEx());

                            const onlyNumberMatch =
                                validNumberOnlyMatch &&
                                validNumberOnlyMatch.length === 1 &&
                                validNumberOnlyMatch[0];

                            if (onlyNumberMatch) {
                                props.onChange(onlyNumberMatch, id);
                            } else if (newValue.length === 0) {
                                props.onChange(newValue, id);
                            } else {
                                resetDebounceForId(item.id);
                            }
                        };

                        return (
                            <TableCell key={id}>
                                <ActionTextField
                                    withDebounce
                                    noMinWidth
                                    id={item.id}
                                    value={displayValue}
                                    placeholder={
                                        fillCellsBetweenValues &&
                                        !item.hasQuantityTarget
                                            ? formattedValue
                                            : undefined
                                    }
                                    disabled={disabled}
                                    validationRegEx={formulaNumberRegEx()}
                                    onChange={handleOnChange}
                                    onResetDebounce={(resetFn) =>
                                        handleResetDebounce(id, resetFn)
                                    }
                                />
                            </TableCell>
                        );
                    }
                })
            )}
        </TableRow>
    );
};

export default DataYearRow;
