import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";
import { DraftIcon } from "common/components/icons";
import Selection from "common/components/input-components/selection/Selection";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";

interface IMeasurementStatusSelectionRowProps {
    status: EMeasurementGrading;
    disabled?: boolean;
    hideDraft?: boolean;
    onClick?: (status: EMeasurementGrading) => void;
}

const MeasurementStatusSelectionRow: React.FC<
    React.PropsWithChildren<IMeasurementStatusSelectionRowProps>
> = (props) => {
    const { status, disabled, hideDraft } = props;

    const { t } = useTranslation();
    const options: Array<
        IValueLabelItem<
            EMeasurementGrading,
            string | JSX.Element,
            React.CSSProperties
        >
    > = [
        {
            label: t(k.ON_TRACK),
            value: EMeasurementGrading.OnTrack,
            param: { backgroundColor: "#396aff", color: "#fff" },
        },
        {
            label: t(k.AT_RISK),
            value: EMeasurementGrading.AtRisk,
            param: { backgroundColor: "#facc15", color: "#4b5563" },
        },
        {
            label: t(k.BLOCKED),
            value: EMeasurementGrading.Blocked,
            param: { backgroundColor: "#ff2929", color: "#fff" },
        },
        {
            label: t(k.COMPLETED),
            value: EMeasurementGrading.Completed,
            param: { backgroundColor: "#0db691", color: "#fff" },
        },
        ...(!hideDraft
            ? [
                  {
                      label: (
                          <>
                              <DraftIcon />
                              {t(k.DRAFT)}
                          </>
                      ),
                      value: EMeasurementGrading.Draft,
                      param: { backgroundColor: "#bfdbfe", color: "#4b5563" },
                  },
              ]
            : []),
    ];

    return (
        <Selection
            testId="measurement-status-selection"
            selectedValue={status}
            options={options}
            variant="horizontal"
            disabled={disabled}
            onSelect={props.onClick}
        />
    );
};

export default MeasurementStatusSelectionRow;
