import { FC, useContext } from "react";

import ChecklistDetailsContext from "../../../checklistContext";
import {
    Actions,
    DateRange,
    PausedBy,
    StartedBy,
    Title,
    Description,
} from "./components";
import ListedContent from "common/components/listed-content/ListedContent";
import WorksheetStatusBadge from "common/components/badges/worksheet-status-badge/WorksheetStatusBadge";

interface Props {}

const SidePanelDetails: FC<Props> = () => {
    const { checklist, selectedChecklistId } = useContext(
        ChecklistDetailsContext,
    );

    return (
        <ListedContent.SidePanel.Details
            context={ChecklistDetailsContext}
            description={selectedChecklistId && <Description />}
        >
            <Title />

            <DateRange />

            <div className="details-row">
                {checklist && (
                    <WorksheetStatusBadge
                        status={checklist.status}
                        endDate={checklist.endDate}
                        archivedAt={checklist.archivedAt}
                        stoppedAt={checklist.pausedAt}
                        completedAt={checklist.completedAt}
                    />
                )}

                <StartedBy />
            </div>

            <PausedBy />
            <Actions />
        </ListedContent.SidePanel.Details>
    );
};

export default SidePanelDetails;
