import "./EquipmentsList.scss";

import React, { useMemo } from "react";

import { EquipmentIcon } from "../icons";
import ValueWrapper from "../value-wrapper/ValueWrapper";
import { useEquipmentOptions } from "components/equipment/api/hooks";

interface IProps {
    equipmentIds: string[];
}

const EquipmentsList: React.FC<IProps> = (props) => {
    const { equipmentIds } = props;

    const { data: equipment } = useEquipmentOptions(equipmentIds.length === 0);

    const items = useMemo(() => {
        return equipment?.filter((x) => equipmentIds.includes(x.value));
    }, [equipment, equipmentIds]);

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <div className="equipments-list--wrapper">
            <EquipmentIcon />
            <div className="equipments-list--values">
                {equipmentIds.map((id) => {
                    const item = equipment?.find((x) => x.value === id);

                    if (item) {
                        return (
                            <ValueWrapper key={item.value}>
                                {item.label}
                            </ValueWrapper>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default EquipmentsList;
