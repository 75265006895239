import { Reducer } from "redux";

import {
    REQUEST_FINALIZE_STEP_INSTANCE,
    SET_LAST_FINALIZED_STEPINSTANCE_ID,
    StepInstanceActionTypes,
    TRY_FINALIZE_STEPINSTANCE_BY_ID,
} from "./types";

export interface IStepInstanceState {
    readonly lastFinalizedStepInstanceId?: string;

    readonly tryFinalizedStepInstanceId?: string;
}

const initialState: IStepInstanceState = {};

export const StepInstanceReducer = (
    state: IStepInstanceState = initialState,
    action: StepInstanceActionTypes,
): IStepInstanceState => {
    switch (action.type) {
        case TRY_FINALIZE_STEPINSTANCE_BY_ID: {
            return {
                ...state,
                tryFinalizedStepInstanceId: action.payload,
            };
        }

        case SET_LAST_FINALIZED_STEPINSTANCE_ID: {
            return {
                ...state,
                lastFinalizedStepInstanceId: action.payload,
            };
        }

        case REQUEST_FINALIZE_STEP_INSTANCE: {
            return {
                ...state,
                tryFinalizedStepInstanceId: undefined,
            };
        }

        default:
            return state;
    }
};
