import axios from "axios";

import ISubProcessDTO from "./ISubProcessDTO";
import ISubProcessFilter from "./ISubProcessFilter";
import ISubProcessRequest from "./ISubProcessRequest";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import IProcessChartNodeResult from "components/process-chart/api/IProcessChartNodeResult";
import convertUpdatedDate from "components/process-chart/api/convertUpdatedDate";
import ITableSet from "http/ITableSet";

const baseUrl = "/api/subprocess";

const QUERY_NAME_DRAFT = "subprocess-draft";
const QUERY_NAME_PUBLISHED = "subprocess-published";

async function fetchOptions(url: string, signal?: AbortSignal) {
    const response = await axios.get<ITableSet<string>>(url, {
        signal,
    });

    const options = response.data.ids.reduce<IValueLabelItem<string, string>[]>(
        (acc, id) => {
            const label = response.data.values[id];

            if (label) {
                const value: IValueLabelItem<string, string> = {
                    value: id,
                    label,
                };

                acc.push(value);
            }

            return acc;
        },
        [],
    );

    const result: ITableSet<string> & {
        options: IValueLabelItem<string, string>[];
    } = {
        ...response.data,
        options,
    };

    return result;
}

export const useSubProcessOptions = () => {
    const url = `${baseUrl}/options`;

    return useQuery({
        queryKey: ["subprocess-options"],
        queryFn: ({ signal }) => fetchOptions(url, signal),
    });
};

export const useProcessStepOptions = (enabled: boolean = true) => {
    const url = `${baseUrl}/process-step/options`;

    return useQuery({
        queryKey: ["process-step-options"],

        queryFn: ({ signal }) => fetchOptions(url, signal),

        enabled,
    });
};

async function getSubProcess(filter: ISubProcessFilter, signal?: AbortSignal) {
    const url = baseUrl;

    const response = await axios.get<IProcessChartNodeResult<ISubProcessDTO>>(
        url,
        {
            params: filter,
            signal,
        },
    );

    convertUpdatedDate(response.data.value);

    return response.data;
}

export const useSubProcess = (
    enabled: boolean,
    parentId: string,
    showDraft: boolean,
) => {
    const queryName = showDraft ? QUERY_NAME_DRAFT : QUERY_NAME_PUBLISHED;

    return useQuery({
        queryKey: [queryName, parentId],

        queryFn: ({ signal }) => {
            const filter: ISubProcessFilter = {
                parentId,
                showDraft,
            };

            return getSubProcess(filter, signal);
        },

        enabled,
    });
};

async function saveSubProcess(request: ISubProcessRequest) {
    const url = baseUrl;

    const response = await axios.post<IProcessChartNodeResult<ISubProcessDTO>>(
        url,
        request,
    );

    convertUpdatedDate(response.data.value);

    return response.data;
}

export const useMutateSubProcess = (parentId: string) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: saveSubProcess,
        // When mutate is called:
        onMutate: async (request) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({
                queryKey: [QUERY_NAME_DRAFT, parentId],
            });

            if (request.asDraft === false) {
                await queryClient.cancelQueries({
                    queryKey: [QUERY_NAME_PUBLISHED, parentId],
                });
            }

            // Snapshot the previous value
            const prevDraftValue = queryClient.getQueryData<
                IProcessChartNodeResult<ISubProcessDTO>
            >([QUERY_NAME_DRAFT, parentId]);

            const prevPublishedValue = queryClient.getQueryData<
                IProcessChartNodeResult<ISubProcessDTO>
            >([QUERY_NAME_PUBLISHED, parentId]);

            // Optimistically update to the new value
            queryClient.setQueryData<IProcessChartNodeResult<ISubProcessDTO>>(
                [QUERY_NAME_DRAFT, parentId],
                (prev) => ({
                    isDeleted: false,
                    isDeletedAsDraft: false,

                    ...prev,

                    value: {
                        ...request.value,
                    },
                }),
            );

            if (request.asDraft === false) {
                queryClient.setQueryData<
                    IProcessChartNodeResult<ISubProcessDTO>
                >([QUERY_NAME_PUBLISHED, parentId], (prev) => ({
                    isDeleted: false,
                    isDeletedAsDraft: false,

                    ...prev,

                    value: {
                        ...request.value,
                    },
                }));
            }

            // Return a context object with the snapshotted value
            return { prevDraftValue, prevPublishedValue };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (
            err,
            request,
            context:
                | {
                      prevDraftValue?: IProcessChartNodeResult<ISubProcessDTO>;
                      prevPublishedValue?: IProcessChartNodeResult<ISubProcessDTO>;
                  }
                | undefined,
        ) => {
            if (context?.prevDraftValue) {
                queryClient.setQueryData<
                    IProcessChartNodeResult<ISubProcessDTO>
                >([QUERY_NAME_DRAFT, parentId], context.prevDraftValue);
            }

            if (context?.prevPublishedValue) {
                if (request.asDraft === false) {
                    queryClient.setQueryData<
                        IProcessChartNodeResult<ISubProcessDTO>
                    >(
                        [QUERY_NAME_PUBLISHED, parentId],
                        context.prevPublishedValue,
                    );
                }
            }
        },
        onSuccess: (data, variables) => {
            if (variables.filter.showDraft) {
                queryClient.setQueryData<
                    IProcessChartNodeResult<ISubProcessDTO>
                >([QUERY_NAME_DRAFT, parentId], data);

                queryClient.invalidateQueries({
                    queryKey: [QUERY_NAME_PUBLISHED, parentId],
                });
            } else {
                queryClient.invalidateQueries({
                    queryKey: [QUERY_NAME_DRAFT, parentId],
                });

                queryClient.setQueryData<
                    IProcessChartNodeResult<ISubProcessDTO>
                >([QUERY_NAME_PUBLISHED, parentId], data);
            }
        },
    });
};
