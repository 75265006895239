import { PageNavigationActionTypes, RECEIVE_PAGE_CHANGE } from "./types";

export const onPageStateChange = (
    hasChanges?: boolean,
): PageNavigationActionTypes => {
    return {
        type: RECEIVE_PAGE_CHANGE,
        payload: hasChanges,
    };
};
