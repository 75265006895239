import "./TeamsListEditable.scss";

import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";

import k from "i18n/keys";

import DropdownButton from "../dropdown/DropdownButton";
import { IDropdownOption } from "../dropdown/api/IDropdownOption";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "../input-components/dropdown/api/hooks";
import TeamsList from "./TeamsList";
import { IValueLabelItem } from "common/IValueLabelItem";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    isTeamEveryone?: boolean;
    icon?: React.ReactNode | null;

    teamIds?: string[];

    editMode?: boolean;

    clearable?: boolean;

    onChange?: (
        team: IValueLabelItem<string, string>,
        remove?: boolean,
    ) => void;
}

const TEAM_EVERYONE = "teamEveryone";

const TeamsListEditable: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        isTeamEveryone,
        icon,
        editMode,
        teamIds,
        clearable = false,
    } = props;

    const { t, i18n } = useTranslation();

    const { data } = useSharedOptions(true, ESharedOptionsType.team, teamIds);

    const selectedTeams = useMemo(() => {
        return (
            teamIds?.reduce<IValueLabelItemWithState[]>((acc, id) => {
                const team = data?.find((x) => x.value === id);

                if (team) {
                    acc.push({
                        value: id,
                        label: team.label,
                        isArchived: team.isArchived ?? false,
                        isDeleted: team.isDeleted ?? false,
                    });
                }

                return acc;
            }, []) ?? []
        );
    }, [teamIds, isTeamEveryone, data]);

    const canRemoveItem =
        selectedTeams.length > 1 ||
        clearable ||
        (isTeamEveryone && selectedTeams.length > 0);

    const options = useMemo(() => {
        if (data) {
            let result: IDropdownOption[] = [
                {
                    value: TEAM_EVERYONE,
                    label: t(k.EVERYONE),
                    selected: isTeamEveryone,
                    disabled: isTeamEveryone && !canRemoveItem,
                },
            ];

            result = result.concat(
                data.reduce<IDropdownOption[]>((acc, x) => {
                    acc.push({
                        value: x.value,
                        label: x.label,
                        selected: teamIds?.includes(x.value),
                        disabled:
                            !isTeamEveryone &&
                            !canRemoveItem &&
                            teamIds?.includes(x.value),
                    });

                    return acc;
                }, []),
            );

            return result;
        }
    }, [teamIds, data, isTeamEveryone, canRemoveItem, i18n.language]);

    const onAdd = (id: string) => {
        const team = data?.find((x) => x.value === id);

        if (props.onChange) {
            if (team) {
                props.onChange(team);
            } else if (id === TEAM_EVERYONE) {
                props.onChange({ value: id, label: t(k.EVERYONE) });
            }
        }
    };

    const handleClickRemove = (id?: string) => {
        if (id) {
            onRemove(id);
        }
    };

    const onRemove = (id: string) => {
        const team = data?.find((x) => x.value === id);

        if (props.onChange) {
            if (team) {
                props.onChange(team, true);
            } else if (id === TEAM_EVERYONE) {
                props.onChange({ value: id, label: t(k.EVERYONE) }, true);
            }
        }
    };

    const onChange = (id: string) => {
        if (id === TEAM_EVERYONE) {
            isTeamEveryone ? onRemove(id) : onAdd(id);
        }

        const selectedTempTeam = selectedTeams?.find((x) => x.value === id);

        selectedTempTeam ? onRemove(id) : onAdd(id);
    };

    return (
        <div className="teams-list-editable__wrapper">
            <TeamsList
                teams={selectedTeams}
                icon={icon}
                isTeamEveryone={isTeamEveryone}
                onRemove={
                    editMode && canRemoveItem ? handleClickRemove : undefined
                }
            />
            {editMode && (
                <DropdownButton
                    buttonTestId="teamsListEditableDropdownButton"
                    options={options}
                    buttonVariant="badge"
                    icon={
                        <div className="teams-list-editable--add-icon">
                            <FiPlusCircle /> {t(k.ADD_TEAM)}
                        </div>
                    }
                    onChange={onChange}
                />
            )}
        </div>
    );
};

export default TeamsListEditable;
