import React from "react";

import i18n from "i18next";

import k from "i18n/keys";

import { IMenuOption } from "../buttons/menu-button/IMenuOption";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";

export enum MenuCommands {
    Preview = "preview",
    PreviewSupplier = "preview-supplier",
    PreviewSupplierContact = "preview-supplier-contact",
    PreviewDeviations = "preview-deviations",
    PreviewOpenDeviations = "preview-open-deviations",
    PreviewCompletedDeviations = "preview-completed-deviations",
    PreviewRecurrence = "preview-recurrence",
    Edit = "edit",
    Stop = "stop",
    Copy = "copy",
    UndoStop = "undo-stop",
    Archive = "archive",
    UndoArchive = "undo-archive",
    Delete = "delete",
    Execute = "execute",
    Details = "details",
    MakePrimary = "make-primary",
    BarcodeSearch = "barcode-search",
    PermissionSettings = "permission-settings",
    Reset = "reset",
    DeleteDraft = "delete-draft",
}

export interface IMenuRules {
    cannotDelete: boolean;
    canChangeStatus: boolean;
}

export const getBaseMenuActions = (
    isArchived: boolean,
    deleteDisabled?: boolean,
): IMenuOption[] => {
    return [
        {
            id: MenuCommands.Edit,
            label: i18n.t(k.EDIT),
            testId: "menu-button-edit",
        },

        {
            id: MenuCommands.Archive,
            label: i18n.t(k.ARCHIVE),
            disabled: isArchived,
            testId: "menu-button-archive",
        },

        {
            id: MenuCommands.UndoArchive,
            label: i18n.t(k.UN_ARCHIVE),
            disabled: !isArchived,
            testId: "menu-button-undo-archive",
        },

        {
            id: MenuCommands.Delete,
            label: i18n.t(k.DELETE),
            disabled: !isArchived || deleteDisabled,
            testId: "menu-button-delete",
        },
    ];
};

export function getChecklistDetailsMenuActions(
    state: ChecklistDynamicStatus,
    canArchive: boolean,
    canDelete: boolean,
    editMode?: boolean,
): IMenuOption[] {
    const isArchived = state === ChecklistDynamicStatus.Archived;
    const isStopped = state === ChecklistDynamicStatus.Stopped;

    return [
        {
            id: MenuCommands.Archive,
            label: i18n.t(k.ARCHIVE),
            disabled: isArchived || !isStopped || !canArchive || editMode,
        },

        {
            id: MenuCommands.UndoArchive,
            label: i18n.t(k.UN_ARCHIVE),
            disabled: !isArchived || !canDelete || editMode,
        },

        {
            id: MenuCommands.Delete,
            label: i18n.t(k.DELETE),
            disabled: !isArchived || !canDelete || editMode,
        },
    ];
}

export function getEquipmentMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getTeamMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getChecklistMenuActions(
    rules: IMenuRules,
    status: ChecklistDynamicStatus,
): IMenuOption[] {
    const { cannotDelete, canChangeStatus } = rules;

    const statusOptionsDisabled = !canChangeStatus;

    const isStopped = status === ChecklistDynamicStatus.Stopped;
    const isArchived = status === ChecklistDynamicStatus.Archived;

    return [
        {
            id: MenuCommands.Edit,
            label: i18n.t(k.EDIT_CHECKLIST),
        },
        {
            id: MenuCommands.Stop,
            label: i18n.t(k.STOP),
            disabled: isStopped || isArchived || statusOptionsDisabled,
        },
        {
            id: MenuCommands.UndoStop,
            label: i18n.t(k.RESUME),
            disabled: !isStopped || statusOptionsDisabled,
        },
        {
            id: MenuCommands.Archive,
            label: i18n.t(k.ARCHIVE),
            disabled: !isStopped || isArchived || statusOptionsDisabled,
        },
        {
            id: MenuCommands.UndoArchive,
            label: i18n.t(k.UN_ARCHIVE),
            disabled: !isArchived || statusOptionsDisabled,
        },
        {
            id: MenuCommands.Delete,
            label: i18n.t(k.DELETE),
            disabled: !isArchived || cannotDelete || statusOptionsDisabled,
        },
    ];
}

export function getImprovementMenuActions(
    isArchived?: boolean,
    isDraft?: boolean,
): IMenuOption[] {
    return [
        {
            id: MenuCommands.Edit,
            label: i18n.t(k.EDIT),
        },

        {
            id: MenuCommands.Copy,
            label: i18n.t(k.COPY_MESSAGE),
            disabled: isDraft,
        },

        {
            id: MenuCommands.Archive,
            label: i18n.t(k.ARCHIVE),
            disabled: isArchived,
        },

        {
            id: MenuCommands.UndoArchive,
            label: i18n.t(k.UN_ARCHIVE),
            disabled: !isArchived,
        },

        {
            id: MenuCommands.Delete,
            label: i18n.t(k.DELETE),
            disabled: !isArchived,
        },
    ];
}
export function getTemplateMenuActions({
    isArchived,
    isDraft,
    canDeleteDraft,
    isChanged,
    createNew,
}: {
    createNew?: boolean;
    isArchived?: boolean;
    isDraft?: boolean;
    canDeleteDraft?: boolean;
    isChanged?: boolean;
}): IMenuOption[] {
    return [
        {
            id: MenuCommands.DeleteDraft,
            label: i18n.t(k.DELETE_DRAFT),
            disabled: !canDeleteDraft || createNew,
            testId: "menu-button-delete-draft",
        },
        {
            id: MenuCommands.Reset,
            label: i18n.t(k.RESET),
            disabled: !isChanged,
            testId: "menu-button-reset",
        },

        {
            id: MenuCommands.Copy,
            label: i18n.t(k.COPY_MESSAGE),
            disabled: isDraft || createNew,
            testId: "menu-button-copy",
        },

        {
            id: MenuCommands.Archive,
            label: i18n.t(k.ARCHIVE),
            disabled: isArchived || createNew,
            testId: "menu-button-archive",
        },

        {
            id: MenuCommands.UndoArchive,
            label: i18n.t(k.UN_ARCHIVE),
            disabled: !isArchived,
            testId: "menu-button-undo-archive",
        },

        {
            id: MenuCommands.Delete,
            label: i18n.t(k.DELETE),
            disabled: !isArchived,
            testId: "menu-button-delete",
        },
    ];
}

export function getCustomerMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getItemsMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getStakeholdersMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getApiKeysMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getItemTypesMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getPurchaseOrderLineStatusMenuActions(
    isArchived: boolean,
): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getCustomerContactMenuActions(
    isArchived: boolean,
    isPrimary: boolean,
): IMenuOption[] {
    const baseMenuOptions = getBaseMenuActions(isArchived);

    return [
        ...baseMenuOptions,

        {
            id: MenuCommands.MakePrimary,
            label: i18n.t(k.MAKE_PRIMARY),
            disabled: isPrimary || isArchived,
        },
    ];
}

export function getSupplierMenuActions(isArchived: boolean): IMenuOption[] {
    return getBaseMenuActions(isArchived);
}

export function getPurchaseOrderMenuActions(
    isArchived: boolean,
): IMenuOption[] {
    const baseOptions = getBaseMenuActions(isArchived);

    return [
        {
            id: MenuCommands.Preview,
            label: i18n.t(k.VIEW),
        },
        ...baseOptions,
    ];
}

export function getSupplierContactMenuActions(
    isArchived: boolean,
    isPrimary: boolean,
): IMenuOption[] {
    const baseMenuOptions = getBaseMenuActions(isArchived);

    return [
        ...baseMenuOptions,

        {
            id: MenuCommands.MakePrimary,
            label: i18n.t(k.MAKE_PRIMARY),
            disabled: isPrimary || isArchived,
        },
    ];
}
