import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import DangerBadge, { IDangerBadgeProps } from "../../base-badges/badge-danger/DangerBadge";

interface IErrorBadgeProps extends IDangerBadgeProps {
    label?: string;
}

const ErrorBadge = (props: IErrorBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return <DangerBadge {...rest}>{label ?? t(k.ERROR)}</DangerBadge>;
};

export default ErrorBadge;
