import "./ReportDetailsFilterMenu.scss";

import React from "react";

import { IHeaderTableCellProps } from "../header-table-cell/IHeaderTableCellProps";
import { ExpandCollapseButton } from "../icon-expand-collapse/ExpandCollapseButton";
import {
    BarcodeFilter,
    CheckboxListFilter,
    ChecklistSelectionFilter,
    DateRangeFilter,
    IFilterProps,
    TextFilter,
    ValueFilter,
    isCheckboxListFilterType,
} from "common/components/table/components/filtering";
import { CustomListFilterType } from "components/template-checklist-report/api/IChecklistReportFilter";
import { ActivityType } from "models/enums/ActivityType";

interface IProps extends IHeaderTableCellProps {}

export const ReportDetailsFilterMenu = (props: IProps) => {
    const { column, customListFilters } = props;

    const isTopLevel = (column?.expandInfo?.level ?? 0) === 0;

    const { customListId, columnKeyId, type } = column ?? { type: null };

    const handleExpand = (mustExpand: boolean) => {
        if (column) {
            props.onExpand(column, mustExpand);
        }
    };

    const handleFilter = (values: CustomListFilterType) => {
        if (column) {
            props.onFilter(column, values);
        }
    };

    let FilterComponent: React.FC<IFilterProps> | undefined;

    switch (type) {
        case ActivityType.Value:
            FilterComponent = ValueFilter;

            break;

        case ActivityType.Text:
        case ActivityType.Hyperlink:
            FilterComponent = TextFilter;

            break;

        case ActivityType.Barcodes:
        case ActivityType.GenerateBarcode:
            FilterComponent = BarcodeFilter;

            break;

        case ActivityType.DateTime:
            FilterComponent = DateRangeFilter;

            break;

        default:
            const isSelectionFilter =
                type === ActivityType.Selection ||
                type === ActivityType.Tasklist;

            if (isTopLevel && isSelectionFilter) {
                FilterComponent = ChecklistSelectionFilter;
            } else if (type && isCheckboxListFilterType[type]) {
                FilterComponent = CheckboxListFilter;
            }

            break;
    }

    const canExpand = type === ActivityType.CustomList;

    const filterValues = columnKeyId ? customListFilters?.[columnKeyId] : null;

    return FilterComponent && type !== null ? (
        <div
            className={
                canExpand
                    ? "report-details-filter-menu__wrapper can-expand"
                    : "report-details-filter-menu__wrapper"
            }
        >
            <FilterComponent
                value={filterValues ?? { type }}
                customListId={customListId}
                columnKeyId={columnKeyId}
                onFilter={handleFilter}
            />

            {canExpand && (
                <ExpandCollapseButton
                    isExpanded={column?.expandInfo?.isExpanded ?? false}
                    onExpand={handleExpand}
                />
            )}
        </div>
    ) : null;
};
