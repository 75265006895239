import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import CommentListWithEditor from "../CommentListWithEditor";
import { useQueryClient } from "@tanstack/react-query";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import { ModalTypes } from "common/components/modal-manager/api/IModalManager";
import { IPaginationSettings } from "common/components/pagination/IPaginationSettings";
import useIsMounted from "common/utils/hooks/useIsMounted";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    STEPINSTANCES_BY_CHECKLIST_ID_KEY,
    useChecklistById,
} from "components/checklists/api/hooks";
import ITableSet from "http/ITableSet";
import IChecklistCommentFilter from "http/filters/IChecklistCommentFilter";
import IWorkCommentRequest from "http/requests/IWorkCommentRequest";
import IWorkCommentResponse from "http/responses/IWorkCommentResponse";
import IChecklistComment from "models/IChecklistComment";
import IStepInstance from "models/IStepInstance";
import { IAppState } from "store/IAppState";
import { getComments, saveComment } from "store/stepInstances/actions";

interface IOwnProps {
    visible?: boolean;

    stepInstance: IStepInstance;
}

interface IStateProps {
    userName: string;
}

interface IDispatchProps {
    getComments: (
        filter: IChecklistCommentFilter,
    ) => Promise<ExecutionResult<IChecklistComment[]>>;
}

type Props = IOwnProps & IStateProps & IDispatchProps;

const CommentsByStepInstanceId: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { visible, stepInstance, userName } = props;

    const modalManagerContext = useContext(ModalManagerContext);

    const isMounted = useIsMounted();

    const queryClient = useQueryClient();

    const { t } = useTranslation();

    const callId = `${ModalTypes.checklist}_${stepInstance.workflowRunId}`;

    const checkIfAdmin =
        modalManagerContext.state.modalList[callId]?.options?.showAll ?? false;

    const { refetch } = useChecklistById(
        false,
        checkIfAdmin,
        stepInstance.workflowRunId,
    );

    const [fetchedComments, setFetchedComments] = useState(false);
    const [comments, setComments] = useState<IChecklistComment[]>();

    const [pagination, setPagination] = useState<IPaginationSettings>({
        activePage: 1,
        pageSize: 10,
        totalCount: stepInstance.commentCount ?? 0,
    });

    const [errors, setErrors] = useState<IValidationMessages>({});

    const fetchData = async (filter: IChecklistCommentFilter) => {
        const result = await props.getComments(filter);

        if (isMounted.current) {
            setComments(result.Data);
        }
    };

    useEffect(() => {
        if (visible && fetchedComments === false) {
            setFetchedComments(true);

            const filter: IChecklistCommentFilter = {
                stepInstanceId: stepInstance.id,
                pageSize: pagination.pageSize,
            };

            fetchData(filter);
        }
    }, [visible, stepInstance]);

    const handleChangePage = (pageNumber: number) => {
        setPagination((x) => ({
            ...x,
            activePage: pageNumber,
        }));

        const filter: IChecklistCommentFilter = {
            stepInstanceId: stepInstance.id,
            page: pageNumber,
            pageSize: pagination.pageSize,
        };

        fetchData(filter);
    };

    const handleAddComment = async (done: boolean, comment: string) => {
        if (done && stepInstance) {
            const request: IWorkCommentRequest = {
                stepInstanceId: stepInstance.id,
                commentMessage: comment,
            };

            try {
                const result = await saveComment(request);

                setComments((prev) => {
                    if (pagination.activePage === 1 && result) {
                        const newComment = result.comment;

                        return [newComment]
                            .concat(prev ?? [])
                            .slice(0, pagination.pageSize);
                    }

                    return prev;
                });

                setPagination((prev) => ({
                    ...prev,
                    totalCount: result?.totalCount,
                }));

                if (result) {
                    updateStepInstance(result);
                }

                setErrors({});
            } catch (error: any) {
                setErrors(error.response?.data.errors);
            }
        }
    };

    const updateStepInstance = (data: IWorkCommentResponse) => {
        queryClient.setQueryData<ITableSet<IStepInstance>>(
            [STEPINSTANCES_BY_CHECKLIST_ID_KEY, stepInstance.workflowRunId],

            (old) => {
                if (old) {
                    const stepInstanceValues = old.values[data.stepInstanceId];

                    if (stepInstanceValues) {
                        return {
                            ids: old.ids,
                            values: {
                                ...old.values,
                                [data.stepInstanceId]: {
                                    ...stepInstanceValues,
                                    commentCount: data.totalCount,
                                },
                            },
                        };
                    } else {
                        return old;
                    }
                }

                return {
                    ids: [],
                    values: {},
                };
            },
        );
    };

    return (
        <CommentListWithEditor
            comments={comments}
            userName={userName}
            pagination={pagination}
            commentEditorInvalid={Boolean(
                errors.CommentMessage || errors._error,
            )}
            onChange={handleAddComment}
            onChangePage={handleChangePage}
        />
    );
};

const mapToProps = (appState: IAppState): IStateProps => {
    const profile = appState.authViewState.profile;

    const userName = profile?.username ?? "";

    return { userName };
};

const actions = {
    getComments,
};

export default connect(mapToProps, actions)(CommentsByStepInstanceId);
