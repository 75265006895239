import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IGradingBadgeBaseProps } from "../IGradingBadgeBaseProps";
import ScoreBadge from "common/components/badges/base-badges/badge-score/ScoreBadge";

type IVeryLowGradeBadgeProps = IGradingBadgeBaseProps;

const VeryLowGradeBadge = (props: IVeryLowGradeBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <ScoreBadge
            score="xs"
            label={label ?? (props.short ? 1 : t(k.IMPACT_SCORE1_LABEL))}
            {...rest}
        />
    );
};

export default VeryLowGradeBadge;
