import { Dispatch, RefObject, SetStateAction } from "react";
import "./SetResponsible.scss";
import TeamsMultiselectWithWrapper from "components/common/multiselect-dropdowns/TeamsMultiselectWithWrapper";
import UserMultiselect from "components/common/multiselect-dropdowns/UserMultiselect";
import { IKpiResponsiblesDto } from "../../api/IKpiResponsiblesDto";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { useTranslation } from "react-i18next";
import k from "i18n/keys";

interface IProps {
    stepRef: RefObject<HTMLDivElement>;
    dataId: string;
    responsibles: IKpiResponsiblesDto;
    setResponsibles: Dispatch<SetStateAction<IKpiResponsiblesDto>>;

    errors?: IValidationMessages;
    onHaveChanges: (haveChanges: boolean) => void;
}

const SetResponsible = (props: IProps) => {
    const { stepRef, dataId, responsibles, errors } = props;

    const handleOnChangeTeams = (values: string[]) => {
        props.setResponsibles((prev) => {
            return {
                ...prev,
                teamIds: values,
            };
        });

        props.onHaveChanges(true);
    };

    const handleOnChangeUsers = (values: string[]) => {
        props.setResponsibles((prev) => {
            return {
                ...prev,
                userIds: values,
            };
        });

        props.onHaveChanges(true);
    };

    const { t } = useTranslation();

    return (
        <div className="kpi-set-responsible" ref={stepRef} data-id={dataId}>
            <UserMultiselect
                boldLabel
                placeholder={t(k.SELECT_USERS)}
                defaultValues={responsibles.userIds}
                values={responsibles.userIds}
                label={t(k.USERS_WHO_CAN_UPDATE_TARGETS)}
                onChange={handleOnChangeUsers}
            />
            <TeamsMultiselectWithWrapper
                values={responsibles.teamIds}
                placeholder={t(k.SELECT_TEAMS)}
                label={t(k.TEAMS_WHO_CAN_UPDATE_TARGETS)}
                onChange={handleOnChangeTeams}
                excludeEveryoneOption
            />
        </div>
    );
};

export default SetResponsible;
