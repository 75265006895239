import "./AccordionLeft.scss";

import {
    CSSProperties,
    MouseEvent,
    PropsWithChildren,
    ReactNode,
    useEffect,
    useState,
} from "react";

import { Collapse } from "react-bootstrap";

import classNames from "classnames";

import AccordionButton from "../buttons/accordion-button/AccordionButton";

interface IProps {
    open?: boolean;
    listenOpen?: boolean;

    wrapperClassName?: string;
    className?: string;
    headerClassName?: string;
    childrenClassName?: string;

    header: ReactNode;
    toggleOnIcon?: boolean;

    onClickHeader?: () => void;
    onToggle?: (open: boolean, event: MouseEvent<HTMLDivElement>) => void;
}

const AccordionLeft = (props: PropsWithChildren<IProps>) => {
    const { open = false, header, toggleOnIcon, listenOpen } = props;

    const [wholeOpen, setWholeOpen] = useState(open);

    useEffect(() => {
        if (listenOpen) {
            setWholeOpen(open);
        }
    }, [open]);

    const toggleCollapse = (event: MouseEvent<HTMLDivElement>) => {
        if (props.onToggle) {
            props.onToggle(!wholeOpen, event);
        }

        setWholeOpen((x) => !x);
    };

    return (
        <AccordionLeftRender
            open={wholeOpen}
            toggleOnIcon={toggleOnIcon}
            header={header}
            wrapperClassName={props.wrapperClassName}
            className={props.className}
            headerClassName={props.headerClassName}
            toggleCollapse={toggleCollapse}
        >
            {props.children}
        </AccordionLeftRender>
    );
};

export default AccordionLeft;

interface IAccordionLeftRenderProps {
    open: boolean;

    wrapperClassName?: string;
    wrapperStyle?: CSSProperties;
    className?: string;
    style?: CSSProperties;
    headerClassName?: string;
    childrenClassName?: string;

    header: ReactNode;
    toggleOnIcon?: boolean;
    closeToRight?: boolean;

    onClickHeader?: () => void;
    toggleCollapse?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const AccordionLeftRender = (
    props: PropsWithChildren<IAccordionLeftRenderProps>,
) => {
    const {
        open,
        header,
        toggleOnIcon,

        closeToRight,
    } = props;

    const wrapperClassName = classNames(
        "app-accordion-left__wrapper",
        props.wrapperClassName,
    );
    const className = classNames("app-accordion", props.className, {
        pointer: !toggleOnIcon,
    });
    const headerClassName = classNames(
        "app-accordion--header",
        props.headerClassName,
        {
            pointer: props.onClickHeader,
        },
    );

    return (
        <div className={wrapperClassName} style={props.wrapperStyle}>
            <div
                className={className}
                style={props.style}
                onClick={toggleOnIcon ? undefined : props.toggleCollapse}
            >
                <div
                    className={`app-accordion--toggle ${
                        toggleOnIcon ? "pointer" : ""
                    }`}
                    onClick={toggleOnIcon ? props.toggleCollapse : undefined}
                >
                    <AccordionButton
                        isOpen={open}
                        closeToRight={closeToRight}
                    />
                </div>
                <div className={headerClassName} onClick={props.onClickHeader}>
                    {header}
                </div>
            </div>

            <Collapse in={open}>
                <div className={props.childrenClassName}>{props.children}</div>
            </Collapse>
        </div>
    );
};
