import "../RecurringChecklistForm.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { AppLocale } from "AppLocale";
import * as yup from "yup";

import k from "i18n/keys";

import IRecurringChecklist, {
    IRecurringWeekly,
} from "../api/IRecurringChecklist";
import { IWeeklyRecurringChecklistRequest } from "../api/IWeeklyRecurringChecklistRequest";
import { WorkflowScheduleInterval } from "../api/WorkflowScheduleIntervals";
import {
    canCalculateDates,
    useCalculateWeeklyRecurringChecklists,
    useDefaultRecurrenceName,
    useSaveRecurringChecklistsMutation,
} from "../api/hooks";
import isDurationZero from "../utility/isDurationZero";
import RecurringChecklistDayOfWeek from "./RecurringChecklistDayOfWeek";
import RecurringChecklistEndAt from "./RecurringChecklistEndAt";
import RecurringChecklistName from "./RecurringChecklistName";
import RecurringChecklistStartAt from "./RecurringChecklistStartAt";
import RecurringChecklistSummary from "./RecurringChecklistSummary";
import RecurringChecklistTimeline from "./RecurringChecklistTimeline";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import Textfield from "common/components/input-components/textfield/Textfield";
import ModalContent from "common/components/modal-content/ModalContent";
import { errorToMessageReducer } from "common/components/validation/yupValidationErrorUtili";
import IValidationMessages from "common/viewModels/IValidationMessages";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import ValidationLabel from "components/common/validation/ValidationLabel";
import useDebounce from "utils/useDebounce";

interface IProps {
    selected: boolean;
    recurringData: IRecurringChecklist;
    template: IValueLabelItem<string, string, string>;

    onHaveChanges: (haveChanges: boolean) => void;
    onPublish: () => void;
    onChangeRecurrence: (id: string) => void;
}

const formSchema = yup.object({
    editedTitle: yup.boolean(),
    name: yup.string().when("editedTitle", {
        is: true,
        then: (schema) => schema.required("REQUIRED"),
    }),
    intervalNumber: yup.number().min(1, "MIN_ONE").required("REQUIRED"),
    workdays: yup.array().min(1, "REQUIRED").required("REQUIRED"),
    startDate: yup.date().required("REQUIRED").nullable(),
    endDate: yup.date().required("REQUIRED").nullable(),
    numberOfRuns: yup.number().min(1).required("REQUIRED"),
    duration: yup.object({
        days: yup.number().required("REQUIRED"),
        hours: yup.number().required("REQUIRED"),
        minutes: yup.number().required("REQUIRED"),
    }),
});

const RecurringChecklistAddWeekly = (props: IProps) => {
    const { selected, template } = props;

    const {
        label: templateTitle,
        value: templateId,
        param: templateVersionId,
    } = template;

    const { t } = useTranslation();

    const saveRecurringChecklist = useSaveRecurringChecklistsMutation();

    const [recurringData, setRecurringData] = useState<IRecurringWeekly>(
        props.recurringData,
    );

    const [recurringFilter, setRecurringFilter] = useState<IRecurringWeekly>(
        props.recurringData,
    );

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [editedTitle, setEditedTitle] = useState(false);

    const isSaving = saveRecurringChecklist.isPending;

    const {
        id,
        workdays = [],
        intervalNumber,
        numberOfRuns,
        startDate,
        endDate,
        duration,
        isStartDate,
        isEndDate,
        name,
        status,
    } = recurringData;

    const placeholder = useDefaultRecurrenceName(
        AppLocale,
        templateTitle,
        startDate,
        endDate,
    );

    const {
        data: recurringChecklistCalculations,
        isLoading: isLoadingCalculations,
    } = useCalculateWeeklyRecurringChecklists(
        useDebounce(recurringFilter, 1000),
        selected,
    );

    useEffect(() => {
        setRecurringData(props.recurringData);
        setRecurringFilter(props.recurringData);

        if (!editedTitle) {
            if (
                props.recurringData.name !== "" &&
                props.recurringData.name !== placeholder
            ) {
                setEditedTitle(true);
            }
        }
    }, [props.recurringData]);

    useEffect(() => {
        if (recurringChecklistCalculations) {
            setErrors(recurringChecklistCalculations.Messages);

            if (recurringChecklistCalculations.Succeeded) {
                setRecurringData((prev) => ({
                    ...prev,
                    endDate: recurringChecklistCalculations.Data?.endDate,
                    numberOfRuns:
                        recurringChecklistCalculations.Data?.numberOfRuns,
                }));
            }
        }
    }, [
        recurringChecklistCalculations?.Succeeded,
        recurringChecklistCalculations?.Data?.endDate,
        recurringChecklistCalculations?.Data?.numberOfRuns,
    ]);

    const handleOnChange = async <T extends keyof IRecurringChecklist>(
        value: IRecurringChecklist[T],
        id: T,
    ) => {
        if (canCalculateDates(id)) {
            setRecurringFilter((prev) => ({ ...prev, [id]: value }));
        }

        setRecurringData((prev) => ({
            ...prev,
            [id]: value,
        }));

        if (!editedTitle && id === "name") {
            setEditedTitle(true);
        }

        props.onHaveChanges(true);

        switch (id) {
            case "name":
            case "intervalNumber":
            case "numberOfRuns":
            case "duration":
                break;

            case "isEndDate": {
                if (value) {
                    setErrors((prev) => {
                        const { numberOfRuns: x, ...rest } = prev;
                        return rest;
                    });
                } else {
                    setErrors((prev) => {
                        const { endDate: x, ...rest } = prev;
                        return rest;
                    });
                }

                break;
            }

            case "startDate":
            case "endDate": {
                const v = value as Date;
                if (
                    (id === "startDate" && endDate && v > endDate) ||
                    (id === "endDate" && startDate && v < startDate)
                ) {
                    setErrors((prev) => {
                        const { ["startDate"]: x, ...rest } = prev;

                        return { ...rest, ["endDate"]: "END_AFTER_START" };
                    });
                }

                break;
            }

            default: {
                try {
                    await formSchema.validateAt(id, {
                        isEndDate,
                        [id]: value,
                    });

                    setErrors((prev) => {
                        const { [id]: x, ...rest } = prev;
                        return rest;
                    });
                } catch (err) {
                    if (err instanceof yup.ValidationError) {
                        const _err = err;
                        setErrors((prev) => ({
                            ...prev,
                            [id]: _err.errors,
                        }));
                    }
                }
            }
        }
    };

    const handleRecurEvery = (value: string) => {
        const val = parseInt(value);

        handleOnChange(isNaN(val) ? undefined : val, "intervalNumber");
    };

    const handleChangeTitle = (value: string) => {
        handleOnChange(value, "name");
    };

    const handleSavePublish = () => {
        handleSave(false);
    };

    const handleSaveDraft = () => {
        handleSave(true);
    };

    const handleOnBlur = async (id?: string, name?: string) => {
        const _id = name ?? id;
        if (_id) {
            try {
                await formSchema.validateAt(_id, {
                    ...recurringData,
                    editedTitle,
                });

                setErrors((prev) => {
                    const { [_id]: value, ...rest } = prev;
                    return rest;
                });
            } catch (err) {
                if (err instanceof yup.ValidationError) {
                    const _err = err;
                    setErrors((prev) => ({
                        ...prev,
                        [_id]: _err.errors,
                    }));
                }
            }
        }
    };

    const handleSave = async (saveAsDraft: boolean) => {
        try {
            await formSchema.validate(
                { ...recurringData, editedTitle },
                { abortEarly: false },
            );

            if (isDurationZero(duration)) {
                setErrors((prev) => ({
                    ...prev,
                    ["duration"]: "DURATION_NOT_ZERO",
                }));
            } else {
                const request: IWeeklyRecurringChecklistRequest = {
                    id,
                    isNew: status === undefined,
                    intervalNumber,
                    startDate,
                    isStartDateUpdated: isStartDate,
                    duration,
                    endDate,
                    numberOfRuns,
                    isEndDateUpdated: isEndDate,
                    name: editedTitle ? name : placeholder,
                    templateId,
                    asDraft: saveAsDraft,

                    workdays,
                };

                if (templateVersionId) {
                    const result = await saveRecurringChecklist.mutateAsync({
                        request,
                        intervalType: WorkflowScheduleInterval.Weekly,
                        templateVersionId,
                    });

                    if (result.Succeeded) {
                        props.onPublish();
                    } else {
                        setErrors(result.Messages);
                    }
                }
            }
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const errorItems = errorToMessageReducer(err);

                setErrors(errorItems);
            }
        }
    };

    if (!selected) {
        return null;
    }

    return (
        <>
            <ModalContent.Body className="recurring-checklist--body">
                <div className="recurring-checklist--body--content">
                    <div className="recurring-checklist--body--recur">
                        {t(k.RECUR_EVERY)}
                        <Textfield
                            id="intervalNumber"
                            value={String(intervalNumber ?? "")}
                            onChange={handleRecurEvery}
                            onBlur={handleOnBlur}
                            isWidthMinimized={true}
                            type="number"
                            invalid={!!errors.IntervalNumber}
                            helperText={
                                <ValidationLabel
                                    errors={
                                        errors.intervalNumber &&
                                        t(errors.intervalNumber)
                                    }
                                />
                            }
                        />
                        {t(k.WEEK_S)}
                    </div>

                    <RecurringChecklistDayOfWeek
                        workdays={workdays}
                        errors={errors}
                        onChange={handleOnChange}
                    />

                    <RecurringChecklistStartAt
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                        errors={errors}
                        startDate={startDate}
                        duration={duration}
                        isStartDate={isStartDate}
                    />

                    <RecurringChecklistEndAt
                        isEndDate={isEndDate}
                        endDate={endDate}
                        numberOfRuns={numberOfRuns}
                        errors={errors}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />

                    <RecurringChecklistName
                        name={editedTitle ? name : placeholder}
                        errors={errors}
                        placeholder={placeholder}
                        onChange={handleChangeTitle}
                        onBlur={handleOnBlur}
                    />

                    <RecurringChecklistTimeline
                        type={WorkflowScheduleInterval.Weekly}
                        dates={
                            recurringChecklistCalculations?.Data?.dates ?? []
                        }
                        id={id}
                        title={name || placeholder}
                        template={template}
                        startDate={startDate}
                        onChangeRecurrence={props.onChangeRecurrence}
                    />

                    <RecurringChecklistSummary
                        intervalName={t(k.WEEK_S)}
                        interval={intervalNumber}
                        calculatedResult={recurringChecklistCalculations?.Data}
                        duration={duration}
                    />

                    <AnimatedSpinner
                        position="center"
                        isVisible={isLoadingCalculations}
                    />
                </div>
            </ModalContent.Body>

            <ModalContent.Footer>
                <div className="recurring-checklist--foot">
                    <Button
                        onClick={handleSaveDraft}
                        variant="draft"
                        size="small"
                        disabled={isSaving}
                        isBusy={isSaving}
                    >
                        {t(k.SAVE_AS_DRAFT)}
                    </Button>
                    <Button
                        onClick={handleSavePublish}
                        variant="success"
                        size="small"
                        disabled={isSaving}
                        isBusy={isSaving}
                        testId="recurringChecklistPublishBtn"
                    >
                        {t(k.SAVE_AND_PUBLISH)}
                    </Button>
                </div>
            </ModalContent.Footer>
        </>
    );
};

export default RecurringChecklistAddWeekly;
