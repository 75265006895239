import React, { useMemo, useState } from "react";

import {
    ITableRowContext,
    TableCellContainerContext,
    useTableRowContainerContext,
} from "./Context";
import { ITableCellProps } from "./ITableCellProps";
import { TableCellComponentType } from "./TableCellComponent";

interface IProps {
    detailsView?: React.ReactNode;
    disableHover?: boolean;
    className?: string;

    isNew?: boolean;
    isArchived?: boolean;
    isDeleted?: boolean;
    isDraft?: boolean;
    fullHeight?: boolean;
    rowTestId?: string;
    noZIndex?: boolean;

    onClick?: (e?: React.MouseEvent<HTMLTableRowElement>) => void;
    onExpandDetailsView?: (isOpen: boolean) => void;
}

const renderHeaderChildren = (
    children: React.ReactNode,
    cellsCount: number,
) => {
    let validElementIndex = 0;
    return React.Children.map(children, (child) => {
        if (
            React.isValidElement(child) &&
            (child.type as TableCellComponentType).isTableCell
        ) {
            const newProps: ITableCellProps = {};

            newProps.index = validElementIndex;
            newProps.zIndexOrderModifier = cellsCount - validElementIndex;

            validElementIndex++;

            return React.cloneElement(child, newProps);
        }

        return child;
    });
};

const renderChildren = (
    children: React.ReactNode,
    handleExpand?: () => void,
    canExpandRow?: boolean,
    isOpen?: boolean,
) => {
    let validElementIndex = 0;
    return React.Children.map(children, (child) => {
        if (
            React.isValidElement(child) &&
            (child.type as TableCellComponentType).isTableCell
        ) {
            const newProps: React.PropsWithChildren<ITableCellProps> = {};

            newProps.index = validElementIndex;

            if (validElementIndex === 0 && canExpandRow) {
                newProps.handleExpand = handleExpand;
                newProps.isExpanded = isOpen;
            }

            validElementIndex++;

            return React.cloneElement(child, newProps);
        }

        return child;
    });
};

const TableRowComponent: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        detailsView,
        isNew,
        isArchived,
        isDeleted,
        isDraft,
        fullHeight,
        noZIndex,
        rowTestId,
    } = props;

    const context = useTableRowContainerContext();

    const [isOpen, setIsOpen] = useState<boolean | undefined>();

    const handleExpand = () => {
        setIsOpen((x) => !x);

        props.onExpandDetailsView?.(!isOpen);
    };

    const contextValue = useMemo(() => {
        const result: ITableRowContext = {
            isHeader: context?.isHeader,
            isFooter: context?.isFooter,
            isLoading: context?.isLoading,
            isHeaderVerticalAlign: context?.isHeaderVerticalAlign,
            columnWidths: context?.columnWidths,
            resizingKey: context?.resizingKey,
            startResizing: context?.startResizing,
            stopResizing: context?.stopResizing,
            registerCellRef: context?.registerCellRef,
            canExpandRow: context?.canExpandRow,
            registerColumnHeaderName: context?.registerColumnHeaderName,
            columnsHeaders: context?.columnsHeaders,
        };

        return result;
    }, [context]);

    const { headerOrFooterRowClassName, className } = useMemo(() => {
        const result: string[] = [];

        if (fullHeight) {
            result.push("table-row--full-height");
        }

        if (props.className) {
            result.push(props.className);
        }

        const headerOrFooterRowClassName =
            result.length > 0 ? result.join(" ") : undefined;

        if (isDeleted) {
            result.push("table-row--deleted");
        } else if (isArchived) {
            result.push("table-row--archived");
        }

        if (isDraft) {
            result.push("table-row--draft");
        }

        if (isNew) {
            result.unshift("is-new");
        }

        if (isOpen) {
            result.unshift("expanded-row");
        }

        if (props.onClick) {
            result.unshift("table-row--selectable");
        }

        const className = result.length > 0 ? result.join(" ") : undefined;

        return {
            headerOrFooterRowClassName,
            className,
        };
    }, [
        props.className,
        isOpen,
        isArchived,
        isDeleted,
        isDraft,
        fullHeight,
        isNew,
        props.onClick,
    ]);

    const cellsCount = 1 + (context?.columnsCount ?? 0);

    if (context?.isHeader) {
        return (
            <TableCellContainerContext.Provider value={contextValue}>
                <tr
                    className={headerOrFooterRowClassName}
                    data-testid={rowTestId}
                >
                    {noZIndex
                        ? props.children
                        : renderHeaderChildren(props.children, cellsCount)}
                </tr>
            </TableCellContainerContext.Provider>
        );
    } else if (context?.isFooter) {
        return (
            <TableCellContainerContext.Provider value={contextValue}>
                <tr
                    className={headerOrFooterRowClassName}
                    data-testid={rowTestId}
                >
                    {props.children}
                </tr>
            </TableCellContainerContext.Provider>
        );
    }

    return (
        <TableCellContainerContext.Provider value={contextValue}>
            <tr
                className={className}
                onClick={props.onClick}
                data-testid={rowTestId}
            >
                {renderChildren(
                    props.children,
                    handleExpand,
                    context?.canExpandRow,
                    isOpen,
                )}
            </tr>

            {context?.canExpandRow && detailsView && (
                <tr className={isOpen ? "expanded-row--details" : "d-none"}>
                    <td colSpan={cellsCount}>
                        {isOpen !== undefined && detailsView}
                    </td>
                </tr>
            )}
        </TableCellContainerContext.Provider>
    );
};

export default TableRowComponent;
