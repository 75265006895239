import "./ToggleView.scss";

import React from "react";

import { useTranslation } from "react-i18next";
import { MdOutlineViewTimeline, MdViewComfy, MdViewList } from "react-icons/md";
import {
    TbTimeline,
    TbTimelineEvent,
    TbTimelineEventText,
} from "react-icons/tb";

import k from "i18n/keys";

import { ViewMode } from "./ChecklistsTable";
import IconButton from "common/components/buttons/icon-button/IconButton";
import Tooltip from "common/components/tooltip/Tooltip";

interface IProps {
    templateNotSelected: boolean;

    viewMode: ViewMode;
    timelineFeatureEnabled?: boolean;

    onChange: (viewMode: ViewMode) => void;
}

const ToggleView: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { templateNotSelected, viewMode, timelineFeatureEnabled } = props;

    const { t } = useTranslation();

    return (
        <div className="toggle-view">
            <Tooltip id="table-view-tooltip" message={t(k.TABLE_VIEW)}>
                <IconButton
                    className={
                        viewMode === ViewMode.TableView ? "active" : "inactive"
                    }
                    disabled={templateNotSelected && !timelineFeatureEnabled}
                    onClick={() => props.onChange(ViewMode.TableView)}
                >
                    <MdViewList />
                </IconButton>
            </Tooltip>

            <Tooltip
                id="table-view-per-template-tooltip"
                message={t(k.TABLE_VIEW_PER_TEMPLATE)}
            >
                <IconButton
                    className={
                        viewMode === ViewMode.TableViewPerTemplate
                            ? "active"
                            : "inactive"
                    }
                    disabled={templateNotSelected}
                    onClick={() =>
                        props.onChange(ViewMode.TableViewPerTemplate)
                    }
                >
                    <MdViewComfy />
                </IconButton>
            </Tooltip>

            {timelineFeatureEnabled && (
                <>
                    <Tooltip
                        id="timeline-view-tooltip"
                        message={t(k.TIMELINE_VIEW)}
                    >
                        <IconButton
                            className={
                                viewMode === ViewMode.TimelineView
                                    ? "active"
                                    : "inactive"
                            }
                            onClick={() =>
                                props.onChange(ViewMode.TimelineView)
                            }
                        >
                            <TbTimelineEvent />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        id="timeline-view-per-template-tooltip"
                        message={t(k.TIMELINE_VIEW_PER_TEMPLATE)}
                    >
                        <IconButton
                            className={
                                viewMode === ViewMode.TimelineViewPerTemplate
                                    ? "active"
                                    : "inactive"
                            }
                            disabled={templateNotSelected}
                            onClick={() =>
                                props.onChange(ViewMode.TimelineViewPerTemplate)
                            }
                        >
                            <TbTimelineEventText />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        id="checklist-timeline-view-tooltip"
                        message={t(k.CHECKLIST_TIMELINE_VIEW)}
                    >
                        <IconButton
                            className={
                                viewMode === ViewMode.ChecklistTimelineView
                                    ? "active"
                                    : "inactive"
                            }
                            onClick={() =>
                                props.onChange(ViewMode.ChecklistTimelineView)
                            }
                        >
                            <TbTimeline />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        id="checklist-timeline-view-per-template-tooltip"
                        message={t(k.CHECKLIST_TIMELINE_VIEW_PER_TEMPLATE)}
                    >
                        <IconButton
                            className={
                                viewMode ===
                                ViewMode.ChecklistTimelineViewPerTemplate
                                    ? "active"
                                    : "inactive"
                            }
                            disabled={templateNotSelected}
                            onClick={() =>
                                props.onChange(
                                    ViewMode.ChecklistTimelineViewPerTemplate,
                                )
                            }
                        >
                            <MdOutlineViewTimeline />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default ToggleView;
