import "./ArrowDownIcon.scss";

import Icon from "./assets/arrow-down-icon.svg";

interface IArrowDownIconProps {
    className?: string;
}

const ArrowDownIcon = ({ className }: IArrowDownIconProps) => {
    return (
        <Icon
            className={`arrow-down-icon${className ? " " + className : ""}`}
        />
    );
};

export default ArrowDownIcon;
