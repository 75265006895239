import "./KpiForm.scss";

import {
    createRef,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { IFormulaItemDto } from "./api/IFormulaItemDto";
import { IKpiDetailsDto } from "./api/IKpiDetailsDto";
import { IKpiFormats } from "./api/IKpiFormats";
import { IKpiRequest } from "./api/IKpiRequest";
import { IKpiResponsiblesDto } from "./api/IKpiResponsiblesDto";
import {
    useCanUpdateKpi,
    useDataSourceFiltersByIds,
    useDataSourceFiltersByKpiId,
    useKpiById,
    useSaveKpiMutation,
} from "./api/hooks";
import KpiDetailsForm from "./components/details/KpiDetailsForm";
import CreateFormula, {
    addUnsetItems,
} from "./components/formula/CreateFormula";
import SetResponsible from "./components/responsible/SetResponsible";
import SetTargets, {
    DEFAULT_MAX_THRESHOLD,
    DEFAULT_MIN_THRESHOLD,
} from "./components/targets/SetTargets";
import {
    IFormulaItem,
    convertToFormulaItems,
    convertToFormulaItemsDto,
    makeTableSet,
    splitString,
} from "./utility/FormulaTools";
import { setValidationErrors } from "./utility/FormulaValidationTools";
import { getTabsErrors } from "./utility/TabValidationTools";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import CurrentScrolledSection from "common/components/current-scrolled-section/CurrentScrolledSection";
import ModalContent from "common/components/modal-content/ModalContent";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import QuickTips from "common/components/quick-tip/QuickTips";
import SplitLayout from "common/components/split-layout/SplitLayout";
import Tab from "common/components/split-layout/components/Tab";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";
import { IKpiTargetDTO } from "components/kpi-page/api/IKpiTargetDTO";
import { newYear } from "components/kpi-page/api/utils";
import ITableSet from "http/ITableSet";

const defaultData = newYear();

/* Temp dev stuff START */
const tempItemString = "(abc+548)*12-fab";

/* END */

const DEFAULT_DECIMAL_PLACES = 2;

export interface TabData {
    id: string;
    label: string;
    tips: string[];
}

const tabs: { [key: string]: TabData } = {
    1: {
        id: "1",
        label: k.DETAILS,

        tips: [k.KPI_DETAILS_TIP1],
    },
    2: {
        id: "2",
        label: k.FORMULA,

        tips: [k.KPI_FORMULA_TIP1, k.KPI_FORMULA_TIP2, k.KPI_FORMULA_TIP3],
    },
    3: {
        id: "3",
        label: k.SET_TARGETS,

        tips: [k.SET_TARGETS_TIP1, k.SET_TARGETS_TIP2],
    },
    4: {
        id: "4",
        label: k.SET_RESPONSIBLE,
        tips: [k.SET_RESPONSIBLE_TIP1],
    },
};
interface IProps {
    id?: string;
    isCreate?: boolean;

    isChanged: boolean;

    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

const KpiForm = (props: React.PropsWithoutRef<IProps>) => {
    const { isCreate, id, isChanged } = props;

    const { data: canUpdate, isLoading: isLoadingCanUpdate } = useCanUpdateKpi(
        id,
        !isCreate,
    );

    const modalManagerContext = useContext(ModalManagerContext);

    const { t, i18n } = useTranslation();

    const [kpiFormats, setKpiFormats] = useState<IKpiFormats | undefined>(
        isCreate
            ? {
                  decimalPlaces: DEFAULT_DECIMAL_PLACES,
              }
            : undefined,
    );

    const [kpiDetails, setKpiDetails] = useState<IKpiDetailsDto>({});

    const [kpiFormula, setKpiFormula] = useState<{
        formulaId: string;
        formulaItems: ITableSet<IFormulaItem>;
    }>({
        formulaId: uuidv4(),
        formulaItems: addUnsetItems(makeTableSet(splitString(tempItemString))),
    });

    const [target, setTarget] = useState<IKpiTargetDTO>({
        id: uuidv4(),
        overIsGood: true,
        underThreshold: DEFAULT_MIN_THRESHOLD,
        overThreshold: DEFAULT_MAX_THRESHOLD,
        values: defaultData.kpiTargetValues,
        kpiTargetValueIdsByYear: {
            [defaultData.year]: defaultData.kpiTargetValueIds,
        },
        yearList: [defaultData.year],
    });

    const [responsibles, setResponsibles] = useState<IKpiResponsiblesDto>({
        teamIds: [],
        userIds: [],
    });

    const [allErrors, setAllErrors] = useState<IValidationMessages>({});
    const [formulaErrors, setFormulaErrors] = useState<IValidationMessages>({});

    const [scopedErrors, setScopedErrors] = useState<
        Record<string, IValidationMessages>
    >({});

    const contentRef = useRef<HTMLDivElement>(null);

    const stepRefs = useMemo(() => {
        return Object.keys(tabs).reduce((map, id) => {
            return { ...map, [id]: createRef<HTMLDivElement>() };
        }, {}) as IDictionary<React.RefObject<HTMLDivElement>>;
    }, []);

    const { data, isLoading: isLoadingKpi } = useKpiById(id);

    const filterIds = useMemo(() => {
        if (data) {
            return data.formula?.formulaItems.reduce((map, item) => {
                const id = item.variable?.dataSource?.filterId;
                if (id) {
                    map.push(id);
                }
                return map;
            }, [] as string[]);
        }
    }, [data]);

    const { data: filters, isLoading: isLoadingFilters } =
        useDataSourceFiltersByIds(filterIds);

    const saveMutation = useSaveKpiMutation();

    const isLoading = isLoadingKpi || isLoadingFilters;

    useEffect(() => {
        if (data && !isChanged) {
            let formulaItemsFilters = data?.formula?.formulaItems
                .map((item) => {
                    return item.variable?.dataSource?.filterId
                        ? {
                              formulaItemId: item.id,
                              variableId: item.variable?.id,
                              dataSourceId: item.variable?.dataSource?.id,
                              filterId: item.variable?.dataSource?.filterId,
                          }
                        : undefined;
                })
                .filter((item) => item);

            if (
                //needed data is loaded
                (formulaItemsFilters &&
                    formulaItemsFilters.length > 0 &&
                    filters &&
                    filters.length > 0) ||
                //no filters needed
                formulaItemsFilters?.length === 0
            ) {
                setKpiDetails(data.details);

                if (data.formula) {
                    let updatedFormulaItems = data.formula.formulaItems
                        .map((x) => {
                            let updatedValue = formulaItemsFilters?.find(
                                (item) => item?.formulaItemId === x.id,
                            );

                            let updatedFilter = filters?.find(
                                (x) => x?.id === updatedValue?.filterId,
                            );
                            if (x && x.variable && x.variable.dataSource) {
                                return {
                                    ...x,
                                    variable: {
                                        ...x.variable,
                                        dataSource: {
                                            ...x.variable?.dataSource,
                                            filter: updatedFilter,
                                        },
                                    },
                                };
                            } else {
                                return x;
                            }
                        })
                        .filter((x) => x) as IFormulaItemDto[];

                    setKpiFormula({
                        formulaId: data.formula.id,
                        formulaItems: addUnsetItems(
                            convertToFormulaItems(updatedFormulaItems),
                        ),
                    });
                }

                if (data.targets) {
                    setTarget(data.targets);
                }

                if (data.responsibles) {
                    setResponsibles(data.responsibles);
                }

                setKpiFormats(data.formats);
            }
        }
    }, [data, canUpdate, filters, isLoadingKpi, isLoadingFilters]);

    const [isFormComplete, setIsFormComplete] = useState(true);

    const showSavedKpi = (id?: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.kpi_details,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleSubmitForm = async () => {
        const formulaItemDTOs = convertToFormulaItemsDto(
            kpiFormula.formulaItems,
        );

        const request: IKpiRequest = {
            id: isCreate || !id ? uuidv4() : id,
            details: kpiDetails,
            formula: {
                id: kpiFormula.formulaId,
                formulaItems: formulaItemDTOs,
            },
            targets: target,
            responsibles: responsibles,

            unitId: kpiFormats?.unit?.id,
            decimalPlaces: kpiFormats?.decimalPlaces,
            formatType: kpiFormats?.formatType,
        };

        const result = await saveMutation.mutateAsync({
            isCreate: Boolean(isCreate),
            value: request,
        });

        if (result.Succeeded) {
            toast.dismiss();

            toast.success(t(k.KPI_SAVED), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClick: () => showSavedKpi(result.Data),
            });
            props.onClose(true);
        } else {
            if (result.Messages) {
                setAllErrors(result.Messages);

                scrollToTopErrorById(
                    getIdsFromValidationMessages(result.Messages),
                );
                setFormulaErrors(
                    setValidationErrors(
                        result.Messages,
                        kpiFormula.formulaItems.ids,
                        i18n,
                    ),
                );
                setScopedErrors(getTabsErrors(result.Messages));

                toast.dismiss();

                toast.error(
                    <div>
                        {t(k.SAVE_FAILED)}: <br />
                        {Object.keys(result.Messages).map((key, i) => (
                            <div key={i}>
                                {i18n.exists(key)
                                    ? t(k[key as keyof typeof k])
                                    : i18n.exists(result.Messages[key] ?? "")
                                      ? t(result.Messages[key] ?? "")
                                      : result.Messages[key]}
                            </div>
                        ))}
                    </div>,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    if (canUpdate === false && !isLoadingCanUpdate) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(k.NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE)}
            />
        );
    }
    const tabList = kpiFormats ? ["1", "2", "3", "4"] : ["1", "2", "4"];
    return (
        <ModalContent maxWidth="full">
            <ModalContent.Header
                title={isCreate ? t(k.CREATE_KPI) : t(k.EDIT_KPI)}
                onClose={handleOnClose}
            />
            <ModalContent.Body className="kpi-form kpi-form--body">
                <CurrentScrolledSection
                    contentRef={contentRef}
                    mobileView={false}
                    initialSection={"1"}
                    scrollOffset={150}
                    children={(scrollToTarget, currentSection) => (
                        <SplitLayout
                            tabs={tabList.map((id) => {
                                const tab = tabs[id];
                                const handleTabClick = (tabId: string) => {
                                    scrollToTarget(tabId, stepRefs);
                                };

                                return (
                                    <Tab
                                        item={tab}
                                        key={tab.id}
                                        active={tab.id === currentSection}
                                        onClick={handleTabClick}
                                        errors={scopedErrors[tab.id]}
                                    />
                                );
                            })}
                            content={
                                isLoading ? (
                                    <AnimatedSpinner
                                        isVisible={isLoading}
                                        position="center"
                                        aligned="center"
                                    />
                                ) : (
                                    <>
                                        <KpiDetailsForm
                                            stepRef={stepRefs["1"]}
                                            dataId={"1"}
                                            kpiDetails={kpiDetails}
                                            setKpiDetails={setKpiDetails}
                                            errors={allErrors}
                                            onHaveChanges={props.onHaveChanges}
                                        />

                                        <CreateFormula
                                            stepRef={stepRefs["2"]}
                                            dataId={"2"}
                                            kpiFormula={kpiFormula}
                                            setKpiFormula={setKpiFormula}
                                            allErrors={allErrors}
                                            onHaveChanges={props.onHaveChanges}
                                            errors={formulaErrors}
                                            kpiId={isCreate ? undefined : id}
                                        />

                                        {kpiFormats && (
                                            <SetTargets
                                                stepRef={stepRefs["3"]}
                                                dataId={"3"}
                                                kpiFormats={kpiFormats}
                                                target={target}
                                                setTarget={setTarget}
                                                onChangeKpiFormats={
                                                    setKpiFormats
                                                }
                                                onHaveChanges={
                                                    props.onHaveChanges
                                                }
                                                errors={allErrors}
                                            />
                                        )}

                                        <SetResponsible
                                            stepRef={stepRefs["4"]}
                                            dataId={"4"}
                                            responsibles={responsibles}
                                            setResponsibles={setResponsibles}
                                            errors={allErrors}
                                            onHaveChanges={props.onHaveChanges}
                                        />
                                    </>
                                )
                            }
                            tips={
                                <QuickTips
                                    tips={
                                        currentSection
                                            ? tabs[currentSection].tips
                                            : []
                                    }
                                />
                            }
                            contentRef={contentRef}
                        />
                    )}
                />
            </ModalContent.Body>

            <ModalContent.Footer>
                <ButtonGroup className="kpi-form--footer">
                    <Button
                        variant="danger"
                        transparent
                        noMinWidth
                        testId="kpi-form-cancel-btn"
                        onClick={handleOnClose}
                    >
                        {t(k.CANCEL)}
                    </Button>

                    <Button
                        variant="success"
                        noMinWidth
                        testId="kpi-form-save-btn"
                        disabled={!isFormComplete || saveMutation.isPending}
                        onClick={handleSubmitForm}
                        isBusy={saveMutation.isPending}
                    >
                        {t(k.SAVE)}
                    </Button>
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default KpiForm;
