import "./AccordionButton.scss";

import classNames from "classnames";

import { ArrowDownIcon } from "common/components/icons";

interface IProps {
    isOpen?: boolean;
    closeToRight?: boolean;
}

const AccordionButton = ({ isOpen = false, closeToRight = false }: IProps) => {
    const className = classNames("accordion-button", {
        open: isOpen,
        "close-to-right": closeToRight && !isOpen,
    });
    return <ArrowDownIcon className={className} />;
};

export default AccordionButton;
