import { Model, ModelWithFields, TableState, attr, fk, many } from "redux-orm";

import IStep from "../IStep";
import IStepDTO from "../dto/IStepDTO";
import IStepListDTO from "../dto/IStepListDTO";
import { ActivityOrmModelType } from "./Activity";

class BaseStepModel extends Model<IStep> {
    public static fields = {
        id: attr(),
        name: attr(),
        description: attr(),
        status: attr(),
        index: attr(),
    };

    public static parseList(data: IStepListDTO): void {
        const Activity = this.session.Activity as ActivityOrmModelType;

        data.activities?.map((x) => Activity.parseDTO(x));

        data.steps?.map((x) => this.parseDTO(x));
    }

    public static parseDTO(data: IStepDTO): ModelWithFields<IStep> {
        return this.upsert(data);
    }
}

export class Step extends BaseStepModel {
    public static modelName = "Step";

    public static fields = {
        ...BaseStepModel.fields,
    };
}

export class LatestVersionStep extends BaseStepModel {
    public static modelName = "LatestVersionStep";
}

export class SelectedStep extends BaseStepModel {
    public static modelName = "SelectedStep";
}

export type StepOrmModelType = typeof Step;
export type LatestVersionStepOrmModelType = typeof LatestVersionStep;
export type SelectedStepOrmModelType = typeof SelectedStep;

export type StepOrmState = TableState<IStep>;

export default Step;
