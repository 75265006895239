import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import DarkBadge, { IDarkBadgeProps } from "common/components/badges/base-badges/badge-dark/DarkBadge";

interface INoActionsTakenBadgeProps extends IDarkBadgeProps {
    label?: string;
}

const NoActionsTakenBadge = (props: INoActionsTakenBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return <DarkBadge {...rest}>{label ?? t(k.NO_ACTIONS_TAKEN)}</DarkBadge>;
};

export default NoActionsTakenBadge;
