import { ORM } from "redux-orm";

import Activity, { ActivityOrmModelType } from "../models/orm/Activity";
import Step, {
    LatestVersionStep,
    LatestVersionStepOrmModelType,
    StepOrmModelType,
} from "../models/orm/Step";
import { IORMState } from "./IORMState";

interface IAllEntitiesModels {
    Activity: ActivityOrmModelType;

    Step: StepOrmModelType;
    LatestVersionStep: LatestVersionStepOrmModelType;
}

const orm = new ORM<IORMState>({
    stateSelector: (state: IORMState) => state,
});

orm.register<IAllEntitiesModels>(
    Activity,

    Step,
    LatestVersionStep,
);

export const initialOrmState: IORMState = orm.getEmptyState();

export default orm;
