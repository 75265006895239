import React from "react";

import { useTranslation } from "react-i18next";

import {
    DynamicImprovementStatus,
} from "models/enums/DynamicImprovementStatus";

import {
    ClosureReasonEnum,
    closureReasonItems,
} from "models/enums/ClosureReasonEnum";
import { getLocaleString } from "i18n/components/GetLocaleString";
import RegisteredBadge from "../simple-badges/badge-registered/RegisteredBadge";
import AnalysingBadge from "../simple-badges/badge-analysing/AnalysingBadge";
import ActingBadge from "../simple-badges/badge-acting/ActingBadge";
import VerifyingBadge from "../simple-badges/badge-verifying/VerifyingBadge";
import ClosedBadge from "../simple-badges/badge-closed/ClosedBadge";
import { IValueLabelItem } from "common/IValueLabelItem";

interface ImprovementStatusBadgeProps {
    status?: DynamicImprovementStatus;
    closureReason?: ClosureReasonEnum;
}

const ImprovementStatusBadge: React.FC<ImprovementStatusBadgeProps> = (
    props,
) => {
    const { status, closureReason } = props;

    const { t } = useTranslation();

    const closureReasonItem = closureReason
        ? closureReasonItems[closureReason]
        : undefined;

    const getBadge = (
        status?: DynamicImprovementStatus,
        closureReason?: IValueLabelItem<ClosureReasonEnum, string, string>,
    ) => {
        switch (status) {
            case DynamicImprovementStatus.Registered:
                return <RegisteredBadge />;
            case DynamicImprovementStatus.Analysing:
                return <AnalysingBadge />;
            case DynamicImprovementStatus.Acting:
                return <ActingBadge />;
            case DynamicImprovementStatus.Verifying:
                return <VerifyingBadge />;
            case DynamicImprovementStatus.Closed:
                return (
                    <ClosedBadge
                        iconTooltip={{
                            id: closureReason?.param ?? "",
                            message: getLocaleString(t, closureReason?.label),
                        }}
                    />
                );

            default:
                return null;
        }
    };

    return getBadge(status, closureReasonItem);
};

export default ImprovementStatusBadge;
