import {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
    keepPreviousData,
    useQuery,
} from "@tanstack/react-query";

export interface IInitialFetchOptions {
    enabled?: boolean;
    syncData?: boolean;
}

export const useInitialFetch = <TData, TError>(
    queryKeys: QueryKey,
    queryFn: QueryFunction<TData>,
    { enabled = true, syncData = true }: IInitialFetchOptions,
    options?: Omit<
        UseQueryOptions<TData, TError>,
        "enabled" | "refetchOnMount" | "queryKey"
    >,
): UseQueryResult<TData, TError> =>
    useQuery<TData, TError>({
        queryKey: [...queryKeys],

        queryFn: queryFn,

        ...(options ?? {}),
        enabled: syncData ? enabled : false,
        placeholderData: syncData
            ? undefined //options?.keepPreviousData
            : keepPreviousData,
        //keepPreviousData: syncData ? options?.keepPreviousData : true,
        refetchOnMount: "always",
        refetchInterval: syncData ? options?.refetchInterval : false,
    });
