import "./MeasurementForm.scss";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OnChangeValue } from "react-select";
import { SortEnd } from "react-sortable-hoc";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { IInputActionDTO } from "../common/actions/api/IInputActionDTO";
import { EMeasurementGrading } from "./api/EMeasurementGrading";
import IMeasurementActionSetDTO from "./api/IMeasurementActionSetDTO";
import { IMeasurementDTO } from "./api/IMeasurementDTO";
import ActionPlan from "./components/ActionPlan";
import {
    convertToTableSet,
    convertToTableSetWithParentLink,
    validateMeasurement,
} from "./utils/MeasurementTools";
import { IValueLabelItem } from "common/IValueLabelItem";
import MeasurementStatusBadge from "common/components/badges/measurement-status-badge/MeasurementStatusBagde";
import Button from "common/components/buttons/Button";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import MeasurementStatusSelectionRow from "common/components/input-components/measurement-status-selection-row/MeasurementStatusSelectionRow";
import MeasurementStatusComment from "common/components/measurement-status-comment/MeasurementStatusComment";
import CustomModal from "common/components/modal-content/CustomModal";
import ModalContent from "common/components/modal-content/ModalContent";
import ParentGoalList from "common/components/parent-goal-list/ParentGoalList";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import SelectUsersDropdown from "common/components/select-users/SelectUsersDropdown";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import TeamsListEditable from "common/components/team-list/TeamsListEditable";
import { arrayMoveTo } from "common/utils/arrayUtils";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import UserMultiselect from "components/common/multiselect-dropdowns/UserMultiselect";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";
import { IParentGoalDTO } from "components/goals-page/api/IParentGoalDTO";
import InfoCard from "components/goals-page/components/InfoCard";
import IItemTypeRowDTO from "components/item-types/api/IItemTypeRowDTO";
import { ItemCategoryType } from "components/item-types/api/ItemCategoryType";
import { usePublishedUnitsList } from "components/item-types/api/hooks";
import ItemTypeModalForm from "components/item-types/components/ItemTypeModalForm";
import IUserDTO from "components/users/api/IUserDTO";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink, {
    createTableSetParentLink,
} from "http/ITableSetWithParentLink";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    data?: IMeasurementDTO;
    parentGoal: IParentGoalDTO;
    canEdit?: boolean;
    allUsers?: IUserDTO[];

    onClose: () => void;
    onSave: (item: IMeasurementDTO) => void;
    onDelete: (id: string) => void;
}

const MeasurementFormLocal = (props: IProps) => {
    const { parentGoal, canEdit, allUsers } = props;
    const userId = useSelector(
        (state: IAppState) => state.authViewState.profile?.id,
    );
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [haveChanges, setHaveChanges] = useState(false);

    const [isTypeFormVisible, setIsTypeFormVisible] = useState(false);

    const [errors, setErrors] = useState<IValidationMessages>({});

    const { data: unitsData } = usePublishedUnitsList();

    const [measurement, setMeasurement] = useState<IMeasurementDTO>({
        id: uuidv4(),
        parentGoal,
        startValue: 0,
        endValue: 100,
        status: EMeasurementGrading.OnTrack,
        responsibleUsersIds: [],
        involvedTeamsIds: [],
        isLocal: true,
    });

    const [actions, setActions] = useState<ITableSet<IMeasurementActionSetDTO>>(
        { ids: [], values: {} },
    );

    const [actionListItems, setActionListItems] = useState<
        ITableSetWithParentLink<
            IInputActionDTO,
            "inputActionIdsByInputActionSetId"
        >
    >(createTableSetParentLink("inputActionIdsByInputActionSetId"));

    const usersCanViewData = allUsers?.filter((x) =>
        measurement.usersIdsCanView?.includes(x.value ?? ""),
    );

    const setData = (measurementData: IMeasurementDTO) => {
        setMeasurement(measurementData);
        if (measurementData.inputActionSets) {
            setActions(convertToTableSet(measurementData.inputActionSets));
        }
        if (measurementData.inputActionsByInputActionSetId) {
            setActionListItems(
                convertToTableSetWithParentLink(
                    measurementData.inputActionsByInputActionSetId,
                ),
            );
        }
    };

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    }, [props.data]);

    const createNew = !props.data;

    const handleSave = async () => {
        const errors = validateMeasurement(measurement);
        if (Object.keys(errors).length > 0) {
            setErrors((prev) => ({ ...prev, ...errors }));
            scrollToTopErrorById(getIdsFromValidationMessages(errors));
            return;
        }
        const result: IMeasurementDTO = {
            ...measurement,
            inputActionSets:
                actions.ids.reduce<IMeasurementActionSetDTO[]>((acc, id) => {
                    const actionSet = actions.values[id];

                    if (actionSet) {
                        acc.push(actionSet);
                    }

                    return acc;
                }, []) ?? [],
            inputActionsByInputActionSetId: actions.ids.reduce<
                IDictionary<IInputActionDTO[]>
            >((acc, id) => {
                const setIds =
                    actionListItems.parents?.inputActionIdsByInputActionSetId?.[
                        id
                    ];
                if (setIds) {
                    const actionsBySet = setIds.reduce<IInputActionDTO[]>(
                        (list, id) => {
                            const action = actionListItems.values[id];

                            if (action) {
                                list.push(action);
                            }

                            return list;
                        },
                        [],
                    );

                    acc[id] = actionsBySet;
                }
                return acc;
            }, {}),
        };

        if (
            !result.responsibleUsersIds ||
            result.responsibleUsersIds.length === 0
        ) {
            result.responsibleUsersIds = userId ? [userId] : [];
        }

        props.onSave(result);
    };

    const handleOnChangeName = (e: IInputActionChangeEvent<string>) => {
        setMeasurement((prev) => ({
            ...prev,
            name: e.value,
        }));

        setHaveChanges(true);
    };

    const handleOnChangeDescription = (e: IInputChangeEvent<string>) => {
        setMeasurement((prev) => ({
            ...prev,
            description: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeStatus = async (status: EMeasurementGrading) => {
        setMeasurement((prev) => ({
            ...prev,
            status,
            statusComment: "",
        }));
        setHaveChanges(true);
    };

    const handleOnChangeStatusComment = (
        e: IInputActionChangeEvent<string>,
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            statusComment: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeStartDate = (
        e: IInputChangeEvent<Date | undefined>,
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            startDate: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeStartValue = (e: IInputChangeEvent<number>) => {
        setMeasurement((prev) => ({
            ...prev,
            startValue: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeEndValue = (e: IInputChangeEvent<number>) => {
        setMeasurement((prev) => ({
            ...prev,
            endValue: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeEndDate = (e: IInputChangeEvent<Date | undefined>) => {
        setMeasurement((prev) => ({
            ...prev,
            endDate: e.value,
        }));
        setHaveChanges(true);
    };

    const handleOnChangeUsers = (
        values: string[],
        options: IValueLabelItem<string, string, any>[],
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            responsibleUsersIds: values,
        }));
        setHaveChanges(true);
    };

    const handleOnSelectUsersCanView = (userId: string) => {
        setMeasurement((prev) => ({
            ...prev,
            usersIdsCanView: prev.usersIdsCanView?.includes(userId)
                ? prev.usersIdsCanView?.filter((x) => x !== userId)
                : [...(prev.usersIdsCanView ?? []), userId],
        }));
        setHaveChanges(true);
    };

    const handleOnChangeInvolvedTeams = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setMeasurement((prev) => ({
                ...prev,
                isTeamEveryone: !remove,
            }));
        } else {
            setMeasurement((prev) => ({
                ...prev,
                involvedTeamsIds: remove
                    ? prev.involvedTeamsIds?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.involvedTeamsIds ?? []), team.value],
            }));
        }
        setHaveChanges(true);
    };

    const handleOnChangeTeamsCanView = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setMeasurement((prev) => ({
                ...prev,
                isTeamEveryoneCanView: !remove,
            }));
        } else {
            setMeasurement((prev) => ({
                ...prev,
                teamsIdsCanView: remove
                    ? prev.teamsIdsCanView?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.teamsIdsCanView ?? []), team.value],
            }));
        }
        setHaveChanges(true);
    };

    const handleOnChangeUnit = (value: OnChangeValue<OptionType, boolean>) => {
        if (value) {
            if ("value" in value) {
                const selectedUnit = unitsData?.find(
                    (x) => x.value === value.value,
                );

                if (selectedUnit !== undefined) {
                    setMeasurement((prev) => ({
                        ...prev,
                        unit: {
                            id: selectedUnit.value,
                            name: selectedUnit.label,
                            type: selectedUnit.param as ItemCategoryType,
                            isArchived: false,
                        },
                    }));
                }
            }
        } else {
            setMeasurement((prev) => ({ ...prev, unit: undefined }));
        }

        setHaveChanges(true);
    };

    const handleOnCreateUnit = (e: { label: string }) => {
        if (e.label) {
            setMeasurement((prev) => ({
                ...prev,
                unit: {
                    id: uuidv4(),
                    name: e.label,
                    type: ItemCategoryType.Unit,
                    isArchived: false,
                },
            }));
        }
        setHaveChanges(true);
        setIsTypeFormVisible(true);
    };

    const handleOnSaveUnit = (value?: IItemTypeRowDTO) => {
        if (value) {
            setMeasurement((prev) => ({
                ...prev,
                unit: value,
            }));
        }
        setHaveChanges(true);
        setIsTypeFormVisible(false);
    };

    const handleOnClose = async () => {
        if (haveChanges) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE_UNSAVED_CHANGES)}</>,
                yesButtonVariant: "danger",
                title: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                if (props.data) {
                    setData(props.data);
                }

                setHaveChanges(false);

                props.onClose();
            }
        } else {
            props.onClose();
        }
    };

    const handleOnHaveChanges = (haveChange: boolean) => {
        setHaveChanges(haveChange);
    };

    const handleOnDelete = async () => {
        const confirmMsg: IShowConfirmArgs = {
            message: <>{t(k.ARE_YOU_SURE)}</>,
            yesButtonVariant: "danger",
            title: t(k.DELETE_THIS_MEASUREMENT),
        };

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        if (confirmOk) {
            props.onDelete(measurement.id);
        }
    };

    const handleOnSortedActionSets = async (params: SortEnd) => {
        setActions((prev) => ({
            ...prev,
            ids: arrayMoveTo(prev.ids, params.oldIndex, params.newIndex),
        }));
        handleOnHaveChanges(true);
    };

    const formatOptionLabel = (options: OptionType) => {
        return (
            <div className="custom-option-label">
                <MeasurementStatusBadge
                    status={options.value as EMeasurementGrading}
                />
            </div>
        );
    };

    return (
        <CustomModal onBackgroundClick={props.onClose}>
            <ModalContent>
                <ModalContent.Header
                    title={
                        createNew ? t(k.NEW_MEASUREMENT) : t(k.EDIT_MEASUREMENT)
                    }
                    onClose={handleOnClose}
                />
                <ModalContent.Body className="measurement-form--body">
                    {isTypeFormVisible && (
                        <ItemTypeModalForm
                            disabledDropdown
                            onSave={handleOnSaveUnit}
                            value={measurement.unit}
                            isCreate
                        />
                    )}
                    <div className="measurement-form--body--first-row">
                        <div
                            className={`measurement-form--body--first-row--name ${
                                !measurement.parentGoal ? "no-parent-goals" : ""
                            }`}
                        >
                            <ActionTextField
                                id={`measurement.${measurement.id}.name`}
                                label={t(k.NAME)}
                                required
                                multiline
                                minRows={1}
                                editMode
                                boldLabel
                                inputSize="large"
                                value={measurement.name ?? ""}
                                onChange={handleOnChangeName}
                                testId="measurement-form--name"
                                placeholder={t(k.ENTER_HERE)}
                                error={getLocaleString(
                                    t,
                                    errors?.[
                                        `measurement.${measurement.id}.name`
                                    ],
                                )}
                                invalid={
                                    !!errors?.[
                                        `measurement.${measurement.id}.name`
                                    ]
                                }
                            />

                            <UserMultiselect
                                className="responsible"
                                label={t(k.RESPONSIBLE)}
                                defaultValues={
                                    measurement.responsibleUsersIds ?? []
                                }
                                onChange={handleOnChangeUsers}
                            />
                            <InputWrapper wrapperLabel={t(k.STATUS)} boldLabel>
                                <MeasurementStatusSelectionRow
                                    status={
                                        measurement.status ??
                                        EMeasurementGrading.OnTrack
                                    }
                                    onClick={handleOnChangeStatus}
                                />
                            </InputWrapper>

                            <MeasurementStatusComment
                                status={measurement.status}
                                comment={measurement.statusComment}
                                editMode
                                canEdit
                                onChangeStatusComment={
                                    handleOnChangeStatusComment
                                }
                            />
                        </div>

                        {measurement.parentGoal && (
                            <ParentGoalList
                                parentGoals={[measurement.parentGoal]}
                                disabled
                            />
                        )}
                    </div>

                    <div className="measurement-form--body--unit-teams-row">
                        <div className="measurement-form--body--unit-teams-row--item">
                            <InputComponent
                                inputType={EActivityType.Textfield}
                                wrapperLabel={t(k.START_VALUE)}
                                fullWidth
                                boldLabel
                                type="number"
                                hideIcon
                                size="large"
                                value={measurement.startValue}
                                onChange={handleOnChangeStartValue}
                                testId="measurement-form--start-value"
                                placeholder={t(k.ENTER_HERE)}
                            />
                            <InputComponent
                                inputType={EActivityType.Textfield}
                                wrapperLabel={t(k.TARGET_VALUE)}
                                fullWidth
                                boldLabel
                                type="number"
                                hideIcon
                                size="large"
                                value={measurement.endValue}
                                onChange={handleOnChangeEndValue}
                                testId="measurement-form--target-value"
                                placeholder={t(k.ENTER_HERE)}
                            />
                            <InputWrapper
                                wrapperLabel={t(k.UNIT)}
                                boldLabel
                                fullWidth
                            >
                                <SelectDropdown
                                    id="unit"
                                    testId="measurement-form--item"
                                    value={unitsData?.find(
                                        (item) =>
                                            item.value === measurement.unit?.id,
                                    )}
                                    isClearable
                                    isSearchable
                                    bold
                                    placeholder={t(
                                        k.START_TYPING_TO_SELECT_OR_CREATE,
                                    )}
                                    options={unitsData}
                                    onChange={handleOnChangeUnit}
                                    onCreate={handleOnCreateUnit}
                                />
                            </InputWrapper>
                        </div>
                        <div className="measurement-form--body--unit-teams-row--item">
                            <InputWrapper
                                wrapperLabel={t(k.INVOLVED_TEAMS)}
                                boldLabel
                                fullWidth
                            >
                                <TeamsListEditable
                                    editMode
                                    clearable
                                    icon={null}
                                    teamIds={measurement.involvedTeamsIds}
                                    isTeamEveryone={measurement.isTeamEveryone}
                                    onChange={handleOnChangeInvolvedTeams}
                                />
                            </InputWrapper>
                            <InputWrapper
                                wrapperLabel={t(k.CAN_VIEW)}
                                boldLabel
                                fullWidth
                            >
                                <div className="measurement-form--body--unit-teams-row--item vertical">
                                    <TeamsListEditable
                                        editMode
                                        clearable
                                        icon={null}
                                        teamIds={measurement.teamsIdsCanView}
                                        isTeamEveryone={
                                            measurement.isTeamEveryoneCanView
                                        }
                                        onChange={handleOnChangeTeamsCanView}
                                    />
                                    <div className="measurement-form--body--unit-teams-row--item horizontal">
                                        <SelectedUsersAvatarList
                                            size={24}
                                            users={usersCanViewData ?? []}
                                        />
                                        <SelectUsersDropdown
                                            users={allUsers ?? []}
                                            selectedUserIds={
                                                measurement.usersIdsCanView ??
                                                []
                                            }
                                            onSelect={
                                                handleOnSelectUsersCanView
                                            }
                                        />
                                    </div>
                                </div>
                            </InputWrapper>
                        </div>
                    </div>

                    <InfoCard
                        label={t(k.DESCRIPTION)}
                        childrenClassName="goal-form--body--description"
                    >
                        <div className="timeframe">
                            <InputComponent
                                id={`measurement.${measurement.id}.startDate`}
                                testId="measurement_startDate_input"
                                inputType={EActivityType.DateTime}
                                wrapperLabel={t(k.START)}
                                value={measurement.startDate}
                                name="startDate"
                                showTimeSelect={false}
                                placeholder={t(k.START)}
                                hideIcon
                                noMinWidth
                                shouldCloseOnSelect
                                onChange={handleOnChangeStartDate}
                                invalid={
                                    !!errors?.[
                                        `measurement.${measurement.id}.startDate`
                                    ]
                                }
                                errors={getLocaleString(
                                    t,
                                    errors?.[
                                        `measurement.${measurement.id}.startDate`
                                    ],
                                )}
                            />

                            <InputComponent
                                testId="measurement_endDate_input"
                                inputType={EActivityType.DateTime}
                                value={measurement.endDate}
                                name="endDate"
                                showTimeSelect={false}
                                wrapperLabel={t(k.DUE)}
                                placeholder={t(k.DUE)}
                                hideIcon
                                noMinWidth
                                shouldCloseOnSelect
                                onChange={handleOnChangeEndDate}
                            />
                        </div>

                        <InputComponent
                            inputType={EActivityType.Textfield}
                            boldLabel
                            hideIcon
                            value={measurement.description ?? ""}
                            onChange={handleOnChangeDescription}
                            multiline
                            minRows={5}
                            testId="measurement-form--description"
                            placeholder={t(k.ENTER_HERE)}
                        />
                    </InfoCard>
                    <ActionPlan
                        isDraggable
                        editMode={true}
                        canEdit={canEdit}
                        inputActionSets={actions}
                        inputActions={actionListItems}
                        setInputActionSets={setActions}
                        onSortedActionSets={handleOnSortedActionSets}
                        setInputActions={setActionListItems}
                        onHaveChanges={handleOnHaveChanges}
                    />
                </ModalContent.Body>

                <ModalContent.Footer className="measurement-form--footer">
                    <Button
                        onClick={handleOnClose}
                        variant="danger"
                        transparent
                    >
                        {t(k.CLOSE)}
                    </Button>
                    {!createNew && (
                        <Button
                            onClick={handleOnDelete}
                            variant="danger"
                            transparent
                        >
                            {t(k.DELETE)}
                        </Button>
                    )}

                    <Button onClick={handleSave} variant="success">
                        {t(k.SAVE)}
                    </Button>
                </ModalContent.Footer>
            </ModalContent>
        </CustomModal>
    );
};

export default MeasurementFormLocal;
