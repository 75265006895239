import React from "react";

import classNames from "classnames";

import Tooltip, { TooltipProps } from "../../tooltip/Tooltip";

import "./Badge.scss";

export interface IBaseBadgeProps {
    testId?: string;
    title?: string;
    textEllipsis?: boolean;
    tooltip?: TooltipProps;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface IBadgeProps extends IBaseBadgeProps {
    className?: string;
}

const Badge = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<IBadgeProps>
>((props, ref) => {
    const {
        testId,
        textEllipsis,
        title,
        tooltip,
        className,
        children,
        onClick,
    } = props;

    const badgeClassName = classNames("app-badge", className, {
        "app-badge--clickable": onClick,
        "text-ellipsis": textEllipsis,
    });

    const renderBadge = (
        <div
            ref={ref}
            onClick={onClick}
            className={badgeClassName}
            data-testid={testId}
            title={title}
        >
            {children}
        </div>
    );

    if (tooltip) {
        return <Tooltip {...tooltip}>{renderBadge}</Tooltip>;
    }

    return renderBadge;
});

export default Badge;
