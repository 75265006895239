import "./ArchivedBadge.scss";

import k from "i18n/keys";
import { useTranslation } from "react-i18next";

import Badge, { IBaseBadgeProps } from "../../base-badges/Badge";

interface IArchivedBadgeProps extends IBaseBadgeProps {
    label?: string;
}

const ArchivedBadge = (props: IArchivedBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Badge className="archived-badge" {...rest}>
            {label ?? t(k.ARCHIVED)}
        </Badge>
    );
};

export default ArchivedBadge;
