import "./ReportTableWrapper.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import AccordionLeft from "common/components/accordion/AccordionLeft";

interface IProps {
    templateName?: string;
}

const ReportTableWrapper: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { templateName } = props;

    const { t } = useTranslation();

    return (
        <AccordionLeft
            wrapperClassName="report-table__wrapper"
            open={true}
            header={
                <React.Fragment>
                    {`${t(k.CHECKLIST_TEMPLATE)}: `}
                    <strong>{templateName}</strong>
                </React.Fragment>
            }
        >
            {props.children}
        </AccordionLeft>
    );
};

export default ReportTableWrapper;
