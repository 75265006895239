import { Action } from "redux";

import ICheckRemoveTeamResponse from "http/responses/ICheckRemoveTeamResponse";

export const TRY_FINALIZE_STEPINSTANCE_BY_ID =
    "TRY_FINALIZE_STEPINSTANCE_BY_ID";

export const REQUEST_FINALIZE_STEP_INSTANCE = "REQUEST_FINALIZE_STEP_INSTANCE";

export const SET_LAST_FINALIZED_STEPINSTANCE_ID =
    "SET_LAST_FINALIZED_STEPINSTANCE_ID";

export const REQUEST_CHECK_REMOVE_TEAM = "REQUEST_CHECK_REMOVE_TEAM";
export const RECIEVE_CHECK_REMOVE_TEAM = "RECIEVE_CHECK_REMOVE_TEAM";

interface IStepInstanceTryFinalizeStepInstanceAction
    extends Action<typeof TRY_FINALIZE_STEPINSTANCE_BY_ID> {
    payload: string;
}

interface IStepInstanceRequestFinalizeStepInstanceAction
    extends Action<typeof REQUEST_FINALIZE_STEP_INSTANCE> {}

interface IStepInstanceSetLastFinalizedId
    extends Action<typeof SET_LAST_FINALIZED_STEPINSTANCE_ID> {
    payload: string;
}

interface IStepInstanceRequestCheckRemoveTeam
    extends Action<typeof REQUEST_CHECK_REMOVE_TEAM> {}
interface IStepInstanceResieveCheckRemoveTeam
    extends Action<typeof RECIEVE_CHECK_REMOVE_TEAM> {
    payload: ICheckRemoveTeamResponse;
}

export type StepInstanceActionTypes =
    | IStepInstanceTryFinalizeStepInstanceAction
    | IStepInstanceRequestFinalizeStepInstanceAction
    | IStepInstanceSetLastFinalizedId
    | IStepInstanceRequestCheckRemoveTeam
    | IStepInstanceResieveCheckRemoveTeam;
