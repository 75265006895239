import "./ScoreBadge.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Badge, { IBaseBadgeProps } from "../Badge";
import classNames from "classnames";

type Score = "xs" | "sm" | "md" | "lg" | "xl";

export interface IScoreBadgeProps extends IBaseBadgeProps {
    label?: string | number | null;
    score?: Score;
}

const ScoreBadge = (props: IScoreBadgeProps) => {
    const { label, ...rest } = props;

    const { t } = useTranslation();

    const className = classNames("score-badge", {
        [`score-badge-${props.score}`]: props.score,
    });

    return (
        <Badge className={className} {...rest}>
            {label ?? t(k.NOT_AVAILABLE_MESSAGE)}
        </Badge>
    );
};

export default ScoreBadge;
