import axios from "axios";

import { IMeasurementProgressFilter } from "./IMeasurementProgressFilter";
import { IMeasurementProgressValueDTO } from "./IMeasurementProgressValueDTO";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { measurementKeys } from "components/measurement/api/hooks";
import IArchiveRequest from "http/requests/IArchiveRequest";

const baseUrl = "/api/measurementProgressValue";

const measurementProgressKeys = {
    all: ["progressValues"] as const,
    getList: () => [...measurementProgressKeys.all] as const,
    progressListByMeasurementId: (measurementId?: string) =>
        [
            ...measurementProgressKeys.all,
            "progressList-byMeasurementId",
            measurementId,
        ] as const,
    calculatedMeasurementProgressValue: (filter?: IMeasurementProgressFilter) =>
        [
            ...measurementProgressKeys.all,
            "calculatedMeasurementProgressValue",
            filter,
        ] as const,
};

export const useSaveMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IMeasurementProgressValueDTO;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/${value.id}`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementProgressKeys.all,
            });

            queryClient.invalidateQueries({
                queryKey: measurementKeys.measurementById(
                    variables.value.measurementId,
                ),
            });
        },
    });
};

export const useMeasurementProgressValue = (
    filter?: IMeasurementProgressFilter,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/calculated-progress`;

    return useQuery({
        queryKey:
            measurementProgressKeys.calculatedMeasurementProgressValue(filter),

        queryFn: async () => {
            const response = await axios.post<IMeasurementProgressValueDTO>(
                url,
                filter,
            );

            return response.data;
        },

        enabled,
    });
};

export const useProgressListByMeasurementId = (measurementId?: string) => {
    const url = `${baseUrl}/list/${measurementId}`;

    return useQuery({
        queryKey:
            measurementProgressKeys.progressListByMeasurementId(measurementId),

        queryFn: async () => {
            const response =
                await axios.get<IMeasurementProgressValueDTO[]>(url);

            return response.data;
        },

        enabled: measurementId !== undefined && measurementId !== "",
    });
};

export const useDeleteMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            try {
                const response = await axios.delete<string>(`${baseUrl}/${id}`);

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementProgressKeys.all,
            });
        },
    });
};

export const useArchiveMeasurementProgressValueMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: IArchiveRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/archive`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementProgressKeys.all,
            });
        },
    });
};
