import React from "react";
import { StepInstanceStatus } from "models/enums/StepInstanceStatus";
import CompletedBadge from "../simple-badges/badge-completed/CompletedBadge";
import FinalizedBadge from "../simple-badges/badge-finalized/FinalizedBadge";
import BlockedBadge from "../simple-badges/badge-blocked/BlockedBadge";
import BlockingBadge from "../simple-badges/badge-blocking/BlockingBadge";

interface IProps {
    status?: StepInstanceStatus;
}

const StepInstanceStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status } = props;

    switch (status) {
        case StepInstanceStatus.Finalized:
            return <FinalizedBadge />;
        case StepInstanceStatus.Completed:
            return <CompletedBadge />;
        case StepInstanceStatus.Blocked:
            return <BlockedBadge />;
        case StepInstanceStatus.IsBlocking:
            return <BlockingBadge />;
        default:
            return null;
    }
};

export default StepInstanceStatusBadge;
