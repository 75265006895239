import "./ActionPlan.scss";

import React, {
    Dispatch,
    SetStateAction,
    createRef,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { SortEnd, SortEvent, SortStart } from "react-sortable-hoc";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { IInputActionDTO } from "../../common/actions/api/IInputActionDTO";
import IMeasurementActionSetDTO from "../api/IMeasurementActionSetDTO";
import {
    useDeleteMeasurementActionSetMutation,
    useSaveInputActionSetMutation,
} from "../api/hooks";
import { ButtonGroup } from "common/components/buttons";
import Button from "common/components/buttons/Button";
import DeleteUndoButton from "common/components/buttons/delete-undo-button/DeleteUndoButton";
import CollapsibleItem from "common/components/collapsableItem/CollapsableItem";
import ImprovementSummary from "common/components/deviation/ImprovementSummary";
import { LoadingIcon } from "common/components/icons";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import IDictionary from "common/viewModels/IDictionary";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import InputActionComponent from "components/common/actions/InputActionComponent";
import {
    useAssignUserToInputActionMutation,
    useCompleteActionInputTaskMutation,
    useDeleteInputActionMutation,
    useSaveInputActionMutation,
} from "components/common/actions/api/hooks";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import {
    Container,
    SortableItem,
} from "components/common/sortableLists/SortableList";
import InfoCard from "components/goals-page/components/InfoCard";
import IImprovementFilter, {
    EImprovementView,
} from "components/improvements/api/IImprovementFIlter";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink from "http/ITableSetWithParentLink";
import { ActivityType } from "models/enums/ActivityType";
import { ETransactionStatus } from "models/enums/TransactionStatus";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    measurementId?: string;
    canEdit?: boolean;
    disableEdit?: boolean;
    editMode?: boolean;
    canEditInPreview?: boolean;
    isDraggable?: boolean;
    inputActionSets?: ITableSet<IMeasurementActionSetDTO>;
    inputActions?: ITableSetWithParentLink<
        IInputActionDTO,
        "inputActionIdsByInputActionSetId"
    >;
    setInputActionSets?: Dispatch<
        SetStateAction<ITableSet<IMeasurementActionSetDTO>>
    >;
    setInputActions?: Dispatch<
        SetStateAction<
            ITableSetWithParentLink<
                IInputActionDTO,
                "inputActionIdsByInputActionSetId"
            >
        >
    >;
    setActiveTransactions?: Dispatch<
        SetStateAction<IDictionary<ETransactionStatus>>
    >;
    onSortedActionSets?: (params: SortEnd) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onHavePreviewChanges?: (haveChanges: boolean) => void;
    onMeasurementRefetch?: () => void;
    onSave?: () => void;
}

const ActionPlan = (props: IProps) => {
    const {
        inputActionSets,
        inputActions,
        editMode,
        isDraggable,
        canEdit,
        canEditInPreview,
        disableEdit,
        measurementId,
    } = props;

    const { t } = useTranslation();
    const assignUserMutation = useAssignUserToInputActionMutation();
    const completeTaskMutation = useCompleteActionInputTaskMutation();
    const saveInputActionMutation = useSaveInputActionMutation();
    const deleteInputActionMutation = useDeleteInputActionMutation();
    const saveInputActionSetMutation = useSaveInputActionSetMutation();
    const deleteInputActionSetMutation =
        useDeleteMeasurementActionSetMutation();

    const dispatch = useDispatch();

    const modalManagerContext = useContext(ModalManagerContext);

    const [toBeFocused, setToBeFocused] = useState<string | undefined>(
        undefined,
    );

    const [currentFocused, setCurrentFocused] = useState<string | undefined>(
        undefined,
    );

    const [localInputAction, setLocalInputAction] = useState<
        IInputActionDTO | undefined
    >(undefined);

    const [localInputActionSet, setLocalInputActionSet] = useState<
        IMeasurementActionSetDTO | undefined
    >(undefined);

    const inputRefs = useMemo<
        IDictionary<React.RefObject<HTMLInputElement>>
    >(() => {
        const allIds = (inputActionSets?.ids ?? []).concat(
            inputActions?.ids ?? [],
        );

        return allIds.reduce<IDictionary<React.RefObject<HTMLInputElement>>>(
            (acc, id) => {
                acc[id] = createRef();
                return acc;
            },
            {},
        );
    }, [inputActions, inputActionSets]);

    useEffect(() => {
        if (toBeFocused) {
            inputRefs[toBeFocused]?.current?.focus();
        }
    }, [toBeFocused, inputRefs]);

    const handleOnSortStart = (params: SortStart, event: SortEvent) => {
        if (event instanceof MouseEvent) {
            document.body.classList.add("grabbing-sort-item");
        }
    };

    const handleOnSortEnd = (params: SortEnd) => {
        props.onSortedActionSets?.(params);

        document.body.classList.remove("grabbing-sort-item");
    };

    const handleOnAddStaticAction = async () => {
        if (editMode) {
            const newSetId = uuidv4();
            const newActionSet = {
                id: newSetId,
                label: t(k.NOTES),
                actionType: ActivityType.Text,
                isRequired: false,
                acceptMultiple: false,
                measurementId: measurementId,
                index: 0,
            };

            const newInputId = uuidv4();
            const newInputAction = {
                id: newInputId,
                label: "",
                placeholder: "",
                actionType: ActivityType.Text,
                isRequired: false,
                acceptMultiple: false,
                setId: newSetId,
            };

            props.setInputActionSets?.((prev) => ({
                ...prev,
                ids: [...prev.ids, newSetId],
                values: {
                    ...prev.values,
                    [newSetId]: newActionSet,
                },
            }));
            props.setInputActions?.((prev) => ({
                ...prev,
                parents: {
                    ...prev.parents,
                    inputActionIdsByInputActionSetId: {
                        ...prev.parents?.inputActionIdsByInputActionSetId,
                        [newSetId]: [newInputId],
                    },
                },
                values: {
                    ...prev.values,
                    [newInputId]: newInputAction,
                },
            }));
            props.onHaveChanges(true);
        } else {
            const newSetId = uuidv4();

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                addStaticAction: ETransactionStatus.Pending,
            }));

            const result = await saveInputActionSetMutation.mutateAsync({
                isCreate: true,
                value: {
                    id: newSetId,
                    label: t(k.NOTES),
                    actionType: ActivityType.Text,
                    measurementId: measurementId,
                    index: 0,
                    inputActions: [
                        {
                            id: uuidv4(),
                            label: "",
                            actionType: ActivityType.Text,
                            isRequired: false,
                            setId: newSetId,
                        },
                    ],
                },
            });

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                addStaticAction: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Succeeded) {
                props.onMeasurementRefetch?.();
                setToBeFocused(result.Data?.firstInputActionId);
            }
        }
    };

    const handleOnAddTaskList = async () => {
        if (editMode) {
            const newSetId = uuidv4();
            const newActionSet = {
                id: newSetId,
                label: t(k.TASK_LIST),
                actionType: ActivityType.Tasklist,
                isRequired: false,
                acceptMultiple: false,
                measurementId: measurementId,
                index: 0,
            };

            const newInputId = uuidv4();
            const newInputAction = {
                id: newInputId,
                label: "",
                placeholder: "",
                actionType: ActivityType.Tasklist,
                isRequired: false,
                acceptMultiple: false,
                setId: newSetId,
            };

            props.setInputActionSets?.((prev) => ({
                ...prev,
                ids: [...prev.ids, newSetId],
                values: {
                    ...prev.values,
                    [newSetId]: newActionSet,
                },
            }));

            props.setInputActions?.((prev) => ({
                ...prev,
                parents: {
                    ...prev.parents,
                    inputActionIdsByInputActionSetId: {
                        ...prev.parents?.inputActionIdsByInputActionSetId,
                        [newSetId]: [newInputId],
                    },
                },
                values: {
                    ...prev.values,
                    [newInputId]: newInputAction,
                },
            }));
            props.onHaveChanges(true);
        } else {
            const newSetId = uuidv4();

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                addTaskList: ETransactionStatus.Pending,
            }));

            const result = await saveInputActionSetMutation.mutateAsync({
                isCreate: true,
                value: {
                    id: newSetId,
                    label: t(k.TASK_LIST),
                    actionType: ActivityType.Tasklist,
                    measurementId: measurementId,
                    index: 0,
                    inputActions: [
                        {
                            id: uuidv4(),
                            label: "",
                            actionType: ActivityType.Tasklist,
                            isRequired: false,
                            setId: newSetId,
                        },
                    ],
                },
            });

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                addTaskList: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Succeeded) {
                props.onMeasurementRefetch?.();
                setToBeFocused(result.Data?.firstInputActionId);
            }
        }
    };

    const inputActionsBySetId = useMemo(() => {
        if (inputActionSets && inputActionSets.ids) {
            const result = inputActionSets.ids.reduce<
                IDictionary<IInputActionDTO[]>
            >((acc, setId) => {
                const inputActionIds =
                    inputActions?.parents?.inputActionIdsByInputActionSetId?.[
                        setId
                    ];

                if (inputActionIds) {
                    const inputActionsInSet = inputActionIds.map(
                        (id) => inputActions.values[id],
                    );

                    acc[setId] = inputActionsInSet.filter(
                        (x) => x !== undefined,
                    ) as IInputActionDTO[];
                }

                return acc;
            }, {});

            return result;
        }

        return {};
    }, [inputActionSets, inputActions]);

    const handleOnDeleteAction = async (setId: string, isDeleted?: boolean) => {
        if (editMode) {
            props.setInputActionSets?.((prev) => {
                const inputActionSet = prev.values[setId];

                if (!inputActionSet) return prev;

                return {
                    ...prev,

                    values: {
                        ...prev.values,
                        [setId]: { ...inputActionSet, isDeleted: !isDeleted },
                    },
                };
            });
            props.onHaveChanges(true);
        } else {
            const title =
                inputActionSets?.values[setId]?.actionType ===
                ActivityType.Tasklist
                    ? t(k.DELETE_THIS_TASK_LIST)
                    : t(k.DELETE_THESE_NOTES);
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
                title: title,
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    deleteAction: ETransactionStatus.Pending,
                }));

                const result =
                    await deleteInputActionSetMutation.mutateAsync(setId);

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    deleteAction: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));

                if (result.Succeeded) {
                    props.onMeasurementRefetch?.();
                }
            }
        }
    };

    const handleOnShowImprovements = (
        onlyNotCompleted: boolean,
        setId: string,
        label?: string,
    ) => {
        const impFilter: IImprovementFilter = {
            [EImprovementView.actionSet]: setId,
            active: onlyNotCompleted,
            closed: !onlyNotCompleted,
        };

        const payload: IShowModalPayload = {
            modalFor: {
                type: ModalTypes.measurement_form,
                id: setId ?? "",
            },

            type: ModalTypes.multiple_improvements,
            callerAddress: location.pathname,
            options: {
                impFilter,
                name: label,
            },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnNewImprovement = (
        inputActionId: string,
        setId: string,
        label?: string,
    ) => {
        const impFilter: IImprovementFilter = {
            [EImprovementView.actionSet]: inputActionId,
            forName: label,
        };

        const payload: IShowModalPayload = {
            callerAddress: location.pathname,
            type: ModalTypes.select_improvement_form,
            modalFor: {
                type: ModalTypes.measurement_form,
                id: setId ?? "",
            },
            options: { impFilter, createNew: true },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleOnAssignUser = async (
        userId: string,
        inputActionId: string,
        remove?: boolean,
    ) => {
        const oldInputActions = inputActions;

        props.setInputActions?.((prev) => {
            const inputAction = prev.values[inputActionId];

            if (inputAction) {
                const assignedUsersIds = remove
                    ? inputAction.assignedUsersIds?.filter(
                          (id) => id !== userId,
                      )
                    : [...(inputAction.assignedUsersIds ?? []), userId];
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        [inputActionId]: {
                            ...inputAction,
                            assignedUsersIds,
                        },
                    },
                };
            }

            return prev;
        });
        if (editMode) {
            props.onHaveChanges(true);
        } else {
            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["assignUserToTask_" + inputActionId]:
                    ETransactionStatus.Pending,
            }));

            const result = await assignUserMutation.mutateAsync({
                userId,
                inputActionId,
            });

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["assignUserToTask_" + inputActionId]: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Invalid) {
                if (oldInputActions) {
                    props.setInputActions?.(oldInputActions);
                }
            }
        }
    };

    const handleOnChangeSetsLabel = (setId: string, value: string) => {
        props.setInputActionSets?.((prev) => {
            const actionSet = prev.values[setId];

            if (actionSet) {
                return {
                    ...prev,
                    values: {
                        ...prev.values,
                        [setId]: {
                            ...actionSet,
                            label: value,
                        },
                    },
                };
            }
            return prev;
        });
        if (editMode) {
            props.onHaveChanges(true);
        } else {
            const inputActionSet = inputActionSets?.values[setId];
            if (inputActionSet) {
                setLocalInputActionSet({
                    ...inputActionSet,
                    label: value,
                });
            }
        }
    };

    const handleOnChange = async <V,>(e: IInputActionChangeEvent<V>) => {
        const { id, value, name } = e;
        if (id && name) {
            const oldInputActions = inputActions;
            props.setInputActions?.((prev) => {
                const inputAction = prev.values[id];

                if (inputAction) {
                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [id]: {
                                ...inputAction,
                                [name]: value,
                            },
                        },
                    };
                }
                return prev;
            });
            if (editMode) {
                props.onHaveChanges(true);
            } else if (name !== "taskValue") {
                const inputAction = inputActions?.values[id];
                if (inputAction) {
                    setLocalInputAction({
                        ...inputAction,
                        [name]: value?.toString() ?? "",
                    });
                }
            } else if (
                name === "taskValue" &&
                inputActions?.values[id]?.taskValue != value
            ) {
                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["completeTask_" + id]: ETransactionStatus.Pending,
                }));

                const result = await completeTaskMutation.mutateAsync(id);

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["completeTask_" + id]: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));

                if (result.Succeeded) {
                    props.onMeasurementRefetch?.();
                } else if (result.Invalid) {
                    if (oldInputActions) {
                        props.setInputActions?.(oldInputActions);
                    }
                }
            }
        }
    };

    const handleOnChangeList = async (setId: string, removeId?: string) => {
        if (editMode) {
            if (removeId) {
                props.setInputActions?.((prev) => {
                    const { [removeId]: inputAction, ...rest } = prev.values;

                    if (inputAction) {
                        return {
                            ...prev,
                            ids: prev.ids.filter((id) => id !== removeId),
                            values: rest,
                            parents: {
                                ...prev.parents,
                                inputActionIdsByInputActionSetId: {
                                    ...prev.parents
                                        ?.inputActionIdsByInputActionSetId,
                                    [setId]:
                                        prev.parents?.inputActionIdsByInputActionSetId?.[
                                            setId
                                        ]?.filter((i) => i !== removeId) ?? [],
                                },
                            },
                        };
                    }
                    return prev;
                });
            } else {
                props.setInputActions?.((prev) => {
                    const newId = uuidv4();
                    return {
                        ...prev,
                        ids: [...prev.ids, newId],
                        values: {
                            ...prev.values,
                            [newId]: {
                                id: newId,
                                label: "",
                                placeholder: "",
                                actionType:
                                    inputActionSets?.values?.[setId]
                                        ?.actionType ?? ActivityType.Tasklist,
                                isRequired: false,
                                acceptMultiple: false,
                            },
                        },
                        parents: {
                            ...prev.parents,
                            inputActionIdsByInputActionSetId: {
                                ...prev.parents
                                    ?.inputActionIdsByInputActionSetId,
                                [setId]: [
                                    ...(prev.parents
                                        ?.inputActionIdsByInputActionSetId?.[
                                        setId
                                    ] ?? []),
                                    newId,
                                ],
                            },
                        },
                    };
                });
            }

            props.onHaveChanges(true);
        } else {
            if (removeId) {
                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["removeInputAction_" + removeId]:
                        ETransactionStatus.Pending,
                }));

                const result =
                    await deleteInputActionMutation.mutateAsync(removeId);

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["removeInputAction_" + removeId]: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));

                if (result.Succeeded) {
                    props.onMeasurementRefetch?.();
                }
            } else {
                const newId = uuidv4();

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["addNewInputAction_" + newId]: ETransactionStatus.Pending,
                }));

                const result = await saveInputActionMutation.mutateAsync({
                    isCreate: true,
                    value: {
                        id: newId,
                        label: "",
                        actionType:
                            inputActionSets?.values?.[setId]?.actionType ??
                            ActivityType.Tasklist,
                        isRequired: false,
                        setId: setId,
                    },
                });

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["addNewInputAction_" + newId]: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));

                if (result.Succeeded) {
                    setToBeFocused(result.Data);
                    props.onMeasurementRefetch?.();
                }
            }
        }
    };

    const handleOnSortList = async (setId: string, updatedOrder: string[]) => {
        if (disableEdit) return;
        const oldInputActions = inputActions;

        props.setInputActions?.((prev) => ({
            ...prev,
            parents: {
                ...prev.parents,
                inputActionIdsByInputActionSetId: {
                    ...prev.parents?.inputActionIdsByInputActionSetId,
                    [setId]: updatedOrder,
                },
            },
        }));
        if (editMode) {
            props.onHaveChanges(true);
        } else if (canEditInPreview) {
            const inputActionSet = inputActionSets?.values[setId];
            if (inputActionSet) {
                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["sortInputActionSet_" + inputActionSet.id]:
                        ETransactionStatus.Pending,
                }));

                const result = await saveInputActionSetMutation.mutateAsync({
                    isCreate: false,
                    value: {
                        ...inputActionSet,
                        inputActions: updatedOrder.reduce<IInputActionDTO[]>(
                            (acc, id) => {
                                const inputAction = inputActions?.values[id];
                                if (inputAction) {
                                    acc.push(inputAction);
                                }
                                return acc;
                            },
                            [],
                        ),
                    },
                });

                props.setActiveTransactions?.((prev) => ({
                    ...prev,
                    ["sortInputActionSet_" + inputActionSet.id]:
                        result.Succeeded
                            ? ETransactionStatus.Success
                            : ETransactionStatus.Error,
                }));

                if (result.Invalid) {
                    if (oldInputActions) {
                        props.setInputActions?.(oldInputActions);
                    }
                }
            }
        }
    };

    const handleOnDeleteInputAction = async (inputActionId: string) => {
        const confirmMsg: IShowConfirmArgs = {
            message: <>{t(k.ARE_YOU_SURE)}</>,
            yesButtonVariant: "danger",
            yesLabel: t(k.DELETE),
            title: t(k.DELETE_THIS_TASK),
        };

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        if (confirmOk) {
            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["deleteInputAction_" + inputActionId]:
                    ETransactionStatus.Pending,
            }));

            const result =
                await deleteInputActionMutation.mutateAsync(inputActionId);

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["deleteInputAction_" + inputActionId]: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Succeeded) {
                props.onMeasurementRefetch?.();
            }
        }
    };

    const handleOnSaveInputActionSet = async (setId: string) => {
        if (localInputActionSet) {
            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["saveInputActionSet_" + localInputActionSet.id]:
                    ETransactionStatus.Pending,
            }));

            const result = await saveInputActionSetMutation.mutateAsync({
                isCreate: false,
                value: {
                    ...localInputActionSet,
                    inputActions: inputActionsBySetId[setId],
                },
            });

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["saveInputActionSet_" + localInputActionSet.id]:
                    result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
            }));

            if (result.Succeeded) {
                props.onMeasurementRefetch?.();
                setLocalInputActionSet(undefined);
            }
        }

        setCurrentFocused(undefined);
    };

    const handleOnFocus = (focusedId: string) => {
        setCurrentFocused(focusedId);
        setToBeFocused(undefined);
    };

    const handleOnSaveInputAction = async () => {
        if (localInputAction) {
            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["saveInputAction_" + localInputAction.id]:
                    ETransactionStatus.Pending,
            }));

            const result = await saveInputActionMutation.mutateAsync({
                isCreate: false,
                value: localInputAction,
            });

            props.setActiveTransactions?.((prev) => ({
                ...prev,
                ["saveInputAction_" + localInputAction.id]: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Succeeded) {
                setLocalInputAction(undefined);
                props.onMeasurementRefetch?.();
            }
        }

        setCurrentFocused(undefined);
    };

    const handleOnToggleCollapsibleLabel = (
        setId: string,
        collapsed: boolean,
    ) => {
        if (collapsed) {
            setToBeFocused(setId);
        } else {
            setToBeFocused(undefined);
        }
    };

    const currentInputActionSets =
        localInputActionSet?.id &&
        inputActionSets?.ids.includes(localInputActionSet?.id)
            ? inputActionSets?.ids.reduce<ITableSet<IMeasurementActionSetDTO>>(
                  (acc, id) => {
                      if (id === localInputActionSet?.id) {
                          acc.values[id] = localInputActionSet;
                      } else {
                          acc.values[id] = inputActionSets?.values[id];
                      }
                      return acc;
                  },
                  {
                      ids: [...inputActionSets?.ids],
                      values: { ...inputActionSets?.values },
                  },
              )
            : inputActionSets;

    return (
        <InfoCard
            label={t(k.ACTION_PLAN)}
            childrenClassName="measurement-action-plan"
        >
            {currentInputActionSets?.ids &&
            currentInputActionSets?.ids.length > 0 ? (
                <div>
                    <Container
                        lockAxis="y"
                        useDragHandle
                        helperClass="sortable-helper"
                        onSortStart={handleOnSortStart}
                        onSortEnd={handleOnSortEnd}
                    >
                        {currentInputActionSets?.ids.map((setId, index) => {
                            const item = currentInputActionSets?.values[setId];

                            if (!item) return null;

                            const isDeleted = item.isDeleted;

                            const onDeleteAction = async () => {
                                await handleOnDeleteAction(
                                    setId,
                                    item.isDeleted,
                                );
                            };

                            const onShowImprovements = (
                                onlyNotCompleted: boolean,
                            ) => {
                                handleOnShowImprovements(
                                    onlyNotCompleted,
                                    setId,
                                    item?.label,
                                );
                            };

                            const onNewImprovement = (
                                inputActionId: string,
                            ) => {
                                handleOnNewImprovement(
                                    inputActionId,
                                    setId,
                                    item?.label,
                                );
                            };

                            const onToggleCollapsibleLabel = (
                                collapsed: boolean,
                            ) => {
                                handleOnToggleCollapsibleLabel(
                                    setId,
                                    collapsed,
                                );
                            };

                            const onChangeLabel = (
                                e: IInputActionChangeEvent<string>,
                            ) => {
                                handleOnChangeSetsLabel(setId, e.value ?? "");
                            };

                            const onChangeList = (removeId?: string) => {
                                handleOnChangeList(setId, removeId);
                            };

                            const onSaveInputActionSet = async () => {
                                await handleOnSaveInputActionSet(setId);
                            };

                            const onFocusInputActionSetLabel = () => {
                                handleOnFocus(setId);
                            };

                            const currentInputActions = inputActionsBySetId[
                                setId
                            ]?.some(
                                (input) =>
                                    input.id &&
                                    input.id === localInputAction?.id,
                            )
                                ? inputActionsBySetId[setId].reduce<
                                      IInputActionDTO[]
                                  >((acc, input) => {
                                      if (
                                          input.id &&
                                          input.id === localInputAction?.id
                                      ) {
                                          acc.push(localInputAction);
                                      } else {
                                          acc.push(input);
                                      }
                                      return acc;
                                  }, [])
                                : inputActionsBySetId[setId];

                            return (
                                <React.Fragment key={item.id}>
                                    <SortableItem
                                        index={index}
                                        useHandle={
                                            isDraggable &&
                                            !disableEdit &&
                                            (canEditInPreview || editMode)
                                        }
                                        className="action-plan-item"
                                    >
                                        <div
                                            className="action-plan-item-container"
                                            data-testid={`measurement-action-set-${index}`}
                                        >
                                            <ImprovementSummary
                                                notCompletedCount={
                                                    item.notCompletedImprovementsCount
                                                }
                                                completedCount={
                                                    item.completedImprovementsCount
                                                }
                                                onClick={onShowImprovements}
                                            />
                                            <div className="action-plan-item-container--head">
                                                {(!!item.label || canEdit) &&
                                                    !disableEdit && (
                                                        <CollapsibleItem
                                                            label={t(
                                                                k.CLICK_TO_ADD_LABEL,
                                                            )}
                                                            onToggle={
                                                                onToggleCollapsibleLabel
                                                            }
                                                            collapsed={
                                                                editMode ===
                                                                    false &&
                                                                currentFocused !==
                                                                    item.id &&
                                                                !item.label
                                                            }
                                                            hideWhenOpen
                                                            collapseUpward
                                                        >
                                                            <ActionTextField
                                                                id={item.id}
                                                                name="label"
                                                                inputRef={
                                                                    inputRefs[
                                                                        item.id
                                                                    ]
                                                                }
                                                                value={
                                                                    item.label
                                                                }
                                                                editable={
                                                                    !(
                                                                        isDeleted ||
                                                                        !canEdit ||
                                                                        disableEdit
                                                                    )
                                                                }
                                                                editMode={
                                                                    editMode
                                                                }
                                                                placeholder={t(
                                                                    k.LABEL,
                                                                )}
                                                                fullWidth
                                                                onChange={
                                                                    onChangeLabel
                                                                }
                                                                onFocus={
                                                                    onFocusInputActionSetLabel
                                                                }
                                                                onBlur={
                                                                    onSaveInputActionSet
                                                                }
                                                            />
                                                        </CollapsibleItem>
                                                    )}
                                            </div>
                                            <div className="delete-btn">
                                                {inputActionSets?.values[
                                                    item.id
                                                ] &&
                                                    canEdit &&
                                                    !disableEdit && (
                                                        <DeleteUndoButton
                                                            testId="delete-btn"
                                                            isDeleted={
                                                                item.isDeleted
                                                            }
                                                            onDelete={
                                                                onDeleteAction
                                                            }
                                                        />
                                                    )}
                                            </div>

                                            <InputActionComponent
                                                id={setId}
                                                inputRefs={inputRefs}
                                                label={t(
                                                    k.PLACEHOLDER_FOR_INPUT_FIELD,
                                                )}
                                                actionType={item.actionType}
                                                disabled={
                                                    isDeleted || disableEdit
                                                }
                                                editable={canEditInPreview}
                                                editMode={editMode}
                                                inputActions={
                                                    currentInputActions
                                                }
                                                isLoading={
                                                    saveInputActionMutation.isPending
                                                }
                                                onChange={handleOnChange}
                                                onDeleteInputAction={
                                                    handleOnDeleteInputAction
                                                }
                                                onSaveInputAction={
                                                    handleOnSaveInputAction
                                                }
                                                onAssignUser={
                                                    handleOnAssignUser
                                                }
                                                onChangeList={onChangeList}
                                                onSortList={handleOnSortList}
                                                onNewImprovement={
                                                    onNewImprovement
                                                }
                                                onFocus={handleOnFocus}
                                            />
                                        </div>
                                    </SortableItem>
                                </React.Fragment>
                            );
                        })}
                    </Container>
                    {canEdit && !disableEdit && (
                        <ButtonGroup className="add-buttons">
                            <Button
                                icon={
                                    saveInputActionSetMutation.isPending ? (
                                        <LoadingIcon />
                                    ) : (
                                        <FiPlus size="22" />
                                    )
                                }
                                transparent
                                disabled={saveInputActionSetMutation.isPending}
                                onClick={handleOnAddStaticAction}
                            >
                                {t(k.ADD_NOTES)}
                            </Button>
                            <Button
                                icon={
                                    saveInputActionSetMutation.isPending ? (
                                        <LoadingIcon />
                                    ) : (
                                        <FiPlus size="22" />
                                    )
                                }
                                transparent
                                disabled={saveInputActionSetMutation.isPending}
                                onClick={handleOnAddTaskList}
                                testId="add-measurement-tasklistBtn"
                            >
                                {t(k.ADD_TASK_LIST)}
                            </Button>
                        </ButtonGroup>
                    )}
                </div>
            ) : (
                <div className="empty-action-plan">
                    <div className="title">{t(k.NO_ACTION_PLAN_ADDED_YET)}</div>
                    {canEdit && !disableEdit && (
                        <>
                            <div className="description">
                                <div>
                                    {t(k.YOU_CAN_ADD_TWO_TYPES_OF_ACTION_PLAN)}
                                </div>
                                <div>
                                    {t(
                                        k.YOU_CAN_ADD_STATIC_OR_TASK_LIST_ACTION_PLAN,
                                    )}
                                </div>
                            </div>

                            <ButtonGroup>
                                <Button
                                    icon={
                                        saveInputActionSetMutation.isPending ? (
                                            <LoadingIcon />
                                        ) : (
                                            <FiPlus size="22" />
                                        )
                                    }
                                    transparent
                                    disabled={
                                        saveInputActionSetMutation.isPending
                                    }
                                    onClick={handleOnAddStaticAction}
                                    testId="add-measurement-noteBtn"
                                >
                                    {t(k.ADD_NOTES)}
                                </Button>
                                <Button
                                    icon={
                                        saveInputActionSetMutation.isPending ? (
                                            <LoadingIcon />
                                        ) : (
                                            <FiPlus size="22" />
                                        )
                                    }
                                    transparent
                                    disabled={
                                        saveInputActionSetMutation.isPending
                                    }
                                    onClick={handleOnAddTaskList}
                                >
                                    {t(k.ADD_TASK_LIST)}
                                </Button>
                            </ButtonGroup>
                        </>
                    )}
                </div>
            )}
        </InfoCard>
    );
};

export default ActionPlan;
