import "./ImpactGradingCard.scss";

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ImpactGradingInput from "./ImpactGradingInput";
import ImpactGradingBadges from "common/components/badges/impact-grading-badges/ImpactGradingBadges";
import { InfoIcon, RequiredStarIcon } from "common/components/icons";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import IDictionary from "common/viewModels/IDictionary";
import { IImpactGradingOption } from "components/impact-grading-page/api/IImpactGradingOption";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";

interface IProps {
    impactId?: string;
    impactListId?: string;
    invalid: boolean;
    required: boolean;
    preview?: boolean;
    preCardContent?: React.ReactNode;

    impactList: IDictionary<ImpactGradingEnum> | null;
    disableBorder?: boolean;

    impactAreas: IImpactGradingOption[];

    disabled?: boolean;

    onChange?: (
        list: IDictionary<ImpactGradingEnum>,
        impactMax: ImpactGradingEnum,
    ) => void;
}

const castImpactGradings = (impactList: IDictionary<ImpactGradingEnum>) => {
    return Object.keys(impactList).reduce<IDictionary<boolean>>((acc, id) => {
        acc[id] = true;

        return acc;
    }, {});
};

const ImpactGradingCard: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const {
        impactId,
        impactListId,
        invalid,
        required,
        impactList,
        impactAreas,
        disabled,
        preview,
        disableBorder,
    } = props;

    const { t } = useTranslation();

    const [selectedImpactAreas, setSelectedImpactAreas] = React.useState(
        castImpactGradings(impactList ?? {}),
    );

    useEffect(() => {
        setSelectedImpactAreas(castImpactGradings(impactList ?? {}));
    }, [impactList]);

    const handleSelectImpactGradingArea = (e: IInputChangeEvent<boolean>) => {
        const { id: impactAreaId } = e;

        if (impactAreaId) {
            if (e.value) {
                setSelectedImpactAreas((prev) => ({
                    ...prev,
                    [impactAreaId]: true,
                }));

                handleSelectImpactGradingValue(
                    impactAreaId,
                    ImpactGradingEnum.None,
                );
            } else if (impactList && impactList[impactAreaId] !== undefined) {
                delete impactList[impactAreaId];

                const impactGradingValues =
                    Object.values(impactList).map(Number);

                setSelectedImpactAreas((prev) => ({
                    ...prev,
                    [impactAreaId]: false,
                }));

                const impactMax = impactGradingValues.length
                    ? Math.max(...impactGradingValues)
                    : ImpactGradingEnum.None;

                props.onChange?.(impactList, impactMax);
            }
        }
    };

    const handleSelectImpactGradingValue = (
        impactAreaId: string,
        value: ImpactGradingEnum,
    ) => {
        const impactGradingValues = [value]
            .concat(
                impactList
                    ? Object.keys(impactList)
                          .filter((id) => id !== impactAreaId)
                          .map(
                              (id) =>
                                  impactList?.[id] ?? ImpactGradingEnum.None,
                          )
                    : [],
            )
            .map(Number);

        const nextImpactList: IDictionary<ImpactGradingEnum> = {
            ...impactList,
            [impactAreaId]: value,
        };

        const impactMax = Math.max(...impactGradingValues);

        props.onChange?.(nextImpactList, impactMax);
    };

    const isManyImpactAreas = (impactAreas?.length ?? 0) > 1;

    return impactAreas && impactAreas.length > 0 ? (
        <div
            id={impactId}
            className={`impact-grading-card--relative ${
                invalid
                    ? "activity-instance--card impact-grading-card__invalid"
                    : disableBorder
                      ? "impact-grading-card--preview"
                      : "activity-instance--card"
            }`}
        >
            {
                <div className="impact-grading-card--relative--pre-card-content">
                    {props.preCardContent}
                </div>
            }
            {isManyImpactAreas && (
                <h6 className="activity-instance--title">
                    <strong>
                        {preview
                            ? t(k.IMPACTED_AREAS)
                            : t(k.CHOOSE_IMPACTED_AREAS)}
                    </strong>
                    {!preview && required && <RequiredStarIcon />}
                </h6>
            )}

            <div
                className="impact-grading-card__list-wrapper"
                id={impactListId}
            >
                {isManyImpactAreas && (
                    <div className="impact-grading-card__list">
                        {impactAreas?.reduce((acc, x) => {
                            const isChecked =
                                selectedImpactAreas[x.value] ?? false;

                            if (preview && !isChecked) {
                                return acc;
                            }

                            const checkbox = (
                                <React.Fragment key={x.value}>
                                    <Checkbox
                                        id={x.value}
                                        value={isChecked}
                                        label={
                                            <React.Fragment>
                                                {x.label}
                                                <InfoIcon
                                                    size="small"
                                                    tooltip={{
                                                        id: x.value,
                                                        message: x.description,
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        disabled={disabled}
                                        onChange={
                                            preview
                                                ? undefined
                                                : handleSelectImpactGradingArea
                                        }
                                    />
                                    {preview && (
                                        <div className="badge-container">
                                            <ImpactGradingBadges
                                                type={
                                                    impactList?.[x.value] ??
                                                    ImpactGradingEnum.None
                                                }
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            );

                            acc.push(checkbox);
                            return acc;
                        }, [] as JSX.Element[])}
                    </div>
                )}
                {!preview && (
                    <>
                        {impactAreas?.map(
                            (impactArea) =>
                                ((isManyImpactAreas &&
                                    selectedImpactAreas[impactArea.value]) ||
                                    impactAreas.length === 1) && (
                                    <div
                                        key={impactArea.value}
                                        className="impact-grading-card__options"
                                    >
                                        <strong>
                                            {t(k.WHAT_IS_THE_IMPACT_FOR)}{" "}
                                            {impactArea.label}?
                                        </strong>

                                        <ImpactGradingInput
                                            impactArea={impactArea}
                                            value={
                                                impactList?.[
                                                    impactArea.value
                                                ] ?? ImpactGradingEnum.None
                                            }
                                            disabled={disabled}
                                            onClick={
                                                handleSelectImpactGradingValue
                                            }
                                        />
                                    </div>
                                ),
                        )}
                    </>
                )}
            </div>
        </div>
    ) : null;
};

export default ImpactGradingCard;
