import "./Tab.scss";

import k from "i18n/keys";

import { TabData } from "common/components/modal-manager-content/kpi-form/KpiForm";
import IValidationMessages from "common/viewModels/IValidationMessages";
import React from "react";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "common/components/icons";

interface TabProps {
    item: TabData;
    errors?: IValidationMessages;
    active: boolean;
    onClick: (id: string) => void;
}

const Tab: React.FC<TabProps> = (props) => {
    const {
        item: { label, id },
        active,
        errors,
    } = props;

    const { t, i18n } = useTranslation();

    const handleOnClick = () => {
        props.onClick(id);
    };

    return (
        <div
            className={`tab ${active ? "selected" : ""}`}
            onClick={handleOnClick}
        >
            {t(label)}
            {errors && (
                <WarningIcon
                    size="small"
                    active
                    tooltip={{
                        id: "template-error-info",
                        message: [
                            <div
                                className="template-error-title"
                                key="template-error-title"
                            >
                                {t(k.TEMPLATE_FORM_ERRORS)}
                            </div>,
                            ...Object.keys(errors).reduce<React.ReactNode[]>(
                                (acc, key) => {
                                    const value = i18n.exists(key)
                                        ? t(k[key as keyof typeof k])
                                        : i18n.exists(errors[key] ?? "")
                                          ? t(errors[key] ?? "")
                                          : key;

                                    acc.push(<div key={key}>{value}</div>);

                                    return acc;
                                },
                                [],
                            ),
                        ],
                    }}
                />
            )}
        </div>
    );
};

export default Tab;
