import axios from "axios";

import { IImpactedProcessUpdateRequest } from "./IImpactedProcessUpdateRequest";
import { IImpactedProcessesDto } from "./IImpactedProcessesDto";
import IImprovementFilter, { EImprovementView } from "./IImprovementFIlter";
import ITemplateVersionConfigurationUsersDto from "./ITemplateVersionConfigurationUsersDto";
import ITemplateVersionConfigurationUsersRequest from "./ITemplateVersionConfigurationUsersRequest";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { convertToDate, getTimeZone } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import {
    STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY,
    STEPINSTANCES_BY_CHECKLIST_ID_KEY,
} from "components/checklists/api/hooks";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import IImprovementDto from "components/improvements/api/IImprovementDto";
import { IImportImprovementRequest } from "components/improvements/components/import-grid/IImportImprovementRequest";
import { ImprovementProcessChartImpactType } from "components/process-chart/api/improvements/ImprovementProcessChartImpactType";
import { ProcessChartImprovementType } from "components/process-chart/api/improvements/ProcessChartImprovementType";
import { IProcessChartImprovementFilter } from "components/process-chart/api/improvements/hooks";
import IPaginatedList from "http/IPaginatedList";
import { ITableList } from "http/ITableList";
import ITableSet from "http/ITableSet";
import { IActionSetImprovementsFilter } from "http/filters/IActionSetImprovementsFilter";
import { IChecklistImprovementsFilter } from "http/filters/IChecklistImprovementsFilter";
import IDeviationPageFilter from "http/filters/IDeviationPageFilter";
import ICompleteImprovementStepRequest from "http/requests/ICompleteImprovementStepRequest";
import IImprovementRequest from "http/requests/IImprovementRequest";
import IImprovementUpdateRequest from "http/requests/IImprovementUpdateRequest";
import IDeviation from "models/IDeviation";
import IStepInstance from "models/IStepInstance";
import IActivityListDTO from "models/dto/IActivityListDTO";
import IFinalizeStepInstanceDTO from "models/dto/IFinalizeStepInstanceDTO";

const improvementUrl = `/api/deviation`;
const templateUrl = `/api/template`;

const GET_PROCESS_CHART_IMPROVEMENTS = "GET_PROCESS_CHART_IMPROVEMENTS";
const GET_CHECKLIST_IMPROVEMENTS = "GET_CHECKLIST_IMPROVEMENTS";
const GET_IMPROVEMENTS_LIST = "GET_IMPROVEMENTS_LIST";
export const GET_IMPROVEMENT_BY_ID = "GET_IMPROVEMENT_BY_ID";
export const STEPINSTANCES_BY_IMPROVEMENT_ID_KEY =
    "STEPINSTANCES_BY_IMPROVEMENT_ID_KEY";
export const STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY =
    "step-instance-activities-by-improvement-id";
const IMPROVEMENT_SELECTED_IMPACT_GRADING_VALUES_BY_ID =
    "IMPROVEMENT_SELECTED_IMPACT_GRADING_VALUES_BY_ID";
const GET_ACTION_SET_IMPROVEMENTS = "GET_ACTION_SET_IMPROVEMENTS";

async function getImprovements(
    filters: IDeviationPageFilter,
    signal?: AbortSignal,
) {
    const url = `${improvementUrl}/list`;

    const response = await axios.post<ITableList<IDeviation>>(url, filters, {
        signal,
    });
    return response.data;
}

export const useImprovementsForPages = (
    enabled: boolean,
    filters?: IDeviationPageFilter,
) => {
    return useQuery({
        queryKey: [GET_IMPROVEMENTS_LIST, filters],

        queryFn: ({ signal }) => {
            if (filters) {
                return getImprovements(filters, signal);
            }
        },

        enabled,
        refetchOnWindowFocus: true,

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const castImprovementImpactedProcesses = (
    data: ITableSet<IDeviation>,
) => {
    const defaultValue = {
        [ProcessChartImprovementType.Process]: [],
        [ProcessChartImprovementType.SubProcess]: [],
        [ProcessChartImprovementType.ProcessStep]: [],
    };

    const result = data.ids.reduce<
        | Record<
              | ProcessChartImprovementType.Process
              | ProcessChartImprovementType.SubProcess
              | ProcessChartImprovementType.ProcessStep,
              string[]
          >
        | undefined
    >((acc, curr) => {
        const improvement = data.values[curr];

        if (improvement) {
            const { impactedProcessDiscovered, impactedProcessOriginated } =
                improvement;

            const impactedProcesses = [
                impactedProcessDiscovered,
                impactedProcessOriginated,
            ];

            for (const impactedProcess of impactedProcesses) {
                if (impactedProcess) {
                    const type = impactedProcess.label;

                    if (type !== ProcessChartImprovementType.None) {
                        if (acc === undefined) {
                            acc = defaultValue;
                        }

                        if (
                            acc[type].includes(impactedProcess.value) === false
                        ) {
                            acc[type].push(impactedProcess.value);
                        }
                    }
                }
            }
        }

        return acc;
    }, undefined);

    return result;
};

async function getProcessChartImprovements(
    filters: IProcessChartImprovementFilter,
    signal?: AbortSignal,
) {
    const url = `${improvementUrl}/process-chart`;

    const response = await axios.post<ITableList<IDeviation>>(url, filters, {
        signal,
    });

    return response.data;
}

const useProcessChartImprovements = (
    enabled: boolean,
    filters: IProcessChartImprovementFilter,
) => {
    return useQuery({
        queryKey: [
            GET_IMPROVEMENTS_LIST,
            GET_PROCESS_CHART_IMPROVEMENTS,
            filters,
        ],

        queryFn: ({ signal }) => {
            if (filters) {
                return getProcessChartImprovements(filters, signal);
            }
        },
    });
};

async function getChecklistImprovements(
    filters: IChecklistImprovementsFilter,
    signal?: AbortSignal,
) {
    const url = `${improvementUrl}/work`;

    const response = await axios.post<ITableList<IDeviation>>(url, filters, {
        signal,
    });

    return response.data;
}

const useChecklistImprovements = (
    enabled: boolean,
    filters: IChecklistImprovementsFilter,
) => {
    return useQuery({
        queryKey: [GET_IMPROVEMENTS_LIST, GET_CHECKLIST_IMPROVEMENTS, filters],
        queryFn: ({ signal }) => {
            if (filters) {
                return getChecklistImprovements(filters, signal);
            }
        },
    });
};

const useActionSetImprovements = (
    enabled: boolean,
    filters: IActionSetImprovementsFilter,
) => {
    return useQuery({
        queryKey: [GET_IMPROVEMENTS_LIST, GET_ACTION_SET_IMPROVEMENTS, filters],

        queryFn: ({ signal }) => {
            if (filters) {
                return getActionSetImprovements(filters, signal);
            }
        },
    });
};

async function getActionSetImprovements(
    filters: IActionSetImprovementsFilter,
    signal?: AbortSignal,
) {
    const url = `${improvementUrl}/action-set`;
    const response = await axios.post<ITableList<IDeviation>>(url, filters, {
        signal,
    });

    return response.data;
}

export const getImprovementData = (
    enabled: boolean,
    filters?: IImprovementFilter,
    pageFilters?: IDeviationPageFilter,
) => {
    if (filters && pageFilters) {
        if (filters.pchart) {
            const processChartFilters: IProcessChartImprovementFilter = {
                id: pageFilters?.showImprovementsForId ?? "",
                type: filters.pchart,
                impactType:
                    filters.impactType ??
                    ImprovementProcessChartImpactType.None,
                showDraft: filters.drafts ?? false,
                ...pageFilters,
            };
            return useProcessChartImprovements(enabled, processChartFilters);
        } else if (filters.checklist || filters.template || filters.activity) {
            const checklistFilters: IChecklistImprovementsFilter = {
                id: pageFilters?.showImprovementsForId ?? "",
                activityInstanceSetId:
                    filters?.[EImprovementView.activityInstanceSetId],
                allForRun: filters?.[EImprovementView.allForRun],
                allForTemplate: filters?.[EImprovementView.allForTemplate],
                onlyActive: filters?.active
                    ? true
                    : filters.closed
                      ? false
                      : undefined,
                ...pageFilters,
            };

            return useChecklistImprovements(enabled, checklistFilters);
        } else if (filters.actionSet) {
            const actionSetFilters: IActionSetImprovementsFilter = {
                actionSetId: filters.actionSet,
                onlyActive: filters?.active
                    ? true
                    : filters.closed
                      ? false
                      : undefined,
                ...pageFilters,
            };

            return useActionSetImprovements(enabled, actionSetFilters);
        }
    }

    return useImprovementsForPages(enabled, pageFilters);
};

export const useImprovementById = (id: string) => {
    return useQuery({
        queryKey: [GET_IMPROVEMENT_BY_ID, id],

        queryFn: async (context) => {
            const url = `${improvementUrl}/${id}`;

            const response = await axios.get<IImprovementDto>(url, {
                signal: context.signal,
            });

            response.data.createdAt = convertToDate(response.data.createdAt);

            response.data.updatedAt = convertToDate(response.data.updatedAt);

            return response.data;
        },

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useStepInstancesByImprovementId = (
    enabled: boolean,
    checkIfAdmin: boolean,
    improvementId?: string,
    refetchInterval?: number,
) => {
    return useQuery({
        queryKey: [STEPINSTANCES_BY_IMPROVEMENT_ID_KEY, improvementId],

        queryFn: async (context) => {
            if (improvementId) {
                const url = `/api/stepinstance/improvement/${improvementId}`;

                const response = await axios.get<IDictionary<IStepInstance>>(
                    url,
                    {
                        params: { checkIfAdmin },
                        signal: context.signal,
                    },
                );

                for (const key in response.data) {
                    response.data[key].completedAt = convertToDate(
                        response.data[key].completedAt,
                    );
                }

                return {
                    values: response.data,
                    ids: Object.keys(response.data),
                } as ITableSet<IStepInstance>;
            }
        },

        enabled,
        refetchInterval,

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useStepInstancesActivitiesByImprovementId = (
    enabled: boolean,
    checkIfAdmin: boolean,
    improvementId?: string,
    refetchInterval?: number,
) => {
    return useQuery({
        queryKey: [
            STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY,
            improvementId,
        ],

        queryFn: async (context) => {
            if (improvementId) {
                const url = `/api/activityInstance/improvement/${improvementId}`;

                const response = await axios.get<IActivityListDTO>(url, {
                    params: { checkIfAdmin },
                    signal: context.signal,
                });

                for (const key in response.data.activityInstances) {
                    response.data.activityInstances[key].updatedAt =
                        convertToDate(
                            response.data.activityInstances[key].updatedAt,
                        );
                }

                return response.data;
            }
        },

        enabled,
        refetchInterval,

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

async function deleteImprovementById(id: string) {
    try {
        const response = await axios.delete(`${improvementUrl}/${id}`);

        return ExecutionResult.Result<IPaginatedList<IDeviation>>(
            response.data,
        );
    } catch (ex) {
        return ExecutionResult.Failed<IPaginatedList<IDeviation>>(ex);
    }
}

function updateImprovementsData(
    deletedImprovementId?: string,
    improvements?: IPaginatedList<IDeviation>,
): IPaginatedList<IDeviation> {
    if (deletedImprovementId && improvements) {
        improvements.items.splice(
            improvements.items.findIndex(function (i) {
                return i.id === deletedImprovementId;
            }),
            1,
        );
        return improvements;
    }
    return {
        items: [],
        totalCount: 0,
    };
}

export const useDeleteImprovementMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { id: string }) => {
            deleteImprovementById(variables.id);
        },

        onMutate: async (variables) => {
            await queryClient.cancelQueries({
                queryKey: [GET_IMPROVEMENTS_LIST],
            });

            const data = queryClient.getQueryData<IPaginatedList<IDeviation>>([
                GET_IMPROVEMENTS_LIST,
            ]);

            const deletedImprovement = variables.id
                ? data?.items.find((x) => x.id === variables.id)?.id
                : undefined;

            queryClient.setQueryData<IPaginatedList<IDeviation>>(
                [GET_IMPROVEMENTS_LIST],
                (prev) => updateImprovementsData(variables.id, prev),
            );
            return {
                prevData: deletedImprovement,
            };
        },
        onSuccess: async (data, variables, response) => {
            if (response) {
                await queryClient.invalidateQueries({
                    queryKey: [GET_IMPROVEMENTS_LIST],
                });

                queryClient.setQueryData<IPaginatedList<IDeviation>>(
                    [GET_IMPROVEMENTS_LIST],
                    (prev) => updateImprovementsData(variables.id, prev),
                );
            }
        },
        onError: (
            err,
            variables,
            context:
                | {
                      prevData?: string;
                  }
                | undefined,
        ) => {
            if (context) {
                queryClient.setQueryData<IPaginatedList<IDeviation>>(
                    [GET_IMPROVEMENTS_LIST],
                    (prev) => updateImprovementsData(context.prevData, prev),
                );
            }
        },
    });
};

export const useUpdateImpactedProcessMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: IImpactedProcessUpdateRequest) => {
            try {
                const response = await axios.put<IImpactedProcessesDto>(
                    `${improvementUrl}/impacted-process`,
                    request,
                );

                return ExecutionResult.Result<IImpactedProcessesDto>(
                    response.data,
                );
            } catch (error) {
                return ExecutionResult.Failed<IImpactedProcessesDto>(error);
            }
        },

        onSuccess: async (response) => {
            if (response.Succeeded && response.Data) {
                await queryClient.invalidateQueries({
                    queryKey: [
                        GET_IMPROVEMENT_BY_ID,
                        response.Data.improvementId,
                    ],
                });
            }
        },
    });
};

async function updateImprovementById(request: IImprovementUpdateRequest) {
    try {
        const response = await axios.put<IImprovementDto>(
            `${improvementUrl}/update`,
            request,
        );

        return ExecutionResult.Result<IImprovementDto>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IImprovementDto>(error);
    }
}

function updateImprovementValue(
    message?: string,
    impact?: ImpactGradingEnum,
    improvement?: IImprovementDto,
): IImprovementDto {
    if (improvement && message && impact) {
        return {
            ...improvement,
            message,
            impact,
        };
    }
    return {
        id: "",
        message: "",
        index: 0,
        impact: ImpactGradingEnum.None,
    };
}

export const useUpdateImprovementMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: IImprovementUpdateRequest) =>
            updateImprovementById(request),

        onMutate: async (variables) => {
            await queryClient.invalidateQueries({
                queryKey: [GET_IMPROVEMENT_BY_ID, variables.id],
            });

            await queryClient.invalidateQueries({
                queryKey: [IMPROVEMENT_SELECTED_IMPACT_GRADING_VALUES_BY_ID],
            });

            const data = queryClient.getQueryData<IImprovementDto>([
                GET_IMPROVEMENT_BY_ID,
                variables.id,
            ]);

            const message = variables.deviationMessage ? data?.message : "";
            const impact = variables.impact
                ? data?.impact
                : ImpactGradingEnum.None;

            queryClient.setQueryData<IImprovementDto>(
                [GET_IMPROVEMENT_BY_ID, variables.id],
                (prev) =>
                    updateImprovementValue(
                        variables.deviationMessage,
                        variables.impact,
                        prev,
                    ),
            );

            queryClient.setQueryData<IImprovementDto>(
                [GET_IMPROVEMENTS_LIST, variables.id],
                (prev) =>
                    updateImprovementValue(
                        variables.deviationMessage,
                        variables.impact,
                        prev,
                    ),
            );
            return {
                prevMessage: message,
                prevImpact: impact,
            };
        },
        onSuccess: (response) => {
            if (response.Succeeded && response.Data) {
                queryClient.invalidateQueries({
                    queryKey: [GET_IMPROVEMENTS_LIST],
                });

                queryClient.invalidateQueries({
                    queryKey: [GET_IMPROVEMENT_BY_ID, response.Data.id],
                });

                queryClient.invalidateQueries({
                    queryKey: [
                        IMPROVEMENT_SELECTED_IMPACT_GRADING_VALUES_BY_ID,
                        response.Data.id,
                    ],
                });

                queryClient.setQueryData<IImprovementDto>(
                    [GET_IMPROVEMENT_BY_ID, response.Data.id],
                    (prev) =>
                        updateImprovementValue(
                            response.Data?.message,
                            response.Data?.impact,
                            prev,
                        ),
                );
                queryClient.setQueryData<IImprovementDto>(
                    [GET_IMPROVEMENTS_LIST, response.Data.id],
                    (prev) =>
                        updateImprovementValue(
                            response.Data?.message,
                            response.Data?.impact,
                            prev,
                        ),
                );
            }
        },
        onError: (
            err,
            variables,
            context:
                | {
                      prevMessage?: string;
                      prevImpact?: ImpactGradingEnum;
                  }
                | undefined,
        ) => {
            if (context) {
                queryClient.setQueryData<IImprovementDto>(
                    [GET_IMPROVEMENT_BY_ID, variables.id],
                    (prev) =>
                        updateImprovementValue(
                            context.prevMessage,
                            context.prevImpact,
                            prev,
                        ),
                );
                queryClient.setQueryData<IImprovementDto>(
                    [GET_IMPROVEMENTS_LIST, variables.id],
                    (prev) =>
                        updateImprovementValue(
                            context.prevMessage,
                            context.prevImpact,
                            prev,
                        ),
                );
            }
        },
    });
};

const createImprovement = async (request: IImprovementRequest) => {
    try {
        const result = await axios.post<IImprovementDto>(
            improvementUrl,
            request,
        );

        return ExecutionResult.Result<IImprovementDto>(result.data);
    } catch (err) {
        return ExecutionResult.Failed<IImprovementDto>(err);
    }
};

export const useCreateImprovementMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: { request: IImprovementRequest }) =>
            createImprovement(args.request),

        onSuccess: async (data, variables) => {
            if (data.Succeeded) {
                // await queryClient.invalidateQueries([
                //     GET_IMPROVEMENTS_DATA_KEY,
                // ]);
                queryClient.setQueryData<IPaginatedList<IDeviation>>(
                    [GET_IMPROVEMENTS_LIST],
                    (prev) => updateImprovementsList(data.Data, prev),
                );

                if (variables.request.activityInstanceSetId) {
                    await queryClient.refetchQueries({
                        queryKey: [STEPINSTANCES_BY_CHECKLIST_ID_KEY],
                    });

                    await queryClient.refetchQueries({
                        queryKey: [STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY],
                    });
                }
            }
        },
    });
};
function updateImprovementsList(
    newImprovement?: IImprovementDto,
    improvements?: IPaginatedList<IDeviation>,
): IPaginatedList<IDeviation> {
    if (newImprovement) {
        const improvement = {
            ...newImprovement,
            isUpdated: false,
            createdBy: { value: "", label: "" },
            updatedBy: { value: "", label: "" },
        };
        if (improvements) {
            improvements?.items.unshift(improvement);
            return improvements;
        } else {
            return {
                items: [improvement],
                totalCount: 1,
            };
        }
    } else {
        return {
            items: [],
            totalCount: 0,
        };
    }
}
export const useSelectedImpactValuesByImprovementId = (
    improvementId?: string,
) => {
    return useQuery({
        queryKey: [
            IMPROVEMENT_SELECTED_IMPACT_GRADING_VALUES_BY_ID,
            improvementId,
        ],

        queryFn: async ({ signal }) => {
            const url = `${improvementUrl}/impact-gradings/${improvementId}`;

            const response = await axios.get<IDictionary<ImpactGradingEnum>>(
                url,
                {
                    signal,
                },
            );

            return response.data;
        },

        enabled: improvementId !== undefined,
    });
};

export async function importImprovementsMutation(
    request: IImportImprovementRequest,
) {
    const url = `${improvementUrl}/import`;

    try {
        request.timezone = getTimeZone();

        const response = await axios.post<number>(url, request);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}

const completeStep = async (
    request: ICompleteImprovementStepRequest,
    signal?: AbortSignal,
) => {
    const url = `${improvementUrl}/mark-step-completed`;

    try {
        const response = await axios.put<IFinalizeStepInstanceDTO>(
            url,
            request,
            {
                signal,
            },
        );

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IFinalizeStepInstanceDTO>(error);
    }
};

export const useCompleteImprovementStepMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: {
            request: ICompleteImprovementStepRequest;
            signal?: AbortSignal;
        }) => completeStep(args.request, args.signal),

        onSuccess: async (data, variables) => {
            if (data.Succeeded) {
                await queryClient.refetchQueries({
                    queryKey: [GET_IMPROVEMENT_BY_ID],
                });

                await queryClient.refetchQueries({
                    queryKey: [STEPINSTANCES_BY_IMPROVEMENT_ID_KEY],
                });
            }
        },
    });
};

export async function getResponsibleUsersForTemplateByTemplateVersionId(
    request: ITemplateVersionConfigurationUsersRequest,
) {
    const url = `${templateUrl}/get-responsible-users`;

    try {
        const response =
            await axios.post<ITemplateVersionConfigurationUsersDto>(
                url,
                request,
            );

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<ITemplateVersionConfigurationUsersDto>(
            error,
        );
    }
}

export const useGetResponsibleUsersForTemplateByTemplateVersionIdMutation =
    () => {
        return useMutation({
            mutationFn: (args: {
                request: ITemplateVersionConfigurationUsersRequest;
            }) =>
                getResponsibleUsersForTemplateByTemplateVersionId(args.request),
        });
    };

export async function getInvolvedUsersForTemplateByTemplateVersionId(
    request: ITemplateVersionConfigurationUsersRequest,
) {
    const url = `${templateUrl}/get-involved-users`;

    try {
        const response =
            await axios.post<ITemplateVersionConfigurationUsersDto>(
                url,
                request,
            );

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<ITemplateVersionConfigurationUsersDto>(
            error,
        );
    }
}

export const useGetInvolvedUsersForTemplateByTemplateVersionIdMutation = () => {
    return useMutation({
        mutationFn: (args: {
            request: ITemplateVersionConfigurationUsersRequest;
        }) => getInvolvedUsersForTemplateByTemplateVersionId(args.request),
    });
};

export async function getUsersToBeNotifiedForTemplateByTemplateVersionId(
    request: ITemplateVersionConfigurationUsersRequest,
) {
    const url = `${templateUrl}/get-notify-users`;

    try {
        const response =
            await axios.post<ITemplateVersionConfigurationUsersDto>(
                url,
                request,
            );

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<ITemplateVersionConfigurationUsersDto>(
            error,
        );
    }
}

export const useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation =
    () => {
        return useMutation({
            mutationFn: (args: {
                request: ITemplateVersionConfigurationUsersRequest;
            }) =>
                getUsersToBeNotifiedForTemplateByTemplateVersionId(
                    args.request,
                ),
        });
    };
