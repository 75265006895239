import "./ValueFilter.scss";

import { useState } from "react";
import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IFilterProps } from "../api/IFilterProps";
import { ValueActivityFilterType } from "../api/ValueActivityFilterType";
import { isStringEmpty } from "../utils";
import Textfield from "common/components/input-components/textfield/Textfield";
import { FilterDropdown } from "common/components/table/components/filtering/filter-dropdown/FilterDropdown";
import { ActivityType } from "models/enums/ActivityType";

interface IProps extends IFilterProps {}

function getInitialValue() {
    const result: ValueActivityFilterType = {
        searchKeyword: "",
        startValue: "",
        endValue: "",
    };

    return result;
}

export const ValueFilter = (props: IProps) => {
    const { value, testId } = props;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(value?.valueFilter !== undefined);
    const [isEdited, setIsEdited] = useState(false);

    const [filterValue, setFilterValue] = useState<ValueActivityFilterType>(
        value?.valueFilter ?? getInitialValue(),
    );

    React.useEffect(() => {
        if (!isOpen) {
            setFilterValue(value?.valueFilter ?? getInitialValue());
        }
    }, [isOpen]);

    const handleReset = () => {
        setFilterValue(getInitialValue());

        setIsEdited(false);

        setIsActive(false);

        props.onFilter({
            type: ActivityType.Value,

            isReset: true,
        });
    };

    const tryHandleReset = (name: string, value: string) => {
        const isEmpty = Object.keys(filterValue).every((key) => {
            if (key === name) {
                return isStringEmpty(value);
            }

            return isStringEmpty(filterValue[key]);
        });

        if (isEmpty) {
            setIsEdited(false);
        }
    };

    const handleFilter = (isReset?: boolean) => {
        if (isReset) {
            handleReset();
        } else {
            const isEmpty = Object.keys(filterValue).every((key) =>
                isStringEmpty(filterValue[key]),
            );

            setIsEdited(!isEmpty);

            setIsActive(!isEmpty);

            const nextValue = Object.keys(
                filterValue,
            ).reduce<ValueActivityFilterType>((acc, key) => {
                acc[key] = (filterValue[key] ?? "").trim();

                return acc;
            }, {});

            props.onFilter({
                type: ActivityType.Value,

                valueFilter: nextValue,

                isReset: isEmpty,
            });
        }
    };

    const handleChange = (value: string, id?: string, name?: string) => {
        if (name) {
            setFilterValue((prev) => ({ ...prev, [name]: value }));

            setIsEdited(true);

            tryHandleReset(name, value);
        }
    };

    const startValueId = React.useId();

    return (
        <FilterDropdown
            isEdited={isEdited}
            isActive={isActive}
            testId={testId}
            onFilter={handleFilter}
            onToggle={setIsOpen}
        >
            <div className="value-filter">
                <Textfield
                    value={filterValue.searchKeyword ?? ""}
                    labelContent={t(k.FILTER_ALL_WHICH_CONTAINS)}
                    placeholder={t(k.SEARCH)}
                    size="large"
                    name="searchKeyword"
                    onChange={handleChange}
                />

                <label className="text-field" htmlFor={startValueId}>
                    <div className="text-field--label">
                        {t(k.VALUE_IS_BETWEEN)}
                    </div>

                    <div className="between-range">
                        <Textfield
                            value={filterValue.startValue ?? ""}
                            placeholder={t(k.ENTER_NUMBER)}
                            size="large"
                            id={startValueId}
                            name="startValue"
                            type="number"
                            onChange={handleChange}
                        />
                        <Textfield
                            value={filterValue.endValue ?? ""}
                            placeholder={t(k.ENTER_NUMBER)}
                            size="large"
                            name="endValue"
                            type="number"
                            onChange={handleChange}
                        />
                    </div>
                </label>
            </div>
        </FilterDropdown>
    );
};
