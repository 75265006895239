import "./WarningIcon.scss";

import { HiOutlineExclamationCircle } from "react-icons/hi";
import classNames from "classnames";
import Icon, { IBaseIconProps } from "../Icon";

interface IWarningIconProps extends IBaseIconProps {
    active?: boolean;
}

const WarningIcon = (props: IWarningIconProps) => {
    const { active: activeColor, className: cn, ...rest } = props;

    const className = classNames(cn, "warning-icon", {
        "warning-icon--color": activeColor,
    });

    return (
        <Icon
            {...rest}
            className={className}
            icon={HiOutlineExclamationCircle}
        />
    );
};

export default WarningIcon;
