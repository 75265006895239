import axios from "axios";

import { useQuery } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import IDictionary from "common/viewModels/IDictionary";
import ITableSet from "http/ITableSet";
import { ITableSetWithValueLabelOptions } from "http/ITableSetWithOptions";

const baseUrl = "/api/role";

const roleKeys = {
    published: (addUserRole: boolean) =>
        ["published-roles", addUserRole] as const,
    rolesByTeamIds: (teamIds?: string[]) =>
        ["roles-by-team-ids", teamIds] as const,
};

export const usePublishedRoles = (enabled = true, addUserRole = true) => {
    const url = `${baseUrl}/published`;

    return useQuery({
        queryKey: roleKeys.published(addUserRole),

        queryFn: async (context) => {
            const response = await axios.get<IDictionary<string>>(url, {
                signal: context.signal,
            });

            if (addUserRole) {
                response.data["USER"] = "USER";
            }
            const ids = Object.keys(response.data);

            const options: IValueLabelItem<string, string>[] = ids.map((x) => ({
                value: x,
                label: response.data[x],
            }));

            const result: ITableSetWithValueLabelOptions<string> = {
                ids,
                values: response.data,
                options,
            };

            return result;
        },

        enabled,

        structuralSharing(prevData: unknown | undefined, nextData: unknown) {
            let isEqual = false;

            const oldData = prevData as
                | ITableSetWithValueLabelOptions<string>
                | undefined;

            const newData = nextData as ITableSetWithValueLabelOptions<string>;

            if (oldData && newData) {
                if (addUserRole) {
                    isEqual =
                        JSON.stringify(oldData.values) ===
                        JSON.stringify({
                            ...newData.values,
                            ["USER"]: "USER",
                        });
                } else {
                    isEqual =
                        JSON.stringify(oldData.values) ===
                        JSON.stringify(newData.values);
                }
            }

            return isEqual ? oldData : newData;
        },
    });
};

export const useRolesByTeamIds = (teamIds?: string[]) => {
    const url = `${baseUrl}/roles-by-team-ids`;

    return useQuery({
        queryKey: roleKeys.rolesByTeamIds(teamIds),

        queryFn: async (context) => {
            const response = await axios.post<ITableSet<string[]>>(
                url,
                teamIds,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },

        enabled: Boolean(teamIds),
    });
};
