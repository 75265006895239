import "./ImpactGrading.scss";

import k from "i18n/keys";

import { LocaleId } from "AppLocale";
import IDictionary from "common/viewModels/IDictionary";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import { useTranslation } from "react-i18next";
import ImpactGradingBadges from "../badges/impact-grading-badges/ImpactGradingBadges";

interface IProps {
    impactList?: IDictionary<ImpactGradingEnum>;
}

const ImpactGrading = (props: IProps) => {
    const { impactList } = props;
    const { t, i18n } = useTranslation();

    const { data: impactAreas } = useImpactGradingOptions(
        i18n.language as LocaleId,
    );

    const impactGradingLabels = {
        [ImpactGradingEnum.None]: "",
        [ImpactGradingEnum.VeryLow]: t(k.IMPACT_SCORE1_LABEL),
        [ImpactGradingEnum.Low]: t(k.IMPACT_SCORE2_LABEL),
        [ImpactGradingEnum.Moderate]: t(k.IMPACT_SCORE3_LABEL),
        [ImpactGradingEnum.High]: t(k.IMPACT_SCORE4_LABEL),
        [ImpactGradingEnum.VeryHigh]: t(k.IMPACT_SCORE5_LABEL),
    };

    const sortedImpactAreas = [...(impactAreas ?? [])].sort((a, b) => {
        const valueA = impactList?.[a.value] ?? 0;
        const valueB = impactList?.[b.value] ?? 0;
        return valueB - valueA;
    });

    return (
        <div className="impact-gradings">
            {sortedImpactAreas.map((impactArea) => {
                if (
                    !impactList?.[impactArea.value] ||
                    impactList?.[impactArea.value] === ImpactGradingEnum.None
                ) {
                    return null;
                }
                return (
                    <div
                        className="impact-gradings--item"
                        key={impactArea.value}
                    >
                        <ImpactGradingBadges
                            short
                            type={impactList?.[impactArea.value]}
                            tooltip={{
                                id: impactArea.value,
                                message:
                                    impactGradingLabels[
                                        impactList?.[impactArea.value]
                                    ],
                            }}
                        />
                        <div
                            className="impact-gradings--item--word-wrap"
                            title={impactArea.label}
                        >
                            {impactArea.label}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ImpactGrading;
