import "./BehindBadge.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Badge, { IBaseBadgeProps } from "../../base-badges/Badge";

interface IBehindBadgeProps extends IBaseBadgeProps {
    label?: string;
}

const BehindBadge = (props: IBehindBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Badge className="behind-badge" {...rest}>
            {label ?? t(k.BEHIND)}
        </Badge>
    );
};

export default BehindBadge;
