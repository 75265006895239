import React, { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdMoveDown } from "react-icons/md";
import { useSelector } from "react-redux";
import { SortEnd } from "react-sortable-hoc";

import AttachmentList from "common/components/attachment-list/AttachmentList";
import DropdownButton from "common/components/dropdown/DropdownButton";
import { IDropdownOption } from "common/components/dropdown/api/IDropdownOption";
import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import ImagePreview from "common/components/images/image-preview/ImagePreview";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import { arrayMoveTo } from "common/utils/arrayUtils";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import ActivityFieldByType from "components/common/activities/ActivityFieldByType";
import ActivityPhotoInput from "components/common/activities/ActivityPhotoInput";
import SortableAttachmentsList from "components/common/activities/SortableAttachmentsList";
import { ActivityTitlePreview } from "components/common/activities/common/ActivityTitlePreview";
import DeleteAndUndoButton from "components/common/buttons/delete-and-undo/DeleteAndUndoButton";
import GenericPropertiesForm from "components/common/shared-properties/GenericPropertiesForm";
import ActivityListData from "components/common/shared-properties/components/ActivityListData";
import { IListItem } from "components/common/shared-properties/components/AddPropertyButton";
import {
    useCustomListNamesByIds,
    useCustomListOptions,
} from "components/custom-list-page/api/hooks";
import { IAddActivityParam } from "components/steps/api/IAddActivityParam";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";
import IActivity from "models/IActivity";
import IAttachment from "models/IAttachment";
import { ActivityType } from "models/enums/ActivityType";
import { IAppState } from "store/IAppState";
import { v4 as uuidv4 } from "uuid";

import ImprovementFormContext from "../../../context/ImprovementFormContext";
import { multiSelectEnabledTypes } from "../../../utils";

import "./EditableActivityList.scss";

interface IProps {
    stepId: string;
    stepIndex: number;
    stepDisabled?: boolean;
}

const EditableActivityList = (props: React.PropsWithChildren<IProps>) => {
    const { stepId, stepIndex, stepDisabled } = props;

    const userDefinedTasks = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.userDefinedTasks ===
            true
        );
    });

    const {
        steps,
        setSteps,
        activities,
        setActivities,
        attachments,
        setAttachments,
        activityInputs,
        setActivityInputs,
        photos,
        setPhotos,
        errors,
        setErrors,
        onHaveChanges,
        moveActivity,
    } = useContext(ImprovementFormContext);

    const { t } = useTranslation();

    const customListIds = React.useMemo(() => {
        const ids = activities?.ids
            .map((id) =>
                activities.values[id]?.param
                    ? undefined
                    : activities.values[id]?.customListId,
            )
            .filter((x) => x) as string[];

        if (ids.length > 0) {
            return ids;
        }
    }, [activities]);

    const { data: customListNamesByIds } =
        useCustomListNamesByIds(customListIds);

    const { data: customListData } = useCustomListOptions();

    const customListOptions = customListData?.values;

    const handleStepIsChanged = () => {
        if (setSteps) {
            setSteps((prev) => {
                const step = prev.values[stepId];

                if (step) {
                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [stepId]: {
                                ...step,
                                isChanged: true,
                            },
                        },
                    };
                }
                return prev;
            });
        }
    };

    const handleAddActivity = (addParam: IAddActivityParam, index: number) => {
        const { label, type, id } = addParam;

        const activity: IActivity = {
            id: uuidv4(),
            label: label ?? "",
            type,
            isRequired: false,
            acceptMultiple: false,
            multiSelectEnabled: multiSelectEnabledTypes.has(type),
            attachments: [],
            customListId: id,
            param: addParam,
            enableUserDefinedActivityInstances: userDefinedTasks,
        };

        if (setActivities) {
            setActivities((prev) => {
                const newIds =
                    prev.parents?.activityIdsByStepId?.[stepId] ?? [];

                newIds?.splice(index + 1, 0, activity.id);

                return {
                    ...prev,
                    values: { ...prev.values, [activity.id]: activity },
                    ids: [...prev.ids, activity.id],
                    parents: {
                        ...prev.parents,
                        activityIdsByStepId: {
                            ...(prev.parents?.activityIdsByStepId ?? {}),
                            [stepId]: newIds,
                        },
                    },
                };
            });

            const nameKey = "step." + stepId + ".activities";

            setErrors((prev) => {
                if (prev && prev[nameKey]) {
                    const { [nameKey]: deletedError, ...rest } = prev;

                    return rest;
                }
                return prev;
            });

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const handleChangeActivity = (activity: IActivity) => {
        if (setActivities) {
            setActivities((prev) => ({
                ...prev,
                values: {
                    ...prev.values,
                    [activity.id]: activity,
                },
            }));

            handleStepIsChanged();
            onHaveChanges(true);

            const activityErrorKey = "activity." + activity.id + ".label";

            setErrors((prev) => {
                if (
                    activity.label.length > 0 &&
                    prev &&
                    prev[activityErrorKey]
                ) {
                    const { [activityErrorKey]: _, ...rest } = prev;
                    return rest;
                } else if (
                    activity.label.length === 0 &&
                    (!prev || !prev[activityErrorKey])
                ) {
                    return {
                        ...prev,
                        [activityErrorKey]: k.REQUIRED,
                    };
                }

                return prev;
            });
        }
    };

    const handleChangePhoto = (
        activityId: string,
        e: IInputChangeEvent<string>,
    ) => {
        if (setPhotos) {
            setPhotos((prev) => ({
                ...prev,

                [activityId]: {
                    ...prev[activityId],
                    value: e.value,
                    isDeleted: e.value === "",
                },
            }));

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const handleSaveAttachments = (
        activityId: string,
        attachments: IAttachment[],
    ) => {
        if (setAttachments) {
            setAttachments((prev) => {
                const mappedAttachments = attachments.reduce<
                    Record<string, IAttachment>
                >((acc, attachment) => {
                    acc[attachment.id] = attachment;
                    return acc;
                }, {});

                return {
                    ...prev,
                    values: { ...prev.values, ...mappedAttachments },
                    parents: {
                        ...prev.parents,
                        attachmentsIdsByActivityId: {
                            ...(prev.parents?.attachmentsIdsByActivityId ?? {}),
                            [activityId]: attachments.map((a) => a.id),
                        },
                    },
                };
            });

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const onActivityDelete = (id: string) => {
        if (setActivities) {
            setActivities((prev) => {
                const activity = prev.values[id];

                if (activity) {
                    handleStepIsChanged();
                    onHaveChanges(true);

                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [id]: {
                                ...activity,
                                isDeleted: !activity.isDeleted,
                            },
                        },
                    };
                }
                return prev;
            });
        }
    };

    const onMoveActivity = (activityId: string, newStepId: string) => {
        moveActivity?.({
            activityId,
            oldStepId: stepId,
            newStepId,
        });
    };

    const handleTemplateListSorted = (params: SortEnd) => {
        if (setActivities) {
            setActivities((prev) => {
                const oldIds = prev.parents?.activityIdsByStepId?.[stepId];

                if (oldIds) {
                    handleStepIsChanged();
                    onHaveChanges(true);

                    return {
                        ...prev,
                        parents: {
                            ...prev.parents,
                            activityIdsByStepId: {
                                ...(prev.parents?.activityIdsByStepId ?? {}),
                                [stepId]: arrayMoveTo(
                                    oldIds,
                                    params.oldIndex,
                                    params.newIndex,
                                ),
                            },
                        },
                    };
                }

                return prev;
            });
        }
    };

    const handleChangeActivityInputList = (
        activityId: string,
        inputList: IActivityInputListItem[],
    ) => {
        if (setActivityInputs) {
            setActivityInputs((prev) => {
                const newValues = inputList.reduce<
                    Record<string, IActivityInputListItem>
                >((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {});

                const values = { ...prev.values, ...newValues };

                return {
                    ...prev,
                    ids: Object.keys(values),
                    values,
                    parents: {
                        ...prev.parents,
                        activityInputsIdsByActivityId: {
                            ...(prev.parents?.activityInputsIdsByActivityId ??
                                {}),
                            [activityId]: inputList.map((i) => i.id),
                        },
                    },
                };
            });

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const moveActivityStepOptions = useMemo(() => {
        if (moveActivity) {
            return steps?.ids.reduce<IDropdownOption[]>((acc, id) => {
                const step = steps?.values[id];
                if (step && step.id !== stepId) {
                    acc.push({
                        value: step.id,
                        label: step.name ?? "",
                    });
                }
                return acc;
            }, []);
        }
    }, [moveActivity, stepId]);

    return (
        <GenericPropertiesForm
            noWrapper
            testId="editable-activity-list"
            itemIds={activities?.parents?.activityIdsByStepId?.[stepId]}
            listData={
                customListOptions
                    ? [
                          ActivityListData,
                          {
                              items: customListOptions.ids.reduce<
                                  IListItem<ActivityType>[]
                              >((acc, id) => {
                                  const item = customListOptions.values[id];
                                  if (item) {
                                      acc.push({
                                          type: ActivityType.CustomList,
                                          id: item.id,
                                          title: item.name,
                                          subTitle: item.description,
                                          icon: (
                                              <CustomListIcon showBackground />
                                          ),
                                      });
                                  }
                                  return acc;
                              }, []),
                              sectionTitle: k.CUSTOM_LISTS,
                          },
                      ]
                    : [ActivityListData]
            }
            buttonTitle={k.ADD_ACTIVITIES}
            itemClassName="step-activity"
            hideOrder
            onAddItem={handleAddActivity}
            sortDisabled={stepDisabled}
            addDisabled={stepDisabled}
            onSorted={handleTemplateListSorted}
            renderTemplate={(itemId, index) => {
                const item = activities?.values[itemId];

                if (!item) {
                    return <></>;
                }

                const activityList =
                    activityInputs?.parents?.activityInputsIdsByActivityId?.[
                        itemId
                    ]?.reduce<IActivityInputListItem[]>((acc, id) => {
                        const item = activityInputs.values[id];
                        if (item) {
                            acc.push(item);
                        }
                        return acc;
                    }, []);

                const attachmentList =
                    attachments?.parents?.attachmentsIdsByActivityId?.[
                        itemId
                    ]?.reduce<IAttachment[]>((acc, id) => {
                        const item = attachments.values[id];
                        if (item) {
                            acc.push(item);
                        }
                        return acc;
                    }, []);

                const photo = photos?.[item.id];

                const handleOnChange = <V,>(
                    e: IInputChangeEvent<V> | IInputActionChangeEvent<string>,
                ) => {
                    const key = e.name ?? e.id;

                    if (key) {
                        handleChangeActivity({
                            ...item,
                            [key]: e.value,
                        });
                    }
                };

                const handleOnDelete = () => {
                    onActivityDelete(item.id);
                };

                const handleOnChangeList = (
                    activityId: string,
                    inputList: IActivityInputListItem[],
                    onInitialize?: boolean,
                ) => {
                    handleChangeActivityInputList(activityId, inputList);
                };

                const handleOnChangePhoto = (e: IInputChangeEvent<string>) => {
                    handleChangePhoto(item.id, e);
                };

                const handleOnSaveAttachments = (
                    attachments: IAttachment[],
                ) => {
                    handleSaveAttachments(item.id, attachments);
                };

                const handleOnMoveActivity = (newStepId: string) => {
                    onMoveActivity(item.id, newStepId);
                };

                let customListName: string = "";
                if (item.type === ActivityType.CustomList) {
                    customListName = item.param
                        ? (item.param.label ?? "")
                        : item.customListId
                          ? (customListNamesByIds?.[item.customListId] ?? "")
                          : t(k.NOT_AVAILABLE_MESSAGE);
                }
                const actualIndex = index + 1;
                return (
                    <ActivityFieldByType
                        isEditMode={!item.isDeleted && !stepDisabled}
                        activity={item}
                        activityList={activityList}
                        onChangeList={handleOnChangeList}
                        errors={errors}
                        userDefinedTasks={userDefinedTasks}
                        onChange={handleOnChange}
                        noTasksMessage={
                            item.type === ActivityType.Tasklist
                                ? k.NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN
                                : undefined
                        }
                        typeField={({ typeList, typeLabel }) => (
                            <div
                                className="step-activity-container"
                                data-testid={`activity-${actualIndex}`}
                            >
                                {item.type === ActivityType.CustomList && (
                                    <div>
                                        <strong>{t(k.CUSTOM_LIST)}:</strong>{" "}
                                        {customListName}
                                    </div>
                                )}

                                <div className="step-activity-container--head">
                                    {item.isDeleted || stepDisabled ? (
                                        <ActivityTitlePreview
                                            id={`activity.${item.id}.label`}
                                            className="order"
                                            order={`${stepIndex}.${actualIndex}`}
                                            defaultLabel={typeLabel}
                                            hasError={Boolean(
                                                errors?.[
                                                    `activity.${item.id}.label`
                                                ],
                                            )}
                                            activity={item}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <div className="order">
                                                {stepIndex}.{actualIndex}
                                            </div>
                                            <ActionTextField
                                                id={`activity.${item.id}.label`}
                                                name="label"
                                                testId={
                                                    "activity-" +
                                                    actualIndex +
                                                    "-label"
                                                }
                                                validationTestId={
                                                    "activity-" +
                                                    actualIndex +
                                                    "-label-validation"
                                                }
                                                value={item.label}
                                                placeholder={typeLabel}
                                                onChange={handleOnChange}
                                                invalid={Boolean(
                                                    errors?.[
                                                        `activity.${item.id}.label`
                                                    ],
                                                )}
                                                error={getLocaleString(
                                                    t,
                                                    errors?.[
                                                        `activity.${item.id}.label`
                                                    ],
                                                )}
                                                postInputContent={
                                                    !item.isDeleted &&
                                                    !stepDisabled &&
                                                    item.type !==
                                                        ActivityType.Tasklist && (
                                                        <Checkbox
                                                            id="isRequired"
                                                            name="isRequired"
                                                            wrapperClassName="input-required-container"
                                                            label={t(
                                                                k.REQUIRED,
                                                            )}
                                                            value={
                                                                item.isRequired ||
                                                                false
                                                            }
                                                            onChange={
                                                                handleOnChange
                                                            }
                                                        />
                                                    )
                                                }
                                            />
                                        </React.Fragment>
                                    )}

                                    {!item.isDeleted &&
                                        !stepDisabled &&
                                        moveActivity && (
                                            <DropdownButton
                                                id="move-activity"
                                                icon={
                                                    <MdMoveDown className="move-activity--icon" />
                                                }
                                                options={
                                                    moveActivityStepOptions
                                                }
                                                singleSelect
                                                onChange={handleOnMoveActivity}
                                            />
                                        )}

                                    {!stepDisabled && (
                                        <DeleteAndUndoButton
                                            testId="delete-btn"
                                            isDeleted={item.isDeleted}
                                            onClick={handleOnDelete}
                                        />
                                    )}
                                </div>

                                {!item.isDeleted && !stepDisabled ? (
                                    <ActivityPhotoInput
                                        id={`photo-${item.id}`}
                                        testId="photo-input"
                                        photoId={item.uploadedFileId}
                                        value={photo}
                                        onChange={handleOnChangePhoto}
                                    />
                                ) : (
                                    photo &&
                                    (((photo.isDeleted ?? false) === false &&
                                        item.uploadedFileId) ||
                                        photo?.value) && (
                                        <div className="activity-photo-preview">
                                            <ImagePreview
                                                value={photo.value}
                                                imgId={item.uploadedFileId}
                                            />
                                        </div>
                                    )
                                )}
                                {(!item.isDeleted || item.description) && (
                                    <TextfieldComplex
                                        hideIcon
                                        multiline
                                        id="description"
                                        name="description"
                                        value={item.description ?? ""}
                                        placeholder={t(k.DESCRIPTION)}
                                        innerLabelContent={t(k.DESCRIPTION)}
                                        onChange={handleOnChange}
                                        preview={item.isDeleted || stepDisabled}
                                    />
                                )}

                                {!item.isDeleted && !stepDisabled ? (
                                    <SortableAttachmentsList
                                        attachments={attachmentList ?? []}
                                        itemClassName="editable-activity-list"
                                        onSave={handleOnSaveAttachments}
                                    />
                                ) : (
                                    <AttachmentList
                                        attachments={attachmentList}
                                    />
                                )}

                                {typeList}
                            </div>
                        )}
                    />
                );
            }}
        />
    );
};

export default EditableActivityList;
