import { Reducer } from "redux";

import {
    CHANGE_LAYOUT_ENVIRONMENT,
    CHANGE_MENU_IS_EXPANDED,
    UiSettingsActionTypes,
} from "./types";
import { IValueLabelItem } from "common/IValueLabelItem";

export enum ELayoutEnvironment {
    BROWSER = 1,
    TEAMS = 2,
}

export interface IUiSettingsState {
    isMenuExpanded: boolean;
    layoutEnvironment: ELayoutEnvironment;
}

const initialState: IUiSettingsState = {
    isMenuExpanded: true,
    layoutEnvironment: ELayoutEnvironment.BROWSER,
};

export const UiSettingsReducer = (
    state = initialState,
    action: UiSettingsActionTypes,
): IUiSettingsState => {
    switch (action.type) {
        case CHANGE_MENU_IS_EXPANDED: {
            return {
                ...state,
                isMenuExpanded: action.payload,
            };
        }
        case CHANGE_LAYOUT_ENVIRONMENT: {
            return {
                ...state,
                layoutEnvironment: action.payload,
            };
        }

        default:
            return state;
    }
};
