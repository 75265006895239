import React from "react";

import { TimelineItem } from "react-calendar-timeline";

import { AppLocale } from "AppLocale";
import axios from "axios";
import { format } from "date-fns";

import { IMonthlyRecurringChecklistRequest } from "./IMonthlyRecurringChecklistRequest";
import IRecurringChecklist, {
    IRecurringMonthly,
    IRecurringWeekly,
    IRecurringYearly,
} from "./IRecurringChecklist";
import { IRecurringChecklistAllChecklistsByTemplateVersionIdRequest } from "./IRecurringChecklistAllChecklistsByTemplateVersionIdRequest";
import { IRecurringChecklistCalculateDatesByIdRequest } from "./IRecurringChecklistCalculateDatesByIdRequest";
import { IRecurringChecklistGroup } from "./IRecurringChecklistGroup";
import { IRecurringChecklistListItem } from "./IRecurringChecklistListItem";
import { IRecurringChecklistUpdatePublishedRequest } from "./IRecurringChecklistUpdatePublishedRequest";
import { IWeeklyRecurringChecklistRequest } from "./IWeeklyRecurringChecklistRequest";
import IWorkflowScheduleCalculateRunDateRequest from "./IWorkflowScheduleCalculateRunDateRequest";
import IWorkflowScheduleCalculatedDatesDTO from "./IWorkflowScheduleCalculatedDatesDTO";
import { IYearlyRecurringChecklistRequest } from "./IYearlyRecurringChecklistRequest";
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { convertToDate, getTimeZone } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import { WorkflowScheduleInterval } from "components/recurring-checklist/api/WorkflowScheduleIntervals";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";
import { IDaysRange } from "models/dto/IDaysRange";
import IWorkflowScheduleDTO from "models/dto/IWorkflowScheduleDTO";

const baseUrl = "/api/workflowSchedule";

type RequestTypes =
    | IWeeklyRecurringChecklistRequest
    | IMonthlyRecurringChecklistRequest
    | IYearlyRecurringChecklistRequest;

async function saveRecurringChecklist(
    intervalType: WorkflowScheduleInterval,
    request: RequestTypes,
) {
    request.timezone = getTimeZone();

    try {
        const url =
            intervalType === WorkflowScheduleInterval.Yearly
                ? `${baseUrl}/save-yearly`
                : intervalType === WorkflowScheduleInterval.Monthly
                  ? `${baseUrl}/save-monthly`
                  : `${baseUrl}/save-weekly`;

        const response = await axios.post<IWorkflowScheduleDTO>(url, request);

        return ExecutionResult.Result<IWorkflowScheduleDTO>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IWorkflowScheduleDTO>(error);
    }
}

const RECURRING_CHECKLIST_BY_ID_KEY = "recurring-checklist-by-id";
const RECURRING_CHECKLIST_GROUPS_BY_TEMPLATE_VERSION_ID_KEY =
    "recurring-checklist-groups-by-template-version-id";
const RECURRING_CHECKLIST_ITEMS_BY_TEMPLATE_VERSION_ID_KEY =
    "recurring-checklist-items-by-template-versionid";

export const useSaveRecurringChecklistsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: {
            request: RequestTypes;
            intervalType: WorkflowScheduleInterval;
            templateVersionId: string;
        }) => saveRecurringChecklist(args.intervalType, args.request),

        onSuccess: async (data, { templateVersionId }) => {
            if (data.Succeeded && data.Data) {
                await queryClient.invalidateQueries({
                    queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, data.Data.id],
                });

                await queryClient.invalidateQueries({
                    queryKey: [
                        RECURRING_CHECKLIST_GROUPS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                });

                await queryClient.invalidateQueries({
                    queryKey: [
                        RECURRING_CHECKLIST_ITEMS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                });
            }
        },
    });
};

async function updatePublishedRecurringChecklist(
    request: IRecurringChecklistUpdatePublishedRequest,
) {
    request.timezone = getTimeZone();

    try {
        const url = `${baseUrl}/update-published`;

        const response = await axios.post<IWorkflowScheduleDTO>(url, request);

        return ExecutionResult.Result<IWorkflowScheduleDTO>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IWorkflowScheduleDTO>(error);
    }
}

export const useUpdatePublishedRecurringChecklistsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (args: {
            request: IRecurringChecklistUpdatePublishedRequest;
            templateVersionId: string;
        }) => updatePublishedRecurringChecklist(args.request),

        onSuccess: async (data, { templateVersionId }) => {
            if (data.Succeeded && data.Data) {
                await queryClient.invalidateQueries({
                    queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, data.Data.id],
                });

                await queryClient.invalidateQueries({
                    queryKey: [
                        RECURRING_CHECKLIST_GROUPS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                });

                await queryClient.invalidateQueries({
                    queryKey: [
                        RECURRING_CHECKLIST_ITEMS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                });
            }
        },
    });
};

export function canCalculateDates(id: keyof IRecurringChecklist) {
    let result = false;

    switch (id) {
        case "intervalNumber":
            result = true;

            break;

        case "startDate":
            result = true;
            break;

        case "isStartDate":
            result = true;

            break;

        case "isEndDate":
            result = true;

            break;

        case "endDate":
            result = true;

            break;

        case "numberOfRuns":
            result = true;
            break;

        case "workdays":
            result = true;
            break;

        case "duration":
            result = true;
            break;

        case "isDayOfMonth":
            result = true;
            break;

        case "dayOfMonth":
            result = true;
            break;

        case "recurringWeek":
            result = true;
            break;

        case "weekday":
            result = true;
            break;

        case "monthsOfYear":
            result = true;
            break;
    }

    return result;
}

async function calculateRunDates(
    request: IWorkflowScheduleCalculateRunDateRequest,
    signal?: AbortSignal,
) {
    try {
        request.timezone = getTimeZone();

        const response = await axios.post<IWorkflowScheduleCalculatedDatesDTO>(
            `${baseUrl}/calculate`,
            request,
            { signal },
        );

        const result = response.data;
        if (result) {
            result.startDate = convertToDate(result.startDate);
            result.endDate = convertToDate(result.endDate);
        }

        return ExecutionResult.Result<IWorkflowScheduleCalculatedDatesDTO>(
            result,
        );
    } catch (error) {
        return ExecutionResult.Failed<IWorkflowScheduleCalculatedDatesDTO>(
            error,
        );
    }
}

export const useCalculateWeeklyRecurringChecklists = (
    data: IRecurringWeekly,
    enabled: boolean,
) => {
    const {
        startDate,
        isStartDate,
        endDate,
        isEndDate,
        numberOfRuns,
        workdays = [],
        intervalNumber,
        duration,
    } = data;

    const isEnabled =
        enabled &&
        startDate &&
        workdays.length > 0 &&
        ((endDate && isEndDate) ||
            (numberOfRuns !== undefined && isEndDate === false));

    return useQuery({
        queryKey: ["calculate-recurring-checklists--weekly", data],
        queryFn: (context) => {
            const request: IWorkflowScheduleCalculateRunDateRequest = {
                numberOfRuns,
                interval: WorkflowScheduleInterval.Weekly,
                intervalNumber,

                isStartDateUpdated: isStartDate,
                startDate,

                isEndDateUpdated: isEndDate,
                endDate,

                duration,
                workdays,
            };

            return calculateRunDates(request, context.signal);
        },
        enabled: Boolean(isEnabled),
        placeholderData: keepPreviousData,
    });
};

export const useCalculateMonthlyRecurringChecklists = (
    data: IRecurringMonthly,
    enabled: boolean,
) => {
    const {
        startDate,
        isStartDate,
        endDate,
        isEndDate,
        numberOfRuns,
        intervalNumber,
        duration,
        weekday,
        isDayOfMonth = false,
        dayOfMonth,
        recurringWeek,
    } = data;

    const isEnabled =
        enabled &&
        ((isDayOfMonth && dayOfMonth !== undefined) ||
            (isDayOfMonth === false &&
                weekday !== undefined &&
                recurringWeek !== undefined)) &&
        startDate &&
        ((endDate && isEndDate) ||
            (numberOfRuns !== undefined && isEndDate === false));

    return useQuery({
        queryKey: ["calculate-recurring-checklists--monthly", data],
        queryFn: (context) => {
            const request: IWorkflowScheduleCalculateRunDateRequest = {
                numberOfRuns,
                interval: WorkflowScheduleInterval.Monthly,
                intervalNumber,

                isStartDateUpdated: isStartDate,
                startDate,

                isEndDateUpdated: isEndDate,
                endDate,

                duration,

                workdays: weekday === undefined ? [] : [weekday],

                isDayOfMonthUpdated: isDayOfMonth,
                dayOfMonth,

                recurringWeek,
            };

            return calculateRunDates(request, context.signal);
        },
        enabled: Boolean(isEnabled),
        placeholderData: keepPreviousData,
    });
};

export const useCalculateYearlyRecurringChecklists = (
    data: IRecurringYearly,
    enabled: boolean,
) => {
    const {
        startDate,
        isStartDate,
        endDate,
        isEndDate,
        numberOfRuns,
        intervalNumber,
        duration,
        weekday,
        isDayOfMonth = false,
        dayOfMonth,
        recurringWeek,
        monthsOfYear = [],
    } = data;

    const isEnabled =
        enabled &&
        monthsOfYear.length > 0 &&
        ((isDayOfMonth && dayOfMonth !== undefined) ||
            (isDayOfMonth === false &&
                weekday !== undefined &&
                recurringWeek !== undefined)) &&
        startDate &&
        ((endDate && isEndDate) ||
            (numberOfRuns !== undefined && isEndDate === false));

    return useQuery({
        queryKey: ["calculate-recurring-checklists--yearly", data],
        queryFn: (context) => {
            const request: IWorkflowScheduleCalculateRunDateRequest = {
                numberOfRuns,
                interval: WorkflowScheduleInterval.Yearly,
                intervalNumber,

                isStartDateUpdated: isStartDate,
                startDate,

                isEndDateUpdated: isEndDate,
                endDate,

                duration,

                workdays: weekday === undefined ? [] : [weekday],

                isDayOfMonthUpdated: isDayOfMonth,
                dayOfMonth,

                recurringWeek,
                monthsOfYear,
            };

            return calculateRunDates(request, context.signal);
        },
        enabled: Boolean(isEnabled),
        placeholderData: keepPreviousData,
    });
};

export async function calculateAddedRunDates(
    request: IRecurringChecklistCalculateDatesByIdRequest,
    signal?: AbortSignal,
) {
    try {
        request.timezone = getTimeZone();

        const response = await axios.post<IDaysRange[]>(
            `${baseUrl}/calculate-added-by-id`,
            request,
            { signal },
        );

        return ExecutionResult.Result<IDaysRange[]>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IDaysRange[]>(error);
    }
}

async function getRecurringChecklistById(id: string, signal?: AbortSignal) {
    const url = `${baseUrl}/recurring-checklist/${id}`;

    const response = await axios.get<IRecurringChecklist>(url, { signal });

    if (response.data) {
        response.data.startDate = convertToDate(response.data.startDate);
        response.data.endDate = convertToDate(response.data.endDate);
    }

    return response.data;
}

export const useRecurringChecklistById = (id?: string) => {
    return useQuery({
        queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, id],
        queryFn: (context) => {
            if (id) {
                return getRecurringChecklistById(id, context.signal);
            }
        },
        placeholderData: keepPreviousData,
    });
};

async function getRecurringChecklistGroups(
    templateVersionId: string,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/recurring-checklist-groups-by-template-version-id/${templateVersionId}`;

    const response = await axios.get<IRecurringChecklistGroup[]>(url, {
        signal,
    });

    return response.data;
}

export const useRecurringChecklistGroupsByTemplateVersionId = (
    templateVersionId?: string,
) => {
    return useQuery({
        queryKey: [
            RECURRING_CHECKLIST_GROUPS_BY_TEMPLATE_VERSION_ID_KEY,
            templateVersionId,
        ],
        queryFn: (context) => {
            if (templateVersionId) {
                return getRecurringChecklistGroups(
                    templateVersionId,
                    context.signal,
                );
            }
        },
        enabled: !!templateVersionId,
    });
};

async function getRecurringChecklistItems(
    templateVersionId: string,
    signal?: AbortSignal,
) {
    const request: IRecurringChecklistAllChecklistsByTemplateVersionIdRequest =
        {
            templateVersionId,
            timezone: getTimeZone(),
        };

    const url = `${baseUrl}/recurring-checklist-items-by-template-version-id`;

    const response = await axios.post<
        IDictionary<IRecurringChecklistListItem[]>
    >(url, request, {
        signal,
    });

    return response.data;
}

export const useRecurringChecklistItemsByTemplateVersionId = (
    templateVersionId?: string,
) => {
    return useQuery({
        queryKey: [
            RECURRING_CHECKLIST_ITEMS_BY_TEMPLATE_VERSION_ID_KEY,
            templateVersionId,
        ],
        queryFn: (context) => {
            if (templateVersionId) {
                return getRecurringChecklistItems(
                    templateVersionId,
                    context.signal,
                );
            }
        },
        enabled: !!templateVersionId,
    });
};

async function pauseRecurringChecklist(request: IPauseRequest) {
    try {
        const response = await axios.post<IPauseResponse>(
            `${baseUrl}/pause`,
            request,
        );

        return ExecutionResult.Result<IPauseResponse>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IPauseResponse>(error);
    }
}

export const usePauseRecurringChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: IPauseRequest) =>
            pauseRecurringChecklist(request),

        onSuccess: async (data) => {
            if (data.Succeeded && data.Data) {
                await queryClient.invalidateQueries({
                    queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, data.Data.id],
                });
            }
        },
    });
};

async function archiveRecurringChecklist(request: IArchiveRequest) {
    try {
        const response = await axios.post<IArchiveResponse>(
            `${baseUrl}/archive`,
            request,
        );

        return ExecutionResult.Result<IArchiveResponse>(response.data);
    } catch (error) {
        return ExecutionResult.Failed<IArchiveResponse>(error);
    }
}

export const useArchiveRecurringChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: IArchiveRequest) =>
            archiveRecurringChecklist(request),

        onSuccess: (data) => {
            if (data.Succeeded && data.Data) {
                queryClient.invalidateQueries({
                    queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, data.Data.id],
                });
            }
        },
    });
};

async function deleteRecurringChecklist(args: {
    id: string;
    templateVersionId: string;
}) {
    const { id } = args;

    try {
        await axios.delete(`${baseUrl}/${id}`);

        return ExecutionResult.Result();
    } catch (error) {
        return ExecutionResult.Failed(error);
    }
}

export const useDeleteRecurringChecklistMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteRecurringChecklist,
        onSuccess: (data, { id, templateVersionId }) => {
            if (data.Succeeded) {
                queryClient.removeQueries({
                    queryKey: [RECURRING_CHECKLIST_BY_ID_KEY, id],
                });

                queryClient.setQueryData<IRecurringChecklistGroup[]>(
                    [
                        RECURRING_CHECKLIST_GROUPS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                    (old) => old?.filter((x) => x.id !== id) ?? [],
                );

                queryClient.setQueryData<
                    IDictionary<IRecurringChecklistListItem[]>
                >(
                    [
                        RECURRING_CHECKLIST_ITEMS_BY_TEMPLATE_VERSION_ID_KEY,
                        templateVersionId,
                    ],
                    (old) => {
                        const { [id]: deletedValue, ...others } = old ?? {};

                        return others;
                    },
                );
            }
        },
    });
};

export function getDefaultRecurrenceName(
    locale: typeof AppLocale,
    templateTitle: string,
    startDate?: Date,
    endDate?: Date,
) {
    const result: string[] = [templateTitle];

    if (startDate) {
        result.push(format(startDate, "Pp", locale));
    }

    if (endDate) {
        result.push(format(endDate, "P", locale));
    }

    return result.join(" - ");
}

export const useDefaultRecurrenceName = (
    locale: typeof AppLocale,
    templateTitle: string,
    startDate?: Date,
    endDate?: Date,
) => {
    return React.useMemo(() => {
        return getDefaultRecurrenceName(
            locale,
            templateTitle,
            startDate,
            endDate,
        );
    }, [locale, templateTitle, startDate, endDate]);
};

export function buildExistingTimelineItems(
    groupId: string,
    list: IRecurringChecklistListItem[],
) {
    return list.map((x) => {
        const value: TimelineItem<{ tempItemMode?: "added" | "removed" }> = {
            id: x.id,
            group: groupId,
            start_time: x.start,
            end_time: x.end,
            title: x.title,
            canMove: false,
            canResize: false,
        };

        return value;
    });
}
