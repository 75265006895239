import "./SingleChecklistTimelineView.scss";
import "react-calendar-timeline/lib/Timeline.css";

import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import Timeline, {
    TimelineMarkers,
    TodayMarker,
} from "react-calendar-timeline";
import { MdOutlineZoomIn, MdOutlineZoomOut } from "react-icons/md";

import classNames from "classnames";
import moment from "moment";

import IconButton from "common/components/buttons/icon-button/IconButton";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { IChecklistEntityDto } from "models/IChecklistEntityDto";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import useDebounce from "utils/useDebounce";

interface IProps {
    showOnlyRowsWithVisibleContent?: boolean;
    defaultTimeStart?: Date;
    defaultTimeEnd?: Date;
    isLoading?: boolean;
    checklistRows: IChecklistEntityDto[];
    onShowChecklistDetails?: (checklistId: string) => void;
    onVisibleBoundsChange?: (start: number, end: number) => void;
    onItemMoveOrResize: (
        itemId: string,
        newStartDate?: Date,
        newEndDate?: Date,
    ) => void;
}

interface TimelineItem {
    id: string;
    group: string;
    title: string;
    start_time: number;
    end_time: number;
    className: string;
}

interface TimelineGroup {
    id: string;
    title: string;
}

const INITIAL_GROUPS_COUNT = 100;
const GROUPS_INCREMENT = 100;
const MIN_ZOOM_DURATION = 2 * 24 * 60 * 60 * 1000; // 2 days in ms
const MAX_ZOOM_DURATION = 365 * 24 * 60 * 60 * 1000; // 1 year in ms

const ZOOM_LEVELS = [
    { label: "2 days", duration: 2 * 24 * 60 * 60 * 1000 },
    { label: "30 days", duration: 30 * 24 * 60 * 60 * 1000 },
    { label: "3 months", duration: 90 * 24 * 60 * 60 * 1000 },
    { label: "6 months", duration: 180 * 24 * 60 * 60 * 1000 },
    { label: "1 year", duration: 365 * 24 * 60 * 60 * 1000 },
];

const SingleChecklistTimelineView: React.FC<IProps> = (props) => {
    const {
        checklistRows,
        isLoading,
        showOnlyRowsWithVisibleContent,
        defaultTimeEnd = moment().startOf("day").add(14, "day").toDate(),
        defaultTimeStart = moment().startOf("day").add(-14, "day").toDate(),
    } = props;

    const [visibleTimeStart, setVisibleTimeStart] = useState<number>(
        defaultTimeStart.getTime(),
    );
    const [visibleTimeEnd, setVisibleTimeEnd] = useState<number>(
        defaultTimeEnd.getTime(),
    );
    const [visibleGroupsCount, setVisibleGroupsCount] =
        useState<number>(INITIAL_GROUPS_COUNT);
    const [displayedChecklistRows, setDisplayedChecklistRows] = useState<
        IChecklistEntityDto[]
    >([]);

    const [currentChecklistRows, setCurrentChecklistRows] =
        useState<IChecklistEntityDto[]>(checklistRows);

    const debouncedTimeRange = useDebounce(
        { start: visibleTimeStart, end: visibleTimeEnd },
        500,
    );

    const timelineContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isLoading && checklistRows) {
            setCurrentChecklistRows(checklistRows);
        }
    }, [checklistRows, isLoading]);

    useEffect(() => {
        let filteredRows = currentChecklistRows;

        if (showOnlyRowsWithVisibleContent) {
            filteredRows = currentChecklistRows.filter((item) => {
                if (
                    item.startDate &&
                    item.endDate &&
                    moment(item.startDate).isValid() &&
                    moment(item.endDate).isValid()
                ) {
                    const itemStartTime = moment(item.startDate).valueOf();
                    const itemEndTime = moment(item.endDate).valueOf();
                    return (
                        itemStartTime <= debouncedTimeRange.end &&
                        itemEndTime >= debouncedTimeRange.start
                    );
                }
                return false;
            });
        }

        setDisplayedChecklistRows(filteredRows.slice(0, visibleGroupsCount));
    }, [
        currentChecklistRows,
        visibleGroupsCount,
        ...(showOnlyRowsWithVisibleContent ? [debouncedTimeRange] : []),
    ]);

    useEffect(() => {
        if (debouncedTimeRange) {
            const { start, end } = debouncedTimeRange;
            props.onVisibleBoundsChange?.(start, end);
        }
    }, [debouncedTimeRange, props.onVisibleBoundsChange]);

    const getItemClassName = useCallback((item: IChecklistEntityDto) => {
        if (item.status === ChecklistDynamicStatus.Finalized) {
            return "finalized";
        } else if (moment(item.endDate).isBefore(moment())) {
            return "past-due";
        } else {
            return "ongoing";
        }
    }, []);

    const items = useMemo(() => {
        if (displayedChecklistRows && displayedChecklistRows.length > 0) {
            return displayedChecklistRows
                .map((item) => {
                    if (
                        item.id &&
                        item.startDate &&
                        item.endDate &&
                        moment(item.startDate).isValid() &&
                        moment(item.endDate).isValid()
                    ) {
                        const itemStartTime = moment(item.startDate).valueOf();
                        const itemEndTime = moment(item.endDate).valueOf();

                        return {
                            id: item.id,
                            group: item.id,
                            title: item.title ?? "",
                            start_time: itemStartTime,
                            end_time: itemEndTime,
                            className: getItemClassName(item),
                        };
                    }
                    return null;
                })
                .filter((item): item is TimelineItem => item !== null);
        }
        return [];
    }, [displayedChecklistRows, getItemClassName]);

    const groups = useMemo(() => {
        if (displayedChecklistRows && displayedChecklistRows.length > 0) {
            return displayedChecklistRows.map((item) => ({
                id: item.id,
                title: item.title ?? "",
            }));
        }
        return [{ id: "dummy-group", title: "" }];
    }, [displayedChecklistRows]);

    const handleItemClick = useCallback(
        (itemId: string) => {
            props.onShowChecklistDetails?.(itemId);
        },
        [props.onShowChecklistDetails],
    );

    const itemRenderer = ({
        item,
        itemContext,
        getItemProps,
        getResizeProps,
    }: {
        item: TimelineItem;
        itemContext: any;
        getItemProps: any;
        getResizeProps: any;
    }) => {
        const { key, className: cn, ...rest } = getItemProps();

        const leftResizeProps = getResizeProps().left;
        const rightResizeProps = getResizeProps().right;

        const className = classNames(cn, {
            dragging: itemContext.dragging,
            resizing: itemContext.resizing,
        });

        return (
            <div key={key} className={className} {...rest}>
                <div
                    {...leftResizeProps}
                    style={{
                        ...leftResizeProps.style,
                        cursor: itemContext.selected ? "col-resize" : "pointer",
                    }}
                />
                <div
                    className="rct-item-content"
                    style={{
                        maxHeight: `${itemContext.dimensions.height}`,
                        cursor: itemContext.selected ? "grab" : "pointer",
                    }}
                >
                    {item.title}
                </div>
                <div
                    {...rightResizeProps}
                    style={{
                        ...rightResizeProps.style,
                        cursor: itemContext.selected ? "col-resize" : "pointer",
                    }}
                />
            </div>
        );
    };

    const groupRenderer = useCallback(({ group }: { group: TimelineGroup }) => {
        return (
            <div
                key={group.id}
                className="custom-group"
                onClick={() => handleItemClick(group.id)}
            >
                {group.title}
            </div>
        );
    }, []);

    const handleTimeChange = useCallback(
        (
            visibleTimeStart: number,
            visibleTimeEnd: number,
            updateScrollCanvas: (start: number, end: number) => void,
        ) => {
            setVisibleTimeStart(visibleTimeStart);
            setVisibleTimeEnd(visibleTimeEnd);
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
        },
        [],
    );

    const handleScroll = useCallback(() => {
        if (!timelineContainerRef.current) return;

        const container = timelineContainerRef.current;
        const { scrollTop, scrollHeight, clientHeight } = container;

        // Threshold to trigger loading more rows (e.g., when 80% scrolled)
        const threshold = 0.8 * scrollHeight;

        if (scrollTop + clientHeight >= threshold) {
            if (visibleGroupsCount < currentChecklistRows.length) {
                setVisibleGroupsCount((prev) =>
                    Math.min(
                        prev + GROUPS_INCREMENT,
                        currentChecklistRows.length,
                    ),
                );
            }
        }
    }, [visibleGroupsCount, currentChecklistRows.length]);

    const handleItemMove = useCallback(
        (itemId: string, dragTime: number, newGroupOrder: number) => {
            const itemToUpdate = currentChecklistRows.find(
                (row) => row.id === itemId,
            );
            if (itemToUpdate) {
                const duration = moment(itemToUpdate.endDate).diff(
                    moment(itemToUpdate.startDate),
                );
                const newStartDate = moment(dragTime).toDate();
                const newEndDate = moment(dragTime + duration).toDate();

                setCurrentChecklistRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === itemId
                            ? {
                                  ...row,
                                  startDate: newStartDate,
                                  endDate: newEndDate,
                              }
                            : row,
                    ),
                );

                props.onItemMoveOrResize(itemId, newStartDate, newEndDate);
            }
        },
        [currentChecklistRows],
    );

    const handleItemResize = useCallback(
        (itemId: string, time: number, edge: "left" | "right") => {
            const itemToUpdate = currentChecklistRows.find(
                (row) => row.id === itemId,
            );
            if (itemToUpdate) {
                let newStartDate = itemToUpdate.startDate;
                let newEndDate = itemToUpdate.endDate;

                if (edge === "left") {
                    newStartDate = moment(time).toDate();
                } else if (edge === "right") {
                    newEndDate = moment(time).toDate();
                }

                setCurrentChecklistRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === itemId
                            ? {
                                  ...row,
                                  startDate: newStartDate,
                                  endDate: newEndDate,
                              }
                            : row,
                    ),
                );

                props.onItemMoveOrResize(itemId, newStartDate, newEndDate);
            }
        },
        [currentChecklistRows],
    );

    const handleItemDoubleClick = useCallback(
        (itemId: string, e: React.MouseEvent) => {
            props.onShowChecklistDetails?.(itemId);
        },
        [props.onShowChecklistDetails],
    );

    useEffect(() => {
        const container = timelineContainerRef.current;
        if (!container) return;

        container.addEventListener("scroll", handleScroll);

        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, []);

    const currentDuration = visibleTimeEnd - visibleTimeStart;

    const getZoomInLevelIndex = (duration: number) => {
        let index = -1;
        for (let i = ZOOM_LEVELS.length - 1; i >= 0; i--) {
            if (ZOOM_LEVELS[i].duration < duration) {
                index = i;
                break;
            }
        }
        return index;
    };

    const getZoomOutLevelIndex = (duration: number) => {
        let index = -1;
        for (let i = 0; i < ZOOM_LEVELS.length; i++) {
            if (ZOOM_LEVELS[i].duration > duration) {
                index = i;
                break;
            }
        }
        return index;
    };

    const zoomInIndex = getZoomInLevelIndex(currentDuration);
    const zoomOutIndex = getZoomOutLevelIndex(currentDuration);
    const canZoomIn = zoomInIndex !== -1;
    const canZoomOut = zoomOutIndex !== -1;

    const handleZoomIn = useCallback(() => {
        const currentDuration = visibleTimeEnd - visibleTimeStart;

        const zoomInIndex = getZoomInLevelIndex(currentDuration);

        if (zoomInIndex !== -1) {
            const newDuration = ZOOM_LEVELS[zoomInIndex].duration;
            const centerTime = (visibleTimeStart + visibleTimeEnd) / 2;
            const newVisibleTimeStart = centerTime - newDuration / 2;
            const newVisibleTimeEnd = centerTime + newDuration / 2;
            setVisibleTimeStart(newVisibleTimeStart);
            setVisibleTimeEnd(newVisibleTimeEnd);
        }
    }, [visibleTimeStart, visibleTimeEnd]);

    const handleZoomOut = useCallback(() => {
        const currentDuration = visibleTimeEnd - visibleTimeStart;

        const zoomOutIndex = getZoomOutLevelIndex(currentDuration);

        if (zoomOutIndex !== -1) {
            const newDuration = ZOOM_LEVELS[zoomOutIndex].duration;
            const centerTime = (visibleTimeStart + visibleTimeEnd) / 2;
            const newVisibleTimeStart = centerTime - newDuration / 2;
            const newVisibleTimeEnd = centerTime + newDuration / 2;
            setVisibleTimeStart(newVisibleTimeStart);
            setVisibleTimeEnd(newVisibleTimeEnd);
        }
    }, [visibleTimeStart, visibleTimeEnd]);

    const todayDate = new Date(Date.now());

    const showSpinner =
        isLoading ||
        visibleTimeStart !== debouncedTimeRange.start ||
        visibleTimeEnd !== debouncedTimeRange.end;

    return (
        <div className="single-checklist-timeline-view">
            <AnimatedSpinner
                position="center"
                aligned="center"
                isVisible={showSpinner}
            />

            <div className="timeline-container" ref={timelineContainerRef}>
                <div className="timeline-container__zoom">
                    <div className="timeline-container__zoom--fixed">
                        <IconButton
                            onClick={handleZoomIn}
                            disabled={!canZoomIn}
                        >
                            <MdOutlineZoomIn />
                        </IconButton>

                        <IconButton
                            onClick={handleZoomOut}
                            disabled={!canZoomOut}
                        >
                            <MdOutlineZoomOut />
                        </IconButton>
                    </div>
                </div>
                <Timeline
                    groups={groups}
                    items={items}
                    visibleTimeStart={visibleTimeStart}
                    visibleTimeEnd={visibleTimeEnd}
                    itemTouchSendsClick={false}
                    stackItems
                    canMove={true}
                    canResize="both"
                    canChangeGroup={false}
                    minZoom={MIN_ZOOM_DURATION}
                    maxZoom={MAX_ZOOM_DURATION}
                    itemRenderer={itemRenderer}
                    groupRenderer={groupRenderer}
                    onTimeChange={handleTimeChange}
                    onItemMove={handleItemMove}
                    onItemResize={handleItemResize}
                    onItemDoubleClick={handleItemDoubleClick}
                >
                    <TimelineMarkers>
                        {todayDate && <TodayMarker date={todayDate} />}
                    </TimelineMarkers>
                </Timeline>
            </div>
        </div>
    );
};

export default SingleChecklistTimelineView;
