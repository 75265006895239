import "./WorksheetStatusBadge.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import ChecklistStatusBadge from "../checklist-status-badge/ChecklistStatusBadge";
import FormattedDate, {
    formatDateWithTypeCheck,
} from "common/components/formattedDate/FormattedDate";
import { ClockIcon } from "common/components/icons";
import TooltipWrapper from "common/components/tooltip/TooltipWrapper";
import {
    ChecklistDynamicStatus,
    allChecklistStatusesAsDictionary,
} from "models/enums/ChecklistDynamicStatus";

const DateKeyByStatus = (status: ChecklistDynamicStatus) => {
    const dateKey = {
        [ChecklistDynamicStatus.Archived]: k.ARCHIVED_AT,
        [ChecklistDynamicStatus.Finalized]: k.FINALIZED_AT,
        [ChecklistDynamicStatus.Stopped]: k.STOPPED_AT,
        default: k.DUE,
    } as Record<number | "default", string>;

    return dateKey[status] || dateKey["default"];
};

interface IProps {
    status: ChecklistDynamicStatus;
    endDate?: Date;
    archivedAt?: Date;
    stoppedAt?: Date;
    stoppedBy?: string;
    completedAt?: Date;
}

const WorksheetStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status, endDate, archivedAt, stoppedAt, completedAt } = props;

    const { t } = useTranslation();

    if (status === ChecklistDynamicStatus.None) {
        return null;
    }

    let dateToShow: Date | undefined;

    switch (status) {
        case ChecklistDynamicStatus.Archived:
            dateToShow = archivedAt;
            break;
        case ChecklistDynamicStatus.Finalized:
            dateToShow = completedAt;
            break;
        case ChecklistDynamicStatus.Behind:
            dateToShow = endDate;
            break;
        case ChecklistDynamicStatus.Stopped:
            dateToShow = stoppedAt;
            break;
    }

    const option = allChecklistStatusesAsDictionary[status];

    return (
        <TooltipWrapper
            showTooltip={!!dateToShow}
            id={`checklist-status-${status}`}
            message={
                <>
                    {t(DateKeyByStatus(status))}{" "}
                    <FormattedDate date={dateToShow} />
                </>
            }
        >
            <div className="worksheet-status-badge ">
                {dateToShow && (
                    <>
                        <ClockIcon />
                        <FormattedDate date={dateToShow} onlyDate />
                    </>
                )}
                <ChecklistStatusBadge
                    status={status}
                    title={
                        t(option?.label) +
                        (dateToShow
                            ? " " +
                              formatDateWithTypeCheck({
                                  date: dateToShow,
                                  onlyDate: true,
                              })
                            : "")
                    }
                    text={t(option?.label)}
                />
            </div>
        </TooltipWrapper>
    );
};

export default WorksheetStatusBadge;
