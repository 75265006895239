import "./ManualDataSource.scss";

import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { Button } from "common/components/buttons";
import { IManualDataSourceValueDto } from "common/components/modal-manager-content/kpi-form/api/IManualDataSourceValueDto";
import { ButtonGroup } from "react-bootstrap";
import { useSaveManualDataSourceValues } from "common/components/modal-manager-content/kpi-form/api/hooks";
import { IManualDataSourceRequest } from "common/components/modal-manager-content/kpi-form/api/IManualDataSourceRequest";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { newManualDataSourceYear } from "../../../../api/utils";
import YearlyTable from "common/components/YearlyTable/YearlyTable";
import { IManualDataSourceDto } from "common/components/modal-manager-content/kpi-form/api/IManualDataSourceDto";
import InputWrapper from "common/components/input-components/InputWrapper";
import { parseDecimalWithLocale } from "AppLocale";
import useComponentActions from "common/components/react-custom-hooks/useComponentActions";
import { InfoIcon } from "common/components/icons";

interface IProps {
    kpiId?: string;
    variableName?: string;
    formulaItemId?: string;
    manualDataSourceId?: string;
    manualDataSource?: IManualDataSourceDto;
    canEdit?: boolean;

    hasChanges?: boolean;
    onHasChanges: (manualDataSourceId: string, hasChanges: boolean) => void;
    registerAction: ReturnType<typeof useComponentActions>["registerAction"];
}

const ManualDataSource: React.FC<IProps> = (props) => {
    const {
        kpiId,
        variableName,
        formulaItemId,
        manualDataSourceId,
        manualDataSource,
        hasChanges,
        canEdit,
    } = props;

    const { t } = useTranslation();

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const [values, setValues] = useState<
        Record<string, IManualDataSourceValueDto | undefined>
    >(manualDataSource?.values ?? {});

    const saveButtonRef = useRef<HTMLButtonElement>(null);

    const [manualValueIds, setManualValueIds] = useState<
        Record<number, string[] | undefined> | undefined
    >(manualDataSource?.manualValueIdsByYear);

    const { mutateAsync: saveMutationAsync, isLoading: isSaving } =
        useSaveManualDataSourceValues();

    const handleCancel = () => {
        if (manualDataSourceId) {
            props.onHasChanges(manualDataSourceId, false);
        }
    };

    const handleScrollToSaveButton = () => {
        saveButtonRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (manualDataSourceId) {
            props.registerAction(manualDataSourceId, handleScrollToSaveButton);
        }
    }, [manualDataSourceId, props.registerAction]);

    const handleSave = async () => {
        var request: IManualDataSourceRequest = {
            id:
                manualDataSourceId === undefined || manualDataSourceId === ""
                    ? uuidv4()
                    : manualDataSourceId,
            values: values,
            requestKpiId: kpiId ?? "",
            requestFormulaItemId: formulaItemId ?? "",
        };

        var result = await saveMutationAsync({
            createNew: false,
            value: request,
        });
        if (result.Succeeded) {
            toast.dismiss();

            toast.success(t(k.KPI_UPDATED), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            props.onHasChanges(request.id, false);
        }
    };

    useEffect(() => {
        if (manualDataSource && currentYear) {
            if (
                manualValueIds === undefined ||
                !Object.keys(manualValueIds).includes(currentYear.toString())
            ) {
                let data = newManualDataSourceYear({
                    nextYear: currentYear,
                    values: hasChanges ? values : manualDataSource.values,
                    valueIds: hasChanges
                        ? manualValueIds
                        : manualDataSource.manualValueIdsByYear,
                });
                setValues((prev) => ({
                    ...prev,
                    ...data.manualDataSourceValues,
                }));
                setManualValueIds((prev) => ({
                    ...prev,
                    [currentYear]: data.manualValueIds,
                }));
            }
        }
    }, [currentYear]);

    useEffect(() => {
        if (manualDataSource && !hasChanges) {
            let data = newManualDataSourceYear({
                nextYear: currentYear,
                values: manualDataSource.values,
                valueIds: manualDataSource.manualValueIdsByYear,
            });
            setValues(data.manualDataSourceValues);
            setManualValueIds({
                [currentYear]: data.manualValueIds,
            });
        }
    }, [manualDataSource, hasChanges]);

    const handleChangeValue = (param: { id: string; value: string }) => {
        const { id, value } = param;

        let item = values[id];

        if (item) {
            const hasValue = value !== "";

            const nextQuantity = hasValue
                ? parseDecimalWithLocale(value)
                : undefined;

            const prevItem = values[id];

            const nextValues: typeof values = prevItem
                ? {
                      ...values,
                      [id]: {
                          ...prevItem,
                          hasQuantity: hasValue,
                          quantity: nextQuantity,
                      },
                  }
                : values;

            setValues(nextValues);
        }
        props.onHasChanges(manualDataSourceId ?? "", true);
    };

    const handleChangeYear = (year: number) => {
        setCurrentYear(year);
    };

    return (
        <div className="manual-data-source">
            <InputWrapper
                htmlFor="manual-data-source--wrapper"
                wrapperLabel={
                    <div className="manual-data-source--wrapper--label">
                        {variableName}
                        <InfoIcon
                            tooltip={{
                                id: "manual-data-source--wrapper--label--info",
                                message: t(k.KPI_MANUAL_DATA_SOURCE_INFO),
                            }}
                        />
                    </div>
                }
                boldLabel
            >
                <YearlyTable
                    year={currentYear}
                    idsByYear={manualValueIds?.[currentYear] ?? []}
                    values={values}
                    onChange={handleChangeValue}
                    onChangeYear={handleChangeYear}
                    allowNegativeNumbers
                    fillCellsBetweenValues={false}
                    disabled={!canEdit}
                />
            </InputWrapper>
            {canEdit && (
                <div className="manual-data-source--buttons">
                    <ButtonGroup className="manual-data-source--buttons--btn-group">
                        <Button
                            variant="danger"
                            transparent
                            noMinWidth
                            disabled={isSaving || !hasChanges}
                            testId="manual-data-source-cancel-btn"
                            onClick={handleCancel}
                        >
                            {t(k.CANCEL)}
                        </Button>
                        <Button
                            variant="success"
                            noMinWidth
                            innerRef={saveButtonRef}
                            testId="manual-data-source-save-btn"
                            disabled={isSaving || !hasChanges}
                            onClick={handleSave}
                            isBusy={isSaving}
                        >
                            {t(k.SAVE)}
                        </Button>
                    </ButtonGroup>
                </div>
            )}
        </div>
    );
};

export default ManualDataSource;
