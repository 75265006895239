import { Reducer } from "redux";

import { PageNavigationActionTypes, RECEIVE_PAGE_CHANGE } from "./types";

export interface IPageNavigationState {
    readonly hasPageChange: boolean;
}

const initialState: IPageNavigationState = {
    hasPageChange: false,
};

export const PageNavigationReducer = (
    state = initialState,
    action: PageNavigationActionTypes,
): IPageNavigationState => {
    switch (action.type) {
        case RECEIVE_PAGE_CHANGE: {
            return {
                ...state,
                hasPageChange: action.payload ?? false,
            };
        }

        default:
            return state;
    }
};
