import { IGradingBadgeBaseProps } from "../simple-badges/badges-grading/IGradingBadgeBaseProps";
import HighGradeBadge from "../simple-badges/badges-grading/badge-high-grade/HighGradeBadge";
import LowGradeBadge from "../simple-badges/badges-grading/badge-low-grade/LowGradeBadge";
import ModerateGradeBadge from "../simple-badges/badges-grading/badge-moderate-grade/ModerateGradeBadge";
import NoGradeBadge from "../simple-badges/badges-grading/badge-no-grade/NoGradeBadge";
import VeryHighGradeBadge from "../simple-badges/badges-grading/badge-very-high-grade/VeryHighGradeBadge";
import VeryLowGradeBadge from "../simple-badges/badges-grading/badge-very-low-grade/VeryLowGradeBadge";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";

type IImpactGradingBadgesAdditionalProps = {
    type: ImpactGradingEnum;
    showNone?: boolean;
};

type IImpactGradingBadgesProps = IGradingBadgeBaseProps &
    IImpactGradingBadgesAdditionalProps;

const badgeByType = (showNone?: boolean) => ({
    [ImpactGradingEnum.None]: showNone ? NoGradeBadge : null,
    [ImpactGradingEnum.VeryLow]: VeryLowGradeBadge,
    [ImpactGradingEnum.Low]: LowGradeBadge,
    [ImpactGradingEnum.Moderate]: ModerateGradeBadge,
    [ImpactGradingEnum.High]: HighGradeBadge,
    [ImpactGradingEnum.VeryHigh]: VeryHighGradeBadge,
});

const ImpactGradingBadges = (props: IImpactGradingBadgesProps) => {
    const { showNone, type, ...rest } = props;
    const Component = badgeByType(showNone)[type];
    return Component ? <Component {...rest} /> : null;
};

export default ImpactGradingBadges;
