import { createRef, useEffect, useState } from "react";

import IElementReference from "../../../../common/components/listed-content/models/IElementReference";
import {
    useGetInvolvedUsersForTemplateByTemplateVersionIdMutation,
    useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation,
    useImprovementById,
    useStepInstancesActivitiesByImprovementId,
    useStepInstancesByImprovementId,
} from "../../api/hooks";
import { IImprovementDetailsProps } from "./ImprovementListedContent";
import { IImprovementData } from "./models/IImprovementData";
import {
    UseMutateAsyncFunction,
    UseMutationResult,
} from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import { InputValueType } from "common/components/activity-instance-card/ActivityInstanceCardById";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ITemplateVersionConfigurationUsersDto from "components/improvements/api/ITemplateVersionConfigurationUsersDto";
import ITemplateVersionConfigurationUsersRequest from "components/improvements/api/ITemplateVersionConfigurationUsersRequest";
import { mergeStringArraysAndDistinct } from "components/improvements/api/utils";
import { usePublishedUserOptions } from "components/users/api/hooks";
import ITableSet from "http/ITableSet";
import IImprovementRequest from "http/requests/IImprovementRequest";
import { IChangedStepInstance } from "http/requests/IWorkflowRunRequest";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import IActivityInstance from "models/IActivityInstance";

const SyncIntervalMs = 5000;

export const useImprovementData = (
    props: IImprovementDetailsProps,
): IImprovementData => {
    const { id, checkCanSeeAll, syncData, initialStepInstanceId } = props;

    const [editMode, setEditMode] = useState(false);
    const [errors, setErrors] = useState<IValidationMessages>();
    const [editedSteps, setEditedSteps] =
        useState<IDictionary<IChangedStepInstance>>();
    const [usersAffected, setUsersAffected] = useState<IDictionary<string[]>>();
    const [editedActivityValues, setEditedActivityValues] = useState<
        IDictionary<InputValueType>
    >({});
    const [stepRefs, setStepRefs] = useState<IElementReference>();
    const [lastUpdatedAt, setLastUpdatedAt] = useState<Date>();

    const [editedImprovement, setEditedImprovement] =
        useState<IImprovementRequest>();

    const [involvedUsers, setInvolvedUsers] =
        useState<Array<IValueLabelItem<string, string>>>();

    const [usersToBeNotified, setUsersToBeNotified] =
        useState<Array<IValueLabelItem<string, string>>>();

    const { data: improvement, isLoading: isLoadingImprovement } =
        useImprovementById(id);

    const { data: stepInstances, isLoading: isLoadingStepInstances } =
        useStepInstancesByImprovementId(
            true,
            checkCanSeeAll,
            id,
            syncData ? SyncIntervalMs : undefined,
        );

    const {
        data: involvedUsersMutationData,
        mutateAsync: involvedUsersMutateAsync,
        isPending: isLoadingInvolvedUsersMutation,
    } = useGetInvolvedUsersForTemplateByTemplateVersionIdMutation();

    const {
        data: notifyUsersMutationData,
        mutateAsync: notifyUsersMutateAsync,
        isPending: isLoadingNotifyUsersMutation,
    } = useGetUsersToBeNotifiedForTemplateByTemplateVersionIdMutation();

    const { data: publishedUsers } = usePublishedUserOptions();

    const {
        data: activities,
        isLoading: isLoadingActivityInstances,
        refetch: refetchActivities,
    } = useStepInstancesActivitiesByImprovementId(
        true,
        checkCanSeeAll,
        id,
        syncData ? SyncIntervalMs : undefined,
    );

    const [activityInstances, setActivityInstances] = useState<
        ITableSet<IActivityInstance> | undefined
    >();

    const [activityInstanceIdsBySet, setActivityInstanceIdsBySet] = useState<
        IDictionary<string[]> | undefined
    >();

    const {
        activityInstances: _activityInstances,
        activityInstancesIdsByActivityId: idsBySet,
    } = activities || {};

    useEffect(() => {
        if (!activities || !improvement) return;

        let stillNotSavedValues;
        let stillNotSavedIds;

        if (activityInstances) {
            //before updating with server data
            //get all the new unsaved instances

            const temp = filter(activityInstances.values, {
                edit: true,
            });

            stillNotSavedValues = mapValues(keyBy(temp, "id"));
            stillNotSavedIds = Object.keys(stillNotSavedValues);

            let instancesIdsBySet;
            let ids: IDictionary<string[]> = {};
            let merged: IDictionary<string[]> = {};

            if (stillNotSavedIds.length > 0) {
                instancesIdsBySet = mapValues(
                    keyBy(Object.values(stillNotSavedValues), "id"),
                    "activityInstanceSetId",
                );

                ids = Object.entries(instancesIdsBySet).reduce(
                    (acc: IDictionary<string[]>, [k, v]) => {
                        acc[v] = acc[v] ? [...acc[v], k] : [k];
                        return acc;
                    },
                    {},
                );

                merged = ids;
                if (idsBySet) {
                    const allKeys = new Set([
                        ...Object.keys(idsBySet),
                        ...Object.keys(ids),
                    ]);

                    merged = Array.from(allKeys).reduce(
                        (acc: IDictionary<string[]>, k) => {
                            acc[k] = [
                                ...new Set([
                                    ...(idsBySet[k] ?? []),
                                    ...(ids[k] ?? []),
                                ]),
                            ];
                            return acc;
                        },
                        {},
                    );
                }

                setActivityInstances({
                    values: {
                        ..._activityInstances,
                        ...(stillNotSavedValues || {}),
                    },
                    ids: [
                        ...Object.keys(_activityInstances || {}),
                        ...(stillNotSavedIds || []),
                    ],
                });
                setActivityInstanceIdsBySet(merged);
            } else {
                setActivityInstances({
                    values: {
                        ...activities?.activityInstances,
                    },
                    ids: [...Object.keys(activities.activityInstances)],
                });
                setActivityInstanceIdsBySet(idsBySet);
            }
        } else {
            setActivityInstances({
                values: {
                    ...activities?.activityInstances,
                },
                ids: [...Object.keys(activities.activityInstances)],
            });
            setActivityInstanceIdsBySet(idsBySet);
        }
    }, [_activityInstances, idsBySet, improvement]);

    const initialSection =
        initialStepInstanceId ?? (stepInstances && stepInstances.ids[0]);

    useEffect(() => {
        if (stepInstances && stepRefs === undefined) {
            setStepRefs(
                stepInstances.ids.reduce<{
                    [key: string]: React.RefObject<HTMLDivElement>;
                }>((acc, item) => {
                    acc[item] = createRef();

                    return acc;
                }, {}),
            );
        }
    }, [stepInstances]);

    useEffect(() => {
        if (activities && improvement) {
            let latestUpdated = improvement.updatedAt
                ? new Date(improvement.updatedAt).getTime()
                : undefined;

            const activityInstanceInputs = Object.values(
                activities?.activityInstances,
            );
            const updatedInputsDates = activityInstanceInputs.reduce(
                (acc: number[], input) => {
                    if (input && input?.updatedAt) {
                        acc.push(input.updatedAt.getTime());
                    }
                    return acc;
                },
                [],
            );
            const latestUpdatedActivityInstance = Math.max(
                ...updatedInputsDates.map((timestamp: number) => timestamp),
            );
            latestUpdated = latestUpdated
                ? latestUpdated > latestUpdatedActivityInstance
                    ? latestUpdated
                    : latestUpdatedActivityInstance
                : undefined;
            setLastUpdatedAt(
                latestUpdated ? new Date(latestUpdated) : undefined,
            );
        }
    }, [improvement, activities]);

    useEffect(() => {
        const fetchData = async (
            mutateAsync: UseMutateAsyncFunction<
                ExecutionResult<ITemplateVersionConfigurationUsersDto>,
                unknown,
                { request: ITemplateVersionConfigurationUsersRequest }
            >,
            shouldSetInvolvedUsers = false,
        ) => {
            if (editedImprovement?.formId) {
                let request = {
                    templateId: editedImprovement.formId,
                    improvementId: improvement?.id,
                    impactedProcesses: editedImprovement?.impactedProcesses,
                };

                let data = await mutateAsync({ request });

                const allUsersArray = mergeStringArraysAndDistinct(
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.discoveredInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.discoveredInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.subProcessIdResponsibleUsers ?? {},
                    ),
                    ...Object.values(
                        data.Data?.originatedInProcessOwners
                            ?.processStepIdResponsibleUsers ?? {},
                    ),
                    data.Data?.originatedInProcessOwners
                        ?.orphanedImprovementResponsibleUsers ?? [],
                    [data.Data?.managerOfReportingUser ?? ""],
                    data.Data?.admins ?? [],
                    data.Data?.deviationManagers ?? [],
                    data.Data?.involvedUserSets?.flatMap((set) => set.users) ??
                        [],
                );

                const selectedUsers = publishedUsers?.options?.filter((user) =>
                    allUsersArray.includes(user.value),
                );

                if (shouldSetInvolvedUsers) {
                    setInvolvedUsers(selectedUsers);
                } else {
                    setUsersToBeNotified(selectedUsers);
                }
                return data;
            }
        };
        //improvement already has specific settings for involved users
        //no need to calculate the ones from template configuration
        if (editedImprovement?.formId) {
            if (
                improvement?.involvedUsers !== undefined &&
                improvement.isInvolvedUsersChanged == true
            ) {
                setInvolvedUsers(
                    publishedUsers?.options?.filter((user) =>
                        Object.keys(improvement?.involvedUsers ?? {}).includes(
                            user.value,
                        ),
                    ),
                );
            } else {
                if (
                    involvedUsersMutationData === undefined ||
                    notifyUsersMutationData == undefined
                ) {
                    fetchData(involvedUsersMutateAsync, true);
                    fetchData(notifyUsersMutateAsync, false);
                }
            }
        }
    }, [editedImprovement?.impactedProcesses, publishedUsers]);

    const isLoading =
        isLoadingImprovement &&
        isLoadingStepInstances &&
        isLoadingActivityInstances;

    const isLoadingInvolvedUsers =
        isLoadingInvolvedUsersMutation || isLoadingNotifyUsersMutation;

    return {
        improvement,
        editedImprovement,
        setEditedImprovement,
        lastUpdatedAt,
        setLastUpdatedAt,
        editMode,
        setEditMode,
        editedActivityValues,
        setEditedActivityValues,
        errors,
        setErrors,
        editedSteps,
        setEditedSteps,
        usersAffected,
        setUsersAffected,
        usersToBeNotified,
        setUsersToBeNotified,
        involvedUsers,
        setInvolvedUsers,
        sectionRefs: stepRefs,
        selectedId: id,
        checkCanSeeAll,
        initialSection,
        stepInstances,

        isLoading,
        isLoadingImprovement,
        isLoadingActivityInstances,
        isLoadingStepInstances,
        isLoadingInvolvedUsers,

        activityInstanceSets: activities?.activityInstanceSets,
        activityInstances,
        setActivityInstances,
        activityInstanceSetIdsByStepInstance:
            activities?.activityInstanceSetIdsByStepInstanceId,
        activityInstanceIdsBySet,
        setActivityInstanceIdsBySet,

        onClose: props.onClose,
        onHaveChanges: props.onHaveChanges,
        onShowModal: props.onShowModal,
        refetchActivities,
    };
};
