import {
    ChecklistDynamicStatus,
    allChecklistStatuses,
} from "models/enums/ChecklistDynamicStatus";

export function getInitialSelectedStatuses(includeScheduled?: boolean) {
    const excludedValues = [
        ChecklistDynamicStatus.None,
        ChecklistDynamicStatus.Archived,
        ...(includeScheduled ? [] : [ChecklistDynamicStatus.Scheduled]),
    ];

    const result = allChecklistStatuses.filter(
        (x) => excludedValues.includes(x.id) === false,
    );

    return result.map((x) => x.id);
}
