import axios from "axios";

import { useQuery } from "@tanstack/react-query";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

const CHECKLIST_REPORT_SELECTION_OPTIONS = "CHECKLIST_REPORT_SELECTION_OPTIONS";

const baseUrl = "/api/workflowRun";

async function fetchSelectionOptions(
    activityVersionId?: string,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/selection-options/${activityVersionId}`;

    const response = await axios.get<IValueLabelItemWithState[]>(url, {
        signal,
    });

    return response.data;
}

export const useChecklistActivitySelectionOptions = (
    activityVersionId?: string,
) => {
    return useQuery({
        queryKey: [CHECKLIST_REPORT_SELECTION_OPTIONS, activityVersionId],

        queryFn: async (context) => {
            const result = await fetchSelectionOptions(
                activityVersionId,
                context.signal,
            );

            return result;
        },

        enabled: !!activityVersionId,
    });
};

const CHECKLIST_REPORT_TASKLIST_OPTIONS = "CHECKLIST_REPORT_TASKLIST_OPTIONS";

async function fetchTasklistOptions(
    activityVersionId?: string,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/tasklist-options/${activityVersionId}`;

    const response = await axios.get<IValueLabelItemWithState[]>(url, {
        signal,
    });

    return response.data;
}

export const useChecklistActivityTasklistOptions = (
    activityVersionId?: string,
) => {
    return useQuery({
        queryKey: [CHECKLIST_REPORT_TASKLIST_OPTIONS, activityVersionId],

        queryFn: async (context) => {
            const result = await fetchTasklistOptions(
                activityVersionId,
                context.signal,
            );

            return result;
        },

        enabled: !!activityVersionId,
    });
};
