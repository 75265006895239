import "./CompleteStepCard.scss";

import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import classNames from "classnames";

import k from "i18n/keys";

import SubmitImg from "../../assets/submit.png";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import Selection from "common/components/input-components/selection/Selection";
import IValidationMessages from "common/viewModels/IValidationMessages";
import ValidationLabel from "components/common/validation/ValidationLabel";
import { markCompleteStepButtonText } from "components/improvement-forms/components/ImprovementForm/utils";
import {
    ClosureReasonEnum,
    getClosureReasonOptions,
} from "models/enums/ClosureReasonEnum";

interface IProps {
    isLastStep?: boolean;
    isCompleted: boolean;
    isSaving: boolean;
    disabled?: boolean;
    errors: IValidationMessages | undefined;
    name?: string;
    onClick: (closureReason?: ClosureReasonEnum) => void;
}

const CompleteStepCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { isSaving, isLastStep, disabled, name } = props;

    const { t, i18n } = useTranslation();

    const reasonList = useMemo(
        () => getClosureReasonOptions(i18n),
        [i18n.language],
    );

    const [closureReason, setClosureReason] = useState<IValueLabelItem>();

    const isSubmitDisabled = isSaving;

    const handleOnStepCompleteClick = () => {
        props.onClick(closureReason?.param ?? ClosureReasonEnum.None);
    };

    const handleOnClosureReasonSelect = (value: string) => {
        const reason = reasonList.find((r) => r.value === value);

        if (reason) {
            setClosureReason(reason);
        }
    };

    const title = isLastStep
        ? k.WANT_TO_CLOSE_THIS_IMPROVEMENT
        : k.DONE_WITH_THIS_STEP;

    const subtitle = isLastStep
        ? k.CHOOSE_REASON_FOR_CLOSURE
        : k.CLICK_THE_BUTTON_BELLOW_TO_MARK_THIS_STEP_AS_COMPLETED;

    const buttonText = name
        ? markCompleteStepButtonText[name]
        : k.MARK_AS_COMPLETED;

    const buttonTestId = isLastStep ? "closeImprovementBtn" : "completeStepBtn";
    const buttonClassname = isLastStep ? "close-button" : "complete-button";
    const contentClassname = isLastStep ? "content-last-step" : "content";

    const completeStepCardClasses = classNames("complete-step-card", {
        "last-step": isLastStep,
    });

    return (
        <div className={completeStepCardClasses}>
            <div className="img">
                <img className="img-icon" src={SubmitImg} alt="" />
            </div>
            <div className={contentClassname}>
                <div className="desc desc-small">
                    <h3 className="title">{t(title)}</h3>
                    {t(subtitle)}.
                </div>
                <div className="complete-step-card--buttons">
                    {isLastStep && (
                        <>
                            {props.errors && (
                                <div className="complete-step-validation title-with-validation--validation">
                                    <ValidationLabel
                                        darkColor
                                        errors={t(
                                            props.errors
                                                ?.ClosureReason as string,
                                        )}
                                    />
                                </div>
                            )}
                            <Selection
                                options={reasonList}
                                selectedValue={closureReason?.value}
                                disabled={disabled}
                                onSelect={handleOnClosureReasonSelect}
                                testId="closureReasonSelectionOption"
                            />
                        </>
                    )}

                    <Button
                        className={buttonClassname}
                        disabled={
                            isSubmitDisabled ||
                            disabled ||
                            (isLastStep && !closureReason)
                        }
                        isBusy={isSubmitDisabled}
                        testId={buttonTestId}
                        onClick={handleOnStepCompleteClick}
                        variant="success"
                        title={
                            isLastStep && !disabled && !closureReason
                                ? t(k.CLOSURE_REASON_REQUIRED)
                                : undefined
                        }
                    >
                        {t(buttonText)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CompleteStepCard;
