import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IGradingBadgeBaseProps } from "../IGradingBadgeBaseProps";
import ScoreBadge from "common/components/badges/base-badges/badge-score/ScoreBadge";

type IHighGradeBadgeProps = IGradingBadgeBaseProps;

const HighGradeBadge = (props: IHighGradeBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <ScoreBadge
            score="lg"
            label={label ?? (props.short ? 4 : t(k.IMPACT_SCORE4_LABEL))}
            {...rest}
        />
    );
};

export default HighGradeBadge;
