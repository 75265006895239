import React, { useMemo } from "react";

import { connect } from "react-redux";

import { IIdModalHaveChanges } from "../api/IModalManager";
import { NavigationBlocker } from "common/components/navigation-blocker";
import { IAppState } from "store/IAppState";

interface IOwnProps {
    haveChangesList?: IIdModalHaveChanges;
    ignoreNextNavigation?: boolean;
}

interface IStateProps {
    pageHasChanges: boolean;
}

type Props = IOwnProps & IStateProps;

const ModalManagerChangeNavBlocker: React.FC<React.PropsWithChildren<Props>> = (
    props,
) => {
    const { pageHasChanges, haveChangesList, ignoreNextNavigation } = props;

    const hasChanges = useMemo(() => {
        if (haveChangesList) {
            return Object.values(haveChangesList).includes(true);
        }

        return false;
    }, [haveChangesList]);

    const showPrompt = ignoreNextNavigation
        ? false
        : pageHasChanges || hasChanges;

    return <NavigationBlocker showPrompt={showPrompt} />;
};

const mapToState = (appState: IAppState): IStateProps => {
    const { hasPageChange } = appState.pageNavigationViewState;

    return {
        pageHasChanges: hasPageChange,
    };
};

export default connect(mapToState)(ModalManagerChangeNavBlocker);
