import { Reducer } from "redux";

import IActivity from "../../models/IActivity";
import IStep from "../../models/IStep";
import { ActivityOrmModelType } from "../../models/orm/Activity";
import {
    LatestVersionStepOrmModelType,
    StepOrmModelType,
} from "../../models/orm/Step";
import { IORMState } from "../IORMState";
import orm, { initialOrmState } from "../orm";
import {
    RECEIVE_DELETE_STEP,
    RECEIVE_DELETE_STEP_DRAFT,
    RECEIVE_GET_STEP_ALL,
    RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID,
    RECEIVE_GET_STEP_DRAFT,
    RECEIVE_SAVE_STEP,
    REQUEST_GET_STEP_ALL,
    REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID,
    REQUEST_GET_STEP_DRAFT,
    StepActionTypes,
} from "./types";

export const StepOrmReducer = (
    state: IORMState = initialOrmState,
    action: StepActionTypes,
): IORMState => {
    const session = orm.session(state);

    const Step = session.Step as StepOrmModelType;
    const LatestVersionStep =
        session.LatestVersionStep as LatestVersionStepOrmModelType;
    const Activity = session.Activity as ActivityOrmModelType;

    switch (action.type) {
        case REQUEST_GET_STEP_ALL: {
            Step.all().delete();

            deleteAllActivities(Activity);

            break;
        }

        case RECEIVE_GET_STEP_ALL: {
            Step.parseList(action.payload);

            break;
        }

        case REQUEST_GET_STEP_DRAFT: {
            deleteLatestVersionSteps(LatestVersionStep, Activity);

            break;
        }

        case RECEIVE_GET_STEP_DRAFT: {
            LatestVersionStep.parseList(action.payload);

            break;
        }

        case REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID: {
            deleteLatestVersionSteps(LatestVersionStep, Activity);

            break;
        }

        case RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID: {
            LatestVersionStep.parseList(action.payload);

            break;
        }

        case RECEIVE_SAVE_STEP: {
            const savedStep = action.payload;

            if (savedStep.isDraft === false) {
                const stepsToDelete = Step.all()
                    .filter(
                        (x: IStep) =>
                            x.id !== savedStep.id &&
                            x.stepVersionId === savedStep.stepVersionId,
                    )
                    .toModelArray();

                for (const stepToDelete of stepsToDelete) {
                    deleteStepWithActivities(stepToDelete, Activity);
                }
            }

            Step.parseDTO(savedStep);

            break;
        }

        case RECEIVE_DELETE_STEP: {
            const stepsToDelete = Step.all()
                .filter((x: IStep) => x.stepVersionId === action.stepVersionId)
                .toModelArray();

            for (const stepToDelete of stepsToDelete) {
                deleteStepWithActivities(stepToDelete, Activity);
            }

            break;
        }

        case RECEIVE_DELETE_STEP_DRAFT: {
            Step.all()
                .filter((x: IStep) => x.stepVersionId === action.stepVersionId)
                .delete();

            break;
        }
    }

    return session.state;
};

function deleteLatestVersionSteps(
    LatestVersionStep: LatestVersionStepOrmModelType,
    Activity: ActivityOrmModelType,
) {
    const stepsToDelete = LatestVersionStep.all().toModelArray();

    for (const stepToDelete of stepsToDelete) {
        deleteStepWithActivities(stepToDelete, Activity);
    }
}

function deleteStepWithActivities(
    stepToDelete: any,
    Activity: ActivityOrmModelType,
) {
    const activities = Activity.all()
        .filter((x: IActivity) => x.stepId === stepToDelete.id)
        .toModelArray();

    for (const activity of activities) {
        // activity.attachments.all().delete();

        activity.delete();
    }

    stepToDelete.delete();
}

function deleteAllActivities(Activity: ActivityOrmModelType) {
    const activities = Activity.all().toModelArray();

    for (const activity of activities) {
        // activity.attachments.all().delete();

        activity.delete();
    }
}
