import "./ReportTable.scss";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { format } from "date-fns";

import k from "i18n/keys";

import { LevelOneReportDetailsHeader } from "../report-table-details/LevelOneReportDetailsHeader";
import { LevelTwoReportDetailsHeader } from "../report-table-details/LevelTwoReportDetailsHeader";
import IChecklistReportTableMatrixResponse from "../report-table-details/api/IChecklistReportResponse";
import { IChecklistReportValueSumFilter } from "../report-table-details/api/IChecklistReportValueSumFilter";
import { TableMatrixCalculator } from "../report-table-details/api/TableMatrixCalculator";
import { ChecklistStatusFilter } from "../report-table-details/filters/ChecklistStatusFilter";
import { getInitialSelectedStatuses } from "../report-table-details/filters/filterUtils";
import {
    ViewMode,
    defaultTimelineTimeEnd,
    defaultTimelineTimeStart,
} from "./ChecklistsTable";
import ReportTableRow from "./ReportTableRow";
import { ReportTableSumCells } from "./ReportTableSumCells";
import ChecklistTimelineView from "./timeline/ChecklistTimelineView";
import SingleChecklistTimelineView from "./timeline/SingleChecklistTimelineView";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import IChanged from "common/IChanged";
import { InfoIcon } from "common/components/icons";
import ArchiveWorksheetMessage from "common/components/messages/ArchiveWorksheetMessage";
import DeleteWorksheetMessage from "common/components/messages/DeleteWorksheetMessage";
import PauseWorksheetMessage from "common/components/messages/PauseWorksheetMessage";
import ResumeWorksheetMessage from "common/components/messages/ResumeWorksheetMessage";
import UnarchiveWorksheetMessage from "common/components/messages/UnarchiveWorksheetMessage";
import Pagination from "common/components/pagination/Pagination";
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHeader,
    TableRow,
} from "common/components/table";
import { MenuCommands } from "common/components/toggle-state/MenuCommands";
import { useUpdateChecklistMutation } from "components/checklists/api/hooks";
import { useCustomListItemNamesByIds } from "components/custom-list-item-page/api/hooks";
import { ChecklistReportSortBy } from "components/template-checklist-report/api/ChecklistReportSortBy";
import IChecklistReportDTO from "components/template-checklist-report/api/IChecklistReportDTO";
import IChecklistReportFilter, {
    CustomListFilterType,
} from "components/template-checklist-report/api/IChecklistReportFilter";
import IChecklistReportRow from "components/template-checklist-report/api/IChecklistReportRow";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";
import {
    getCalculateValueSums,
    useArchiveChecklistMutation,
    useChecklistReportDetailsQuery,
    useChecklistReportItemsQuery,
    useDeleteByIdChecklistMutation,
    usePauseChecklistMutation,
} from "components/template-checklist-report/api/hooks";
import ISortOrder from "http/filters/ISortOrder";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import IWorkflowRunRequest from "http/requests/IWorkflowRunRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";
import {
    ChecklistDynamicStatus,
    allChecklistStatuses,
} from "models/enums/ChecklistDynamicStatus";
import { showConfirmNoThunk } from "store/confirms/actions";

const DEFAULT_PAGE_SIZE = 10;

const WORKSHEET_REPORT_COLSPAN = 7;

type DateFilterKeysType = keyof Pick<
    IChecklistReportFilter,
    "startDate" | "endDate"
>;

interface IReportTableProps {
    templateVersionId?: string;

    isAdministrator: boolean;

    filter: IChanged<IChecklistReportFilter>;

    syncData?: boolean;

    viewMode: ViewMode;

    onBarcodeSearch?: (barcode: string) => void;

    onShowChecklistDetails: (checklistId: string) => void;

    onShowRecurrence: (recurringId: string) => void;

    onShowImprovements: (improvementId: string) => void;

    onShowChecklistTemplate?: (templateId: string) => void;
}

const ReportTable = (props: IReportTableProps) => {
    const {
        isAdministrator,
        templateVersionId,
        filter: externalFilter,
        syncData,
        viewMode,
    } = props;

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const [activePage, setActivePage] = useState(1);

    const [tableMatrix, setTableMatrix] =
        useState<IChecklistReportTableMatrixResponse | null>(null);

    const updateMutation = useUpdateChecklistMutation();

    const tableMatrixCalculator = React.useRef(
        new TableMatrixCalculator(setTableMatrix),
    ).current;

    const handleExpandHeader = async (
        column: ITemplateReportActivityTitle,
        mustExpand: boolean,
    ) => {
        await tableMatrixCalculator.expandAsync(column, mustExpand);
    };

    const isInTimelineView = viewMode.toLocaleLowerCase().includes("timeline");

    const [requestFilter, setRequestFilter] = useState<IChecklistReportFilter>({
        status: getInitialSelectedStatuses(isInTimelineView),
    });

    const [prevExternalFilter, setPrevExternalFilter] =
        useState<IChecklistReportFilter>();

    const [sort, setSort] = useState<ISortOrder<ChecklistReportSortBy>>({
        sortBy: ChecklistReportSortBy.StartDate,
        sortAsc: false,
    });

    const {
        data: checklistReportDto,
        isLoading,
        refetch: refetchReportItems,
    } = useChecklistReportItemsQuery(
        { syncData },
        externalFilter.value,
        requestFilter,
    );

    const showMatchResultsColumn = checklistReportDto?.hasSearchResult;

    const checklistRows = checklistReportDto?.paginatedData.items ?? [];

    const inChecklistTimelineView =
        viewMode === ViewMode.ChecklistTimelineView ||
        viewMode === ViewMode.ChecklistTimelineViewPerTemplate;

    const { data: reportDetailsResponse } = useChecklistReportDetailsQuery(
        { syncData },
        checklistReportDto?.uniqueKeys ?? {},
        (templateVersionId ? checklistReportDto?.checklistIds : undefined) ??
            [],
    );

    useEffect(() => {
        if (reportDetailsResponse) {
            tableMatrixCalculator.setup(reportDetailsResponse);
        } else if (!templateVersionId) {
            tableMatrixCalculator.reset();
        }
    }, [templateVersionId, reportDetailsResponse?.checklistIds]);

    const { data: selectedCustomListItemsNames } = useCustomListItemNamesByIds(
        tableMatrix?.uniqueSelectedCustomListItemIds,
    );

    const handleOnChecklistMoveOrResize = async (
        checklistId: string,
        newStartDate?: Date,
        newEndDate?: Date,
    ) => {
        const checklist = checklistRows.find((x) => x.id === checklistId);
        const request: IWorkflowRunRequest = {
            id: checklistId,
            name: checklist?.title,
            startDate: newStartDate,
            endDate: newEndDate,
            checkIfAdmin: isAdministrator,
        };
        await updateMutation.mutateAsync({ request });
    };

    const fetchData = async (
        pageNumber: number,
        newSort?: ISortOrder<ChecklistReportSortBy>,
    ) => {
        setRequestFilter((prev) => {
            const newRequestFilter: IChecklistReportFilter = {
                page: pageNumber,
                pageSize: isInTimelineView ? undefined : DEFAULT_PAGE_SIZE,

                sortBy: inChecklistTimelineView
                    ? ChecklistReportSortBy.StartDate
                    : sort.sortBy,
                sortAsc: inChecklistTimelineView ? false : sort.sortAsc,

                ...externalFilter.value,

                status:
                    isInTimelineView && requestFilter.status
                        ? [
                              ...new Set([
                                  ...requestFilter.status,
                                  ChecklistDynamicStatus.Scheduled,
                              ]),
                          ]
                        : requestFilter.status,

                ...newSort,

                templateVersionIds: templateVersionId
                    ? [templateVersionId]
                    : externalFilter.value.templateVersionIds,
            };

            return { ...prev, ...newRequestFilter };
        });
    };

    const onFilterChange = () => {
        const page = 1;

        setActivePage(page);

        if (inChecklistTimelineView) {
            setSort({
                sortBy: ChecklistReportSortBy.StartDate,
                sortAsc: false,
            });
        }

        fetchData(page);
    };

    const valueSumFilter = React.useMemo(() => {
        if (reportDetailsResponse && tableMatrix?.hasValueColumn) {
            const result: IChecklistReportValueSumFilter = {
                filter: requestFilter,
                columns: tableMatrix?.valueColumns ?? [],
            };

            return result;
        }
    }, [reportDetailsResponse, tableMatrix?.valueColumns, requestFilter]);

    const sumOfValuesResponse = useQuery({
        queryKey: ["calc-value-sum", valueSumFilter?.columns],
        queryFn: ({ signal }) => {
            if (valueSumFilter) {
                return getCalculateValueSums(valueSumFilter, signal);
            }
        },

        enabled: valueSumFilter ? true : false,
    });

    const handleTimeRangeChange = useCallback((start: number, end: number) => {
        setRequestFilter((prev) => ({
            ...prev,
            startDate: new Date(start),
            endDate: new Date(end),
            page: 1,
        }));
    }, []);

    useEffect(() => {
        if (
            prevExternalFilter === undefined ||
            (prevExternalFilter &&
                (prevExternalFilter.keywordSearch !==
                    externalFilter.value.keywordSearch ||
                    prevExternalFilter.templateVersionIds !==
                        externalFilter.value.templateVersionIds ||
                    prevExternalFilter.status !== externalFilter.value.status ||
                    prevExternalFilter.startDate !==
                        externalFilter.value.startDate ||
                    prevExternalFilter.endDate !==
                        externalFilter.value.endDate))
        ) {
            onFilterChange();
        }

        setPrevExternalFilter(externalFilter.value);
    }, [externalFilter, viewMode]);

    const refreshListWhenItemRemoved = () => {
        if (checklistRows.length === 1 && activePage > 1) {
            const pageNumber = activePage - 1;

            setActivePage(pageNumber);

            fetchData(pageNumber);
        } else {
            refetchReportItems();
        }
    };

    const onStatusUpdated = (
        id: string,
        status: ChecklistDynamicStatus | undefined,
    ) => {
        updateItem((x) =>
            x.id === id
                ? {
                      ...x,
                      status: status ?? x.status,
                  }
                : x,
        );
    };

    const updateItem = (
        updater: (x: IChecklistReportRow) => IChecklistReportRow,
    ) => {
        queryClient.setQueryData<IChecklistReportDTO>(
            ["useChecklistReportItems", requestFilter],
            (prev) => {
                let result: IChecklistReportDTO;

                if (prev) {
                    result = {
                        ...prev,
                        paginatedData: {
                            ...prev.paginatedData,
                            items: prev.paginatedData.items.map(updater),
                        },
                    };
                } else {
                    result = {
                        totalOnlyStoppableChecklistsCount: 0,
                        totalCompletedImprovementsCount: 0,
                        totalNotCompletedImprovementsCount: 0,
                        paginatedData: { items: [], totalCount: 0 },

                        hasSearchResult: false,
                        checklistIds: [],
                        uniqueKeys: {},
                    };
                }

                return result;
            },
        );
    };

    const onArchived = (response?: IArchiveResponse) => {
        if (response) {
            onStatusUpdated(response.id, response.status);

            const isArchived =
                response.status === ChecklistDynamicStatus.Archived;

            if (isArchived && response.isNotVisible) {
                refreshListWhenItemRemoved();
            }
        }
    };

    const archiveMutation = useArchiveChecklistMutation();

    const handleUnarchive = async (checklistId: string) => {
        if (await showConfirmNoThunk(dispatch, <UnarchiveWorksheetMessage />)) {
            const request: IArchiveRequest = {
                id: checklistId,
                isArchive: false,
                isArchivedVisible: true,
            };

            const response = await archiveMutation.mutateAsync(request);

            onArchived(response);
        }
    };

    const handleArchive = async (checklistId: string) => {
        if (await showConfirmNoThunk(dispatch, <ArchiveWorksheetMessage />)) {
            const request: IArchiveRequest = {
                id: checklistId,
                isArchive: true,
                isArchivedVisible:
                    requestFilter.status?.includes(
                        ChecklistDynamicStatus.Archived,
                    ) ?? false,
            };

            const response = await archiveMutation.mutateAsync(request);

            onArchived(response);
        }
    };

    const onPause = (response?: IPauseResponse) => {
        if (response) {
            onStatusUpdated(response.id, response.status);
        }

        queryClient.invalidateQueries({
            queryKey: ["useTotalChecklistsCountToPause"],
        });
    };

    const pauseMutation = usePauseChecklistMutation();

    const handleResume = async (checklistId: string) => {
        if (await showConfirmNoThunk(dispatch, <ResumeWorksheetMessage />)) {
            const request: IPauseRequest = {
                id: checklistId,
                isPause: false,
            };

            const response = await pauseMutation.mutateAsync(request);

            onPause(response);
        }
    };

    const handleStop = async (checklistId: string) => {
        if (await showConfirmNoThunk(dispatch, <PauseWorksheetMessage />)) {
            const request: IPauseRequest = {
                id: checklistId,
                isPause: true,
            };

            const response = await pauseMutation.mutateAsync(request);

            onPause(response);
        }
    };

    const deleteMutation = useDeleteByIdChecklistMutation();

    const handleDelete = async (checklistId: string) => {
        if (await showConfirmNoThunk(dispatch, <DeleteWorksheetMessage />)) {
            const result = await deleteMutation.mutateAsync(checklistId);

            if (result) {
                refreshListWhenItemRemoved();
            }
        }
    };

    const handlePreview = (checklistId: string) => {
        props.onShowChecklistDetails(checklistId);
    };

    const handlePreviewRecurrence = (checklistId: string) => {
        const checklist = checklistRows.find((x) => x.id === checklistId);

        if (checklist) {
            const reccurenceId = checklist.scheduleId;

            if (reccurenceId) {
                props.onShowRecurrence(reccurenceId);
            }
        }
    };

    const handleActionSelect = (checklistId: string, command: MenuCommands) => {
        switch (command) {
            case MenuCommands.Preview:
                handlePreview(checklistId);

                break;

            case MenuCommands.PreviewRecurrence:
                handlePreviewRecurrence(checklistId);

                break;

            case MenuCommands.Stop:
                handleStop(checklistId);

                break;

            case MenuCommands.UndoStop:
                handleResume(checklistId);

                break;

            case MenuCommands.Archive:
                handleArchive(checklistId);

                break;

            case MenuCommands.UndoArchive:
                handleUnarchive(checklistId);

                break;

            case MenuCommands.Delete:
                handleDelete(checklistId);

                break;

            case MenuCommands.PreviewDeviations:
                props.onShowImprovements(checklistId);

                break;

            case MenuCommands.BarcodeSearch:
                if (props.onBarcodeSearch) {
                    const barcode = checklistId;

                    props.onBarcodeSearch(barcode);
                }

                break;

            default:
                break;
        }
    };

    const handleSort = (
        e: React.UIEvent,
        isBusy?: boolean,
        name?: string | number,
    ) => {
        if (isBusy) {
            return;
        }

        if (typeof name === "number") {
            const newSortAsc = sort.sortBy === name ? !sort.sortAsc : true;

            const newSort: ISortOrder<ChecklistReportSortBy> = {
                sortBy: name,
                sortAsc: newSortAsc,
            };

            setSort(newSort);

            fetchData(activePage, newSort);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber);

        fetchData(pageNumber);
    };

    const [dateFilterLabels, setDateFilterLabels] = useState<
        Partial<Record<DateFilterKeysType, string>>
    >({});

    const handleFilterDate =
        (keyName: DateFilterKeysType) => (nextDate?: Date) => {
            const page = 1;

            setActivePage(page);

            setRequestFilter((prev) => ({
                ...prev,
                [keyName]: nextDate,
                page,
            }));

            setDateFilterLabels((prev) => ({
                ...prev,
                [keyName]: nextDate ? format(nextDate, "P") : undefined,
            }));
        };

    const handleFilterByStatus = (ids: ChecklistDynamicStatus[]) => {
        const page = 1;

        setActivePage(page);

        setRequestFilter((prev) => ({
            ...prev,
            status: ids,
            page,
        }));
    };

    const handleFilterByCustomLists = (
        column: ITemplateReportActivityTitle,
        filterValues: CustomListFilterType,
    ) => {
        const page = 1;

        setActivePage(page);

        setRequestFilter((prev) => {
            const nextCustomListFilters = { ...prev.customListFilters };

            const nextCustomListFilterColumnKeyIdsPath = {
                ...prev.customListFilterColumnKeyIdsPath,
            };

            if (filterValues.isReset) {
                delete nextCustomListFilters[column.columnKeyId];

                delete nextCustomListFilterColumnKeyIdsPath[column.columnKeyId];
            } else {
                nextCustomListFilters[column.columnKeyId] = filterValues;

                const isTopLevel = column.expandInfo
                    ? (column.expandInfo.isTopLevel ?? false)
                    : true;

                if (isTopLevel === false) {
                    const columnKeyIdsPath =
                        column.expandInfo?.columnKeyIdsPath ?? [];

                    nextCustomListFilterColumnKeyIdsPath[column.columnKeyId] =
                        columnKeyIdsPath;
                }
            }

            const filterKeys = Object.keys(nextCustomListFilters);

            const columns = tableMatrix?.headerSecond.columns ?? [];

            for (const columnKeyId of filterKeys) {
                const columnIsFound = columns.some(
                    (x) => x?.columnKeyId === columnKeyId,
                );

                if (!columnIsFound) {
                    delete nextCustomListFilters[columnKeyId];

                    delete nextCustomListFilterColumnKeyIdsPath[columnKeyId];
                }
            }

            return {
                ...prev,

                page,

                customListFilters: nextCustomListFilters,

                customListFilterColumnKeyIdsPath:
                    nextCustomListFilterColumnKeyIdsPath,
            };
        });
    };

    const statusFilterLabel = useMemo(() => {
        const selectedValues = requestFilter.status ?? [];

        const allDynamicStatuses = allChecklistStatuses.filter(
            (x) => x.id !== ChecklistDynamicStatus.None,
        );

        const defaultSelectedValuesLen = isAdministrator
            ? allDynamicStatuses.length
            : allDynamicStatuses.length - 1;

        if (
            selectedValues.length === 0 ||
            selectedValues.length === defaultSelectedValuesLen
        ) {
            return t(k.SHOW_ALL);
        }

        const len = allDynamicStatuses.length - 2;

        if (
            len === selectedValues.length &&
            selectedValues.includes(ChecklistDynamicStatus.Archived) ===
                false &&
            selectedValues.includes(ChecklistDynamicStatus.Scheduled) === false
        ) {
            if (isAdministrator) {
                return t(k.SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED);
            } else {
                return t(k.SHOWING_ALL_EXCEPT_SCHEDULED);
            }
        }

        const result = allDynamicStatuses
            .filter((x) => selectedValues.includes(x.id))
            .map((x) => t(x.label));

        const lastSelected = result.pop();

        if (result.length === 0) {
            return `${t(k.SHOWING)} ${lastSelected}`;
        }

        return `${t(k.SHOWING)} ${result.join(", ")} ${t(
            k.AND_,
        )} ${lastSelected}`;
    }, [requestFilter.status, i18n.language]);

    const newSort =
        viewMode !== ViewMode.TimelineView &&
        viewMode !== ViewMode.TimelineViewPerTemplate
            ? sort
            : undefined;

    const showTimeline =
        viewMode === ViewMode.TimelineView ||
        viewMode === ViewMode.TimelineViewPerTemplate ||
        viewMode === ViewMode.ChecklistTimelineView ||
        viewMode === ViewMode.ChecklistTimelineViewPerTemplate;

    const totalColumnsCount =
        WORKSHEET_REPORT_COLSPAN +
        (showMatchResultsColumn ? 1 : 0) +
        (tableMatrix?.headerSecond?.columns ?? []).length +
        1;

    return (
        <div
            className={`template-checklists-report${showTimeline ? " show-timeline" : ""}`}
        >
            <Table
                className="template-checklists-report--table"
                isLoading={
                    viewMode === ViewMode.TableView ||
                    viewMode === ViewMode.TableViewPerTemplate
                        ? isLoading
                        : false
                }
                defaultShowLoader={true}
                name="checklists-report-table"
                recordsCount={
                    viewMode === ViewMode.TableView ||
                    viewMode === ViewMode.TableViewPerTemplate
                        ? checklistRows.length
                        : 1
                }
                hasFilters={externalFilter.isChanged}
                columnsCount={tableMatrix ? totalColumnsCount : undefined}
                emptyMessage={
                    <React.Fragment>
                        <h3>{t(k.ITS_EMPTY_HERE)}</h3>
                        {t(k.NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE)}
                    </React.Fragment>
                }
                noResultsMessage={
                    <React.Fragment>
                        <h3>{t(k.NO_RESULTS)}</h3>

                        {t(
                            k.CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS,
                        )}
                    </React.Fragment>
                }
                pagination={
                    viewMode === ViewMode.TableView ||
                    viewMode === ViewMode.TableViewPerTemplate ? (
                        <Pagination
                            isBusy={isLoading}
                            activePage={activePage}
                            pageSize={DEFAULT_PAGE_SIZE}
                            totalCount={
                                checklistReportDto?.paginatedData.totalCount
                            }
                            onChange={handlePageChange}
                        />
                    ) : undefined
                }
            >
                <TableHeader>
                    {tableMatrix && (
                        <TableRow fullHeight noZIndex>
                            <TableCell
                                colSpan={
                                    WORKSHEET_REPORT_COLSPAN +
                                    (showMatchResultsColumn ? 1 : 0)
                                }
                                isHighlighted={true}
                                className="top-level-header table-cell__truncate"
                            >
                                {t(k.INFO)}
                            </TableCell>

                            <LevelOneReportDetailsHeader
                                tableMatrix={tableMatrix}
                            />
                        </TableRow>
                    )}

                    <TableRow fullHeight noZIndex>
                        <TableCell
                            name={ChecklistReportSortBy.Name}
                            isHighlighted={reportDetailsResponse !== undefined}
                            onClick={handleSort}
                            className="table-cell__sticky"
                            sortingBy={newSort?.sortBy}
                            sortingIsAsc={newSort?.sortAsc}
                        >
                            {t(k.CHECKLIST)}
                        </TableCell>

                        {showMatchResultsColumn && (
                            <TableCell>{t(k.RESULTS)}</TableCell>
                        )}

                        {tableMatrix ? undefined : (
                            <TableCell
                                name={ChecklistReportSortBy.WorkflowName}
                                onClick={handleSort}
                                className="column-lg"
                                sortingBy={newSort?.sortBy}
                                sortingIsAsc={newSort?.sortAsc}
                            >
                                {t(k.TEMPLATE)}
                            </TableCell>
                        )}

                        <TableCell
                            name={ChecklistReportSortBy.StartDate}
                            onClick={handleSort}
                            sortingBy={newSort?.sortBy}
                            sortingIsAsc={newSort?.sortAsc}
                            width="sm"
                            filterMenu={
                                (viewMode === ViewMode.TableView ||
                                    viewMode ===
                                        ViewMode.TableViewPerTemplate) && (
                                    <TableCell.FilteringDate
                                        testId="filterMenu.startDate"
                                        date={requestFilter.startDate}
                                        onFilter={handleFilterDate("startDate")}
                                    />
                                )
                            }
                        >
                            {t(k.START)}

                            {dateFilterLabels["startDate"] && (
                                <span className="filter-label">
                                    ({dateFilterLabels["startDate"]})
                                </span>
                            )}
                        </TableCell>
                        <TableCell
                            name={ChecklistReportSortBy.EndDate}
                            onClick={handleSort}
                            sortingBy={newSort?.sortBy}
                            sortingIsAsc={newSort?.sortAsc}
                            width="sm"
                            filterMenu={
                                (viewMode === ViewMode.TableView ||
                                    viewMode ===
                                        ViewMode.TableViewPerTemplate) && (
                                    <TableCell.FilteringDate
                                        testId="filterMenu.endDate"
                                        date={requestFilter.endDate}
                                        onFilter={handleFilterDate("endDate")}
                                    />
                                )
                            }
                        >
                            {t(k.DUE)}

                            {dateFilterLabels["endDate"] && (
                                <span className="filter-label">
                                    ({dateFilterLabels["endDate"]})
                                </span>
                            )}
                        </TableCell>

                        <TableCell
                            name={ChecklistReportSortBy.Version}
                            onClick={handleSort}
                            sortingBy={newSort?.sortBy}
                            sortingIsAsc={newSort?.sortAsc}
                        >
                            {t(k.VERSION)}
                        </TableCell>

                        <TableCell
                            filterMenu={
                                <ChecklistStatusFilter
                                    isAdministrator={isAdministrator}
                                    values={requestFilter.status ?? []}
                                    onChange={handleFilterByStatus}
                                />
                            }
                        >
                            {t(k.STATUS)}
                            &nbsp;
                            <InfoIcon
                                size="small"
                                tooltip={{
                                    id: `tootip-${templateVersionId}-status`,
                                    message: statusFilterLabel,
                                    alignSelf: "center",
                                }}
                            />
                        </TableCell>

                        <TableCell>{t(k.STEPS_DONE)}</TableCell>

                        <TableCell>{t(k.IMPROVEMENTS)}</TableCell>

                        {tableMatrix && (
                            <LevelTwoReportDetailsHeader
                                customListFilters={
                                    requestFilter.customListFilters ?? null
                                }
                                tableMatrix={tableMatrix}
                                onFilter={handleFilterByCustomLists}
                                onExpand={handleExpandHeader}
                            />
                        )}
                    </TableRow>
                </TableHeader>
                {(viewMode === ViewMode.TableView ||
                    viewMode === ViewMode.TableViewPerTemplate) && (
                    <TableBody>
                        {checklistRows.map((row) => (
                            <ReportTableRow
                                key={row.id}
                                value={row}
                                tableMatrix={tableMatrix}
                                selectedCustomListItemsNames={
                                    selectedCustomListItemsNames
                                }
                                isAdministrator={isAdministrator}
                                onSelectAction={handleActionSelect}
                            />
                        ))}
                    </TableBody>
                )}

                <TableFooter>
                    <TableRow>
                        <TableCell
                            key="sum-cell_matches"
                            colSpan={
                                WORKSHEET_REPORT_COLSPAN +
                                (showMatchResultsColumn ? 1 : 0) +
                                (tableMatrix ? -2 : -1)
                            }
                        >
                            {t(k.TOTAL_VALUES_FOR_ALL)}{" "}
                            {checklistReportDto?.paginatedData.totalCount}{" "}
                            {t(k.MATCHING_CHECKLISTS_)}
                        </TableCell>

                        <TableCell
                            colSpan={2}
                            key="sum-cell_imp"
                            className="improvements-summary"
                        >
                            {checklistReportDto && (
                                <React.Fragment>
                                    {
                                        checklistReportDto.totalNotCompletedImprovementsCount
                                    }{" "}
                                    {t(k.OPEN)},{" "}
                                    {
                                        checklistReportDto.totalCompletedImprovementsCount
                                    }{" "}
                                    {t(k.COMPLETED)}
                                </React.Fragment>
                            )}
                        </TableCell>

                        {tableMatrix && (
                            <ReportTableSumCells
                                tableMatrix={tableMatrix}
                                sumOfValues={sumOfValuesResponse.data ?? null}
                            />
                        )}
                    </TableRow>
                </TableFooter>
            </Table>

            {showTimeline && (
                <div className="timeline-view">
                    {(viewMode === ViewMode.TimelineView ||
                        viewMode === ViewMode.TimelineViewPerTemplate) && (
                        <ChecklistTimelineView
                            showOnlyRowsWithVisibleContent
                            defaultTimeStart={defaultTimelineTimeStart}
                            defaultTimeEnd={defaultTimelineTimeEnd}
                            isLoading={isLoading}
                            checklistRows={checklistRows}
                            onVisibleBoundsChange={handleTimeRangeChange}
                            onShowChecklistDetails={
                                props.onShowChecklistDetails
                            }
                            onShowChecklistTemplate={
                                props.onShowChecklistTemplate
                            }
                            onItemMoveOrResize={handleOnChecklistMoveOrResize}
                        />
                    )}
                    {(viewMode === ViewMode.ChecklistTimelineView ||
                        viewMode ===
                            ViewMode.ChecklistTimelineViewPerTemplate) && (
                        <SingleChecklistTimelineView
                            showOnlyRowsWithVisibleContent
                            defaultTimeStart={defaultTimelineTimeStart}
                            defaultTimeEnd={defaultTimelineTimeEnd}
                            isLoading={isLoading}
                            checklistRows={checklistRows}
                            onVisibleBoundsChange={handleTimeRangeChange}
                            onShowChecklistDetails={
                                props.onShowChecklistDetails
                            }
                            onItemMoveOrResize={handleOnChecklistMoveOrResize}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ReportTable;
