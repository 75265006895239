import { Reducer } from "redux";

import {
    FAILURE_DELETE_STEP_DRAFT,
    RECEIVE_DELETE_STEP_DRAFT,
    RECEIVE_GET_STEP_ALL,
    RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID,
    RECEIVE_GET_STEP_DRAFT,
    RECEIVE_SAVE_STEP,
    REQUEST_DELETE_STEP_DRAFT,
    REQUEST_GET_STEP_ALL,
    REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID,
    REQUEST_GET_STEP_DRAFT,
    SET_STEP_IS_LOADING,
    StepActionTypes,
} from "./types";
import IDictionary from "common/viewModels/IDictionary";
import IActivityInputListItem from "http/requests/IActivityInputListItem";

export interface IStepState {
    readonly isLoading: boolean;
    readonly isDeletingDraft: boolean;
    readonly isLatestVersionLoading: boolean;
    readonly isSelectedLoading: boolean;
    readonly isSelectedForAllWorkflowsLoading: boolean;

    readonly activityList: IDictionary<IActivityInputListItem[]>;
}

const initialState: IStepState = {
    isLoading: false,
    isDeletingDraft: false,
    isLatestVersionLoading: false,
    isSelectedLoading: false,
    isSelectedForAllWorkflowsLoading: false,
    activityList: {},
};

const buildActivityList = (activityList?: IActivityInputListItem[]) => {
    return (
        activityList?.reduce(
            (map, x) => {
                if (x.activityId) {
                    if (map[x.activityId]) {
                        map[x.activityId]?.push(x);
                    } else {
                        map[x.activityId] = [x];
                    }
                }

                return map;
            },
            {} as IDictionary<IActivityInputListItem[]>,
        ) ?? {}
    );
};

export const StepReducer = (
    state: IStepState = initialState,
    action: StepActionTypes,
): IStepState => {
    switch (action.type) {
        case SET_STEP_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case REQUEST_GET_STEP_ALL: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case RECEIVE_GET_STEP_ALL: {
            return {
                ...state,
                isLoading: false,
                activityList: buildActivityList(action.payload?.activityList),
            };
        }

        case REQUEST_GET_STEP_DETAILS_BY_STEP_VERSION_ID: {
            return {
                ...state,
                isLatestVersionLoading: true,
            };
        }

        case RECEIVE_GET_STEP_DETAILS_BY_STEP_VERSION_ID: {
            return {
                ...state,
                isLatestVersionLoading: false,
                activityList: buildActivityList(action.payload?.activityList),
            };
        }

        case REQUEST_GET_STEP_DRAFT: {
            return {
                ...state,
                isLatestVersionLoading: true,
            };
        }

        case RECEIVE_GET_STEP_DRAFT: {
            return {
                ...state,
                isLatestVersionLoading: false,
                activityList: buildActivityList(action.payload?.activityList),
            };
        }

        case RECEIVE_SAVE_STEP: {
            return initialState;
        }

        case REQUEST_DELETE_STEP_DRAFT: {
            return {
                ...state,
                isDeletingDraft: true,
            };
        }

        case RECEIVE_DELETE_STEP_DRAFT: {
            return {
                ...state,
                isDeletingDraft: false,
            };
        }

        case FAILURE_DELETE_STEP_DRAFT: {
            return {
                ...state,
                isDeletingDraft: false,
            };
        }

        default:
            return state;
    }
};
