import axios from "axios";

import { IDataSourceFilterDto } from "./IDataSourceFilterDto";
import { IKpiDto, IKpiWithDetailsDto } from "./IKpiDto";
import { IKpiFormulaCalculations } from "./IKpiFormulaCalculations";
import { IKpiFormulaDto } from "./IKpiFormulaDto";
import { IKpiRequest } from "./IKpiRequest";
import { IManualDataSourceDto } from "./IManualDataSourceDto";
import { IManualDataSourceRequest } from "./IManualDataSourceRequest";
import { IManualDataSourceValueDto } from "./IManualDataSourceValueDto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IValueLabelItem } from "common/IValueLabelItem";
import { convertToDate, getTimeZone } from "common/utils/time";
import ExecutionResult from "common/viewModels/ExecutionResult";
import IKpiFilter from "components/kpi-page/api/IKpiFilter";
import { IKpiTargetValueDTO } from "components/kpi-page/api/IKpiTargetValueDTO";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { ISortRequest } from "http/requests/ISortRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const baseUrl = "/api/kpi";

const kpiKeys = {
    all: ["kpis"] as const,
    getList: (filter?: IKpiFilter) => [...kpiKeys.all, filter] as const,
    kpiById: (id?: string) => [...kpiKeys.all, "kpi-byId", id] as const,
    options: ["kpi-options-list"] as const,
    formulaCalculationsByKpiId: (id?: string) =>
        ["kpi-formula-calculations-by-id", id] as const,
    formulaCalculationsByKpiIdWithFilter: (id?: string, filter?: IKpiFilter) =>
        [...kpiKeys.formulaCalculationsByKpiId(id), filter] as const,
    canAccess: (id?: string) => ["can-access-kpi", id] as const,
    canUpdate: (id?: string) => ["can-update-kpi", id] as const,

    targetValuesByKpiId: (kpiId: string) =>
        ["target-values-by-kpi-id", kpiId] as const,
    formulaItemNamesByKpiId: (kpiId: string) =>
        ["formula-item-names-by-kpi-id", kpiId] as const,

    manualDataSourcesByKpiId: (kpiId?: string) =>
        ["manual-data-sources-by-kpi-id", kpiId] as const,

    manualValuesByKpiId: (kpiId: string) =>
        ["manual-values-by-kpi-id", kpiId] as const,

    manualValuesPerFormulaItemByKpiId: (kpiId?: string) =>
        ["manual-values-per-formula-item-by-kpi-id", kpiId] as const,
    yearOptions: (kpiId?: string) => ["kpi-year-options-list", kpiId] as const,

    dataSourceFiltersById: (
        kpiId?: string,
        filterId?: string,
        formulaItemId?: string,
        variableId?: string,
        dataSourceId?: string,
    ) =>
        [
            "data-source-filters-by-id",
            kpiId,
            filterId,
            formulaItemId,
            variableId,
            dataSourceId,
        ] as const,
    dataSourceFiltersByIds: (filterIds?: string[]) =>
        ["data-source-filters-by-ids", filterIds] as const,
};

export const useKpiList = (filter?: IKpiFilter, enabled = true) => {
    const url = `${baseUrl}/list`;

    return useQuery({
        queryKey: kpiKeys.getList(filter),

        queryFn: async (context) => {
            const response = await axios.post<IKpiDto[]>(url, filter, {
                signal: context.signal,
            });

            return response.data;
        },

        enabled: enabled,
    });
};

export const useKpiOptions = (excludeId?: string, enabled?: boolean) => {
    return useQuery({
        queryKey: kpiKeys.options,

        queryFn: async (context) => {
            const url = `${baseUrl}/kpi-options`;

            const response = await axios.get<IValueLabelItem<string, string>[]>(
                url,
                {
                    signal: context.signal,
                },
            );
            if (excludeId) {
                return response.data.filter((x) => x.value !== excludeId);
            }

            return response.data;
        },

        enabled: enabled ?? true,
    });
};
export const useKpiById = (id?: string) => {
    const url = `${baseUrl}/${id}`;

    return useQuery({
        queryKey: kpiKeys.kpiById(id),

        queryFn: async (context) => {
            const response = await axios.get<IKpiWithDetailsDto>(url, {
                signal: context.signal,
            });

            for (const targetValue of Object.values(
                response.data.targets?.values ?? {},
            )) {
                if (targetValue) {
                    const convertedDate = convertToDate(targetValue.date);

                    if (convertedDate) {
                        targetValue.date = convertedDate;
                    }
                }
            }

            return response.data;
        },

        enabled: !!id,

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useSaveKpiMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IKpiRequest;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(baseUrl, value);

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: kpiKeys.all });
            queryClient.refetchQueries({
                queryKey: kpiKeys.kpiById(variables.value.id),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.formulaCalculationsByKpiId(
                    variables.value.id,
                ),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.dataSourceFiltersById(variables.value.id),
            });
            queryClient.invalidateQueries({
                queryKey: kpiKeys.canAccess(variables.value.id),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.manualValuesPerFormulaItemByKpiId(
                    variables.value.id,
                ),
            });
        },
    });
};

export const useValidateFormulaMutation = () => {
    const url = `${baseUrl}/validate-formula`;

    return useMutation({
        mutationFn: async (variables: { value: IKpiFormulaDto }) => {
            try {
                const { value } = variables;

                const response = await axios.post<IKpiFormulaDto>(url, value);

                const result = response.data;

                return ExecutionResult.Result<IKpiFormulaDto>(result);
            } catch (error) {
                return ExecutionResult.Failed<IKpiFormulaDto>(error);
            }
        },
    });
};

function updateIsArchivedById(isArchived: boolean, prev?: IKpiDto): IKpiDto {
    if (prev) {
        return {
            ...prev,
            isArchived,
        };
    }

    return {
        id: "",
        isArchived: false,
        details: {},
    };
}

export const useArchiveKpiMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({
                queryKey: kpiKeys.kpiById(variables.request.id),
            });

            const data = queryClient.getQueryData<IKpiDto>(
                kpiKeys.kpiById(variables.request.id),
            );

            const isArchived = data?.isArchived;

            queryClient.setQueryData<IKpiDto>(
                kpiKeys.kpiById(variables.request.id),
                (prev) =>
                    updateIsArchivedById(variables.request.isArchive, prev),
            );

            return {
                prevValue: isArchived,
            };
        },
        onError: (
            err,
            variables,
            context: { prevValue?: boolean } | undefined,
        ) => {
            if (context) {
                queryClient.setQueryData<IKpiDto>(
                    kpiKeys.kpiById(variables.request.id),
                    (prev) =>
                        updateIsArchivedById(context.prevValue ?? false, prev),
                );
            }
        },
    });
};

export const useDeleteKpiMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { id: string }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.id}`);
                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: kpiKeys.kpiById(variables.id),
            });
        },
    });
};

export const useKpiFormulaCalculationsById = (
    enabled: boolean,
    kpiId?: string,
    filter?: IKpiFilter,
) => {
    return useQuery({
        queryKey: kpiKeys.formulaCalculationsByKpiIdWithFilter(kpiId, filter),

        queryFn: async (context) => {
            if (kpiId) {
                const url = `${baseUrl}/formula-calculations/${kpiId}`;

                const response = await axios.post<IKpiFormulaCalculations>(
                    url,
                    filter,
                    {
                        signal: context.signal,
                    },
                );

                return response.data;
            }
        },

        enabled: enabled && Boolean(kpiId),
    });
};

export const useCanAccessKpi = (kpiId?: string, enabled?: boolean) => {
    const url = kpiId
        ? `${baseUrl}/can-access/${kpiId}`
        : `${baseUrl}/can-access`;

    return useQuery({
        queryKey: kpiKeys.canAccess(kpiId),
        queryFn: async (context) => {
            const response = await axios.get<boolean>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        enabled: enabled ?? true,
    });
};

export const useCanUpdateKpi = (kpiId?: string, enabled?: boolean) => {
    const url = `${baseUrl}/can-update/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.canUpdate(kpiId),

        queryFn: async (context) => {
            const response = await axios.get<boolean>(url, {
                signal: context.signal,
            });

            return response.data;
        },

        enabled: enabled ?? true,
    });
};

export const useTargetValuesByKpiId = (kpiId: string) => {
    const url = `${baseUrl}/target-values-by-kpi-id/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.targetValuesByKpiId(kpiId),
        queryFn: async (context) => {
            const response = await axios.get<
                Record<string, IKpiTargetValueDTO>
            >(url, {
                signal: context.signal,
            });

            return response.data;
        },
    });
};

export const useFormulaItemNames = (kpiId: string, enabled?: boolean) => {
    const url = `${baseUrl}/formula-item-names-by-kpi-id/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.formulaItemNamesByKpiId(kpiId),
        queryFn: async (context) => {
            const response = await axios.get<Record<string, string>>(url, {
                signal: context.signal,
            });

            return response.data;
        },
        enabled: enabled,
    });
};

export const useManualDataSourcesByKpiId = (kpiId?: string) => {
    const url = `${baseUrl}/list-manual-data-sources-by-kpi-id/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.manualDataSourcesByKpiId(kpiId),
        queryFn: async (context) => {
            if (kpiId) {
                const response = await axios.get<IManualDataSourceDto[]>(url, {
                    signal: context.signal,
                });

                return response.data;
            }
        },
        enabled: kpiId !== undefined && kpiId !== "",
        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useSaveManualDataSourceValues = () => {
    const url = `${baseUrl}/manual-data-source`;
    var queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            createNew: boolean;
            value: IManualDataSourceRequest;
        }) => {
            try {
                let result: IManualDataSourceDto;

                const { createNew, value } = variables;

                value.timezone = getTimeZone();

                if (createNew) {
                    const response = await axios.post<IManualDataSourceDto>(
                        url,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<IManualDataSourceDto>(
                        url,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<IManualDataSourceDto>(result);
            } catch (error) {
                return ExecutionResult.Failed<IManualDataSourceDto>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: kpiKeys.all });
            queryClient.refetchQueries({
                queryKey: kpiKeys.kpiById(variables.value.requestKpiId),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.formulaCalculationsByKpiId(
                    variables.value.requestKpiId,
                ),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.manualDataSourcesByKpiId(
                    variables.value.requestKpiId,
                ),
            });
            queryClient.refetchQueries({
                queryKey: kpiKeys.manualValuesPerFormulaItemByKpiId(
                    variables.value.requestKpiId,
                ),
            });
        },
    });
};

//for history
export const useManualDataSourceValuesByKpiId = (kpiId: string) => {
    const url = `${baseUrl}/manual-values-by-kpi-id/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.manualValuesByKpiId(kpiId),
        queryFn: async (context) => {
            const response = await axios.get<
                Record<string, IManualDataSourceValueDto>
            >(url, {
                signal: context.signal,
            });

            return response.data;
        },
    });
};

export const useManualDataSourcePerFormulaItemByKpiId = (kpiId?: string) => {
    const url = `${baseUrl}/manual-data-source-per-formula-item-for-kpi-id/${kpiId}`;

    return useQuery({
        queryKey: kpiKeys.manualValuesPerFormulaItemByKpiId(kpiId),
        queryFn: async (context) => {
            if (kpiId) {
                const response = await axios.get<Record<string, string>>(url, {
                    signal: context.signal,
                });

                return response.data;
            }
        },
    });
};

export const useKpiYearOptionsById = (id?: string) => {
    const url = id ? `${baseUrl}/options/${id}` : `${baseUrl}/options`;

    return useQuery({
        queryKey: kpiKeys.yearOptions(id),

        queryFn: async (context) => {
            const response = await axios.get<number[]>(url, {
                signal: context.signal,
            });
            return response.data;
        },

        structuralSharing(oldData, newData) {
            return JSON.stringify(oldData) === JSON.stringify(newData)
                ? oldData
                : newData;
        },
    });
};

export const useSwapKpisMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { request: ISortRequest }) => {
            const { request } = variables;
            try {
                await axios.post(`${baseUrl}/sort`, request);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: kpiKeys.all });
        },
    });
};

export const useDataSourceFiltersByKpiId = (id?: string) => {
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: kpiKeys.dataSourceFiltersById(id),

        queryFn: async () => {
            var kpiData = queryClient.getQueryData<IKpiWithDetailsDto>(
                kpiKeys.kpiById(id),
            );

            const fetchedData = kpiData?.formula?.formulaItems.map((x) => ({
                formulaItemId: x.id,
                variableId: x.variableId,
                dataSourceId: x.variable?.dataSourceId,
                filterId: x.variable?.dataSource?.filterId,
            }));

            var allFilters = await Promise.all(
                fetchedData
                    ? fetchedData.map(async (filter) => {
                          if (filter?.filterId !== undefined) {
                              const url = `${baseUrl}/data-source-filter/${filter?.filterId}`;

                              try {
                                  const response =
                                      await axios.get<IDataSourceFilterDto>(
                                          url,
                                          {
                                              // signal: context.signal,
                                          },
                                      );

                                  return response.data;
                              } catch (error) {
                                  // Handle errors appropriately
                                  console.error(
                                      `Error fetching data for filterId ${filter?.filterId}:`,
                                      error,
                                  );
                              }
                          }
                      })
                    : [],
            );

            return allFilters.length > 0 ? allFilters : undefined;
        },

        enabled: !!id,
    });
};

export const useDataSourceFiltersByIds = (filterIds?: string[]) => {
    return useQuery({
        queryKey: kpiKeys.dataSourceFiltersByIds(filterIds),

        queryFn: async () => {
            if (!filterIds) {
                return undefined;
            }

            const allFilters = await Promise.all(
                filterIds.map(async (filterId) => {
                    const url = `${baseUrl}/data-source-filter/${filterId}`;

                    try {
                        const response =
                            await axios.get<IDataSourceFilterDto>(url);
                        return response.data;
                    } catch (error) {
                        console.error(
                            `Error fetching data for filterId ${filterId}:`,
                            error,
                        );
                    }
                }),
            );

            return allFilters.filter(
                (filter): filter is IDataSourceFilterDto =>
                    filter !== undefined,
            );
        },

        enabled: filterIds && filterIds.length > 0,
    });
};
