import React from "react";

import ErrorBadge from "common/components/badges/simple-badges/badge-error/ErrorBadge";
import RequiredBadge from "common/components/badges/simple-badges/badge-required/RequiredBadge";

interface IProps {
    id?: string;
    isRequired?: boolean;
    hasError?: boolean;
}

const ActivityDetails: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { hasError, isRequired, id } = props;

    if (isRequired || hasError) {
        return (
            <div className="step-activity-details" id={id}>
                {isRequired && <RequiredBadge />}

                {hasError && <ErrorBadge />}
            </div>
        );
    } else {
        return null;
    }
};

export default ActivityDetails;
