import React from "react";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import { Roles, TRoles } from "components/roles/api/Roles";

import { ERoleSource } from "components/roles/api/RoleSource";
import PrimaryBadge from "common/components/badges/base-badges/badge-primary/PrimaryBadge";
import SuccessBadge from "common/components/badges/base-badges/badge-success/SuccessBadge";

interface IProps {
    role?: TRoles;
    info?: React.ReactNode;
    type?: ERoleSource;
}

const RoleBadge: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { role, info, type } = props;

    const { t } = useTranslation();

    if (role === undefined) {
        return null;
    }

    const roleKey = role as keyof typeof k;

    const label = k[roleKey] ? t(k[roleKey]) : role;

    const tooltip = info ? { id: role, message: info } : undefined;

    return role === Roles.ADMINISTRATOR ? (
        <SuccessBadge outline={type == ERoleSource.Team} tooltip={tooltip}>
            {label}
        </SuccessBadge>
    ) : (
        <PrimaryBadge outline={type == ERoleSource.Team} tooltip={tooltip}>
            {label}
        </PrimaryBadge>
    );
};

export default RoleBadge;
