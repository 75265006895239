import "./ClosedBadge.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import Badge, { IBaseBadgeProps } from "../../base-badges/Badge";
import InfoIcon from "common/components/icons/icon-info/InfoIcon";
import { TooltipProps } from "common/components/tooltip/Tooltip";

interface IClosedBadgeProps extends IBaseBadgeProps {
    label?: string;
    iconTooltip?: TooltipProps;
}

const ClosedBadge = (props: IClosedBadgeProps) => {
    const { label, iconTooltip, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Badge className="closed-badge" {...rest}>
            {label ?? t(k.CLOSED)}
            {iconTooltip && (
                <>
                    &nbsp;
                    <InfoIcon size="small" tooltip={iconTooltip} />
                </>
            )}
        </Badge>
    );
};

export default ClosedBadge;
