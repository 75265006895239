import "./InfoIcon.scss";

import { AiOutlineInfoCircle } from "react-icons/ai";
import classNames from "classnames";
import Icon, { IBaseIconProps } from "common/components/icons/Icon";

const InfoIcon = (props: IBaseIconProps) => {
    const { className: cn, ...rest } = props;

    const className = classNames(cn, "icon-info");

    return <Icon {...rest} className={className} icon={AiOutlineInfoCircle} />;
};

export default InfoIcon;
