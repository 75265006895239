import { RefObject, createRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { IImprovementFormProps } from "../ImprovementForm";
import IImprovementFormData, { IMoveActivity } from "./IImprovementFormData";
import { ModalTypes } from "common/components/modal-manager/api/IModalManager";
import showErrorToast from "common/utils/errors/toastErrors";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";
import ITemplateConfigurationDTO from "components/improvement-forms/api/ITemplateConfigurationDTO";
import { TemplateResponsibleType } from "components/improvement-forms/api/TemplateResponsibleType";
import { useGetTemplateDraftConfiguration } from "components/improvement-forms/api/hooks";
import {
    useDraftStepsByTemplateVersionId,
    useSharedSteps,
} from "components/steps/api/hooks";
import { copyById } from "components/templates/api/actions";
import {
    useArchiveTemplateByVersionId,
    useCanEditTemplate,
    useDeleteTemplateByVersionId,
    useDeleteTemplateDraftByVersionId,
    useMutateTemplate,
    useTemplateDraftByVersionId,
} from "components/templates/api/hooks";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink, {
    createTableSetParentLink,
} from "http/ITableSetWithParentLink";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import IActivityRequest from "http/requests/IActivityRequest";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IStepRequest from "http/requests/IStepRequest";
import IWorkflowRequest from "http/requests/IWorkflowRequest";
import IActivity from "models/IActivity";
import IAttachment from "models/IAttachment";
import IStep from "models/IStep";
import IWorkflow from "models/IWorkflow";
import IInputValueDTO from "models/dto/IInputValueDTO";
import ITemplateStepsListDTO from "models/dto/ITemplateStepsListDTO";
import { ActivityType } from "models/enums/ActivityType";
import { TemplateType } from "models/enums/TemplateType";

export const CONFIGURATION_STEP_ID = "configurationId";

interface IImprovementForm extends IWorkflow {}

const getInitialForm: IImprovementForm = {
    id: uuidv4(),
    workflowVersionId: uuidv4(),
    name: "",
    description: "",
    index: 0,
    isDraft: false,
    isDefault: false,
    stepIds: [],
    addedStepIds: [],
    deletedStepIds: [],
    changedStepIds: [],
    hasPublishedVersion: false,
    hasNewVersion: false,
    version: 0,
    isArchived: false,
};

const getInitialStep = ({ id = uuidv4(), name = "", index = 0 }): IStep => ({
    id,
    stepVersionId: uuidv4(),
    name,
    description: "",
    isLocal: true,
    isDraft: true,
    index,
    teamIds: [],
    equipmentIds: [],
    hasPublishedVersion: false,
    hasNewVersion: false,
    isTeamEveryone: true,
    version: 0,
    isAdded: true,
});

const initializeConfigurationStep = (templateVersionId?: string) => {
    return templateVersionId
        ? undefined
        : {
              templateVersionId,
              responsibleTypes: [
                  TemplateResponsibleType.OriginatedInProcessOwner,
                  TemplateResponsibleType.DiscoveredInProcessOwner,
              ],
              involvedTypes: [],
              notifyTypes: [
                  TemplateResponsibleType.OriginatedInProcessOwner,
                  TemplateResponsibleType.DiscoveredInProcessOwner,
              ],
              involvedUserSets: [],
          };
};

const improvementStepKeys = [k.REVIEW, k.ANALYZE, k.ACT, k.VERIFY];

const initializeSteps = (createNew?: boolean): ITableSet<IStep> => {
    if (createNew) {
        return improvementStepKeys.reduce<ITableSet<IStep>>(
            (acc, key, index) => {
                const newId = uuidv4();
                acc.ids.push(newId);
                acc.values[newId] = getInitialStep({
                    id: newId,
                    name: key,
                    index,
                });
                return acc;
            },
            {
                values: {},
                ids: [],
            },
        );
    }
    return {
        values: {},
        ids: [],
    };
};

const getInitialActivity = ({
    id = uuidv4(),
    stepId,
    label = "",
    index = 0,
    type = ActivityType.Value,
    isRequired = true,
    acceptMultiple = false,
}: Partial<IActivity>): IActivity => ({
    id,
    label,
    type,
    index,
    isRequired,
    acceptMultiple,
    stepId,
    attachments: [],
});

const initializeActivities = (
    steps: ITableSet<IStep>,
): ITableSetWithParentLink<IActivity, ITemplateStepsListDTO> => {
    const activities: ITableSetWithParentLink<
        IActivity,
        ITemplateStepsListDTO
    > = {
        values: {},
        ids: [],
        parents: { ["activityIdsByStepId"]: {} },
    };

    steps.ids.forEach((stepId) => {
        const step = steps.values[stepId];

        if (step) {
            if (step.name === k.REVIEW) {
                return;
            }

            if (step.name === k.ANALYZE) {
                const activityId = uuidv4();

                activities.ids.push(activityId);

                activities.values[activityId] = getInitialActivity({
                    id: activityId,
                    stepId,
                    type: ActivityType.Text,
                });

                activities.parents = {
                    ["activityIdsByStepId"]: {
                        ...(activities.parents?.["activityIdsByStepId"] ?? {}),
                        [stepId]: [activityId],
                    },
                };

                return;
            }

            if (step.name === k.ACT) {
                const activityId = uuidv4();

                activities.ids.push(activityId);

                activities.values[activityId] = getInitialActivity({
                    id: activityId,
                    stepId,
                    type: ActivityType.Tasklist,
                    acceptMultiple: false,
                });

                activities.parents = {
                    ["activityIdsByStepId"]: {
                        ...(activities.parents?.["activityIdsByStepId"] ?? {}),
                        [stepId]: [activityId],
                    },
                };

                return;
            }

            return;
        }
    });

    return activities;
};

export const useImprovementFormData = (
    props: IImprovementFormProps,
): IImprovementFormData => {
    const {
        id,
        createNew,
        onHaveChanges,
        onUpdateModal,
        haveChanges,
        syncData,
    } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [isSaving, setIsSaving] = useState(false);

    const [errors, setErrors] = useState<IValidationMessages>();

    const { data: sharedSteps } = useSharedSteps();

    useEffect(() => {
        const errorValues = Object.values(errors ?? {});

        if (errorValues.some((value) => value !== undefined) === false) {
            setErrors(undefined);
        }
    }, [errors]);

    const [stepRefs, setStepRefs] =
        useState<IDictionary<RefObject<HTMLDivElement>>>();

    const { data: canEditForm, isLoading: canEditFormLoading } =
        useCanEditTemplate(id);

    const {
        data: improvementFormData,
        isLoading: isLoadingTemplate,
        isError: isErrorTemplate,
    } = useTemplateDraftByVersionId(id, syncData);

    const {
        data: improvementFormConfigurationData,
        isLoading: isLoadingConfig,
    } = useGetTemplateDraftConfiguration(id);

    const [configuration, setConfiguration] = useState<
        ITemplateConfigurationDTO | undefined
    >(initializeConfigurationStep(id));

    useEffect(() => {
        if (improvementFormConfigurationData) {
            setConfiguration(improvementFormConfigurationData);
        }
    }, [improvementFormConfigurationData]);

    const [improvementForm, setImprovementForm] =
        useState<IImprovementForm>(getInitialForm);

    useEffect(() => {
        if (improvementFormData) {
            setImprovementForm(improvementFormData);
        }
    }, [improvementFormData]);

    const { data: stepsList, isLoading: isLoadingStepsList } =
        useDraftStepsByTemplateVersionId(id, undefined, syncData);

    const [steps, setSteps] = useState<ITableSet<IStep>>(
        initializeSteps(createNew),
    );

    const [activities, setActivities] = useState<
        ITableSetWithParentLink<IActivity, ITemplateStepsListDTO>
    >(createTableSetParentLink("activityIdsByStepId"));

    useEffect(() => {
        if (createNew && activities.ids.length === 0 && steps.ids.length > 0) {
            setActivities(initializeActivities(steps));
        }
    }, [createNew, steps, activities]);

    const [photos, setPhotos] = useState<Record<string, IInputValueDTO>>({});

    const [attachments, setAttachments] = useState<
        ITableSetWithParentLink<IAttachment, ITemplateStepsListDTO>
    >(createTableSetParentLink("attachmentsIdsByActivityId"));

    const [activityInputs, setActivityInputs] = useState<
        ITableSetWithParentLink<IActivityInputListItem, ITemplateStepsListDTO>
    >(createTableSetParentLink("activityInputsIdsByActivityId"));

    const templateMutation = useMutateTemplate();
    const templateDeleteMutation = useDeleteTemplateByVersionId();
    const templateDraftDeleteMutation = useDeleteTemplateDraftByVersionId();
    const templateArchiveMutation = useArchiveTemplateByVersionId();

    const setAllStates = () => {
        if (improvementFormData && stepsList) {
            setSteps((prev) => {
                return {
                    values: stepsList.steps,
                    ids: improvementFormData.stepIds,
                };
            });

            setActivities({
                values: stepsList.activities,
                ids: Object.keys(stepsList.activities),
                parents: {
                    activityIdsByStepId: stepsList.activityIdsByStepId,
                },
            });

            setAttachments({
                values: stepsList.attachments,
                ids: Object.keys(stepsList.attachments),
                parents: {
                    attachmentsIdsByActivityId:
                        stepsList.attachmentsIdsByActivityId,
                },
            });

            setActivityInputs({
                values: stepsList.activityInputs,
                ids: Object.keys(stepsList.activityInputs),
                parents: {
                    activityInputsIdsByActivityId:
                        stepsList.activityInputsIdsByActivityId,
                },
            });
        }
    };

    useEffect(() => {
        if (improvementFormData && stepsList) {
            setAllStates();
        }
    }, [
        improvementFormData,
        stepsList?.steps,
        stepsList?.activities,
        stepsList?.activityInputs,
        stepsList?.attachments,
    ]);

    useEffect(() => {
        if (steps?.ids) {
            let stepRefs = steps.ids.reduce<{
                [key: string]: React.RefObject<HTMLDivElement>;
            }>((prev, cur) => {
                return { ...prev, [cur]: createRef() };
            }, {});

            setStepRefs(
                configuration
                    ? { ...stepRefs, [CONFIGURATION_STEP_ID]: createRef() }
                    : stepRefs,
            );
        }
    }, [steps?.ids, configuration]);

    const getDeletedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && step.isDeleted;
        });
    };

    const getAddedOrChangedSteps = () => {
        return steps.ids.filter((id) => {
            const step = steps.values[id];
            return step && (step.isAdded || step.isChanged) && step.isLocal;
        });
    };

    const handleOnSave = async (draft?: boolean) => {
        const stepRequests = steps.ids.reduce<IStepRequest[]>((acc, stepId) => {
            const step = steps.values[stepId];

            if (step) {
                const stepActivities =
                    activities.parents?.activityIdsByStepId?.[stepId]?.reduce<
                        IActivity[]
                    >((acc, id) => {
                        const item = activities.values[id];
                        if (item && !item.isDeleted) {
                            acc.push(item);
                        }
                        return acc;
                    }, []) ?? [];

                const {
                    id,
                    stepVersionId,
                    name,
                    description,

                    teamIds,
                    equipmentIds,
                    isTeamEveryone,
                    isBlocking,
                } = step;

                const stepRequest: IStepRequest = {
                    id,
                    stepVersionId,
                    name,
                    description,

                    isTeamEveryone,
                    isBlocking,

                    activities: stepActivities.map((activity) => {
                        const activityAttachments =
                            attachments.parents?.attachmentsIdsByActivityId?.[
                                activity.id
                            ]?.reduce<IAttachment[]>((acc, id) => {
                                const item = attachments.values[id];
                                if (item && !item.isDeleted) {
                                    acc.push(item);
                                }
                                return acc;
                            }, []) ?? [];

                        const activityList =
                            activityInputs.parents?.activityInputsIdsByActivityId?.[
                                activity.id
                            ]?.reduce<IActivityInputListItem[]>((acc, id) => {
                                const item = activityInputs.values[id];
                                if (item) {
                                    acc.push(item);
                                }
                                return acc;
                            }, []) ?? [];

                        const result: IActivityRequest = {
                            ...activity,
                            attachments: activityAttachments,
                            photo: photos[activity.id],
                            activityList,
                        };

                        return result;
                    }),

                    teamIds,
                    equipmentIds,
                    workflowId: createNew ? undefined : improvementForm.id,
                    isLocal: true,
                    saveAsDraft: true,
                };

                acc.push(stepRequest);
            }

            return acc;
        }, []);

        let _errors: IValidationMessages = {};

        if (
            stepRequests[1] &&
            stepRequests[1].name === k.ANALYZE &&
            stepRequests[1].activities.length === 0
        ) {
            _errors["step." + stepRequests[1].id + ".activities"] = t(
                k.AT_LEAST_ONE_ACTIVITY_IS_REQUIRED,
            );
        }

        if (
            stepRequests[2] &&
            stepRequests[2].name === k.ACT &&
            stepRequests[2].activities.length === 0
        ) {
            _errors["step." + stepRequests[2].id + ".activities"] = t(
                k.AT_LEAST_ONE_ACTIVITY_IS_REQUIRED,
            );
        }

        if (Object.keys(_errors).length > 0) {
            setErrors((prev) => ({
                ...prev,
                ..._errors,
            }));

            return;
        }

        const workflowRequest: IWorkflowRequest = {
            id: improvementForm.id,
            workflowVersionId: improvementForm.workflowVersionId,
            saveAsDraft: draft ?? false,
            name: improvementForm.name,
            description: improvementForm.description,
            index: improvementForm.index,
            selectedStepIds: steps.ids,
            deletedStepIds: getDeletedSteps(),
            replacedIds: {},
            stepRequests: stepRequests.filter((x) =>
                getAddedOrChangedSteps().includes(x.id),
            ),
            type: TemplateType.Improvement,
            configuration: configuration,
        };

        setIsSaving(true);

        const result = await templateMutation.mutateAsync({
            request: workflowRequest,
            isCreate: createNew ?? false,
            invalidateSteps: true,
        });

        setIsSaving(false);

        if (result.Succeeded) {
            onHaveChanges(false);

            setErrors(undefined);

            if (draft) {
                toast.dismiss();

                toast.success(t(k.DRAFT_SAVED), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                if (createNew && result.Data?.workflowVersionId) {
                    onUpdateModal({
                        newId: result.Data.workflowVersionId,
                        newType: ModalTypes.improvement_form,
                        onSave: true,
                    });
                }
            } else {
                props.onClose(true);
            }
        } else {
            setErrors(result.Messages);
            scrollToTopErrorById(getIdsFromValidationMessages(result.Messages));
        }
    };

    const handleOnArchive = async () => {
        if (improvementForm) {
            const archiveRequest: IArchiveRequest = {
                id: improvementForm.workflowVersionId,
                isArchive: !improvementForm.isArchived,
            };

            const result = await templateArchiveMutation.mutateAsync({
                request: archiveRequest,
            });
        }
    };

    const handleOnReset = async () => {
        if (improvementFormData && stepsList) {
            setImprovementForm(improvementFormData);
            setAllStates();
        } else {
            setSteps({
                values: {},
                ids: [],
            });
            setImprovementForm(getInitialForm);
            setActivities(createTableSetParentLink("activityIdsByStepId"));
            setAttachments(
                createTableSetParentLink("attachmentsIdsByActivityId"),
            );
            setActivityInputs(
                createTableSetParentLink("activityInputsIdsByActivityId"),
            );
        }

        onHaveChanges(false);
    };

    const handleOnDelete = async (draft?: boolean) => {
        if (improvementForm?.id) {
            if (draft) {
                const result = await templateDraftDeleteMutation.mutateAsync({
                    versionId: improvementForm.workflowVersionId,
                });

                if (result.Succeeded) {
                    if (result.Data?.hasPublishedVersion === false) {
                        props.onClose(true, true);
                    }
                    setErrors(undefined);
                }
            } else {
                const result = await templateDeleteMutation.mutateAsync({
                    versionId: improvementForm.workflowVersionId,
                });

                if (result.Succeeded) {
                    props.onClose(true, true);
                }
            }
        }
    };

    const handleOnCopy = async () => {
        try {
            const result = await copyById(improvementForm.workflowVersionId);

            onUpdateModal({
                newId: result.templateVersionId,
                newType: ModalTypes.improvement_form,
            });
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                showErrorToast(t, error.response?.data.errors._error);
            }
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const moveActivity = ({
        activityId,
        oldStepId,
        newStepId,
    }: IMoveActivity) => {
        setActivities((prev) => {
            const newResult = { ...prev };
            const activity = newResult.values[activityId];

            if (
                activity &&
                activity.stepId === oldStepId &&
                newResult.parents?.activityIdsByStepId?.[oldStepId].includes(
                    activityId,
                )
            ) {
                activity.stepId = newStepId;
                activity.movedFromStepId = oldStepId;

                newResult.parents.activityIdsByStepId[oldStepId] =
                    newResult.parents.activityIdsByStepId[oldStepId].filter(
                        (x) => x !== activityId,
                    );
                newResult.parents.activityIdsByStepId[newStepId] = [
                    ...(newResult.parents.activityIdsByStepId[newStepId] ?? []),
                    activityId,
                ];

                setSteps((prev) => {
                    let updatedPrev = { ...prev };

                    const oldStep = updatedPrev.values[oldStepId];
                    const newStep = updatedPrev.values[newStepId];

                    if (oldStep && newStep) {
                        updatedPrev.values = {
                            ...updatedPrev.values,
                            [oldStepId]: {
                                ...oldStep,
                                isChanged: true,
                            },
                            [newStepId]: {
                                ...newStep,
                                isChanged: true,
                            },
                        };
                    }

                    return updatedPrev;
                });

                return newResult;
            }

            return prev;
        });
    };

    const initialSection = steps && steps.ids[0];

    const isLoading = isLoadingTemplate && isLoadingStepsList;
    return {
        createNew,

        isLoading,
        isLoadingTemplate,
        isErrorTemplate,
        isLoadingSteps: isLoadingStepsList,
        isSaving,

        errors,
        setErrors,

        sectionRefs: stepRefs,

        canEditForm,
        canEditFormLoading,

        sharedSteps,

        improvementForm: improvementForm,
        setImprovementForm: setImprovementForm,

        isArchived: improvementForm.isArchived,

        steps,
        setSteps,

        activities,
        setActivities,

        attachments,
        setAttachments,

        activityInputs,
        setActivityInputs,

        photos,
        setPhotos,

        configuration,
        setConfiguration,

        initialSection,

        haveChanges,

        onHaveChanges,

        handleOnSave,

        handleOnClose,

        handleOnArchive,

        handleOnDelete,

        handleOnReset,

        handleOnCopy,

        moveActivity,
    };
};
