import React from "react";

import ReportTableCell from "../report-table/ReportTableCell";
import SeparateTablesCell from "../report-table/SeparateTablesCell";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";
import StepInstanceStatusBadge from "common/components/badges/step-instance-status-badge/StepInstanceStatusBadge";
import { TableCell } from "common/components/table";
import IDictionary from "common/viewModels/IDictionary";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";
import { ChecklistReportMatrixRowType } from "components/template-checklist-report/components/report-table-details/api/IChecklistReportResponse";

interface IProps {
    columns: Array<ITemplateReportActivityTitle | undefined>;
    row: ChecklistReportMatrixRowType | null;

    selectedCustomListItemsNames?: Record<
        string,
        IValueLabelItemWithState | undefined
    >;
    highlightedActivityInstanceIds: IDictionary<boolean>;

    onBarcodeSearch: (barcode: string) => void;
}

export const ReportTableMatrixDetailRow = (props: IProps) => {
    const {
        columns,
        row,
        selectedCustomListItemsNames,
        highlightedActivityInstanceIds,
    } = props;

    return (
        <React.Fragment>
            <SeparateTablesCell />

            {row
                ? undefined
                : columns.map((_, index) => (
                      <TableCell key={index}></TableCell>
                  ))}

            {row?.cellValues.map((cellValue, index) => {
                if (cellValue.isHidden) {
                    return;
                }

                const { stepInstanceStatus, rowSpan } = cellValue;

                const { isStatusColumn, expandInfo } = columns[index] ?? {};

                if (isStatusColumn) {
                    return (
                        <TableCell key={index} isHighlighted rowSpan={rowSpan}>
                            {stepInstanceStatus ? (
                                <StepInstanceStatusBadge
                                    status={stepInstanceStatus}
                                />
                            ) : (
                                <ReportTableCell.NotAvailableMessage />
                            )}
                        </TableCell>
                    );
                }

                return (
                    <ReportTableCell
                        key={index}
                        isTopLevel={expandInfo?.isTopLevel ?? false}
                        isCellBusy={expandInfo?.isBusy ?? false}
                        value={cellValue}
                        selectedCustomListItemsNames={
                            selectedCustomListItemsNames
                        }
                        highlightedActivityInstanceIds={
                            highlightedActivityInstanceIds
                        }
                        onBarcodeSearch={props.onBarcodeSearch}
                    />
                );
            })}
        </React.Fragment>
    );
};
