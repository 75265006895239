import { ClosureReasonEnum } from "models/enums/ClosureReasonEnum";
import NoActionsTakenBadge from "../simple-badges/badges-closure-reasons/badge-no-actions-taken/NoActionsTakenBadge";
import NotActionableBadge from "../simple-badges/badges-closure-reasons/badge-not-actionable/NotActionableBadge";
import ResolvedFullyBadge from "../simple-badges/badges-closure-reasons/badge-resolved-fully/ResolvedFullyBadge";
import ResolvedPartiallyBadge from "../simple-badges/badges-closure-reasons/badge-resolved-partially/ResolvedPartiallyBadge";
import PostponedBadge from "../simple-badges/badges-closure-reasons/postponed/PostponedBadge";
import { IDarkBadgeProps } from "../base-badges/badge-dark/DarkBadge";

interface IClosureReasonBadgesProps extends IDarkBadgeProps {
    closureReason?: ClosureReasonEnum;
}

const ClosureReasonBadges = (props: IClosureReasonBadgesProps) => {
    const { closureReason, ...rest } = props;
    switch (closureReason) {
        case ClosureReasonEnum.ResolvedFully:
            return <ResolvedFullyBadge {...rest} />;
        case ClosureReasonEnum.ResolvedPartially:
            return <ResolvedPartiallyBadge {...rest} />;
        case ClosureReasonEnum.NoActionsTaken:
            return <NoActionsTakenBadge {...rest} />;
        case ClosureReasonEnum.NotActionable:
            return <NotActionableBadge {...rest} />;
        case ClosureReasonEnum.Postponed:
            return <PostponedBadge {...rest} />;
        default:
            return null;
    }
};

export default ClosureReasonBadges;
