import React from "react";

import { DataSheetGridRef } from "react-datasheet-grid";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { LocaleId } from "AppLocale";
import { NIL as NIL_UUID } from "uuid";

import k from "i18n/keys";

import {
    IImportImprovementRequest,
    IImportImprovementRequestItem,
} from "./IImportImprovementRequest";
import { ImportGrid } from "./ImportGrid";
import { ImportImprovementRow } from "./ImportImprovementRow";
import { IShowErrorPanel, PanelWithErrors } from "./PanelWithErrors";
import { useMutation } from "@tanstack/react-query";
import IChanged from "common/IChanged";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import { ErrorByIdList } from "common/components/datasheet-grid";
import { ESelectedProcessType } from "common/components/input-components/dropdown-tree/ESelectedProcessType";
import Modal from "common/components/modals/Modal";
import PageChangeDispatcher from "common/components/navigation-blocker/PageChangeDispatcher";
import { useHasPageChanges } from "common/components/navigation-blocker/redux/useHasPageChanges";
import IDictionary from "common/viewModels/IDictionary";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { ImpactGradingEnum } from "components/impact-grading-page/api/ImpactGradingEnum";
import { useImpactGradingOptions } from "components/impact-grading-page/api/hooks";
import { IImpactedProcessValue } from "components/improvements/api/IImpactedProcessValue";
import { importImprovementsMutation } from "components/improvements/api/hooks";
import { useAllProcessChartOptions } from "components/process-chart/pages/api/hooks";
import { useStepsByTemplateVersionId } from "components/steps/api/hooks";
import { useTemplateByVersionId } from "components/templates/api/hooks";
import ITableSet from "http/ITableSet";
import { ActivityType } from "models/enums/ActivityType";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    templateVersionId: string;

    onHide: (amount?: number) => void;
}

type TreeListOptionType = IValueLabelItem & { level: ESelectedProcessType };

function getImpactedProcessValue(
    allProcessChartOptions: TreeListOptionType[],
    item: IValueLabelItem | null,
) {
    const value = item?.value;

    if (value === "") {
        const result: IImpactedProcessValue = {
            isNotSure: true,
        };

        return result;
    } else if (value === NIL_UUID) {
        const result: IImpactedProcessValue = {
            isNotSure: false,
        };

        return result;
    } else if (value) {
        const option = allProcessChartOptions.find((x) => x.value === value);

        if (option) {
            const result: IImpactedProcessValue = {
                isNotSure: false,
            };

            switch (option.level) {
                case ESelectedProcessType.process:
                    result.processId = value;

                    break;
                case ESelectedProcessType.sub_process:
                    result.subProcessId = value;

                    break;
                case ESelectedProcessType.process_step:
                    result.processStepId = value;

                    break;
            }

            return result;
        }
    }
}

function isObjectWithValue(item: any): item is { value: string } {
    return (
        item &&
        !Array.isArray(item) &&
        typeof item === "object" &&
        "value" in item
    );
}

export const ImportGridModal = (props: IProps) => {
    const { templateVersionId } = props;

    const { t, i18n } = useTranslation();

    const setHasPageChanges = useHasPageChanges();
    const dispatch = useDispatch();

    const showErrorsRef = React.useRef<IShowErrorPanel>(null);

    const importMutation = useMutation({
        mutationFn: importImprovementsMutation,
    });

    const gridRef = React.useRef<DataSheetGridRef>(null);

    const invalidColumnIdsByRowId = React.useRef(
        new Map<string, Set<string>>(),
    );

    const [errorsByIds, setErrorsByIds] =
        React.useState<
            Array<{ key: string; values: string[]; index: number }>
        >();

    const isSaving = importMutation.isPending;

    const { data: improvementFormSteps } =
        useStepsByTemplateVersionId(templateVersionId);

    const { data: improvementForm } = useTemplateByVersionId(templateVersionId);

    const allProcessChartOptionsResult = useAllProcessChartOptions();

    const impactGradingResult = useImpactGradingOptions(
        i18n.language as LocaleId,
    );

    const [data, setData] = React.useState<IChanged<ImportImprovementRow[]>>({
        value: [],
    });

    const allProcessChartOptions = React.useMemo(() => {
        if (allProcessChartOptionsResult.data) {
            const result: Array<TreeListOptionType> = [];

            allProcessChartOptionsResult.data.forEach((process) => {
                result.push({
                    ...process,
                    level: ESelectedProcessType.process,
                });

                process.children.forEach((subProcess) => {
                    result.push({
                        ...subProcess,
                        level: ESelectedProcessType.sub_process,
                    });

                    subProcess.children.forEach((processStep) => {
                        result.push({
                            ...processStep,
                            level: ESelectedProcessType.process_step,
                        });
                    });
                });
            });

            return result;
        }
    }, [allProcessChartOptionsResult.data]);

    const columnNames = React.useMemo(() => {
        const result: ITableSet<string> & { dynamicIds: string[] } = {
            ids: [],
            values: {},
            dynamicIds: [],
        };

        result.ids.push("deviationMessage");
        result.values["deviationMessage"] = t(k.DESCRIPTION);

        result.ids.push("impact");
        result.values["impact"] = t(k.CHOOSE_IMPACTED_AREAS);

        result.ids.push("discovered");
        result.values["discovered"] = t(k.DISCOVERED_IN);

        result.ids.push("originated");
        result.values["originated"] = t(k.ORIGINATED_IN);

        const stepIds: string[] = Object.keys(
            improvementFormSteps?.steps ?? {},
        );

        for (const stepId of stepIds) {
            const activityIds: string[] =
                improvementFormSteps?.activityIdsByStepId[stepId] ?? [];

            for (const currActivityId of activityIds) {
                const activity =
                    improvementFormSteps?.activities[currActivityId];

                if (!activity) {
                    continue;
                }

                const activityId = activity.id;

                result.ids.push(activityId);
                result.dynamicIds.push(activityId);
                result.values[activityId] = activity.label;

                if (activity.type === ActivityType.Tasklist) {
                    const tasklistOptions = (
                        improvementFormSteps?.activityInputsIdsByActivityId[
                            activityId
                        ] ?? []
                    ).map(
                        (inputId) =>
                            improvementFormSteps.activityInputs[inputId],
                    );

                    tasklistOptions.forEach((option) => {
                        result.ids.push(option.id);
                        result.dynamicIds.push(option.id);
                        result.values[option.id] =
                            `${activity.label} - ${option.label}`;
                    });
                }
            }
        }

        result.ids.push("createdBy");
        result.values["createdBy"] = t(k.CREATED_BY);

        result.ids.push("createdAt");
        result.values["createdAt"] = t(k.CREATED_AT);

        return result;
    }, [improvementFormSteps]);

    const handleChange = (data: ImportImprovementRow[]) => {
        setData({ value: data, isChanged: true });
    };

    const handleCancel = async () => {
        if (isSaving) {
            return;
        }

        if (data.isChanged) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE_UNSAVED_CHANGES)}</>,
                yesButtonVariant: "danger",
                title: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                handleHide();
            }
        } else {
            handleHide();
        }
    };

    const handleSave = async () => {
        if (!improvementForm) {
            return;
        }

        const templateId = improvementForm.id;

        const orderedColumnIds = columnNames.ids;
        const dynamicInputIds = columnNames.dynamicIds;

        const request: IImportImprovementRequest = {
            templateId: templateId,
            items: [],
        };

        request.items = data.value.map((row) => {
            const hasInvalidImpactListItem = impactGradingResult.data?.some(
                (curr) => {
                    const item = row[curr.value];

                    if (isObjectWithValue(item)) {
                        return item.value === NIL_UUID;
                    }

                    return false;
                },
            );

            const selectedImpactList =
                impactGradingResult.data?.reduce<
                    IDictionary<ImpactGradingEnum>
                >((acc, curr) => {
                    const { value } = curr;

                    const item = row[value];

                    if (hasInvalidImpactListItem) {
                        acc[value] = Number(ImpactGradingEnum.None);
                    } else if (
                        isObjectWithValue(item) &&
                        item.value !== NIL_UUID
                    ) {
                        acc[value] = Number(item.value);
                    }

                    return acc;
                }, {}) ?? {};

            const impactGradingValues = Object.values(selectedImpactList)
                .concat(ImpactGradingEnum.None)
                .map(Number);

            const impactMax = Math.max(...impactGradingValues);

            const result: IImportImprovementRequestItem = {
                id: row.id,

                formId: templateId,
                values: {},

                deviationMessage: row.deviationMessage ?? "",
                impact: impactMax,

                impactList: selectedImpactList,
                impactedProcesses: {
                    discovered: getImpactedProcessValue(
                        allProcessChartOptions ?? [],
                        row.discovered,
                    ),
                    originated: getImpactedProcessValue(
                        allProcessChartOptions ?? [],
                        row.originated,
                    ),
                },

                createdAt: row.createdAt,
            };

            if (row.createdByUser) {
                result.createdBy = row.createdByUser.value;
            }

            dynamicInputIds.forEach((id) => {
                const dynamicValue = row[id];

                if (dynamicValue && result.values) {
                    const activity = improvementFormSteps?.activities[id];

                    if (id in (improvementFormSteps?.activityInputs ?? {})) {
                        result.values[id] = {
                            value: "1",
                        };
                    } else if (activity) {
                        if (
                            activity.type === ActivityType.Customers ||
                            activity.type === ActivityType.Suppliers ||
                            activity.type === ActivityType.Items ||
                            activity.type === ActivityType.Users ||
                            activity.type === ActivityType.Positions ||
                            activity.type === ActivityType.Competencies ||
                            activity.type === ActivityType.CustomList
                        ) {
                            result.values[id] = {
                                selectedIds: dynamicValue,
                                value: "",
                                type: activity.type,
                            };
                        } else {
                            result.values[id] = {
                                value: dynamicValue,
                                type: activity.type,
                            };

                            if (isObjectWithValue(dynamicValue)) {
                                result.values[id].value = dynamicValue.value;
                            }
                        }
                    }
                }
            });

            return result;
        });

        const result = await importMutation.mutateAsync(request);

        if (result.Succeeded) {
            handleHide(result.Data);
        } else {
            showErrorsRef.current?.show();

            if (result.Messages._error) {
                toast.error(
                    <React.Fragment>{result.Messages._error}</React.Fragment>,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );
            }

            invalidColumnIdsByRowId.current.clear();

            Object.keys(result.ErrorsById).forEach((id) => {
                const invalidColumnIds = new Set(result.ErrorsById[id]);

                const impactGradings = impactGradingResult.data ?? [];

                const impactGradingLen = impactGradings.length;

                if (invalidColumnIds.has("impact") && impactGradingLen > 0) {
                    invalidColumnIds.delete("impact");

                    impactGradings.forEach((x) => {
                        invalidColumnIds.add(x.value);
                    });
                }

                invalidColumnIdsByRowId.current.set(id, invalidColumnIds);
            });

            setErrorsByIds(
                Object.entries(result.ErrorsById)
                    .map((x) => ({
                        key: x[0],

                        values: x[1]
                            .map((id) => ({
                                id,
                                index: orderedColumnIds.findIndex(
                                    (currentId) => currentId === id,
                                ),
                            }))
                            .sort((a, b) => a.index - b.index)
                            .map((x) => x.id),

                        index: request.items.findIndex(
                            (item) => item.id === x[0],
                        ),
                    }))
                    .sort((a, b) => a.index - b.index),
            );
        }
    };

    const handleClickOnError = (args: {
        key: string;
        name: string;
        index: number;
    }) => {
        let col: string = args.name;

        if (col === "impact") {
            const impactGradings = impactGradingResult.data ?? [];

            const impactGradingLen = impactGradings.length;

            if (impactGradingLen > 0) {
                gridRef.current?.setSelection({
                    min: { col: impactGradings[0].value, row: args.index },
                    max: {
                        col: impactGradings[impactGradingLen - 1].value,
                        row: args.index,
                    },
                });
            }
        } else {
            if (col === "createdBy") {
                col = "createdByUser";
            }

            gridRef.current?.setActiveCell({ col, row: args.index });
        }
    };

    const handleHide = (amount?: number) => {
        setHasPageChanges(false);

        props.onHide(amount);
    };

    const isSavingButtonDisabled =
        isSaving || Boolean(data.isChanged) === false;

    return (
        <Modal show size="full" fullHeight onHide={handleCancel}>
            <Modal.Title>{t(k.IMPORT)}</Modal.Title>
            <Modal.Body>
                {isSaving && <AnimatedSpinner position="center" isVisible />}

                <PanelWithErrors
                    ref={showErrorsRef}
                    errors={
                        isSaving ? (
                            <AnimatedSpinner position="center" isVisible />
                        ) : (
                            <ErrorByIdList
                                errors={importMutation.data?.Messages ?? null}
                                errorsByIds={errorsByIds ?? null}
                                labelTranslationKeys={columnNames.values}
                                onClick={handleClickOnError}
                            />
                        )
                    }
                >
                    <ImportGrid
                        ref={gridRef}
                        templateVersionId={templateVersionId}
                        invalidColumnIdsByRowId={
                            invalidColumnIdsByRowId.current
                        }
                        impactGradings={impactGradingResult.data ?? null}
                        allProcessChartOptions={allProcessChartOptions ?? null}
                        onChange={handleChange}
                    />
                </PanelWithErrors>

                <PageChangeDispatcher hasChanges={data.isChanged} />
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        variant="danger"
                        transparent
                        onClick={handleCancel}
                        disabled={isSaving}
                    >
                        {t(k.CANCEL)}
                    </Button>

                    <Button
                        variant="success"
                        onClick={handleSave}
                        isBusy={isSaving}
                        disabled={isSavingButtonDisabled}
                    >
                        {t(k.SAVE)}
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};
