import React, { useEffect, useRef } from "react";

import classnames from "classnames";

import {
    ReactMouseOrTouchEvent,
    defaultMinColumnWidthVertical,
} from "../useResizableColumns";
import { useTableCellContainerContext } from "./Context";
import { ITableCellProps } from "./ITableCellProps";
import TableResizeHandle from "./TableResizeHandle";
import TagsContainer from "./TagsContainer";
import {
    ITableColumnDateFilterProps,
    TableColumnDateFilter,
} from "./filtering";
import ColumnFilteringComponent, {
    IColumnFilteringProps,
} from "./filtering/custom-list/ColumnFilteringComponent";
import ColumnSortingComponent, {
    IColumnSortingProps,
} from "./sorting/ColumnSortingComponent";
import AccordionButton from "common/components/buttons/accordion-button/AccordionButton";
import TextOverflow from "common/components/text-overflow/TextOverflow";

export interface ITableCellPropsEx extends ITableCellProps {
    name?: string | number;

    align?: "center";
    noVerticalAlign?: boolean;
    useOverflow?: boolean;
    useEllipsis?: boolean;
    noWrap?: boolean;
    flexWrapContent?: boolean;

    stickyTopCellContent?: boolean;

    zIndex?: number;
    /** icon: 64px, xs: 128px, sm: 192px, md: 256px, lg: 352px, xl: 448px */
    width?: "icon" | "xs" | "sm" | "md" | "lg" | "xl";
    /** icon: 64px, xs: 128px, sm: 192px, md: 256px, lg: 352px, xl: 448px */
    minWidth?: "icon" | "xs" | "sm" | "md" | "lg" | "xl";

    sortingBy?: string | number;
    sortingIsAsc?: boolean;
    sortingPosition?: "start" | "end";

    filterMenu?: React.ReactNode;

    emptyCell?: boolean;

    flexColumn?: boolean;

    pointerCursorForClickable?: boolean;

    isBusy?: boolean;

    testId?: string;

    onClick?: (
        e: React.UIEvent,
        isBusy?: boolean,
        name?: string | number,
    ) => void;

    onMouseEnter?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
}

export type TableCellComponentType = React.FC<
    React.PropsWithChildren<ITableCellPropsEx>
> & {
    Sorting: React.FC<IColumnSortingProps>;
    Filtering: React.FC<IColumnFilteringProps>;
    isTableCell: boolean;
    FilteringDate: React.FC<ITableColumnDateFilterProps>;
    Tags: React.FC<React.PropsWithChildren>;
};

const TableCellComponent: TableCellComponentType = (props) => {
    const {
        name,
        index,
        isHighlighted,
        colSpan,
        rowSpan,
        align,

        noWrap,
        useOverflow,
        useEllipsis,
        stickyTopCellContent,
        width,
        minWidth,
        noVerticalAlign,

        sortingBy,
        sortingIsAsc,

        filterMenu,

        emptyCell,
        pointerCursorForClickable = true,
        isExpanded,
        flexColumn,
        flexWrapContent,

        isBusy: isCellBusy,

        testId,
    } = props;

    const sortingPosition = props.sortingPosition ?? "end";

    const {
        isHeader,
        isHeaderVerticalAlign,
        isLoading,
        startResizing,
        stopResizing,
        registerCellRef,
        columnWidths,
        canExpandRow,
        resizingKey,
        columnsHeaders,
        registerColumnHeaderName,
    } = useTableCellContainerContext() ?? {};

    const getResizingProp = {
        index: index,
        name: name,
        "": undefined,
    };
    const resizingKeyProp = getResizingProp[resizingKey ?? ""];

    const cellWidth =
        index !== undefined
            ? resizingKey === "index"
                ? columnWidths?.[index]?.width
                : columnsHeaders?.[index]
                  ? columnWidths?.[columnsHeaders[index]]?.width
                  : undefined
            : undefined;

    const zIndex = (props.zIndex ?? 0) + (props.zIndexOrderModifier ?? 0);

    const style = {
        zIndex: zIndex > 0 ? zIndex : undefined,
        width: cellWidth ? `${cellWidth}px` : undefined,
    };
    const contentStyle = { width: cellWidth ? `${cellWidth}px` : undefined };

    const cellRef = useRef<HTMLTableCellElement>(null);

    useEffect(() => {
        if (
            isHeader &&
            cellRef.current &&
            resizingKeyProp !== undefined &&
            !isLoading
        ) {
            registerCellRef?.(
                cellRef.current,
                resizingKeyProp,
                isHeaderVerticalAlign && !noVerticalAlign
                    ? defaultMinColumnWidthVertical
                    : undefined,
            );
        }
    }, [registerCellRef, resizingKeyProp, isLoading]);

    useEffect(() => {
        if (
            resizingKey === "name" &&
            isHeader &&
            name !== undefined &&
            index !== undefined
        ) {
            registerColumnHeaderName?.(index, name);
        }
    }, [registerColumnHeaderName, name, index]);

    const clickHandler = (e: React.UIEvent) => {
        if (props.onClick) {
            props.onClick(e, isLoading, name);
        }
    };

    const resizable =
        ((resizingKey === "index" && index !== undefined) ||
            (resizingKey === "name" && name !== undefined)) &&
        !!startResizing &&
        !!stopResizing;

    const showResizeHandle = resizable && isHeader && !!cellWidth;

    const thClassName = classnames("table-cell", props.className, {
        "table-cell__header": isHeader,
        "table-cell__no-vert-align":
            isHeader && isHeaderVerticalAlign && noVerticalAlign,
        "table-cell__vert-align":
            isHeader && isHeaderVerticalAlign && !noVerticalAlign,
        "table-cell__highlight": isHighlighted,
        "table-cell__align-center": align === "center",
        "table-cell__sticky-top": stickyTopCellContent,
        [`cell-width-${width}`]: width,
        [`cell-min-width-${minWidth}`]: minWidth,
        "table-cell__empty": emptyCell,
        "table-cell__flex-column": flexColumn,
        "table-cell__no-wrap": noWrap,
    });

    const handleStartResizing = (e: ReactMouseOrTouchEvent) => {
        return resizingKeyProp !== undefined
            ? startResizing?.(e, resizingKeyProp)
            : undefined;
    };

    const useTextOverflow =
        typeof props.children === "string" && (useOverflow ?? true);

    if (isHeader) {
        const isCursorPointer = isLoading
            ? false
            : props.onClick !== undefined && pointerCursorForClickable;

        const contentHeaderClassName = classnames({
            "th-content": true,
            "th-pointer": isCursorPointer,
        });

        if (isHeaderVerticalAlign && !noVerticalAlign) {
            return (
                <th
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    className={thClassName}
                    style={style}
                    ref={cellRef}
                    data-testid={testId}
                >
                    <div
                        className={contentHeaderClassName}
                        style={contentStyle}
                    >
                        <div
                            className={`th-click${
                                filterMenu ? " th-filter" : ""
                            }`}
                            onClick={props.onClick ? clickHandler : undefined}
                        >
                            <div className="th-children">
                                {useTextOverflow ||
                                useOverflow ||
                                useEllipsis ? (
                                    <TextOverflow ellipsis={useEllipsis}>
                                        {props.children}
                                    </TextOverflow>
                                ) : (
                                    props.children
                                )}
                            </div>
                            {sortingBy !== undefined && (
                                <ColumnSortingComponent
                                    isActive={sortingBy === name}
                                    sortAsc={sortingIsAsc}
                                />
                            )}
                        </div>
                        {filterMenu}
                        {showResizeHandle && (
                            <TableResizeHandle
                                startResizing={handleStartResizing}
                                stopResizing={stopResizing}
                            />
                        )}
                    </div>
                </th>
            );
        }

        return (
            <th
                colSpan={colSpan}
                rowSpan={rowSpan}
                className={thClassName}
                style={style}
                ref={cellRef}
                data-testid={testId}
            >
                <div className={contentHeaderClassName} style={contentStyle}>
                    <div
                        className={`th-click${filterMenu ? " th-filter" : ""}`}
                        onClick={props.onClick ? clickHandler : undefined}
                    >
                        {sortingBy !== undefined &&
                            sortingPosition === "start" && (
                                <ColumnSortingComponent
                                    isActive={sortingBy === name}
                                    sortAsc={sortingIsAsc}
                                />
                            )}
                        <div className="th-children">
                            {emptyCell ? (
                                <>&nbsp;</>
                            ) : useTextOverflow ||
                              useOverflow ||
                              useEllipsis ? (
                                <TextOverflow ellipsis={useEllipsis ?? true}>
                                    {props.children}
                                </TextOverflow>
                            ) : (
                                props.children
                            )}
                        </div>
                        {sortingBy !== undefined &&
                            sortingPosition === "end" && (
                                <ColumnSortingComponent
                                    isActive={sortingBy === name}
                                    sortAsc={sortingIsAsc}
                                />
                            )}
                    </div>
                    {filterMenu}
                    {showResizeHandle && (
                        <TableResizeHandle
                            startResizing={handleStartResizing}
                            stopResizing={stopResizing}
                        />
                    )}
                </div>
            </th>
        );
    }

    const isClickable = props.onClick !== undefined;

    const tdClassName = classnames(thClassName, {
        "cell-is-loading": isCellBusy,
        "td-pointer": isClickable && pointerCursorForClickable,
        "td-default-cursor": isClickable && !pointerCursorForClickable,
    });

    const contentBodyClassName = classnames({
        "td-content": true,
        "sticky-top-cell": stickyTopCellContent,
        "flex-wrap": flexWrapContent,
    });

    return (
        <td
            colSpan={colSpan}
            rowSpan={rowSpan}
            className={tdClassName}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick ? clickHandler : undefined}
            style={style}
            data-testid={testId}
        >
            <div className={contentBodyClassName} style={contentStyle}>
                {index === 0 && canExpandRow && (
                    <div className="expand-col" onClick={props.handleExpand}>
                        <AccordionButton isOpen={isExpanded} />
                    </div>
                )}
                {useTextOverflow || useOverflow || useEllipsis ? (
                    <TextOverflow
                        ellipsis={useEllipsis}
                        multiLine={useOverflow && useEllipsis}
                    >
                        {props.children}
                    </TextOverflow>
                ) : (
                    props.children
                )}
            </div>
        </td>
    );
};

TableCellComponent.Sorting = ColumnSortingComponent;
TableCellComponent.Filtering = ColumnFilteringComponent;
TableCellComponent.isTableCell = true;
TableCellComponent.FilteringDate = TableColumnDateFilter;
TableCellComponent.Tags = TagsContainer;

export default TableCellComponent;
