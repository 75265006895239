import "./ActingBadge.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Badge, { IBaseBadgeProps } from "../../base-badges/Badge";

interface IActingBadgeProps extends IBaseBadgeProps {
    label?: string;
}

const ActingBadge = (props: IActingBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Badge className="acting-badge" {...rest}>
            {label ?? t(k.ACTING)}
        </Badge>
    );
};

export default ActingBadge;
