import "./MeasurementForm.scss";

import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OnChangeValue } from "react-select";
import { SortEnd } from "react-sortable-hoc";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import { IInputActionDTO } from "../common/actions/api/IInputActionDTO";
import { EMeasurementGrading } from "./api/EMeasurementGrading";
import IMeasurementActionSetDTO from "./api/IMeasurementActionSetDTO";
import { IMeasurementDTO } from "./api/IMeasurementDTO";
import { IMeasurementRequest } from "./api/IMeasurementRequest";
import {
    useArchiveMeasurementMutation,
    useChangeMeasurementStatusCommentMutation,
    useChangeMeasurementStatusMutation,
    useDeleteMeasurementMutation,
    useMeasurementAccessRole,
    useMeasurementById,
    useMeasurementViewAsAccessRole,
    useSaveMeasurementMutation,
    useSortActionSetsMutation,
} from "./api/hooks";
import ActionPlan from "./components/ActionPlan";
import MeasurementChart from "./components/MeasurementChart";
import MeasurementProgressList from "./measurement-progress-value/MeasurementProgressList";
import {
    convertToTableSet,
    convertToTableSetWithParentLink,
    decomposeMeasurementAccessRole,
    getMeasurementProgress,
} from "./utils/MeasurementTools";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import FormattedDate from "common/components/formattedDate/FormattedDate";
import FormattedDueDate from "common/components/formattedDate/FormattedDueDate";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import MeasurementStatusSelectionRow from "common/components/input-components/measurement-status-selection-row/MeasurementStatusSelectionRow";
import MeasurementStatusComment from "common/components/measurement-status-comment/MeasurementStatusComment";
import ModalContent from "common/components/modal-content/ModalContent";
import { ModalManagerContext } from "common/components/modal-manager/ModalManagerContext";
import {
    IActiveTransactionsPayload,
    IShowModalPayload,
    ModalTypes,
} from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import ParentGoalList from "common/components/parent-goal-list/ParentGoalList";
import ProgressBar, {
    Position,
} from "common/components/progress-bar/ProgressBar";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { OptionType } from "common/components/select-dropdown/SelectDropdownTypes";
import SelectUsersAvatarListEditable from "common/components/select-users/SelectUsersAvatarListEditable";
import SelectedUsersAvatarList from "common/components/select-users/SelectedUsersAvatarList";
import TeamsListById from "common/components/team-list/TeamsListById";
import TeamsListEditable from "common/components/team-list/TeamsListEditable";
import { arrayMoveTo } from "common/utils/arrayUtils";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import { IInputActionChangeEvent } from "components/common/actions/IInputActionProps";
import ActionTextField from "components/common/actions/inputActions/components/ActionTextField";
import UserMultiselect from "components/common/multiselect-dropdowns/UserMultiselect";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import {
    getIdsFromValidationMessages,
    scrollToTopErrorById,
} from "components/common/validation/ScrollToError";
import { IServerGoalPageFilter } from "components/goals-page/api/IGoalPageFilters";
import InfoCard from "components/goals-page/components/InfoCard";
import { HistoryOwnerEnum } from "components/history/api/HistoryOwnerEnum";
import HistoryButton from "components/history/components/HistoryButton";
import IItemTypeRowDTO from "components/item-types/api/IItemTypeRowDTO";
import { ItemCategoryType } from "components/item-types/api/ItemCategoryType";
import { usePublishedUnitsList } from "components/item-types/api/hooks";
import ItemTypeModalForm from "components/item-types/components/ItemTypeModalForm";
import { usePublishedUserOptions } from "components/users/api/hooks";
import ITableSet from "http/ITableSet";
import ITableSetWithParentLink, {
    createTableSetParentLink,
} from "http/ITableSetWithParentLink";
import { getLocaleString } from "i18n/components/GetLocaleString";
import { ETransactionStatus } from "models/enums/TransactionStatus";
import { IAppState } from "store/IAppState";
import { IShowConfirmArgs, showConfirmNoThunk } from "store/confirms/actions";

interface IProps {
    id?: string;
    goalId?: string;
    createNew?: boolean;
    haveChanges: boolean;
    syncData?: boolean;
    filter?: IServerGoalPageFilter;
    onHaveChanges: (haveChanges: boolean) => void;
    onUpdateModal: (newId: string) => void;
    onClose: (onSave?: boolean, newId?: string) => void;
    onSave?: (value: string) => void;
    onUpdateActiveTransactions?: (payload: IActiveTransactionsPayload) => void;
}

const MeasurementForm = (props: IProps) => {
    const { id, goalId, createNew, haveChanges, syncData, filter } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isTypeFormVisible, setIsTypeFormVisible] = useState(false);

    const [editMode, setEditMode] = useState(createNew ?? false);

    const [progressListVisible, setProgressListVisible] = useState(false);

    const [actionPlanHasChanges, setActionPlanHasChanges] = useState(false);

    const [activeTransactions, setActiveTransactions] = useState<
        IDictionary<ETransactionStatus>
    >({});

    const [errors, setErrors] = useState<IValidationMessages>({});

    const modalManagerContext = useContext(ModalManagerContext);

    const statusCommentRef = useRef<
        HTMLInputElement | HTMLTextAreaElement | null
    >(null);
    const [shouldStatusCommentFocus, setShouldStatusCommentFocus] =
        useState(false);

    const changeStatusMutation = useChangeMeasurementStatusMutation();
    const changeStatusCommentMutation =
        useChangeMeasurementStatusCommentMutation();
    const saveMutation = useSaveMeasurementMutation();
    const sortActionSetsMutation = useSortActionSetsMutation();
    const archiveMutation = useArchiveMeasurementMutation();
    const deleteMutation = useDeleteMeasurementMutation();
    const { data: unitsData } = usePublishedUnitsList();

    const canAccessGoals = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR ||
                roles.GOALS_OWNER ||
                roles.GOALS_EDITOR ||
                roles.GOALS_USER) ??
            false
        );
    });

    const _canCreate = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return (
            (roles.ADMINISTRATOR || roles.GOALS_OWNER || roles.GOALS_EDITOR) ??
            false
        );
    });

    const _canDelete = useSelector((appState: IAppState) => {
        const { roles } = appState.authViewState;

        return roles.ADMINISTRATOR || roles.GOALS_OWNER;
    });

    const {
        data: measurementAccessRole,
        isLoading: isLoadingMeasurementAccessRole,
    } = useMeasurementAccessRole(
        !createNew && canAccessGoals ? props.id : undefined,
    );

    const measurementAccessPermissions = decomposeMeasurementAccessRole(
        measurementAccessRole,
    );

    const {
        data: measurementViewAsAccessRole,
        isLoading: isLoadingMeasurementViewAsAccessRole,
    } = useMeasurementViewAsAccessRole(
        !createNew && canAccessGoals ? props.id : undefined,
        filter,
    );

    const goalViewAsAccessPermissions = decomposeMeasurementAccessRole(
        measurementViewAsAccessRole,
    );

    const isFilterEmpty =
        filter === undefined ||
        !Object.values(filter).some((value) => value !== undefined) ||
        measurementViewAsAccessRole === undefined;

    const canAccess = !isFilterEmpty
        ? measurementAccessPermissions.canView &&
          goalViewAsAccessPermissions.canView
        : _canCreate || measurementAccessPermissions.canView;
    const canEdit = !isFilterEmpty
        ? measurementAccessPermissions.canEdit &&
          goalViewAsAccessPermissions.canEdit
        : _canCreate || measurementAccessPermissions.canEdit;
    const canEditInPreview = !isFilterEmpty
        ? measurementAccessPermissions.canEditInPreview &&
          goalViewAsAccessPermissions.canEditInPreview
        : _canCreate || measurementAccessPermissions.canEditInPreview;
    const canCreate = !isFilterEmpty
        ? _canCreate && goalViewAsAccessPermissions.canCreate
        : _canCreate;
    const canDelete = !isFilterEmpty
        ? _canDelete && goalViewAsAccessPermissions.canEverything
        : _canDelete;

    const {
        data: measurementData,
        isLoading: measurementLoading,
        status: measurementStatus,
        refetch: refetchMeasurementData,
    } = useMeasurementById(id, (syncData && canAccess) ?? false);

    const [measurement, setMeasurement] = useState<IMeasurementDTO>({
        id: createNew ? uuidv4() : "noData",
        parentGoal: { id: goalId ?? "", name: "", responsibleUsersIds: [] },
        startValue: 0,
        statusComment: "",
        status: EMeasurementGrading.OnTrack,
        endValue: 100,
        responsibleUsersIds: [],
        involvedTeamsIds: [],
    });

    const isArchived = !!measurement.archivedAt;

    const { data: allUsers, isLoading: isLoadingUsers } =
        usePublishedUserOptions();

    const responsibleUsersData = allUsers?.options?.filter((x) =>
        measurement.responsibleUsersIds?.includes(x.value ?? ""),
    );

    const usersCanViewData = allUsers?.options?.filter((x) =>
        measurement.usersIdsCanView?.includes(x.value ?? ""),
    );

    const [actionSets, setActionSets] = useState<
        ITableSet<IMeasurementActionSetDTO>
    >({ ids: [], values: {} });

    const [actionListItems, setActionListItems] = useState<
        ITableSetWithParentLink<
            IInputActionDTO,
            "inputActionIdsByInputActionSetId"
        >
    >(createTableSetParentLink("inputActionIdsByInputActionSetId"));

    const setData = (measurementData: IMeasurementDTO) => {
        setMeasurement(measurementData);
        if (measurementData.inputActionSets) {
            setActionSets(convertToTableSet(measurementData.inputActionSets));
        }
        if (measurementData.inputActionsByInputActionSetId) {
            setActionListItems(
                convertToTableSetWithParentLink(
                    measurementData.inputActionsByInputActionSetId,
                ),
            );
        }
    };

    const handleOnRefetchMeasurement = async () => {
        await refetchMeasurementData();
    };

    useEffect(() => {
        if (measurementData) {
            setData(measurementData);
        }
    }, [measurementData]);

    useEffect(() => {
        if (shouldStatusCommentFocus && statusCommentRef.current) {
            statusCommentRef.current.focus();
            setShouldStatusCommentFocus(false);
        }
    }, [shouldStatusCommentFocus, statusCommentRef]);

    useEffect(() => {
        const isLoading = Object.values(activeTransactions).some(
            (x) => x === ETransactionStatus.Pending,
        );

        const isError = Object.values(activeTransactions).some(
            (x) => x === ETransactionStatus.Error,
        );

        const isSuccess = Object.values(activeTransactions).every(
            (x) => x === ETransactionStatus.Success,
        );

        if (isLoading) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Pending,
            });
        } else if (isError) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Error,
                error: { modalTitle: measurement.name },
            });
        } else if (isSuccess) {
            props.onUpdateActiveTransactions?.({
                transactionStatus: ETransactionStatus.Success,
            });
        }
    }, [activeTransactions]);

    const handleShowHistory = () => {
        const payload: IShowModalPayload = {
            id: measurement.id,
            type: ModalTypes.history,
            callerAddress: location.pathname,

            options: {
                historyOwnerType: HistoryOwnerEnum.Measurement,
            },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleSave = async () => {
        const { inputActionSets, inputActionsByInputActionSetId } =
            actionSets.ids.reduce<{
                inputActionSets: IMeasurementActionSetDTO[];
                inputActionsByInputActionSetId: IDictionary<IInputActionDTO[]>;
            }>(
                (acc, id) => {
                    const actionSet = actionSets.values[id];

                    if (actionSet && actionSet.isDeleted !== true) {
                        acc.inputActionSets.push(actionSet);

                        const setIds =
                            actionListItems.parents
                                ?.inputActionIdsByInputActionSetId?.[id];

                        if (setIds) {
                            const actionsBySet = setIds.reduce<
                                IInputActionDTO[]
                            >((list, id) => {
                                const inputAction = actionListItems.values[id];

                                if (inputAction) {
                                    list.push(inputAction);
                                }

                                return list;
                            }, []);

                            acc.inputActionsByInputActionSetId[id] =
                                actionsBySet;
                        }
                    }

                    return acc;
                },
                { inputActionSets: [], inputActionsByInputActionSetId: {} },
            );

        let request: IMeasurementRequest = {
            ...measurement,
            goalId: measurement.parentGoal?.id,
            inputActionSets,
            inputActionsByInputActionSetId,
        };

        setActiveTransactions((prev) => ({
            ...prev,
            saveMeasurement: ETransactionStatus.Pending,
        }));

        const result = await saveMutation.mutateAsync({
            isCreate: !!createNew,
            value: request,
        });

        setActiveTransactions((prev) => ({
            ...prev,
            saveMeasurement: result.Succeeded
                ? ETransactionStatus.Success
                : ETransactionStatus.Error,
        }));

        if (result.Succeeded) {
            const measurementId = result.Data;

            if (measurementId) {
                props.onSave?.(measurementId);

                if (createNew) {
                    props.onUpdateModal(measurementId);
                }
            }

            setEditMode(false);
            setErrors({});
            props.onHaveChanges(false);
        } else if (result.Invalid) {
            setErrors(result.Messages);
            scrollToTopErrorById(getIdsFromValidationMessages(result.Messages));
        }
    };

    const handleOnChangeName = (e: IInputActionChangeEvent<string>) => {
        setMeasurement((prev) => ({
            ...prev,
            name: e.value ?? "",
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeDescription = (e: IInputChangeEvent<string>) => {
        setMeasurement((prev) => ({
            ...prev,
            description: e.value ?? "",
        }));
        props.onHaveChanges(true);
    };

    const handleOnCloseProgressListModal = () => {
        setProgressListVisible(false);
    };

    const handleOnHavePreviewChanges = (haveChanges: boolean) => {
        setActionPlanHasChanges(haveChanges);
    };

    const handleArchive = async () => {
        if (measurement.id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: isArchived ? "blue" : "danger",
                yesLabel: isArchived ? t(k.UN_ARCHIVE) : t(k.ARCHIVE),
                title: isArchived
                    ? t(k.UNARCHIVE_THIS_MEASUREMENT)
                    : t(k.ARCHIVE_THIS_MEASUREMENT),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                setActiveTransactions((prev) => ({
                    ...prev,
                    archiveMeasurement: ETransactionStatus.Pending,
                }));

                const result = await archiveMutation.mutateAsync({
                    id: measurement.id,
                    isArchive: !isArchived,
                });

                setActiveTransactions((prev) => ({
                    ...prev,
                    archiveMeasurement: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));
            }
        }
    };

    const handleOnChangeStatus = async (status: EMeasurementGrading) => {
        const oldMeasurement = measurement;
        setMeasurement((prev) => ({
            ...prev,
            status,
            statusComment: "",
        }));

        if (
            status === EMeasurementGrading.AtRisk ||
            status === EMeasurementGrading.Blocked
        ) {
            setShouldStatusCommentFocus(true);
        }

        if (editMode) {
            props.onHaveChanges(true);
        } else {
            setActiveTransactions((prev) => ({
                ...prev,
                statusMeasurement: ETransactionStatus.Pending,
            }));

            const result = await changeStatusMutation.mutateAsync({
                measurementId: measurement.id,
                status,
                statusComment: "",
            });

            setActiveTransactions((prev) => ({
                ...prev,
                statusMeasurement: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Invalid) {
                setMeasurement(oldMeasurement);
            }
        }
    };

    const handleOnChangeStatusComment = (
        e: IInputActionChangeEvent<string | undefined>,
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            statusComment: e.value ?? "",
        }));
        if (editMode) {
            props.onHaveChanges(true);
        }
    };

    const handleSaveStatusComment = async () => {
        setActiveTransactions((prev) => ({
            ...prev,
            saveStatusMeasurement: ETransactionStatus.Pending,
        }));

        const result = await changeStatusCommentMutation.mutateAsync({
            measurementId: measurement.id,
            statusComment: measurement.statusComment ?? "",
        });

        setActiveTransactions((prev) => ({
            ...prev,
            saveStatusMeasurement: result.Succeeded
                ? ETransactionStatus.Success
                : ETransactionStatus.Error,
        }));
    };

    const handleOnChangeStartDate = (
        e: IInputChangeEvent<Date | undefined>,
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            startDate: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeStartValue = (e: IInputChangeEvent<number>) => {
        setMeasurement((prev) => ({
            ...prev,
            startValue: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeEndValue = (e: IInputChangeEvent<number>) => {
        setMeasurement((prev) => ({
            ...prev,
            endValue: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnEdit = () => {
        setEditMode(true);
    };

    const handleUpdateProgress = () => {
        const payload: IShowModalPayload = {
            type: ModalTypes.measurement_progress_form,
            callerAddress: location.pathname,
            options: {
                parentId: id,
                createNew: true,
            },
        };

        modalManagerContext.onShowModal(payload);
    };

    const handleDelete = async () => {
        if (id) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE)}</>,
                yesButtonVariant: "danger",
                yesLabel: t(k.DELETE),
                title: t(k.DELETE_THIS_MEASUREMENT),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                setActiveTransactions((prev) => ({
                    ...prev,
                    deleteMeasurement: ETransactionStatus.Pending,
                }));

                const result = await deleteMutation.mutateAsync(id);

                setActiveTransactions((prev) => ({
                    ...prev,
                    deleteMeasurement: result.Succeeded
                        ? ETransactionStatus.Success
                        : ETransactionStatus.Error,
                }));

                if (result.Succeeded) {
                    props.onClose(true);
                }
            }
        }
    };

    const handleOnChangeEndDate = (e: IInputChangeEvent<Date | undefined>) => {
        setMeasurement((prev) => ({
            ...prev,
            endDate: e.value,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeUsers = (
        values: string[],
        options: IValueLabelItem<string, string, any>[],
    ) => {
        setMeasurement((prev) => ({
            ...prev,
            responsibleUsersIds: values,
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeUsersCanView = (userId: string) => {
        setMeasurement((prev) => ({
            ...prev,
            usersIdsCanView: prev.usersIdsCanView?.includes(userId)
                ? prev.usersIdsCanView?.filter((x) => x !== userId)
                : [...(prev.usersIdsCanView ?? []), userId],
        }));
        props.onHaveChanges(true);
    };

    const handleOnChangeInvolvedTeams = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setMeasurement((prev) => ({
                ...prev,
                isTeamEveryone: !remove,
            }));
        } else {
            setMeasurement((prev) => ({
                ...prev,
                involvedTeamsIds: remove
                    ? prev.involvedTeamsIds?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.involvedTeamsIds ?? []), team.value],
            }));
        }
        props.onHaveChanges(true);
    };

    const handleOnChangeTeamsCanView = (
        team: IValueLabelItem<string, string, any>,
        remove?: boolean | undefined,
    ) => {
        if (team.value === "teamEveryone") {
            setMeasurement((prev) => ({
                ...prev,
                isTeamEveryoneCanView: !remove,
            }));
        } else {
            setMeasurement((prev) => ({
                ...prev,
                teamsIdsCanView: remove
                    ? prev.teamsIdsCanView?.filter(
                          (teamId) => teamId !== team.value,
                      )
                    : [...(prev.teamsIdsCanView ?? []), team.value],
            }));
        }
        props.onHaveChanges(true);
    };

    const handleOnChangeUnit = (value: OnChangeValue<OptionType, boolean>) => {
        if (value) {
            if ("value" in value) {
                const selectedUnit = unitsData?.find(
                    (x) => x.value === value.value,
                );

                if (selectedUnit !== undefined) {
                    setMeasurement((prev) => ({
                        ...prev,
                        unit: {
                            id: selectedUnit.value,
                            name: selectedUnit.label,
                            type: selectedUnit.param as ItemCategoryType,
                            isArchived: false,
                        },
                    }));
                }
            }
        } else {
            setMeasurement((prev) => ({ ...prev, unit: undefined }));
        }
        props.onHaveChanges(true);
    };

    const handleOnCreateUnit = (e: { label: string }) => {
        if (e.label) {
            setMeasurement((prev) => ({
                ...prev,
                unit: {
                    id: uuidv4(),
                    name: e.label,
                    type: ItemCategoryType.Unit,
                    isArchived: false,
                },
            }));
        }
        props.onHaveChanges(true);
        setIsTypeFormVisible(true);
    };

    const handleOnSaveUnit = (value?: IItemTypeRowDTO) => {
        if (value) {
            setMeasurement((prev) => ({
                ...prev,
                unit: value,
            }));
        }
        props.onHaveChanges(true);
        setIsTypeFormVisible(false);
    };

    const handleOnSortedActionSets = async (params: SortEnd) => {
        const oldActionSets = actionSets;
        setActionSets((prev) => ({
            ...prev,
            ids: arrayMoveTo(prev.ids, params.oldIndex, params.newIndex),
        }));
        if (editMode) {
            props.onHaveChanges(true);
        } else {
            setActiveTransactions((prev) => ({
                ...prev,
                sortActionSet: ETransactionStatus.Pending,
            }));

            const result = await sortActionSetsMutation.mutateAsync({
                request: {
                    parentId: measurement.id,
                    sortedIds: arrayMoveTo(
                        actionSets.ids,
                        params.oldIndex,
                        params.newIndex,
                    ),
                },
            });

            setActiveTransactions((prev) => ({
                ...prev,
                sortActionSet: result.Succeeded
                    ? ETransactionStatus.Success
                    : ETransactionStatus.Error,
            }));

            if (result.Invalid) {
                setActionSets(oldActionSets);
            }
        }
    };

    const handleOnCancel = async () => {
        if (haveChanges) {
            const confirmMsg: IShowConfirmArgs = {
                message: <>{t(k.ARE_YOU_SURE_UNSAVED_CHANGES)}</>,
                yesButtonVariant: "danger",
                title: t(k.THERE_ARE_UNSAVED_CHANGES),
            };

            const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

            if (confirmOk) {
                if (measurementData) {
                    setData(measurementData);
                }

                props.onHaveChanges(false);

                setEditMode(false);
            }
        } else {
            setEditMode(false);
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const handleViewAllProgress = () => {
        setProgressListVisible(true);
    };

    const handleViewParentGoal = (id: string) => {
        const payload: IShowModalPayload = {
            id,
            type: ModalTypes.goal_form,
            callerAddress: location.pathname,
        };

        modalManagerContext.onShowModal(payload);
    };

    if (
        isLoadingMeasurementAccessRole ||
        isLoadingMeasurementViewAsAccessRole
    ) {
        return <AnimatedSpinner isVisible aligned="center" position="center" />;
    }
    if (
        (!createNew &&
            (!canAccess || (!measurementData && !measurementLoading))) ||
        (createNew && !canCreate)
    ) {
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(
                    k.NO_AVAILABLE_MEASUREMENTS_FOUND_FOR_YOUR_USER_ROLE,
                )}
            />
        );
    }

    const showParentGoal = responsibleUsersData && measurement.parentGoal;

    const showInvolvedTeamsEditableList =
        (measurement.involvedTeamsIds &&
            measurement.involvedTeamsIds.length > 0) ||
        measurement.isTeamEveryone;

    const showTeamsCanViewEditableList =
        (measurement.usersIdsCanView &&
            measurement.usersIdsCanView.length > 0) ||
        (measurement.teamsIdsCanView &&
            measurement.teamsIdsCanView.length > 0) ||
        measurement.isTeamEveryoneCanView;

    const showDescriptionCard =
        editMode ||
        measurement.description ||
        measurement.startDate ||
        measurement.endDate;

    return (
        <>
            {progressListVisible && measurement.id !== "noData" && (
                <MeasurementProgressList
                    measurementId={measurement.id}
                    onClose={handleOnCloseProgressListModal}
                />
            )}
            <ModalContent>
                <ModalContent.Header
                    title={
                        editMode
                            ? createNew
                                ? t(k.NEW_MEASUREMENT)
                                : t(k.EDIT_MEASUREMENT)
                            : t(k.VIEW_MEASUREMENT)
                    }
                    actions={
                        <div className="measurement-form--header-actions">
                            {!createNew && (
                                <>
                                    <div
                                        className="last-modified"
                                        onClick={handleShowHistory}
                                    >
                                        {t(k.LAST_MODIFIED)}:{" "}
                                        <FormattedDate
                                            date={measurement.modifiedAt}
                                            showMonthName
                                            shortMonthName
                                        />{" "}
                                        {t(k.BY_)}{" "}
                                        {measurement.modifiedByName ??
                                            measurement.createdByName}
                                    </div>

                                    <HistoryButton
                                        id={measurement.id}
                                        type={HistoryOwnerEnum.Measurement}
                                    />
                                </>
                            )}
                        </div>
                    }
                    onClose={handleOnClose}
                />
                <ModalContent.Body className="measurement-form--body">
                    {!createNew && measurementLoading ? (
                        <AnimatedSpinner
                            isVisible
                            aligned="center"
                            position="center"
                        />
                    ) : (
                        <>
                            {isTypeFormVisible && (
                                <ItemTypeModalForm
                                    disabledDropdown
                                    onSave={handleOnSaveUnit}
                                    value={measurement.unit}
                                    isCreate
                                />
                            )}

                            <div className="measurement-form--body--first-row">
                                <div
                                    className={`measurement-form--body--first-row--name ${
                                        !showParentGoal ? "no-parent-goals" : ""
                                    }`}
                                >
                                    <ActionTextField
                                        id={`measurement.${measurement.id}.name`}
                                        label={editMode ? t(k.NAME) : null}
                                        required
                                        multiline
                                        minRows={1}
                                        editMode={editMode}
                                        editable={editMode && canEditInPreview}
                                        boldLabel
                                        variant={
                                            !editMode ? "title" : undefined
                                        }
                                        noPadding={!editMode}
                                        inputSize="large"
                                        value={measurement.name ?? ""}
                                        onChange={handleOnChangeName}
                                        testId="measurement-form--name"
                                        placeholder={t(k.ENTER_HERE)}
                                        error={getLocaleString(
                                            t,
                                            errors?.[
                                                `measurement.${measurement.id}.name`
                                            ],
                                        )}
                                        invalid={
                                            !!errors?.[
                                                `measurement.${measurement.id}.name`
                                            ]
                                        }
                                    />

                                    {!editMode ? (
                                        <div className="status-responsible-row">
                                            {responsibleUsersData &&
                                            responsibleUsersData.length > 0 ? (
                                                <SelectedUsersAvatarList
                                                    maxShownAvatars={5}
                                                    size={24}
                                                    users={responsibleUsersData}
                                                />
                                            ) : (
                                                t(
                                                    k.NO_USERS_ASSIGNED_TO_THIS_GOAL,
                                                )
                                            )}
                                            <MeasurementStatusSelectionRow
                                                disabled={
                                                    !!measurement.archivedAt ||
                                                    !canEditInPreview
                                                }
                                                status={
                                                    measurement.status ??
                                                    EMeasurementGrading.OnTrack
                                                }
                                                hideDraft={!canEdit}
                                                onClick={handleOnChangeStatus}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <UserMultiselect
                                                className="responsible"
                                                label={t(k.RESPONSIBLE)}
                                                defaultValues={
                                                    measurement.responsibleUsersIds ??
                                                    []
                                                }
                                                onChange={handleOnChangeUsers}
                                            />
                                            <InputWrapper
                                                wrapperLabel={t(k.STATUS)}
                                                boldLabel
                                            >
                                                <MeasurementStatusSelectionRow
                                                    status={
                                                        measurement.status ??
                                                        EMeasurementGrading.OnTrack
                                                    }
                                                    onClick={
                                                        handleOnChangeStatus
                                                    }
                                                />
                                            </InputWrapper>
                                        </>
                                    )}

                                    <MeasurementStatusComment
                                        inputRef={statusCommentRef}
                                        status={measurement.status}
                                        comment={measurement.statusComment}
                                        editMode={editMode}
                                        canEdit={
                                            canEditInPreview &&
                                            !measurement.archivedAt
                                        }
                                        onChangeStatusComment={
                                            handleOnChangeStatusComment
                                        }
                                        onSaveStatusComment={
                                            handleSaveStatusComment
                                        }
                                    />
                                </div>

                                {showParentGoal && (
                                    <ParentGoalList
                                        parentGoals={
                                            measurement.parentGoal
                                                ? [measurement.parentGoal]
                                                : []
                                        }
                                        onClick={handleViewParentGoal}
                                    />
                                )}
                            </div>

                            {editMode ? (
                                <div className="measurement-form--body--unit-teams-row">
                                    <div className="measurement-form--body--unit-teams-row--item">
                                        <InputComponent
                                            inputType={EActivityType.Textfield}
                                            wrapperLabel={t(k.START_VALUE)}
                                            fullWidth
                                            boldLabel
                                            type="number"
                                            hideIcon
                                            size="large"
                                            value={measurement.startValue}
                                            onChange={handleOnChangeStartValue}
                                            testId="measurement-form--start-value"
                                            placeholder={t(k.ENTER_HERE)}
                                        />
                                        <InputComponent
                                            inputType={EActivityType.Textfield}
                                            wrapperLabel={t(k.TARGET_VALUE)}
                                            fullWidth
                                            boldLabel
                                            type="number"
                                            hideIcon
                                            size="large"
                                            value={measurement.endValue}
                                            onChange={handleOnChangeEndValue}
                                            testId="measurement-form--target-value"
                                            placeholder={t(k.ENTER_HERE)}
                                        />
                                        <InputWrapper
                                            wrapperLabel={t(k.UNIT)}
                                            boldLabel
                                            fullWidth
                                        >
                                            <SelectDropdown
                                                id="unit"
                                                testId="measurement-form--unit"
                                                value={unitsData?.find(
                                                    (item) =>
                                                        item.value ===
                                                        measurement.unit?.id,
                                                )}
                                                isClearable
                                                isSearchable
                                                bold
                                                placeholder={t(
                                                    k.START_TYPING_TO_SELECT_OR_CREATE,
                                                )}
                                                options={unitsData}
                                                onChange={handleOnChangeUnit}
                                                onCreate={handleOnCreateUnit}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="measurement-form--body--unit-teams-row--item">
                                        <InputWrapper
                                            wrapperLabel={t(k.INVOLVED_TEAMS)}
                                            boldLabel
                                            fullWidth
                                        >
                                            <TeamsListEditable
                                                editMode
                                                clearable
                                                icon={null}
                                                teamIds={
                                                    measurement.involvedTeamsIds
                                                }
                                                isTeamEveryone={
                                                    measurement.isTeamEveryone
                                                }
                                                onChange={
                                                    handleOnChangeInvolvedTeams
                                                }
                                            />
                                        </InputWrapper>
                                        <InputWrapper
                                            wrapperLabel={t(k.CAN_VIEW)}
                                            boldLabel
                                            fullWidth
                                        >
                                            <div className="measurement-form--body--unit-teams-row--item vertical">
                                                <TeamsListEditable
                                                    editMode
                                                    clearable
                                                    icon={null}
                                                    teamIds={
                                                        measurement.teamsIdsCanView
                                                    }
                                                    isTeamEveryone={
                                                        measurement.isTeamEveryoneCanView
                                                    }
                                                    onChange={
                                                        handleOnChangeTeamsCanView
                                                    }
                                                />
                                                <SelectUsersAvatarListEditable
                                                    maxShownAvatars={10}
                                                    selectedUserIds={
                                                        measurement.usersIdsCanView
                                                    }
                                                    editMode={editMode}
                                                    onChangeUser={
                                                        handleOnChangeUsersCanView
                                                    }
                                                    allUsers={allUsers?.options}
                                                />
                                            </div>
                                        </InputWrapper>
                                    </div>
                                </div>
                            ) : (
                                <div className="measurement-form--body--progress-row">
                                    <div className="progress-column">
                                        <ProgressBar
                                            type="circle"
                                            now={getMeasurementProgress(
                                                measurement,
                                            )}
                                            contentPos={Position.Bottom}
                                            content={
                                                <div className="measurement-form--body--progress-row--progress-bar">
                                                    {measurement.currentValue ??
                                                        measurement.startValue ??
                                                        0}{" "}
                                                    / {measurement.endValue}{" "}
                                                    {measurement.unit?.name}
                                                </div>
                                            }
                                        />
                                        <FormattedDueDate
                                            date={measurement.endDate}
                                        />
                                    </div>
                                    <InputWrapper
                                        wrapperLabel={t(k.INVOLVED_TEAMS)}
                                        boldLabel
                                    >
                                        {showInvolvedTeamsEditableList ? (
                                            <TeamsListById
                                                icon={null}
                                                teamIds={
                                                    measurement.involvedTeamsIds
                                                }
                                                isTeamEveryone={
                                                    measurement.isTeamEveryone
                                                }
                                            />
                                        ) : (
                                            t(k.NO_ONE_ASSIGNED)
                                        )}
                                    </InputWrapper>
                                    <InputWrapper
                                        wrapperLabel={t(k.CAN_VIEW)}
                                        boldLabel
                                    >
                                        {showTeamsCanViewEditableList ? (
                                            <div className="measurement-form--body--progress-row--item">
                                                <TeamsListById
                                                    icon={null}
                                                    teamIds={
                                                        measurement.teamsIdsCanView
                                                    }
                                                    isTeamEveryone={
                                                        measurement.isTeamEveryoneCanView
                                                    }
                                                />
                                                <SelectedUsersAvatarList
                                                    size={24}
                                                    users={
                                                        usersCanViewData ?? []
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            t(k.NO_ONE_ASSIGNED)
                                        )}
                                    </InputWrapper>
                                </div>
                            )}
                            {showDescriptionCard && (
                                <InfoCard
                                    label={t(k.DESCRIPTION)}
                                    childrenClassName="goal-form--body--description"
                                >
                                    <div className="timeframe">
                                        {editMode ? (
                                            <>
                                                <InputComponent
                                                    id={`measurement.${measurement.id}.startDate`}
                                                    testId="measurement_startDate_input"
                                                    inputType={
                                                        EActivityType.DateTime
                                                    }
                                                    wrapperLabel={t(k.START)}
                                                    value={
                                                        measurement.startDate
                                                    }
                                                    name="startDate"
                                                    showTimeSelect={false}
                                                    placeholder={t(k.START)}
                                                    hideIcon
                                                    noMinWidth
                                                    shouldCloseOnSelect
                                                    onChange={
                                                        handleOnChangeStartDate
                                                    }
                                                    invalid={
                                                        !!errors?.[
                                                            `measurement.${measurement.id}.startDate`
                                                        ]
                                                    }
                                                    errors={getLocaleString(
                                                        t,
                                                        errors?.[
                                                            `measurement.${measurement.id}.startDate`
                                                        ],
                                                    )}
                                                />

                                                <InputComponent
                                                    testId="measurement_endDate_input"
                                                    inputType={
                                                        EActivityType.DateTime
                                                    }
                                                    value={measurement.endDate}
                                                    name="endDate"
                                                    showTimeSelect={false}
                                                    wrapperLabel={t(k.DUE)}
                                                    placeholder={t(k.DUE)}
                                                    hideIcon
                                                    noMinWidth
                                                    shouldCloseOnSelect
                                                    onChange={
                                                        handleOnChangeEndDate
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {measurement.startDate && (
                                                    <div className="timeframe--unit">
                                                        <div className="timeframe--unit--label">
                                                            {t(k.START)}:{" "}
                                                        </div>
                                                        <FormattedDate
                                                            onlyDate
                                                            showMonthName
                                                            shortMonthName
                                                            date={
                                                                measurement.startDate
                                                            }
                                                        />{" "}
                                                    </div>
                                                )}
                                                {measurement.endDate && (
                                                    <div className="timeframe--unit">
                                                        <div className="timeframe--unit--label">
                                                            {t(k.END)}:{" "}
                                                        </div>
                                                        <FormattedDate
                                                            onlyDate
                                                            showMonthName
                                                            shortMonthName
                                                            date={
                                                                measurement.endDate
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <InputComponent
                                        inputType={EActivityType.Textfield}
                                        boldLabel
                                        hideIcon
                                        preview={!editMode}
                                        value={
                                            !editMode
                                                ? (measurement.description ??
                                                  t(k.NO_DESCRIPTION_PROVIDED))
                                                : (measurement.description ??
                                                  "")
                                        }
                                        onChange={handleOnChangeDescription}
                                        multiline
                                        minRows={5}
                                        testId="measurement-form--description"
                                        placeholder={t(k.ENTER_HERE)}
                                    />
                                </InfoCard>
                            )}
                            <ActionPlan
                                isDraggable
                                measurementId={
                                    createNew ? undefined : measurement.id
                                }
                                editMode={editMode}
                                canEdit={canEdit}
                                disableEdit={!!measurement.archivedAt}
                                canEditInPreview={canEditInPreview}
                                inputActionSets={actionSets}
                                inputActions={actionListItems}
                                setInputActionSets={setActionSets}
                                setInputActions={setActionListItems}
                                setActiveTransactions={setActiveTransactions}
                                onHaveChanges={props.onHaveChanges}
                                onSortedActionSets={handleOnSortedActionSets}
                                onHavePreviewChanges={
                                    handleOnHavePreviewChanges
                                }
                                onMeasurementRefetch={
                                    handleOnRefetchMeasurement
                                }
                                onSave={handleSave}
                            />
                            {!editMode && measurement.id !== "noData" && (
                                <MeasurementChart
                                    isProgressDirectionUp={
                                        measurement.endValue >
                                        measurement.startValue
                                    }
                                    measurementId={measurement.id}
                                    emptyContent={
                                        <div className="empty-chart">
                                            <div>
                                                {t(k.NO_PROGRESS_TO_SHOW)}
                                            </div>
                                            {canEditInPreview && (
                                                <Button
                                                    onClick={
                                                        handleUpdateProgress
                                                    }
                                                    transparent
                                                >
                                                    {t(k.ADD_PROGRESS)}
                                                </Button>
                                            )}
                                        </div>
                                    }
                                />
                            )}
                        </>
                    )}
                </ModalContent.Body>

                <ModalContent.Footer className="measurement-form--footer">
                    {createNew || !editMode ? (
                        <Button
                            onClick={handleOnClose}
                            variant="danger"
                            transparent
                        >
                            {t(k.CLOSE)}
                        </Button>
                    ) : (
                        <Button
                            onClick={handleOnCancel}
                            variant="danger"
                            transparent
                        >
                            {t(k.CANCEL)}
                        </Button>
                    )}

                    {editMode ? (
                        <Button onClick={handleSave} variant="success">
                            {t(k.SAVE)}
                        </Button>
                    ) : (
                        <>
                            {canEdit && (
                                <div className="buttons">
                                    {!createNew && (
                                        <>
                                            {!isArchived && (
                                                <>
                                                    <Button
                                                        onClick={
                                                            handleViewAllProgress
                                                        }
                                                        transparent
                                                    >
                                                        {t(
                                                            k.VIEW_ALL_PROGRESSES,
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={
                                                            handleUpdateProgress
                                                        }
                                                        transparent
                                                    >
                                                        {t(k.ADD_PROGRESS)}
                                                    </Button>
                                                </>
                                            )}
                                            <Button
                                                onClick={handleArchive}
                                                variant="bordered"
                                                transparent
                                            >
                                                {isArchived
                                                    ? t(k.UN_ARCHIVE)
                                                    : t(k.ARCHIVE)}
                                            </Button>
                                        </>
                                    )}
                                    {isArchived ? (
                                        <>
                                            {canDelete && (
                                                <Button
                                                    onClick={handleDelete}
                                                    variant="danger"
                                                >
                                                    {t(k.DELETE)}
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {!actionPlanHasChanges && (
                                                <Button onClick={handleOnEdit}>
                                                    {t(k.EDIT)}
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </ModalContent.Footer>
            </ModalContent>
        </>
    );
};

export default MeasurementForm;
