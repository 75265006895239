import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SortEnd } from "react-sortable-hoc";

import AttachmentList from "common/components/attachment-list/AttachmentList";
import CustomListIcon from "common/components/icons/icon-custom-list/CustomListIcon";
import ImagePreview from "common/components/images/image-preview/ImagePreview";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Checkbox from "common/components/input-components/checkbox/Checkbox";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import { arrayMoveTo } from "common/utils/arrayUtils";
import ActivityDetails from "components/common/activities/ActivityDetails";
import ActivityFieldByType from "components/common/activities/ActivityFieldByType";
import ActivityPhotoInput from "components/common/activities/ActivityPhotoInput";
import SortableAttachmentsList from "components/common/activities/SortableAttachmentsList";
import ActivityDeleteButton from "components/common/activities/common/ActivityDeleteButton";
import { ActivityTitlePreview } from "components/common/activities/common/ActivityTitlePreview";
import GenericPropertiesForm from "components/common/shared-properties/GenericPropertiesForm";
import ActivityListData from "components/common/shared-properties/components/ActivityListData";
import { IListItem } from "components/common/shared-properties/components/AddPropertyButton";
import TitleWithValidation from "components/common/validation/TitleWithValidation";
import ValidationLabel from "components/common/validation/ValidationLabel";
import {
    useCustomListNamesByIds,
    useCustomListOptions,
} from "components/custom-list-page/api/hooks";
import { multiSelectEnabledTypes } from "components/improvement-forms/components/ImprovementForm/utils";
import { InfoIcon } from "common/components/icons";
import { IAddActivityParam } from "components/steps/api/IAddActivityParam";
import IActivityInputListItem from "http/requests/IActivityInputListItem";
import { getLocaleString } from "i18n/components/GetLocaleString";
import k from "i18n/keys";
import IActivity from "models/IActivity";
import IAttachment from "models/IAttachment";
import { ActivityType } from "models/enums/ActivityType";
import { IAppState } from "store/IAppState";
import { v4 as uuidv4 } from "uuid";

import TemplateFormContext from "../../../context/TemplateFormContext";

import "./EditableActivityList.scss";

interface IProps {
    stepId: string;
    stepIndex: number;
    stepDisabled?: boolean;
    hideExtraDescriptions?: boolean;
}

const EditableActivityList = (props: React.PropsWithChildren<IProps>) => {
    const { stepId, stepIndex, stepDisabled, hideExtraDescriptions } = props;

    const userDefinedTasks = useSelector((appState: IAppState) => {
        return (
            appState.authViewState.profile?.appFeatures?.userDefinedTasks ===
            true
        );
    });

    const {
        setSteps,
        activities,
        setActivities,
        attachments,
        setAttachments,
        activityInputs,
        setActivityInputs,
        photos,
        setPhotos,
        errors,
        setErrors,
        onHaveChanges,
    } = useContext(TemplateFormContext);

    const { t } = useTranslation();

    const customListIds = React.useMemo(() => {
        const ids = activities?.ids
            .map((id) =>
                activities.values[id]?.param
                    ? undefined
                    : activities.values[id]?.customListId,
            )
            .filter((x) => x) as string[];

        if (ids.length > 0) {
            return ids;
        }
    }, [activities]);

    const { data: customListNamesByIds } =
        useCustomListNamesByIds(customListIds);

    const { data: customListData } = useCustomListOptions();

    const customListOptions = customListData?.values;

    const handleStepIsChanged = () => {
        if (setSteps) {
            setSteps((prev) => {
                const step = prev.values[stepId];

                if (step) {
                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [stepId]: {
                                ...step,
                                isChanged: true,
                            },
                        },
                    };
                }
                return prev;
            });
        }
    };

    const handleAddActivity = (addParam: IAddActivityParam, index: number) => {
        const { label, type, id } = addParam;

        const activity: IActivity = {
            id: uuidv4(),
            label: label ?? "",
            type,
            isRequired: false,
            acceptMultiple: false,
            multiSelectEnabled: multiSelectEnabledTypes.has(type),
            attachments: [],
            customListId: id,
            param: addParam,
            enableUserDefinedActivityInstances: userDefinedTasks,
        };

        if (setActivities) {
            setActivities((prev) => {
                const newIds =
                    prev.parents?.["activityIdsByStepId"]?.[stepId] ?? [];

                newIds?.splice(index + 1, 0, activity.id);

                return {
                    ...prev,
                    values: { ...prev.values, [activity.id]: activity },
                    ids: [...prev.ids, activity.id],
                    parents: {
                        ...prev.parents,
                        activityIdsByStepId: {
                            ...(prev.parents?.activityIdsByStepId ?? {}),
                            [stepId]: newIds,
                        },
                    },
                };
            });

            const nameKey = `step.${stepId}.activities`;
            const nameError = errors?.[nameKey];

            if (nameError) {
                setErrors((prev) => ({
                    ...prev,
                    [nameKey]: undefined,
                }));
            }

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const handleChangeActivity = (activity: IActivity) => {
        if (setActivities) {
            setActivities((prev) => ({
                ...prev,
                values: {
                    ...prev.values,
                    [activity.id]: activity,
                },
            }));

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const handleChangePhoto = (
        activityId: string,
        e: IInputChangeEvent<string>,
    ) => {
        if (setPhotos) {
            setPhotos((prev) => ({
                ...prev,

                [activityId]: {
                    ...prev[activityId],
                    value: e.value,
                    isDeleted: e.value === "",
                },
            }));

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const handleSaveAttachments = (
        activityId: string,
        attachments: IAttachment[],
    ) => {
        if (setAttachments) {
            setAttachments((prev) => {
                const mappedAttachments = attachments.reduce<
                    Record<string, IAttachment>
                >((acc, attachment) => {
                    acc[attachment.id] = attachment;
                    return acc;
                }, {});

                return {
                    ...prev,
                    values: { ...prev.values, ...mappedAttachments },
                    parents: {
                        ...prev.parents,
                        attachmentsIdsByActivityId: {
                            ...(prev.parents?.attachmentsIdsByActivityId ?? {}),
                            [activityId]: attachments.map((a) => a.id),
                        },
                    },
                };
            });

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    const onActivityDelete = (id: string) => {
        if (setActivities) {
            setActivities((prev) => {
                const activity = prev.values[id];

                if (activity) {
                    handleStepIsChanged();
                    onHaveChanges(true);

                    return {
                        ...prev,
                        values: {
                            ...prev.values,
                            [id]: {
                                ...activity,
                                isDeleted: !activity.isDeleted,
                            },
                        },
                    };
                }
                return prev;
            });
        }
    };

    const handleTemplateListSorted = (params: SortEnd) => {
        if (setActivities) {
            setActivities((prev) => {
                const oldIds = prev.parents?.activityIdsByStepId?.[stepId];

                if (oldIds) {
                    handleStepIsChanged();
                    onHaveChanges(true);

                    return {
                        ...prev,
                        parents: {
                            ...prev.parents,
                            activityIdsByStepId: {
                                ...(prev.parents?.activityIdsByStepId ?? {}),
                                [stepId]: arrayMoveTo(
                                    oldIds,
                                    params.oldIndex,
                                    params.newIndex,
                                ),
                            },
                        },
                    };
                }

                return prev;
            });
        }
    };

    const handleChangeActivityInputList = (
        activityId: string,
        inputList: IActivityInputListItem[],
    ) => {
        if (setActivityInputs) {
            setActivityInputs((prev) => {
                const newValues = inputList.reduce<
                    Record<string, IActivityInputListItem>
                >((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {});

                const values = { ...prev.values, ...newValues };

                return {
                    ...prev,
                    ids: Object.keys(values),
                    values,
                    parents: {
                        ...prev.parents,
                        activityInputsIdsByActivityId: {
                            ...(prev.parents?.activityInputsIdsByActivityId ??
                                {}),
                            [activityId]: inputList.map((i) => i.id),
                        },
                    },
                };
            });

            handleStepIsChanged();
            onHaveChanges(true);
        }
    };

    return (
        <div className="editable-activity-list">
            <GenericPropertiesForm
                testId="editable-activity-list"
                title={
                    <TitleWithValidation
                        id={`step.${stepId}.activities`}
                        title={t(k.ACTIVITIES)}
                        bold
                        errors={getLocaleString(
                            t,
                            errors?.[`step.${stepId}.activities`],
                        )}
                    />
                }
                itemIds={activities?.parents?.activityIdsByStepId?.[stepId]}
                listData={
                    customListOptions
                        ? [
                              ActivityListData,
                              {
                                  items: customListOptions.ids.reduce<
                                      IListItem<ActivityType>[]
                                  >((acc, id) => {
                                      const item = customListOptions.values[id];
                                      if (item) {
                                          acc.push({
                                              type: ActivityType.CustomList,
                                              id: item.id,
                                              title: item.name,
                                              subTitle: item.description,
                                              icon: (
                                                  <CustomListIcon
                                                      showBackground
                                                  />
                                              ),
                                          });
                                      }
                                      return acc;
                                  }, []),
                                  sectionTitle: k.CUSTOM_LISTS,
                              },
                          ]
                        : [ActivityListData]
                }
                buttonTitle={k.ADD_ACTIVITIES}
                itemClassName="step-activity"
                hideOrder
                onAddItem={handleAddActivity}
                sortDisabled={stepDisabled}
                addDisabled={stepDisabled}
                onSorted={handleTemplateListSorted}
                renderTemplate={(itemId, index) => {
                    const item = activities?.values[itemId];

                    if (!item) {
                        return <></>;
                    }

                    const activityList =
                        activityInputs?.parents?.activityInputsIdsByActivityId?.[
                            itemId
                        ]?.reduce<IActivityInputListItem[]>((acc, id) => {
                            const item = activityInputs.values[id];
                            if (item) {
                                acc.push(item);
                            }
                            return acc;
                        }, []);

                    const attachmentList =
                        attachments?.parents?.attachmentsIdsByActivityId?.[
                            itemId
                        ]?.reduce<IAttachment[]>((acc, id) => {
                            const item = attachments.values[id];
                            if (item) {
                                acc.push(item);
                            }
                            return acc;
                        }, []);

                    const photo = photos?.[item.id];

                    const handleOnChange = <V,>(e: IInputChangeEvent<V>) => {
                        const key = e.name;

                        if (key) {
                            handleChangeActivity({
                                ...item,
                                [key]: e.value,
                            });
                        }
                    };

                    const handleOnDelete = () => {
                        onActivityDelete(item.id);
                    };

                    const handleOnChangeList = (
                        activityId: string,
                        inputList: IActivityInputListItem[],
                        onInitialize?: boolean,
                    ) => {
                        handleChangeActivityInputList(activityId, inputList);
                    };

                    const handleOnChangePhoto = (
                        e: IInputChangeEvent<string>,
                    ) => {
                        handleChangePhoto(item.id, e);
                    };

                    const handleOnSaveAttachments = (
                        attachments: IAttachment[],
                    ) => {
                        handleSaveAttachments(item.id, attachments);
                    };

                    let customListName: string = "";
                    if (item.type === ActivityType.CustomList) {
                        customListName = item.param
                            ? (item.param.label ?? "")
                            : item.customListId
                              ? (customListNamesByIds?.[item.customListId] ??
                                "")
                              : t(k.NOT_AVAILABLE_MESSAGE);
                    }

                    return (
                        <ActivityFieldByType
                            isEditMode={!item.isDeleted && !stepDisabled}
                            activity={item}
                            activityList={activityList}
                            onChangeList={handleOnChangeList}
                            errors={errors}
                            userDefinedTasks={userDefinedTasks}
                            onChange={handleOnChange}
                            typeField={({ typeList, typeLabel }) => (
                                <div
                                    className="step-activity-container"
                                    data-testid={`activity-${index}`}
                                >
                                    {item.type === ActivityType.CustomList && (
                                        <div className="step-activity-container__custom-list">
                                            <strong>{t(k.CUSTOM_LIST)}:</strong>
                                            {customListName}
                                            <InfoIcon
                                                tooltip={{
                                                    id:
                                                        item.id +
                                                        "_" +
                                                        customListName +
                                                        "_accessInfo",
                                                    message: t(
                                                        k.CUSTOM_LIST_OPTIONS_IN_CHECKLIST_WARNING,
                                                    ),
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div className="step-activity-container--head">
                                        {item.isDeleted || stepDisabled ? (
                                            <ActivityTitlePreview
                                                id={`activity.${item.id}.label`}
                                                className="order"
                                                order={`${stepIndex}.${
                                                    index + 1
                                                }`}
                                                defaultLabel={typeLabel}
                                                hasError={Boolean(
                                                    errors?.[
                                                        `activity.${item.id}.label`
                                                    ],
                                                )}
                                                activity={item}
                                            />
                                        ) : (
                                            <React.Fragment>
                                                <div className="order">
                                                    {stepIndex}.{index + 1}
                                                </div>

                                                <TextfieldComplex
                                                    size="large"
                                                    hideIcon
                                                    id={`activity.${item.id}.label`}
                                                    testId="label"
                                                    name="label"
                                                    value={item.label}
                                                    placeholder={typeLabel}
                                                    onChange={handleOnChange}
                                                    invalid={Boolean(
                                                        errors?.[
                                                            `activity.${item.id}.label`
                                                        ],
                                                    )}
                                                    bold
                                                    postInputContent={
                                                        <div className="input-required-container">
                                                            {!item.isDeleted &&
                                                            !stepDisabled &&
                                                            item.type !==
                                                                ActivityType.Tasklist ? (
                                                                <Checkbox
                                                                    id="isRequired"
                                                                    name="isRequired"
                                                                    label={t(
                                                                        k.REQUIRED,
                                                                    )}
                                                                    value={
                                                                        item.isRequired ||
                                                                        false
                                                                    }
                                                                    onChange={
                                                                        handleOnChange
                                                                    }
                                                                />
                                                            ) : (
                                                                <ActivityDetails
                                                                    id={`activity.${item.id}.label`}
                                                                    isRequired={
                                                                        item.isRequired &&
                                                                        item.type !==
                                                                            ActivityType.Tasklist
                                                                    }
                                                                    hasError={Boolean(
                                                                        errors?.[
                                                                            `activity.${item.id}.label`
                                                                        ],
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    }
                                                    helperText={
                                                        <ValidationLabel
                                                            id={`activity.${item.id}.label`}
                                                            errors={
                                                                errors?.[
                                                                    `activity.${item.id}.label`
                                                                ]
                                                            }
                                                        />
                                                    }
                                                />
                                            </React.Fragment>
                                        )}

                                        {!stepDisabled && (
                                            <ActivityDeleteButton
                                                testId="delete-btn"
                                                isDeleted={item.isDeleted}
                                                onDelete={handleOnDelete}
                                            />
                                        )}
                                    </div>

                                    {!hideExtraDescriptions && (
                                        <>
                                            {!item.isDeleted &&
                                            !stepDisabled ? (
                                                <ActivityPhotoInput
                                                    id={`photo-${item.id}`}
                                                    testId="photo-input"
                                                    photoId={
                                                        item.uploadedFileId
                                                    }
                                                    value={photo}
                                                    onChange={
                                                        handleOnChangePhoto
                                                    }
                                                />
                                            ) : (
                                                photo &&
                                                (((photo.isDeleted ?? false) ===
                                                    false &&
                                                    item.uploadedFileId) ||
                                                    photo?.value) && (
                                                    <div className="activity-photo-preview">
                                                        <ImagePreview
                                                            value={photo.value}
                                                            imgId={
                                                                item.uploadedFileId
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                            {(!item.isDeleted ||
                                                item.description) && (
                                                <TextfieldComplex
                                                    hideIcon
                                                    multiline
                                                    id="description"
                                                    name="description"
                                                    value={
                                                        item.description ?? ""
                                                    }
                                                    placeholder={t(
                                                        k.DESCRIPTION,
                                                    )}
                                                    innerLabelContent={t(
                                                        k.DESCRIPTION,
                                                    )}
                                                    onChange={handleOnChange}
                                                    preview={
                                                        item.isDeleted ||
                                                        stepDisabled
                                                    }
                                                />
                                            )}
                                        </>
                                    )}

                                    {!item.isDeleted && !stepDisabled ? (
                                        <SortableAttachmentsList
                                            attachments={attachmentList ?? []}
                                            itemClassName="editable-activity-list"
                                            onSave={handleOnSaveAttachments}
                                        />
                                    ) : (
                                        <AttachmentList
                                            attachments={attachmentList}
                                        />
                                    )}

                                    {typeList}
                                </div>
                            )}
                        />
                    );
                }}
            />
        </div>
    );
};

export default EditableActivityList;
