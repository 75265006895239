import axios from "axios";

import { ISharedOptionRequest } from "./ISharedOptionRequest";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

export enum ESharedOptionsType {
    "customer" = "customer",
    "supplier" = "supplier",
    "item" = "item",
    "user" = "user",
    "position" = "position",
    "competency" = "competency",
    "team" = "team",
    "equipment" = "equipment",
}

export const useSharedOptions = (
    enabled: boolean,
    type?: ESharedOptionsType,
    selectedIds?: string[],
) => {
    const url = `/api/${type}/shared`;

    return useQuery({
        queryKey: ["shared-options", type, selectedIds ?? []],

        queryFn: async ({ signal }) => {
            if (type) {
                const request: ISharedOptionRequest = {
                    ids: selectedIds ?? [],
                };

                const response = await axios.post<IValueLabelItemWithState[]>(
                    url,
                    request,
                    {
                        signal,
                    },
                );

                return response.data;
            }
        },

        enabled,
        placeholderData: keepPreviousData,
        //keepPreviousData: true,
    });
};
