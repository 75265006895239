import "./FilterDropdown.scss";

import React, { useState } from "react";

import ReactDOM from "react-dom";
import { Dropdown } from "react-overlays";

import { FilterMenu } from "./components/FilterMenu";
import { FilterToggle } from "./components/FilterToggle";

interface IProps {
    isActive: boolean;
    isEdited: boolean;
    testId?: string;

    commandClassName?: string;
    commandDescription?: React.ReactNode;

    onFilter: (isReset?: boolean) => void;
    onToggle: (open: boolean) => void;
}

export const FilterDropdown = (props: React.PropsWithChildren<IProps>) => {
    const { isActive, isEdited, testId, commandClassName, commandDescription } =
        props;

    const [open, setOpen] = useState(false);

    const handleToggle = (
        nextShow: boolean,
        e?: Event | React.SyntheticEvent<Element, Event>,
    ) => {
        e?.stopPropagation();

        setOpen(nextShow);

        props.onToggle(nextShow);
    };

    const portalRoot = document.getElementById("root");

    return (
        <Dropdown show={open} onToggle={handleToggle} alignEnd={true}>
            <div className="app-table--header-filter">
                <FilterToggle isActive={isActive} testId={testId} />
                {portalRoot
                    ? ReactDOM.createPortal(
                          <FilterMenu
                              testId={testId}
                              isActive={isActive}
                              isEdited={isEdited}
                              commandClassName={commandClassName}
                              commandDescription={commandDescription}
                              onFilter={props.onFilter}
                          >
                              {props.children}
                          </FilterMenu>,
                          portalRoot,
                      )
                    : null}
            </div>
        </Dropdown>
    );
};
