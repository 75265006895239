import "./HeaderFilters.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { AiOutlineStop } from "react-icons/ai";
import { useSelector } from "react-redux";

import k from "i18n/keys";

import { ViewMode } from "./ChecklistsTable";
import ToggleView from "./ToggleView";
import IChanged from "common/IChanged";
import { IValueLabelItem } from "common/IValueLabelItem";
import Button from "common/components/buttons/Button";
import { FilterIcon, SearchIcon } from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import InputWrapper from "common/components/input-components/InputWrapper";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";
import { DEFAULT_DELAY_INTERVAL_MS } from "common/utils/time";
import TemplateMultiselect from "components/common/multiselect-dropdowns/TemplateMultiselect";
import BarcodeScanAppend from "components/template-checklist-report-search/components/BarcodeScanAppend";
import IChecklistReportFilter from "components/template-checklist-report/api/IChecklistReportFilter";
import { IAppState } from "store/IAppState";

interface IProps {
    canStopMultipleChecklists?: boolean;

    value: IChecklistReportFilter;
    viewMode: ViewMode;

    totalCountToStop?: number;

    onChange: (value: IChanged<IChecklistReportFilter>) => void;

    isPausingAll: boolean;

    onPauseAll: (value: IChecklistReportFilter) => void;

    onFetchTemplateList: (
        options: Array<IValueLabelItem<string, string>>,
    ) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
}

const HeaderFilters: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        canStopMultipleChecklists,
        totalCountToStop,
        isPausingAll,
        viewMode,
    } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState<IChecklistReportFilter>(props.value);

    const barcodeFeatureEnabled = useSelector(
        (state: IAppState) =>
            state.authViewState.profile?.appFeatures?.barcodeActivity,
    );

    const timelineFeatureEnabled = useSelector(
        (state: IAppState) =>
            state.authViewState.profile?.appFeatures
                ?.checklistReportTimelineView,
    );

    useEffect(() => {
        setValue((prev) =>
            prev.keywordSearch === props.value.keywordSearch
                ? prev
                : {
                      ...prev,
                      keywordSearch: props.value.keywordSearch,
                  },
        );
    }, [props.value.keywordSearch]);

    useEffect(() => {
        if (value === props.value) {
            return;
        }

        const timer = setTimeout(() => {
            const isChanged =
                Boolean(value.keywordSearch) ||
                (value.templateVersionIds?.length ?? 0) > 0;

            props.onChange({ value, isChanged });
        }, DEFAULT_DELAY_INTERVAL_MS);

        return () => clearTimeout(timer);
    }, [value]);

    const handleOnChangeKeywordFilter = (e: IInputChangeEvent<string>) => {
        setValue((prev) => ({
            ...prev,
            keywordSearch: e.value,
        }));
    };

    const handleScanBarcode = (barcode: string) => {
        setValue((prev) => ({
            ...prev,
            keywordSearch: barcode,
        }));
    };

    const handleOnChangeTemplates = (newValues: string[]) => {
        setValue((prev) => ({
            ...prev,
            templateVersionIds: newValues,
        }));
    };

    const handlePauseAll = () => {
        props.onPauseAll(value);
    };

    const templateNotSelected =
        (props.value.templateVersionIds?.length ?? 0) === 0;

    const stopChecklistIsHidden =
        isPausingAll ||
        (totalCountToStop ?? 0) === 0 ||
        (Boolean(props.value.keywordSearch) === false &&
        templateNotSelected);

    return (
        <div className="template-checklists-report__header-filters">
            {canStopMultipleChecklists && stopChecklistIsHidden === false && (
                <div className="stop-checklists-btn">
                    <Button
                        size="small"
                        variant="danger"
                        onClick={handlePauseAll}
                    >
                        <AiOutlineStop />
                        {t(k.STOP_MATCHING_CHECKLISTS)}

                        {totalCountToStop ? (
                            <strong>({totalCountToStop})</strong>
                        ) : undefined}
                    </Button>
                </div>
            )}

            <InputComponent
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.SEARCH)}
                wrapperIcon={<SearchIcon />}
                id="filter.search"
                value={value.keywordSearch ?? ""}
                placeholder={t(k.SEARCH)}
                onChange={handleOnChangeKeywordFilter}
                hideIcon={true}
                size="large"
                autoCompleteOff
                postInputContent={
                    barcodeFeatureEnabled && (
                        <BarcodeScanAppend onChange={handleScanBarcode} />
                    )
                }
                autoFocus={true}
            />

            <InputWrapper
                wrapperLabel={t(k.FILTER_BY_TEMPLATES)}
                wrapperIcon={<FilterIcon />}
            >
                <TemplateMultiselect
                    values={value.templateVersionIds}
                    onChange={handleOnChangeTemplates}
                    onFetch={props.onFetchTemplateList}
                />
            </InputWrapper>

            <ToggleView
                timelineFeatureEnabled={timelineFeatureEnabled}
                templateNotSelected={templateNotSelected}
                viewMode={viewMode}
                onChange={props.onViewModeChange}
            />
        </div>
    );
};

export default HeaderFilters;
