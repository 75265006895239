import "./WarningBadge.scss";

import Badge, { IBaseBadgeProps } from "../Badge";
import { PropsWithChildren } from "react";

export interface IWarningBadgeProps extends IBaseBadgeProps {}

const WarningBadge = (props: PropsWithChildren<IWarningBadgeProps>) => {
    const { children, ...rest } = props;

    return (
        <Badge className="warning-badge" {...rest}>
            {children}
        </Badge>
    );
};

export default WarningBadge;
