import axios from "axios";

import { IChecklistReportExpandableColumnRequest } from "./IChecklistReportExpandableColumnRequest";
import { IChecklistReportExpandableColumnResponse } from "./IChecklistReportExpandableColumnResponse";
import { ITemplateReportActivityTitle } from "components/template-checklist-report/api/ITemplateReportActivity";
import { TemplateReportValuesByColumnKeyIdType } from "components/template-checklist-report/api/TemplateReportValuesByColumnKeyIdType";
import IChecklistReportTableMatrixResponse, {
    ChecklistReportMatrixCellType,
    ChecklistReportMatrixRowSetType,
    ChecklistReportMatrixRowType,
} from "components/template-checklist-report/components/report-table-details/api/IChecklistReportResponse";
import { ChecklistReportValueSumFilterColumnType } from "components/template-checklist-report/components/report-table-details/api/IChecklistReportValueSumFilter";
import { ActivityType } from "models/enums/ActivityType";

const initialTableMatrixValue: IChecklistReportTableMatrixResponse = {
    checklistIds: [],

    headerFirst: {
        stepInstanceIds: [],
        colSpanByStepInstanceId: {},
        labelByStepInstanceId: {},
    },

    headerSecond: {
        columns: [],
    },

    rowSetByChecklistId: {},

    uniqueSelectedCustomListItemIds: [],

    valueColumns: [],
    hasValueColumn: false,
};

type ExpandedColumnByIndexType = {
    columnIndex: number;
    column: ITemplateReportActivityTitle | undefined;
};

export class TableMatrixCalculator {
    private _tokenVersion: number = 0;
    private _anyExpandedColumn: boolean = false;
    private readonly baseUrl: string = "/api/workflowRun";

    private initialValue: IChecklistReportTableMatrixResponse =
        initialTableMatrixValue;
    private value: IChecklistReportTableMatrixResponse =
        initialTableMatrixValue;

    private expandedColumnsByColumnKeyId: Record<
        string,
        Array<ITemplateReportActivityTitle | undefined> | undefined
    > = {};

    private onChange: (
        value: IChecklistReportTableMatrixResponse | null,
    ) => void;

    /**
     *
     */
    constructor(
        onChange: (value: IChecklistReportTableMatrixResponse | null) => void,
    ) {
        this.onChange = onChange;
    }

    private getMatrixValues = () => {
        return { ...this.value };
    };

    public setup = (response: IChecklistReportTableMatrixResponse) => {
        this._tokenVersion++;

        this.initialValue = response;

        this.value = this.cloneInitialValue();

        this.tryBindExpandedColumns();

        this.onChange(this.getMatrixValues());
    };

    public reset = () => {
        this._tokenVersion = 0;

        this.initialValue = initialTableMatrixValue;

        this.value = initialTableMatrixValue;

        this.onChange(null);
    };

    private tryBindExpandedColumns = () => {
        if (!this._anyExpandedColumn) {
            return;
        }

        const { checklistIds, rowSetByChecklistId, headerSecond } = this.value;

        const headerColumns = headerSecond.columns;

        const defaultLevel = 1;

        for (
            let columnIndex = headerColumns.length - 1;
            columnIndex > 0;
            columnIndex--
        ) {
            const column = headerColumns[columnIndex];

            if (!column?.expandInfo) {
                continue;
            }

            const { stepInstanceId } = column.expandInfo;

            const addedColumns = (
                this.expandedColumnsByColumnKeyId[column.columnKeyId] ?? []
            ).map((x) => {
                const addedColumn = this.mapColumn(x);

                if (addedColumn?.expandInfo) {
                    addedColumn.expandInfo.isBusy = true;

                    addedColumn.expandInfo.stepInstanceId = stepInstanceId;
                }

                return addedColumn;
            });

            if (addedColumns.length > 0) {
                headerColumns.splice(columnIndex + 1, 0, ...addedColumns);

                column.expandInfo.isExpanded = true;
                column.expandInfo.level = defaultLevel;

                const addedCellValues =
                    addedColumns.map<ChecklistReportMatrixCellType>(
                        (_) => ({}),
                    );

                for (const checklistId of checklistIds) {
                    const primaryRow =
                        rowSetByChecklistId[checklistId]?.primaryRow;

                    if (primaryRow) {
                        primaryRow.cellValues.splice(
                            columnIndex + 1,
                            0,
                            ...addedCellValues,
                        );

                        primaryRow.cellValues[columnIndex].isCellExpanded =
                            true;
                    }
                }
            }
        }

        this.bindHeaderFirst();

        this.bindValueColumns();

        this.fetchExpandedColumnsRecursively(
            0,
            this.getExpandedColumnsByLevel(defaultLevel),
            this._tokenVersion,
        );
    };

    private getExpandedColumnsByLevel = (level: number) => {
        const headerColumns = this.value.headerSecond.columns;

        const result = headerColumns
            .map<ExpandedColumnByIndexType>((x, i) => ({
                columnIndex: i,
                column: x,
            }))
            .filter(
                (x) =>
                    x.column?.expandInfo?.isExpanded &&
                    x.column.expandInfo.level === level,
            );

        return result;
    };

    private fetchExpandedColumnsRecursively = (
        index: number,
        columns: ExpandedColumnByIndexType[],
        token: number,
    ) => {
        if (columns.length === 0 || this._tokenVersion !== token) {
            return;
        }

        if (index >= columns.length) {
            const currentLevel = columns[0].column?.expandInfo?.level ?? 0;

            const nextLevelColumns = this.getExpandedColumnsByLevel(
                currentLevel + 1,
            );

            this.fetchExpandedColumnsRecursively(0, nextLevelColumns, token);

            return;
        }

        const item = columns[index];

        if (item.column) {
            const { columnIndex, column } = item;

            this.fetchExpandedColumnsAsync(columnIndex, column).then(
                (response) => {
                    if (this._tokenVersion !== token) {
                        return;
                    }

                    if (column.expandInfo) {
                        column.expandInfo.isBusy = false;
                    }

                    if (response) {
                        const endIndex = this.getColumnStopIndex(
                            columnIndex,
                            column,
                        );

                        const expandedColumns =
                            this.value.headerSecond.columns.slice(
                                columnIndex + 1,
                                endIndex === -1 ? undefined : endIndex,
                            );

                        for (const expandedColumn of expandedColumns) {
                            if (
                                expandedColumn?.expandInfo &&
                                expandedColumn.expandInfo.level ===
                                    column.expandInfo?.level
                            ) {
                                expandedColumn.expandInfo.isBusy = false;
                            }
                        }

                        response.columns = expandedColumns;

                        this.deleteCellValuesFromRows(
                            columnIndex,
                            expandedColumns.length,
                        );

                        this.updateAndBindValuesOnExpand(
                            columnIndex,
                            response,
                            true,
                        );
                    }

                    this.delay(100).then((_) =>
                        this.fetchExpandedColumnsRecursively(
                            index + 1,
                            columns,
                            token,
                        ),
                    );
                },
            );
        }
    };

    private cloneTableMatrixRowSet = (
        tableMatrix: IChecklistReportTableMatrixResponse,
    ) => {
        const result: Record<
            string,
            ChecklistReportMatrixRowSetType | undefined
        > = {};

        const { checklistIds, rowSetByChecklistId } = tableMatrix;

        checklistIds.reduce((acc, checklistId) => {
            const { rowSpan, primaryRow, secondaryRows } =
                rowSetByChecklistId[checklistId] ?? {};

            acc[checklistId] = {
                rowSpan: (rowSpan ?? 0) > 1 ? rowSpan : undefined,
                primaryRow: primaryRow ? this.cloneRow(primaryRow) : undefined,
                secondaryRows: secondaryRows?.map(this.cloneRow),
            };

            return acc;
        }, result);

        return result;
    };

    private cloneRow = (row: ChecklistReportMatrixRowType) => {
        const result: ChecklistReportMatrixRowType = {
            cellValues: row.cellValues.map((x) => ({ ...x })),
        };

        return result;
    };

    private mapColumn = (column: ITemplateReportActivityTitle | undefined) => {
        if (column) {
            return {
                ...column,
                expandInfo: column.expandInfo
                    ? { ...column.expandInfo }
                    : undefined,
            };
        }
    };

    private cloneInitialValue() {
        const initialValue = this.initialValue;

        const nextRowSet = this.cloneTableMatrixRowSet(initialValue);

        const result: IChecklistReportTableMatrixResponse = {
            checklistIds: [...initialValue.checklistIds],

            headerFirst: {
                stepInstanceIds: [...initialValue.headerFirst.stepInstanceIds],
                colSpanByStepInstanceId: {
                    ...initialValue.headerFirst.colSpanByStepInstanceId,
                },
                labelByStepInstanceId: {
                    ...initialValue.headerFirst.labelByStepInstanceId,
                },
            },

            headerSecond: {
                columns: [
                    ...initialValue.headerSecond.columns.map(this.mapColumn),
                ],
            },

            rowSetByChecklistId: nextRowSet,

            uniqueSelectedCustomListItemIds: [
                ...initialValue.uniqueSelectedCustomListItemIds,
            ],

            valueColumns: [...initialValue.valueColumns],
            hasValueColumn: initialValue.hasValueColumn,
        };

        return result;
    }

    private mergeUniqueSelectedCustomListItemIds(
        newUniqueSelectedCustomListItemIds: Array<{
            key: string;
            value?: Date;
        }>,
    ) {
        if (newUniqueSelectedCustomListItemIds.length === 0) {
            return;
        }

        const allUniqueIds = newUniqueSelectedCustomListItemIds.concat(
            this.value.uniqueSelectedCustomListItemIds,
        );

        const uniqueByKeyMap = new Map(allUniqueIds.map((x) => [x.key, x]));

        this.value.uniqueSelectedCustomListItemIds = Array.from(
            uniqueByKeyMap.values(),
        );
    }

    private getNewRowCellValues(
        newColumns: Array<ITemplateReportActivityTitle | undefined>,
        rowSpan: number,
        columnValuesByColumnKeyId?: TemplateReportValuesByColumnKeyIdType,
    ) {
        const result: ChecklistReportMatrixCellType[] = [];

        newColumns.reduce((acc, column) => {
            const nextValue = column
                ? columnValuesByColumnKeyId?.[column.columnKeyId]
                : undefined;

            const cellValue: ChecklistReportMatrixCellType = {
                value: nextValue ?? undefined,
            };

            if (rowSpan > 1) {
                cellValue.rowSpan = rowSpan;
            }

            acc.push(cellValue);

            return acc;
        }, result);

        return result;
    }

    private findLeastCommonMultiple(arr: number[]) {
        arr = arr.sort((a, b) => a - b);

        let num = arr[0];

        for (let i = 1; i < arr.length; i++) {
            let j = 1;

            while (num % arr[i] !== 0) {
                j = j + 1;

                num = j * arr[0];
            }

            arr[0] = num;
        }

        return num;
    }

    private castExpandResponse(
        columnIndex: number,
        response: IChecklistReportExpandableColumnResponse,
    ) {
        const tableMatrix = this.value;

        const { checklistIds, rowSetByChecklistId } = tableMatrix;

        const result: Record<string, ChecklistReportMatrixRowType[]> = {};

        for (const checklistId of checklistIds) {
            const allRows = this.getAllRowsByChecklistId(
                tableMatrix,
                checklistId,
            );

            const allRowIds = response.rowIdsByChecklistId[checklistId] ?? [];

            const maxRowIdsCount = Math.max(
                ...allRowIds.map((x) => (x.length === 0 ? 1 : x.length)),
            );

            const expandableColumnRowSpan =
                allRows[0].cellValues[columnIndex].rowSpan ?? 1;

            const leastCommonMultiple = this.findLeastCommonMultiple([
                maxRowIdsCount,
                expandableColumnRowSpan,
            ]);

            const prevTotalRowsCount = allRows.length;

            const nextTotalRowsCount = leastCommonMultiple * allRowIds.length;

            const shouldAddNewRows = nextTotalRowsCount > prevTotalRowsCount;

            if (shouldAddNewRows) {
                const diff = nextTotalRowsCount - prevTotalRowsCount;

                for (let i = 0; i < diff; i++) {
                    const newRow = this.getNewEmptyRow(
                        tableMatrix,
                        checklistId,
                    );

                    allRows.push(newRow);
                }

                const { primaryRow, secondaryRows } =
                    rowSetByChecklistId[checklistId] ?? {};

                rowSetByChecklistId[checklistId] = {
                    rowSpan: allRows.length,
                    primaryRow,
                    secondaryRows,
                };

                const columnsCount =
                    rowSetByChecklistId[checklistId]?.primaryRow?.cellValues
                        .length ?? 0;

                const rowSpanMutliplier =
                    leastCommonMultiple / expandableColumnRowSpan;

                for (let i = 0; i < prevTotalRowsCount; i++) {
                    const rowIndex = prevTotalRowsCount - 1 - i;

                    for (let j = 0; j < columnsCount; j++) {
                        const cell = allRows[rowIndex].cellValues[j];

                        if (cell.isHidden) {
                            continue;
                        }

                        const prevRowSpan = cell.rowSpan ?? 1;

                        const nextRowSpan = prevRowSpan * rowSpanMutliplier;

                        cell.rowSpan =
                            nextRowSpan > 1 ? nextRowSpan : undefined;

                        if (rowIndex > 0) {
                            // relocate cell values

                            const newRowIndex = rowSpanMutliplier * rowIndex;

                            const emptyCell =
                                allRows[newRowIndex].cellValues[j];

                            allRows[newRowIndex].cellValues[j] = cell;

                            allRows[rowIndex].cellValues[j] = emptyCell;
                        }
                    }
                }
            }

            // add empty cells to the right of the expanded column

            for (const row of allRows) {
                row.cellValues[columnIndex].isCellExpanded = true;

                const emptyCellValues =
                    response.columns.map<ChecklistReportMatrixCellType>(() => ({
                        isHidden: true,
                    }));

                row.cellValues.splice(columnIndex + 1, 0, ...emptyCellValues);
            }

            // assign actual new cell values

            const rowIdsWithMaxWidth: Array<string | null>[] = [];

            for (let i = 0; i < allRowIds.length; i++) {
                const emptyRowIdsDiffCount =
                    maxRowIdsCount > allRowIds[i].length
                        ? maxRowIdsCount - allRowIds[i].length
                        : 0;

                const rowIds = Array.from(Array(emptyRowIdsDiffCount).keys())
                    .map<string | null>((_) => null)
                    .concat(allRowIds[i].map<string | null>((id) => id));

                rowIdsWithMaxWidth.push(rowIds);
            }

            let rowIndex: number = 0;

            const rowSpan = leastCommonMultiple / maxRowIdsCount;

            for (const rowIds of rowIdsWithMaxWidth) {
                for (const rowId of rowIds) {
                    const columnValuesByColumnKeyId = rowId
                        ? response.columnValuesByRowId[rowId]
                        : undefined;

                    const addedCellValues = this.getNewRowCellValues(
                        response.columns,
                        rowSpan,
                        columnValuesByColumnKeyId,
                    );

                    const row = allRows[rowIndex];

                    row.cellValues.splice(
                        columnIndex + 1,
                        addedCellValues.length,
                    );

                    row.cellValues.splice(
                        columnIndex + 1,
                        0,
                        ...addedCellValues,
                    );

                    rowIndex += rowSpan;
                }
            }

            result[checklistId] = allRows;
        }

        return result;
    }

    private updateAndBindValuesOnExpand(
        columnIndex: number,
        response: IChecklistReportExpandableColumnResponse,
        triggerOnChange: boolean,
    ) {
        const result = this.castExpandResponse(columnIndex, response);

        this.bindRows(result);

        this.mergeUniqueSelectedCustomListItemIds(
            response.uniqueSelectedCustomListItemIds ?? [],
        );

        if (triggerOnChange) {
            this.onChange(this.getMatrixValues());
        }
    }

    private getNewEmptyRow(
        value: IChecklistReportTableMatrixResponse,
        checklistId: string,
    ) {
        const row = {
            checklistId,
            cellValues:
                value.headerSecond.columns.map<ChecklistReportMatrixCellType>(
                    () => ({
                        isHidden: true,
                    }),
                ),
        };

        return row;
    }

    private bindHeaderFirst() {
        const colSpanByStepInstanceId: Record<string, number | undefined> = {};

        this.value.headerSecond.columns.reduce((acc, column) => {
            const { stepInstanceId } = column?.expandInfo ?? {};

            if (stepInstanceId) {
                acc[stepInstanceId] = (acc[stepInstanceId] ?? 0) + 1;
            }

            return acc;
        }, colSpanByStepInstanceId);

        this.value.headerFirst.colSpanByStepInstanceId =
            colSpanByStepInstanceId;
    }

    expandAsync = async (
        column: ITemplateReportActivityTitle,
        mustExpand: boolean,
    ) => {
        const columnIndex = this.value.headerSecond.columns.indexOf(column);

        if (columnIndex < 0) {
            return;
        }

        if (mustExpand) {
            try {
                const response = await this.fetchExpandedColumnsAsync(
                    columnIndex,
                    column,
                );

                if (response) {
                    this.expand(columnIndex, column, response);
                }
            } catch {}
        } else {
            this.collapse(columnIndex, column);
        }

        this.bindHeaderFirst();

        this.bindValueColumns();

        this.storeExpandedColumns(column, mustExpand);

        this.onChange(this.getMatrixValues());
    };

    private getExpandedColumnKeyId = (column: ITemplateReportActivityTitle) => {
        const result = column.expandInfo?.isTopLevel
            ? column.columnKeyId
            : column.expandInfo?.columnKeyIdsPath?.[0];

        return result;
    };

    private storeExpandedColumns = (
        column: ITemplateReportActivityTitle,
        mustExpand: boolean,
    ) => {
        if (column.expandInfo?.isTopLevel && mustExpand === false) {
            this.expandedColumnsByColumnKeyId[column.columnKeyId] = undefined;
        } else {
            const expandedColumnKeyId = this.getExpandedColumnKeyId(column);

            const expandedColumnStartIndex =
                this.value.headerSecond.columns.findIndex(
                    (x) => x && x.columnKeyId === expandedColumnKeyId,
                );

            if (
                expandedColumnKeyId &&
                expandedColumnStartIndex >= 0 &&
                this.value.headerSecond.columns[expandedColumnStartIndex]
            ) {
                const expanedColumnEndIndex = this.getColumnStopIndex(
                    expandedColumnStartIndex,
                    this.value.headerSecond.columns[expandedColumnStartIndex],
                );

                const expandedColumns = this.value.headerSecond.columns.slice(
                    expandedColumnStartIndex + 1,
                    expanedColumnEndIndex === -1
                        ? undefined
                        : expanedColumnEndIndex,
                );

                this.expandedColumnsByColumnKeyId[expandedColumnKeyId] =
                    expandedColumns;
            }
        }
    };

    private fetchExpandedColumnsAsync = async (
        columnIndex: number,
        column: ITemplateReportActivityTitle,
    ) => {
        const url = `${this.baseUrl}/expanded-columns`;

        const request = this.getRequest(columnIndex, column);

        if (request) {
            const response =
                await axios.post<IChecklistReportExpandableColumnResponse>(
                    url,
                    request,
                );

            return response.data;
        }
    };

    private expand = (
        columnIndex: number,
        column: ITemplateReportActivityTitle,
        response: IChecklistReportExpandableColumnResponse,
    ) => {
        const addedColumns =
            response.columns.map((item) => {
                if (item) {
                    const newColumn: ITemplateReportActivityTitle = {
                        ...item,

                        expandInfo: {
                            level: (column.expandInfo?.level ?? 0) + 1,
                            parentCustomListId: column.customListId,
                            stepInstanceId:
                                column.expandInfo?.stepInstanceId ?? "",

                            columnKeyIdsPath: (
                                column.expandInfo?.columnKeyIdsPath ?? []
                            ).concat(column.columnKeyId),
                        },
                    };

                    return newColumn;
                }
            }) ?? [];

        this.value.headerSecond.columns.splice(
            columnIndex + 1,
            0,
            ...addedColumns,
        );

        if (column.expandInfo) {
            column.expandInfo.isExpanded = true;
            column.expandInfo.level = column.expandInfo.level + 1;
        }

        this.updateAndBindValuesOnExpand(columnIndex, response, false);

        this._anyExpandedColumn = true;
    };

    private getColumnStopIndex = (
        columnIndex: number,
        column: ITemplateReportActivityTitle,
    ) => {
        const { level: columnLevel } = column.expandInfo ?? {
            level: 0,
        };

        const stopIndex = this.value.headerSecond.columns.findIndex(
            (x, index) => {
                const { level, isExpanded } = x?.expandInfo ?? { level: 0 };

                return (
                    index > columnIndex &&
                    (level < columnLevel ||
                        (level === columnLevel && isExpanded))
                );
            },
        );

        return stopIndex;
    };

    private collapse = (
        columnIndex: number,
        column: ITemplateReportActivityTitle,
    ) => {
        const stopIndex = this.getColumnStopIndex(columnIndex, column);

        const columnsLength = this.value.headerSecond.columns.length;

        const deleteCount =
            (stopIndex === -1 ? columnsLength - 1 : stopIndex - 1) -
            columnIndex;

        this.value.headerSecond.columns.splice(columnIndex + 1, deleteCount);

        if (column.expandInfo) {
            column.expandInfo.isExpanded = false;
            column.expandInfo.level = column.expandInfo.level - 1;
        }

        this.tryResetRows(columnIndex, deleteCount);
    };

    private tryResetRows(columnIndex: number, deleteCount: number) {
        const anyExpandedColumn = this.value.headerSecond.columns.some(
            (x) => x?.expandInfo?.isExpanded,
        );

        const checklistIds = this.value.checklistIds;

        if (anyExpandedColumn) {
            this._anyExpandedColumn = true;

            for (const checklistId of checklistIds) {
                const rows = this.getAllRowsByChecklistId(
                    this.value,
                    checklistId,
                );

                for (const row of rows) {
                    if (columnIndex < row.cellValues.length) {
                        row.cellValues[columnIndex].isCellExpanded = false;
                    }

                    row.cellValues.splice(columnIndex + 1, deleteCount);
                }
            }
        } else {
            this._anyExpandedColumn = false;

            this.value = this.cloneInitialValue();
        }
    }

    private deleteCellValuesFromRows = (
        columnIndex: number,
        columnCount: number,
    ) => {
        const { checklistIds, rowSetByChecklistId } = this.value;

        for (const checklistId of checklistIds) {
            const { primaryRow, secondaryRows = [] } =
                rowSetByChecklistId[checklistId] ?? {};

            if (primaryRow) {
                primaryRow.cellValues.splice(columnIndex + 1, columnCount);
            }

            for (const row of secondaryRows) {
                row.cellValues.splice(columnIndex + 1, columnCount);
            }
        }
    };

    private getAllRowsByChecklistId(
        value: IChecklistReportTableMatrixResponse,
        checklistId: string,
    ) {
        const { primaryRow, secondaryRows = [] } =
            value.rowSetByChecklistId[checklistId] ?? {};

        const defaultRow: typeof primaryRow = {
            cellValues: [],
        };

        return [primaryRow ?? defaultRow].concat(secondaryRows);
    }

    private bindRows(
        rowsByChecklistId: Record<
            string,
            ChecklistReportMatrixRowType[] | undefined
        >,
    ) {
        const checklistIds = this.value.checklistIds;

        for (const checklistId of checklistIds) {
            const rows = rowsByChecklistId[checklistId];

            if (rows && rows.length > 0) {
                this.value.rowSetByChecklistId[checklistId] = {
                    rowSpan:
                        this.value.rowSetByChecklistId[checklistId]?.rowSpan,
                    primaryRow: rows[0],
                    secondaryRows: rows.slice(1),
                };
            }
        }
    }

    private getRequest = (
        columnIndex: number,
        column: ITemplateReportActivityTitle,
    ) => {
        const { customListId, expandInfo } = column;

        if (customListId) {
            const { isTopLevel, parentCustomListId } = expandInfo ?? {};

            const rowIdsByChecklistId: Record<string, Array<string[]>> = {};

            this.value.checklistIds.reduce((acc, checklistId) => {
                const rows = this.getAllRowsByChecklistId(
                    this.value,
                    checklistId,
                );

                const selectedIds: Array<string[]> = [];

                for (const row of rows) {
                    const cellValue = row.cellValues[columnIndex];

                    if (cellValue.isHidden) {
                        continue;
                    }

                    const selectedCustomListItemIds =
                        cellValue.value?.values?.map((x) => x.value) ?? [];

                    selectedIds.push(selectedCustomListItemIds);
                }

                acc[checklistId] = selectedIds;

                return acc;
            }, rowIdsByChecklistId);

            const request: IChecklistReportExpandableColumnRequest = {
                customListId,
                rowIdsByChecklistId,
                parentCustomListId: isTopLevel ? undefined : parentCustomListId,
            };

            return request;
        }
    };

    private bindValueColumns = () => {
        const result: {
            hasValueColumn: boolean;
            valueColumns: Array<ChecklistReportValueSumFilterColumnType | null>;
        } = {
            hasValueColumn: false,
            valueColumns: [],
        };

        const columns = this.value.headerSecond.columns;

        columns.reduce((acc, el) => {
            let newItem: ChecklistReportValueSumFilterColumnType | null = null;

            if (el) {
                const isStatusColumn = el.isStatusColumn ?? false;

                if (
                    isStatusColumn === false &&
                    el.type === ActivityType.Value
                ) {
                    acc.hasValueColumn = true;

                    newItem = {
                        columnKeyId: el.columnKeyId,
                        path: el.expandInfo?.columnKeyIdsPath ?? null,
                        isActivity: el.expandInfo?.isTopLevel ?? false,
                    };
                }
            }

            acc.valueColumns.push(newItem);

            return acc;
        }, result);

        this.value.valueColumns = result.valueColumns;
        this.value.hasValueColumn = result.hasValueColumn;
    };

    private delay = (t: number) => {
        return new Promise((resolve) => setTimeout(resolve, t));
    };
}
