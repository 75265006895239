import "./SelectDropdown.scss";

import React, { useMemo } from "react";

import Select, {
    ActionMeta,
    CSSObjectWithLabel,
    GroupBase,
    InputActionMeta,
    MenuPlacement,
    OnChangeValue,
    Options,
    OptionsOrGroups,
    PropsValue,
    StylesConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { Accessors } from "react-select/dist/declarations/src/useCreatable";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

import { OptionType } from "./SelectDropdownTypes";
import ClearIndicator from "./components/ClearIndicator";
import CollapsibleGroup, {
    CollapsibleGroupHeading,
} from "./components/CollapsibleGroup";
import collapsibleGroupsState from "./components/CollapsibleGroupState";
import CreateOptionLabel from "./components/CreateOptionLabel";
import { SelectCustomInput as Input } from "./components/CustomInput";
import DropdownIndicator from "./components/DropdownIndicator";
import { MultiValueLabelWithClick } from "./components/MultiValueLabelWithClick";
import { SingleValueWithClick } from "./components/SingleValueWithClick";
import formatGroupLabel from "./components/formatGroupLabel";
import formatOptionLabel from "./components/formatOptionLabel";
import { isValidNewOption } from "./utils";

interface IProps<
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
> {
    id?: string;
    inputId?: string;
    isMulti?: boolean;
    bold?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    closeMenuOnSelect?: boolean;

    createOnlyOnInput?: boolean;

    invalid?: boolean;
    placeholder?: React.ReactNode;
    styles?: StylesConfig<Option, IsMulti, Group>;

    name?: string;
    value?: PropsValue<Option>;

    options?: OptionsOrGroups<Option, Group>;

    menuPlacement?: MenuPlacement;

    menuPortalTarget?: HTMLElement | null;

    testId?: string;

    useCollapsibleGroups?: boolean;
    openedGroupsByDefault?: boolean;

    onChange?: (
        value: OnChangeValue<Option, IsMulti>,
        actionMeta: ActionMeta<Option>,
    ) => void;

    onBlur?: () => void;

    onCreate?: (e: { name?: string; label: string }) => void;

    formatOptionLabel?: (
        data: Option,
        labelMeta: FormatOptionLabelMeta<Option>,
    ) => React.ReactNode;

    formatGroupLabel?: (group: GroupBase<Option>) => React.ReactNode;

    filterOption?: (
        option: FilterOptionOption<Option>,
        rawInput: string,
    ) => boolean;

    onInputChange?: (inputValue: string, actionMeta: InputActionMeta) => void;

    onPreviewSelectedOption?: (id: string) => void;
}

const selectDropdownComponents = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>({
    onPreviewSelectedOption,
    useCollapsableGroups,
}: {
    onPreviewSelectedOption?: boolean;
    useCollapsableGroups?: boolean;
}) => {
    const baseComponents: SelectComponentsConfig<Option, IsMulti, Group> = {
        ClearIndicator,
        DropdownIndicator,
        Input,
    };

    if (onPreviewSelectedOption) {
        baseComponents.MultiValueLabel = MultiValueLabelWithClick;
        baseComponents.SingleValue = SingleValueWithClick;
    }

    if (useCollapsableGroups) {
        baseComponents.Group = CollapsibleGroup;
        baseComponents.GroupHeading = CollapsibleGroupHeading;
    }

    return baseComponents;
};

const formatCreateLabel = (inputValue: string) => {
    return <CreateOptionLabel inputValue={inputValue} />;
};

const defaultStyles = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> => ({
    container: (base) =>
        ({
            ...base,
            width: "100%",
            lineHeight: "normal",
        }) as CSSObjectWithLabel,
    control: (base) => ({
        ...base,
    }),
    input: (base) =>
        ({
            ...base,
            padding: "0px",
        }) as CSSObjectWithLabel,
    option: (styles, props) => {
        const data = props.data;

        if (data.isArchived || data.isDeleted) {
            return {
                ...styles,
                color: "#ff8282",
            } as CSSObjectWithLabel;
        }

        return styles;
    },
    singleValue: (styles, props) => {
        const data = props.data;

        if (data.isArchived || data.isDeleted) {
            return {
                ...styles,
                color: "#ff8282",
            } as CSSObjectWithLabel;
        }

        return styles;
    },
    menu: (styles) => ({
        ...styles,
    }),

    menuPortal: (styles) => {
        return { ...styles, zIndex: 19000 };
    },

    multiValue: (styles, props) => {
        const data = props.data;

        if (data.isDeleted) {
            return {
                ...styles,
                backgroundColor: "#fee2e2",
                border: "1px solid #ff8282",
            } as CSSObjectWithLabel;
        } else if (data.isArchived) {
            return {
                ...styles,
                border: "1px solid #afafaf",
                backgroundColor: "#f6f6f6",
            } as CSSObjectWithLabel;
        }

        return data.isFixed
            ? ({ ...styles, backgroundColor: "gray" } as CSSObjectWithLabel)
            : styles;
    },
    multiValueLabel: (base, props) => {
        const data = props.data;

        if (data.isDeleted) {
            return {
                ...base,
                color: "#ff2929",
            } as CSSObjectWithLabel;
        } else if (data.isArchived) {
            return {
                ...base,
                color: "#636363",
            } as CSSObjectWithLabel;
        } else if (data.isFixed) {
            return {
                ...base,
                fontWeight: "bold",
                color: "white",
                paddingRight: 6,
            } as CSSObjectWithLabel;
        } else {
            return base;
        }
    },
    multiValueRemove: (base, state) => {
        const data = state.data;

        return data.isFixed
            ? ({ ...base, display: "none" } as CSSObjectWithLabel)
            : base;
    },
});

const SelectDropdown = <
    Option extends OptionType,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
>(
    props: IProps<Option, IsMulti, Group>,
) => {
    const {
        id,
        name,
        inputId,
        bold,
        isSearchable,
        isClearable,
        isDisabled,
        closeMenuOnSelect,
        createOnlyOnInput,
        placeholder,
        invalid,
        value,
        options,

        filterOption,
        onInputChange,
        menuPlacement,
        menuPortalTarget,
        testId,
        useCollapsibleGroups = false,
        openedGroupsByDefault,
    } = props;

    const [groupState, setGroupState, handleOnInputChange] =
        collapsibleGroupsState(
            useCollapsibleGroups,
            options,
            openedGroupsByDefault,
            onInputChange,
        );

    const styles = props.styles ?? defaultStyles<Option, IsMulti, Group>();

    const noOptions = (options?.length ?? 0) === 0;

    const className = useMemo(() => {
        const result: string[] = ["app-select-dropdown"];

        if (invalid) {
            result.push("app-select-dropdown--invalid");
        }

        if (bold) {
            result.push("app-select-dropdown--bold");
        }

        return result.join(" ");
    }, [invalid, bold]);

    const classNamePrefix = "app-select-dropdown";

    const handleOnChangeForCreatable = (
        value: OnChangeValue<Option, IsMulti>,
        actionMeta: ActionMeta<Option>,
    ) => {
        if (noOptions) {
            handleOnCreate("");
        } else if (props.onChange) {
            props.onChange(value, actionMeta);
        }
    };

    const handleOnCreate = (inputValue: string) => {
        if (props.onCreate) {
            props.onCreate({ name, label: inputValue });
        }
    };

    const handleIsValidNewOption = (
        inputValue: string,
        value: Options<Option>,
        options: OptionsOrGroups<Option, GroupBase<Option>>,
        accessors: Accessors<Option>,
    ) => {
        if (createOnlyOnInput && inputValue.length === 0) {
            return false;
        }

        if (noOptions) {
            return true;
        }

        return isValidNewOption(inputValue, value, options, accessors);
    };

    const selectProps: StateManagerProps<Option, IsMulti, Group> = {
        className,
        classNamePrefix,
        name,
        id,
        inputId,
        styles,
        isMulti: props.isMulti as IsMulti,
        isSearchable,
        isClearable,
        isDisabled,
        placeholder,
        value,
        options,
        closeMenuOnSelect,
        onChange: props.onChange,
        onBlur: props.onBlur,
        inputDataTestId: testId,
        components: selectDropdownComponents<Option, IsMulti, Group>({
            onPreviewSelectedOption: !!props.onPreviewSelectedOption,
            useCollapsableGroups: useCollapsibleGroups,
        }),
        formatOptionLabel: props.formatOptionLabel ?? formatOptionLabel,
        formatGroupLabel:
            (props.formatGroupLabel ?? useCollapsibleGroups)
                ? undefined
                : formatGroupLabel,
        filterOption,
        menuPlacement,
        menuPortalTarget,
        onPreview: props.onPreviewSelectedOption,
        closeMenuOnScroll: menuPortalTarget ? () => true : undefined,
        onInputChange: handleOnInputChange,
        unstyled: true,
        onMenuOpen: () => {},
        onMenuClose: () => {},
        groupState,
        setGroupState,
    };

    if (props.onCreate) {
        const creatableSelectComponents = {
            ...selectProps,
            isValidNewOption: handleIsValidNewOption,
            onChange: handleOnChangeForCreatable,
            onCreateOption: handleOnCreate,
            formatCreateLabel,
        };
        return <CreatableSelect {...creatableSelectComponents} />;
    }

    return <Select {...selectProps} />;
};

export default SelectDropdown;
