import "./TeamForm.scss";

import React, { useMemo, useState } from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";

import IValidationMessages from "../../../common/viewModels/IValidationMessages";
import k from "../../../i18n/keys";
import { useSaveTeamMutation, useTeamById } from "../api/hooks";
import IChanged from "common/IChanged";
import { IValueParamItem } from "common/IValueParamItem";
import Button from "common/components/buttons/Button";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import Textarea from "common/components/input-components/textarea/Textarea";
import TextfieldComplex from "common/components/input-components/textfield/TextfieldComplex";
import ModalContent from "common/components/modal-content/ModalContent";
import LabelWithError from "components/auth/components/LabelWithError";
import SelectRoles from "components/common/selectDropdowns/SelectRoles";
import SelectUsers from "components/common/selectDropdowns/SelectUsers";
import ITeamRowDTO from "components/teams/api/ITeamRowDTO";

interface IProps {
    id?: string;
    createNew?: boolean;

    onCloseModal: (onSave?: boolean) => void;
    onHaveChanges: (haveChanges: boolean) => void;
}

const TeamForm: React.FC<IProps> = (props) => {
    const { createNew } = props;

    const [item, setItem] = useState<IChanged<ITeamRowDTO>>({
        value: {
            id: uuidv4(),
            isArchived: false,
            archivedByName: "",
            description: "",
            name: "",
            checklistCount: 0,
            stepCount: 0,
            templateCount: 0,
        },
    });
    const [errors, setErrors] = useState<IValidationMessages>({});
    const saveMutation = useSaveTeamMutation();

    const { t } = useTranslation();

    const { data: value, isLoading: isLoadingTeam } = useTeamById(props.id);

    useEffect(() => {
        if (value) {
            setItem({
                value,
            });
        }
    }, [value]);

    const teamUsers = useMemo(() => {
        const users = item.value.userIds;

        if (users) {
            const list: IValueParamItem<string, boolean>[] = users.map((x) => ({
                value: x,
                param: false,
            }));

            return list;
        }
    }, [item.value.userIds]);

    const handleChange = (e: IInputChangeEvent<string>) => {
        if (e.id) {
            const name = e.id;

            setItem({
                ...item,
                isChanged: true,
                value: {
                    ...item.value,
                    [name]: e.value,
                },
            });

            props.onHaveChanges(true);
        }
    };

    const onUserChange = (values: string[]) => {
        setItem({
            ...item,
            isChanged: true,
            value: {
                ...item.value,
                userIds: values,
            },
        });

        props.onHaveChanges(true);
    };

    const onRoleChange = (values: string[]) => {
        setItem({
            ...item,
            isChanged: true,
            value: {
                ...item.value,
                roleIds: values,
            },
        });

        props.onHaveChanges(true);
    };

    const handleSave = async () => {
        if (item.isChanged) {
            await saveMutation.mutateAsync(
                {
                    isCreate: props.id === undefined,
                    value: item.value,
                },
                {
                    onError: (error: any) => {
                        setErrors(error.response?.data.errors);
                    },
                    onSuccess: () => {
                        props.onCloseModal(true);
                    },
                },
            );
        }
    };

    const handleOnClose = () => props.onCloseModal();

    const saveDisabled = isLoadingTeam || saveMutation.isPending;

    const roles = useMemo(() => {
        const roleIds = item.value.roleIds;
        if (roleIds) {
            const roles: IValueParamItem<string, boolean>[] = roleIds.map(
                (x) => ({ value: x, param: false }),
            );

            return roles;
        }
    }, [item.value.roleIds]);

    return (
        <ModalContent>
            <ModalContent.Header
                title={createNew ? t(k.ADD_TEAM) : t(k.EDIT_TEAM)}
                className="team-form--header"
                onClose={handleOnClose}
            />

            <ModalContent.Body className="team-form--body">
                <TextfieldComplex
                    hideIcon
                    id="name"
                    value={item.value.name ?? ""}
                    placeholder={t(k.ENTER_HERE)}
                    size="large"
                    bold
                    invalid={Boolean(errors?.name)}
                    innerLabelContent={
                        <LabelWithError
                            label={
                                <React.Fragment>
                                    {t(k.NAME)}
                                    &nbsp;
                                    <span className="text--danger">*</span>
                                </React.Fragment>
                            }
                            error={
                                errors?.name
                                    ? t(k[errors.name as keyof typeof k])
                                    : undefined
                            }
                        />
                    }
                    testId="nameTeamField"
                    disabled={isLoadingTeam}
                    onChange={handleChange}
                />

                <Textarea
                    hideIcon
                    id="description"
                    value={item.value.description ?? ""}
                    placeholder={t(k.ENTER_HERE)}
                    bold
                    innerLabelContent={t(k.DESCRIPTION)}
                    testId="descriptionTeamField"
                    disabled={isLoadingTeam}
                    onChange={handleChange}
                />

                <SelectUsers
                    id="teamUsers"
                    selectedUsers={teamUsers ?? item.value.userIds}
                    multi
                    boldLabel
                    testId="selectUsersTeamInput"
                    onChange={onUserChange}
                />

                <SelectRoles
                    id="teamRoles"
                    selectedRoles={roles}
                    hideUserRole
                    multi
                    boldLabel
                    testId="selectRolesTeamInput"
                    onChange={onRoleChange}
                />
            </ModalContent.Body>
            <ModalContent.Footer className="team-form--footer">
                <Button
                    testId="cancelTeamBtn"
                    variant="danger"
                    transparent
                    onClick={handleOnClose}
                >
                    {t(k.CANCEL)}
                </Button>

                <Button
                    testId="saveTeamBtn"
                    variant="success"
                    onClick={handleSave}
                    disabled={saveDisabled}
                    isBusy={saveMutation.isPending}
                >
                    {t(k.SAVE)}
                </Button>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default TeamForm;
