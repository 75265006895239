import { Reducer } from "redux";

import IAttachmentFormData from "../../common/viewModels/IAttachmentFormData";
import { AttachmentType } from "../../models/enums/AttachmentType";
import {
    AttachmentActionTypes,
    HIDE_ATTACHMENT_FORM,
    SET_ATTACHMENT_IS_LOADING,
    SHOW_ATTACHMENT_FORM,
} from "./types";

export interface IAttachmentState {
    readonly isLoading: boolean;
    readonly selectedType: AttachmentType;

    readonly formResolved: boolean;
    readonly formData?: IAttachmentFormData;
}

const initialState: IAttachmentState = {
    isLoading: false,
    selectedType: AttachmentType.None,

    formResolved: false,
};

export const AttachmentReducer = (
    state = initialState,
    action: AttachmentActionTypes,
): IAttachmentState => {
    switch (action.type) {
        case SET_ATTACHMENT_IS_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }

        case SHOW_ATTACHMENT_FORM: {
            return {
                ...state,
                formResolved: false,
                formData: undefined,
                selectedType: action.payload,
            };
        }

        case HIDE_ATTACHMENT_FORM: {
            return {
                ...state,
                formResolved: true,
                formData: action.payload,
                selectedType: AttachmentType.None,
            };
        }

        default:
            return state;
    }
};
