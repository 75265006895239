import { FormatOptionLabelMeta } from "react-select";

import { OptionType } from "../SelectDropdownTypes";

const formatOptionLabel = <Option extends OptionType>(
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
) => {
    return (
        <div className="custom-option-label">
            <span>{data.label}</span>
            {data.subLabel && <small>{data.subLabel}</small>}
        </div>
    );
};

export default formatOptionLabel;
