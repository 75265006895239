import "./ImprovementsCard.scss";

import { useTranslation } from "react-i18next";

import moment from "moment";

import k from "i18n/keys";

import ImpactGradingBadges from "common/components/badges/impact-grading-badges/ImpactGradingBadges";
import ImprovementStatusBadge from "common/components/badges/improvement-status-badge/ImprovementStatusBadge";
import { convertToDate } from "common/utils/time";
import IDeviation from "models/IDeviation";

interface IProps {
    value: IDeviation;
    onClick: (id: string) => void;
}

const ImprovementsCard: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { value } = props;

    const { t } = useTranslation();

    const handleOnClick = () => {
        props.onClick(value.id);
    };
    return (
        <div className="improvement-card" onClick={handleOnClick}>
            <div className="improvement-card--badges">
                <ImpactGradingBadges type={value.impact} />
                <ImprovementStatusBadge status={value.dynamicStatus} />
            </div>
            <div className="improvement-card--message">{value.message}</div>
            <div className="improvement-card--form">{value.formName}</div>
            <div className="improvement-card--date">
                {moment(convertToDate(value.updatedAt)).fromNow()} {t(k.BY_)}{" "}
                {value.updatedBy?.label}
            </div>
        </div>
    );
};

export default ImprovementsCard;
