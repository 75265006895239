import { isDevelopmentEnvironment } from "config";
import { Reducer, Store, combineReducers } from "redux";

import { ProcessChartReducer } from "../components/process-chart/redux/reducer";
import { IAppState } from "./IAppState";
import { IORMState } from "./IORMState";
import { AllEntitiesReducer } from "./allEntitiesCombinedReducer";
import { AttachmentReducer } from "./attachments/reducer";
import { AuthReducer } from "./auth/reducer";
import { BarcodeReducer } from "./barcode/reducer";
import { ConfirmReducer } from "./confirms/reducer";
import { ImagesReducer } from "./images/reducer";
import { PageNavigationReducer } from "./page-navigation/reducer";
import { StepInstanceReducer } from "./stepInstances/reducer";
import { StepReducer } from "./steps/reducer";
import { UiSettingsReducer } from "./uiSettings/reducer";
import { configureStore } from "@reduxjs/toolkit";

let globalStore: Store<IAppState>;

export default function configureAppStore(): Store<IAppState, any> {
    const reducers = {
        ormEntities: AllEntitiesReducer as Reducer<IORMState, any>,
        authViewState: AuthReducer,
        uiSettingsViewState: UiSettingsReducer,
        confirmViewState: ConfirmReducer,
        stepViewState: StepReducer,
        stepInstanceViewState: StepInstanceReducer,
        attachmentViewState: AttachmentReducer,
        pageNavigationViewState: PageNavigationReducer,
        imageViewState: ImagesReducer,
        barcodeViewState: BarcodeReducer,
        processChartViewState: ProcessChartReducer,
    };

    // In development, use the browser's Redux dev tools extension if installed
    const isDevelopment = isDevelopmentEnvironment();

    const rootReducer = combineReducers({
        ...reducers,
    });

    // Create a configure store function of type `IAppState`
    globalStore = configureStore({
        reducer: rootReducer,
        devTools: isDevelopment,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });

    return globalStore;
}

export { globalStore };
