import "./TeamsList.scss";

import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import InfoBadge from "../badges/base-badges/badge-info/InfoBadge";
import RemoveButton from "../buttons/remove-button/RemoveButton";
import { TeamIcon } from "../icons";
import ValueWrapper from "../value-wrapper/ValueWrapper";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

interface IProps {
    teams?: IValueLabelItemWithState<string, string, any>[];

    isTeamEveryone?: boolean;

    icon?: React.ReactNode | null;
    haveWordWrap?: boolean;

    fullWidth?: boolean;

    testId?: string;

    onClick?: (id?: string) => void;
    onRemove?: (id?: string) => void;
}

const TeamsList: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { teams, isTeamEveryone, icon, testId, haveWordWrap, fullWidth } =
        props;

    const { t } = useTranslation();

    return (
        <div className={`teams-list__wrapper ${fullWidth ? "full-width" : ""}`}>
            {icon !== null && (icon ?? <TeamIcon />)}

            <div
                className={`teams-list--badges ${
                    fullWidth ? "full-width" : ""
                }`}
                data-testid={testId}
            >
                {isTeamEveryone && (
                    <InfoBadge textEllipsis={haveWordWrap} outline>
                        <div className="badge-everyone">
                            {t(k.EVERYONE)}
                            {props.onRemove && (
                                <RemoveButton
                                    id={"teamEveryone"}
                                    onClick={(e, id) => props.onRemove?.(id)}
                                />
                            )}
                        </div>
                    </InfoBadge>
                )}

                {teams?.map((team) => (
                    <ValueWrapper
                        key={team.value}
                        id={team.value}
                        isArchived={team.isArchived}
                        isDeleted={team.isArchived}
                        onClick={props.onClick}
                        onRemove={props.onRemove}
                    >
                        {team.label}
                    </ValueWrapper>
                ))}
            </div>
        </div>
    );
};

export default TeamsList;
