import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import WarningBadge, { IWarningBadgeProps } from "../../base-badges/badge-warning/WarningBadge";

interface IRequiredBadgeProps extends IWarningBadgeProps {
    label?: string;
}

const RequiredBadge = (props: IRequiredBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return <WarningBadge {...rest}>{label ?? t(k.REQUIRED)}</WarningBadge>;
};

export default RequiredBadge;
