import { LocaleId } from "AppLocale";
import axios from "axios";

import ICompetencyFilter from "./ICompetencyFilter";
import { ICompetencyOption } from "./ICompetencyOption";
import ICompetencyRowDTO from "./ICompetencyRowDTO";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IValueParamItem } from "common/IValueParamItem";
import ExecutionResult from "common/viewModels/ExecutionResult";
import ITableSet from "http/ITableSet";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const keys = {
    orderedOptions: "competency-options-ordered" as const,
    options: "competency-options" as const,
    list: "competency-list" as const,
};

const baseUrl = "/api/competency";

export const useOrderedCompetencyOptions = (localeId: LocaleId) => {
    const url = `${baseUrl}/competency-options`;

    return useQuery({
        queryKey: [keys.orderedOptions, localeId],
        queryFn: async (context) => {
            const response = await axios.get<ITableSet<IValueParamItem>>(url, {
                signal: context.signal,
                params: { localeId },
            });

            return response.data;
        },
    });
};

export const useCompetencyOptions = (localeId: LocaleId) => {
    const url = `${baseUrl}/options`;

    return useQuery({
        queryKey: [keys.options, localeId],
        queryFn: async (context) => {
            const response = await axios.get<
                Record<string, ICompetencyOption | undefined>
            >(url, {
                signal: context.signal,
                params: { localeId },
            });

            return response.data;
        },
    });
};

export const useCompetencyList = (filter: ICompetencyFilter) => {
    const url = `${baseUrl}/list`;

    return useQuery({
        queryKey: [keys.list, filter],
        queryFn: async (context) => {
            const response = await axios.post<ITableSet<ICompetencyRowDTO>>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
    });
};

export const useDeleteCompetencyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            id: string;
            filter: ICompetencyFilter;
        }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.id}`);

                return true;
            } catch (ex) {}

            return false;
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: [keys.list, variables.filter],
            });
        },
    });
};

function updateIsArchivedById(
    id: string,
    isArchived: boolean,
    list?: ITableSet<ICompetencyRowDTO>,
): ITableSet<ICompetencyRowDTO> {
    const rowValue = list?.values[id];
    if (list) {
        if (rowValue) {
            return {
                ...list,
                values: {
                    ...list.values,
                    [id]: {
                        ...rowValue,
                        isArchived,
                    },
                },
            };
        }

        return list;
    }

    return { ids: [], values: {} };
}

export const useArchiveCompetencyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: {
            request: IArchiveRequest;
            filter: ICompetencyFilter;
        }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({
                queryKey: [keys.list, variables.filter],
            });

            const data = queryClient.getQueryData<ITableSet<ICompetencyRowDTO>>(
                [keys.list, variables.filter],
            );

            const isArchived = data?.values[variables.request.id]?.isArchived;

            queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                [keys.list, variables.filter],
                (prev) =>
                    updateIsArchivedById(
                        variables.request.id,
                        variables.request.isArchive,
                        prev,
                    ),
            );

            return {
                prevValue: isArchived,
            };
        },
        onSuccess: async (data, variables, response) => {
            if (response) {
                await queryClient.invalidateQueries({
                    queryKey: [keys.list, variables.filter],
                });
            }
        },
        onError: (
            err,
            variables,
            context: { prevValue?: boolean } | undefined,
        ) => {
            if (context) {
                queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                    [keys.list, variables.filter],
                    (prev) =>
                        updateIsArchivedById(
                            variables.request.id,
                            context.prevValue ?? false,
                            prev,
                        ),
                );
            }
        },
    });
};

export const useSaveCompetencyMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: ICompetencyRowDTO;
            filter: ICompetencyFilter;
        }) => {
            try {
                let result: ICompetencyRowDTO;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<ICompetencyRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<ICompetencyRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<ICompetencyRowDTO>(result);
            } catch (error) {
                return ExecutionResult.Failed<ICompetencyRowDTO>(error);
            }
        },

        onSuccess: (data, variables) => {
            const newValue = data.Data;

            if (newValue) {
                if (variables.isCreate) {
                    queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                        [keys.list, variables.filter],
                        (prev) =>
                            prev
                                ? {
                                      ...prev,

                                      ids: [newValue.id].concat(prev.ids),

                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },
                                  }
                                : { ids: [], values: {} },
                    );
                } else {
                    queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                        [keys.list, variables.filter],
                        (prev) =>
                            prev
                                ? {
                                      ...prev,
                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },
                                  }
                                : { ids: [], values: {} },
                    );
                }
            }
        },
    });
};

export const useSaveCompetencyOrderMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            ids: string[];
            filter: ICompetencyFilter;
        }) => {
            try {
                await axios.put(`${baseUrl}/order`, variables.ids);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },

        onSuccess: (_, variables) => {
            queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                [keys.list, variables.filter],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              ids: variables.ids,
                          }
                        : { ids: [], values: {} },
            );

            queryClient.setQueryData<ITableSet<ICompetencyRowDTO>>(
                ["competency-options"],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              ids: variables.ids,
                          }
                        : { ids: [], values: {} },
            );
        },
    });
};

export async function importCompetencyMutation(data: ICompetencyRowDTO[]) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
