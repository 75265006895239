import "./AnimatedSpinner.scss";

import React, { useMemo } from "react";

import { Spinner } from "react-bootstrap";

interface IProps {
    isVisible?: boolean;

    timeout?: number;

    className?: string;

    position?: "center";

    aligned?: "center";

    testId?: string;
}

const AnimatedSpinner: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { isVisible, className, position, aligned, testId } = props;

    // const timeout = props.timeout === undefined ? 400 : props.timeout;

    const spinnerBaseClassName = "animated-spinner";

    const spinnerClassName = useMemo(() => {
        const result = [spinnerBaseClassName];

        if (className) {
            result.push(className);
        }

        return result.join(" ");
    }, [className]);

    const wrapperClassName = useMemo(() => {
        const result = ["spinner-wrapper", "no-print"];

        if (position) {
            result.push(`position-${position}`);
        }

        if (aligned) {
            result.push(`aligned-${aligned}`);
        }

        return result.join(" ");
    }, [position, aligned]);

    return (
        <div className={wrapperClassName} data-testid={testId}>
            {isVisible && (
                <>
                    <Spinner
                        animation="grow"
                        variant="success"
                        className={spinnerClassName}
                    />
                    {props.children}
                </>
            )}
        </div>
    );
};

export default AnimatedSpinner;
