import React from "react";
import { EMeasurementGrading } from "components/measurement/api/EMeasurementGrading";
import BehindBadge from "../simple-badges/badge-behind/BehindBadge";
import AtRiskBadge from "../simple-badges/badge-at-risk/AtRiskBadge";
import BlockedDangerBadge from "../simple-badges/badge-blocked-danger/BlockedDangerBadge";
import CompletedBadge from "../simple-badges/badge-completed/CompletedBadge";
import OnTrackBadge from "../simple-badges/badge-on-track/OnTrackBadge";
import DraftBadge from "../simple-badges/badge-draft/DraftBadge";
import { TooltipProps } from "common/components/tooltip/Tooltip";

interface IProps {
    hideOnTrack?: boolean;
    status: EMeasurementGrading;
    iconTooltip?: TooltipProps;
}

const MeasurementStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { status, hideOnTrack, iconTooltip } = props;

    switch (status) {
        case EMeasurementGrading.Behind:
            return <BehindBadge />;
        case EMeasurementGrading.Blocked:
            return <BlockedDangerBadge iconTooltip={iconTooltip} />;
        case EMeasurementGrading.AtRisk:
            return <AtRiskBadge iconTooltip={iconTooltip} />;
        case EMeasurementGrading.Completed:
            return <CompletedBadge />;
        case EMeasurementGrading.Draft:
            return <DraftBadge />;
        default:
            return hideOnTrack ? null : <OnTrackBadge />;
    }
};

export default MeasurementStatusBadge;
