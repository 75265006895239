import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import SeparateTablesCell from "../report-table/SeparateTablesCell";
import IChecklistReportTableMatrixResponse from "./api/IChecklistReportResponse";
import { HeaderTableCell } from "./header-table-cell/HeaderTableCell";
import { IHeaderTableCellProps } from "./header-table-cell/IHeaderTableCellProps";
import { TableCell } from "common/components/table";

interface IProps
    extends Pick<
        IHeaderTableCellProps,
        "onFilter" | "customListFilters" | "onExpand"
    > {
    tableMatrix: IChecklistReportTableMatrixResponse;
}

export const LevelTwoReportDetailsHeader = (props: IProps) => {
    const { customListFilters } = props;

    const columns = props.tableMatrix.headerSecond.columns;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <SeparateTablesCell />

            {columns.map((column, index) =>
                column?.isStatusColumn ? (
                    <TableCell key={index} isHighlighted>
                        {t(k.STATUS)}
                    </TableCell>
                ) : (
                    <HeaderTableCell
                        key={index}
                        column={column ?? null}
                        customListFilters={customListFilters}
                        onFilter={props.onFilter}
                        onExpand={props.onExpand}
                    />
                ),
            )}
        </React.Fragment>
    );
};
