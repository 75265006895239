import { LocaleId } from "AppLocale";
import axios from "axios";

import IImpactGradingFilter from "./IImpactGradingFilter";
import { IImpactGradingOption } from "./IImpactGradingOption";
import IImpactGradingRowDTO from "./IImpactGradingRowDTO";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { ITableList } from "http/ITableList";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";

const baseUrl = "/api/impact-grading";

const LIST_QUERY_KEY = "impact-grading-list";

export const useImpactGradingList = (filter?: IImpactGradingFilter) => {
    const url = `${baseUrl}/list`;

    return useQuery({
        queryKey: [LIST_QUERY_KEY, filter],

        queryFn: async (context) => {
            const response = await axios.post<ITableList<IImpactGradingRowDTO>>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },

        enabled: !!filter,
    });
};

export const useImpactGradingOptions = (
    localeId?: LocaleId,
    enabled: boolean = true,
) => {
    const url = `${baseUrl}/options`;

    return useQuery({
        queryKey: ["impact-grading-published-options", localeId],

        queryFn: async (context) => {
            const response = await axios.get<IImpactGradingOption[]>(url, {
                signal: context.signal,
                params: { localeId },
            });

            return response.data;
        },

        enabled: !!localeId && enabled,
    });
};

export const useDeleteImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { id: string }) => {
            try {
                await axios.delete(`${baseUrl}/${variables.id}`);

                return ExecutionResult.Success();
            } catch (error) {
                return ExecutionResult.Failed(error);
            }
        },

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [LIST_QUERY_KEY] });
        },
    });
};

export const useArchiveImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (variables: { request: IArchiveRequest }) =>
            axios.post<IArchiveResponse>(
                `${baseUrl}/archive`,
                variables.request,
            ),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [LIST_QUERY_KEY] });
        },
    });
};

export const useSaveImpactGradingMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IImpactGradingRowDTO;
            filter: IImpactGradingFilter;
        }) => {
            try {
                let result: IImpactGradingRowDTO;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<IImpactGradingRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                } else {
                    const response = await axios.put<IImpactGradingRowDTO>(
                        baseUrl,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<IImpactGradingRowDTO>(result);
            } catch (error) {
                return ExecutionResult.Failed<IImpactGradingRowDTO>(error);
            }
        },

        onSuccess: (data, variables) => {
            const newValue = data.Data;

            if (newValue) {
                if (variables.isCreate) {
                    queryClient.setQueryData<ITableList<IImpactGradingRowDTO>>(
                        [LIST_QUERY_KEY, variables.filter],
                        (prev) =>
                            prev
                                ? {
                                      ...prev,

                                      ids: [newValue.id]
                                          .concat(prev.ids)
                                          .slice(
                                              0,
                                              variables.filter.pageSize ??
                                                  prev.ids.length + 1,
                                          ),

                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },

                                      totalCount: (prev.totalCount ?? 0) + 1,
                                  }
                                : { ids: [], values: {} },
                    );
                } else {
                    queryClient.setQueryData<ITableList<IImpactGradingRowDTO>>(
                        [LIST_QUERY_KEY, variables.filter],
                        (prev) =>
                            prev
                                ? {
                                      ...prev,
                                      values: {
                                          ...prev.values,
                                          [newValue.id]: newValue,
                                      },
                                  }
                                : { ids: [], values: {} },
                    );
                }
            }
        },
    });
};

export async function importImpactGradingMutation(
    data: IImpactGradingRowDTO[],
) {
    const url = `${baseUrl}/import`;

    try {
        const response = await axios.post<number>(url, data);

        return ExecutionResult.Result(response.data);
    } catch (error) {
        return ExecutionResult.Failed<number>(error);
    }
}
