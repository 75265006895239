import { Reducer } from "redux";

import {
    HIDE_IMAGE_PREVIEW,
    ImagesActionTypes,
    SHOW_IMAGE_PREVIEW,
    SHOW_IMAGE_PREVIEW_BY_ID,
} from "./types";

export interface IImagesState {
    readonly isVisible: boolean;

    readonly value?: string;
    readonly imgId?: string;
}

const initialState: IImagesState = {
    isVisible: false,
};

export const ImagesReducer = (
    state = initialState,
    action: ImagesActionTypes,
): IImagesState => {
    switch (action.type) {
        case SHOW_IMAGE_PREVIEW: {
            return {
                ...state,
                isVisible: true,
                value: action.payload,
                imgId: undefined,
            };
        }

        case SHOW_IMAGE_PREVIEW_BY_ID: {
            return {
                ...state,
                isVisible: true,
                imgId: action.payload,
                value: undefined,
            };
        }

        case HIDE_IMAGE_PREVIEW: {
            return {
                ...state,
                isVisible: false,
                value: undefined,
                imgId: undefined,
            };
        }

        default:
            return state;
    }
};
