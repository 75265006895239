import "./BarcodeFilter.scss";

import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IFilterProps } from "../api/IFilterProps";
import { Button } from "common/components/buttons";
import Textfield from "common/components/input-components/textfield/Textfield";
import { FilterDropdown } from "common/components/table/components/filtering/filter-dropdown/FilterDropdown";
import BarcodeScanAppend from "components/template-checklist-report-search/components/BarcodeScanAppend";

interface IProps extends IFilterProps {}

function getInitialValue() {
    return [""];
}

export const BarcodeFilter = (props: IProps) => {
    const { value, testId } = props;

    const propertyType = value?.type;

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const [isActive, setIsActive] = useState(
        (value?.selectionFilter ?? []).length > 0,
    );
    const [isEdited, setIsEdited] = useState(false);

    const [filterValue, setFilterValue] = useState<string[]>(
        value?.selectionFilter ?? getInitialValue(),
    );

    React.useEffect(() => {
        if (!isOpen) {
            setFilterValue(value?.selectionFilter ?? getInitialValue());
        }
    }, [isOpen]);

    const handleReset = () => {
        if (propertyType !== undefined) {
            setFilterValue(getInitialValue());

            setIsEdited(false);

            setIsActive(false);

            props.onFilter({
                type: propertyType,

                isReset: true,
            });
        }
    };

    const tryHandleReset = (values: string[]) => {
        const [isEmpty] = getNextValues(values);

        if (isEmpty) {
            setIsEdited(false);
        }
    };

    const getNextValues = (sourceValues: string[]) => {
        const nextValue = sourceValues
            .map((x) => x.trim())
            .filter((x) => x !== "");

        const isEmpty = nextValue.some((x) => x !== "") === false;

        return [isEmpty, nextValue] as const;
    };

    const handleFilter = (isReset?: boolean) => {
        if (isReset) {
            handleReset();
        } else if (propertyType !== undefined) {
            const [isEmpty, nextValues] = getNextValues(filterValue);

            setIsEdited(!isEmpty);

            setIsActive(!isEmpty);

            props.onFilter({
                type: propertyType,

                selectionFilter: nextValues,

                isReset: isEmpty,
            });
        }
    };

    const handleChange = (value: string, id?: string, name?: string) => {
        const index = Number(name);

        setFilterValue((prev) => {
            const nextValues = [...prev];

            nextValues[index] = value;

            return nextValues;
        });

        setIsEdited(true);

        const nextValues = [...filterValue];

        nextValues[index] = value;

        tryHandleReset(nextValues);
    };

    const handleScanBarcode = (barcode: string, index?: number) => {
        if (index !== undefined) {
            handleChange(barcode, undefined, String(index));
        }
    };

    const handleAdd = () => {
        setFilterValue((prev) => [...prev, ""]);
    };

    return (
        <FilterDropdown
            isEdited={isEdited}
            isActive={isActive}
            testId={testId}
            onFilter={handleFilter}
            onToggle={setIsOpen}
        >
            <div className="barcode-filter">
                {filterValue.map((x, index) => (
                    <Textfield
                        key={index}
                        value={x}
                        labelContent={
                            index === 0
                                ? t(k.FILTER_ALL_WHICH_CONTAINS)
                                : undefined
                        }
                        placeholder={t(k.SCAN_OR_ENTER_BARCODE)}
                        size="large"
                        name={String(index)}
                        type="number"
                        hideNumberArrows
                        onChange={handleChange}
                        postInputContent={
                            <BarcodeScanAppend
                                index={index}
                                onChange={handleScanBarcode}
                            />
                        }
                    />
                ))}

                <Button
                    variant="success"
                    size="small"
                    transparent
                    onClick={handleAdd}
                >
                    {t(k.ADD)}
                </Button>
            </div>
        </FilterDropdown>
    );
};
