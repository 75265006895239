import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import SuccessBadge, { ISuccessBadgeProps } from "../../base-badges/badge-success/SuccessBadge";

interface IFinalizedBadgeProps extends ISuccessBadgeProps {
    label?: string;
}

const FinalizedBadge = (props: IFinalizedBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <SuccessBadge {...rest}>
            {label ?? t(k.FINALIZED)}
        </SuccessBadge>
    );
};

export default FinalizedBadge;
