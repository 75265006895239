import { Reducer } from "redux";

import { Roles, TRoles } from "../../components/roles/api/Roles";
import { IProfileDTO, IUserRoles } from "../../models/dto/IProfileDTO";
import {
    AuthActionTypes,
    CHECK_LOCAL_TOKEN,
    RECEIVE_LOGIN,
    REQUEST_LOGOUT,
} from "./types";

export interface IAuthState {
    hasCheckedLocalToken: boolean;
    isAuthenticated: boolean;
    profile?: IProfileDTO;

    roles: IUserRoles;

    serverAuthFailed?: boolean;
}

const initialState: IAuthState = {
    hasCheckedLocalToken: false,

    isAuthenticated: false,

    roles: {},
};

function getRoles(roles?: TRoles[]): IUserRoles {
    let result: IUserRoles = {};

    if (roles) {
        result = roles.reduce<IUserRoles>((prev, cur) => {
            const key = Object.keys(Roles)[Object.values(Roles).indexOf(cur)];
            prev[key as keyof typeof Roles] = true;

            return prev;
        }, {});
    }

    return result;
}

export const AuthReducer = (
    state = initialState,
    action: AuthActionTypes,
): IAuthState => {
    switch (action.type) {
        case CHECK_LOCAL_TOKEN: {
            const roles = getRoles(action.payload?.roles.map((x) => x.label));

            return {
                ...state,

                hasCheckedLocalToken: true,
                isAuthenticated: Boolean(action.payload),
                profile: action.payload,
                roles,
            };
        }

        case RECEIVE_LOGIN: {
            const roles = getRoles(action.payload?.roles.map((x) => x.label));

            return {
                ...state,

                isAuthenticated: true,
                profile: action.payload,
                roles,
                serverAuthFailed: false,
            };
        }

        case REQUEST_LOGOUT: {
            return {
                ...initialState,
                hasCheckedLocalToken:
                    action.payload?.force ?? state.hasCheckedLocalToken,
                serverAuthFailed: action.payload?.serverAuthFailed,
            };
        }

        default:
            return state;
    }
};
