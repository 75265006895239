import { PropsWithChildren } from "react";

import classNames from "classnames";

import Badge, { IBaseBadgeProps } from "../Badge";

import "./DarkBadge.scss";

export interface IDarkBadgeProps extends IBaseBadgeProps {
    outline?: boolean;
}

const DarkBadge = (props: PropsWithChildren<IDarkBadgeProps>) => {
    const { children, outline, ...rest } = props;

    const className = classNames("dark-badge", {
        "dark-badge--outline": outline,
    });

    return (
        <Badge className={className} {...rest}>
            {children}
        </Badge>
    );
};

export default DarkBadge;
