import React from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IValueLabelItem } from "common/IValueLabelItem";
import { DraftIcon } from "common/components/icons";
import Selection from "common/components/input-components/selection/Selection";
import { EGoalGrading } from "components/goals-page/api/EGoalGrading";

interface IGoalStatusSelectionRowProps {
    status: EGoalGrading;
    disabled?: boolean;
    hideDraft?: boolean;
    onClick?: (status: EGoalGrading) => void;
}

const GoalStatusSelectionRow: React.FC<
    React.PropsWithChildren<IGoalStatusSelectionRowProps>
> = (props) => {
    const { status, disabled, hideDraft } = props;

    const { t } = useTranslation();

    const options: Array<
        IValueLabelItem<EGoalGrading, string | JSX.Element, React.CSSProperties>
    > = [
        {
            label: t(k.ON_TRACK),
            value: EGoalGrading.OnTrack,
            param: { backgroundColor: "#396aff", color: "#fff" },
        },
        {
            label: t(k.AT_RISK),
            value: EGoalGrading.AtRisk,
            param: { backgroundColor: "#facc15", color: "#4b5563" },
        },
        {
            label: t(k.BLOCKED),
            value: EGoalGrading.Blocked,
            param: { backgroundColor: "#ff2929", color: "#fff" },
        },
        {
            label: t(k.COMPLETED),
            value: EGoalGrading.Completed,
            param: { backgroundColor: "#0db691", color: "#fff" },
        },
        ...(!hideDraft
            ? [
                  {
                      label: (
                          <>
                              <DraftIcon />
                              {t(k.DRAFT)}
                          </>
                      ),
                      value: EGoalGrading.Draft,
                      param: { backgroundColor: "#bfdbfe", color: "#4b5563" },
                  },
              ]
            : []),
    ];

    return (
        <Selection
            testId="goal-status-selection"
            selectedValue={status}
            options={options}
            variant="horizontal"
            disabled={disabled}
            onSelect={props.onClick}
        />
    );
};

export default GoalStatusSelectionRow;
