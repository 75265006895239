import "./SidePanelDetails.scss";

import React, { useContext } from "react";

import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import TemplateFormContext from "../../../context/TemplateFormContext";
import DraftBadge from "common/components/badges/simple-badges/badge-draft/DraftBadge";
import { WarningIcon } from "common/components/icons";
import { EActivityType } from "common/components/input-components/EActivityType";
import { IInputChangeEvent } from "common/components/input-components/IInputProps";
import InputComponent from "common/components/input-components/InputComponent";
import ListedContent from "common/components/listed-content/ListedContent";
import { getLocaleString } from "i18n/components/GetLocaleString";

const SidePanelDetails: React.FC = (props) => {
    const {
        template,
        setTemplate,
        onHaveChanges,
        isArchived,
        errors,
        setErrors,
        createNew,
    } = useContext(TemplateFormContext);

    const { t } = useTranslation();

    const handleOnChange = (e: IInputChangeEvent<string>) => {
        const name = e.name;
        if (name) {
            setTemplate((prev) => (prev ? { ...prev, [name]: e.value } : prev));

            onHaveChanges(true);
            const nameKey = `template.${name}`;
            const nameError = errors?.[nameKey];

            if (e.value && nameError) {
                setErrors((prev) => ({
                    ...prev,
                    [nameKey]: undefined,
                }));
            } else if (e.value === "") {
                setErrors((prev) => ({
                    ...prev,
                    [nameKey]: k.TEMPLATE_NAME_REQUIRED,
                }));
            }
        }
    };

    const isDraft =
        template?.lastPublishedVersion !== template?.version && !createNew;

    return (
        <ListedContent.SidePanel.Details
            context={TemplateFormContext}
            description={
                <InputComponent
                    testId="description-input"
                    inputType={EActivityType.MultiLineTextfield}
                    wrapperLabel={t(k.SUMMARY)}
                    boldLabel
                    id="form.templateDescription"
                    name="description"
                    value={template?.description ?? ""}
                    placeholder={t(
                        k.WRITE_A_SUMMARY_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE,
                    )}
                    disabled={isArchived}
                    hideIcon
                    onChange={handleOnChange}
                />
            }
        >
            <div className="side-panel__details__title">
                {t(k.TEMPLATE_INFORMATION)}
                {errors && (
                    <WarningIcon
                        active
                        tooltip={{
                            id: "template-error-info",
                            message: [
                                <div
                                    className="template-error-title"
                                    key="template-error-title"
                                >
                                    {t(k.TEMPLATE_FORM_ERRORS)}
                                </div>,
                                ...Object.keys(errors).reduce<
                                    React.ReactNode[]
                                >((acc, key) => {
                                    const item = errors[key];
                                    if (item) {
                                        if (Array.isArray(item)) {
                                            const value = item.map(
                                                (x) =>
                                                    getLocaleString(t, x) ?? x,
                                            );

                                            acc.push(
                                                <div key={key}>{value}</div>,
                                            );
                                        } else {
                                            const value =
                                                getLocaleString(t, item) ??
                                                item;

                                            acc.push(
                                                <div key={key}>{value}</div>,
                                            );
                                        }
                                    }
                                    return acc;
                                }, []),
                            ],
                        }}
                    />
                )}
                {isDraft && <DraftBadge testId="templateDraftBadge" />}
            </div>

            <InputComponent
                testId="name-input"
                inputType={EActivityType.Textfield}
                wrapperLabel={t(k.TEMPLATE_NAME)}
                boldLabel
                required
                id="template.name"
                name="name"
                value={template?.name ?? ""}
                placeholder={t(k.ENTER_HERE)}
                disabled={isArchived}
                hideIcon
                onChange={handleOnChange}
                invalid={!!errors?.[`template.name`]}
                errors={getLocaleString(t, errors?.[`template.name`])}
            />
        </ListedContent.SidePanel.Details>
    );
};

export default SidePanelDetails;
