import "./DropdownButton.scss";

import React, { useMemo } from "react";

import { Dropdown } from "react-overlays";
import { DropDirection } from "react-overlays/DropdownContext";

import Toggle from "../buttons/menu-button/components/Toggle";
import DropdownMenu from "./DropdownMenu";
import { IDropdownOption } from "./api/IDropdownOption";

interface IProps {
    id?: string;
    disabled?: boolean;
    alignEnd?: boolean;
    drop?: DropDirection;
    className?: string;
    label?: React.ReactNode;
    icon?: React.ReactNode;
    background?: "dark";
    hideSearch?: boolean;
    buttonVariant?: "badge";
    buttonTestId?: string;
    options?: IDropdownOption[];
    shouldPreventClickEventPropagation?: boolean;
    singleSelect?: boolean;

    onChange: (id: string) => void;
    onToggle?: (nextShow: boolean) => void;
}

const DropdownButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        id,
        buttonTestId,
        options,
        className,
        label,
        icon,
        disabled,
        alignEnd,
        drop,
        background,
        buttonVariant,
        shouldPreventClickEventPropagation = false,
        singleSelect = false,
        hideSearch,
    } = props;

    const [menuVisible, setMenuVisible] = React.useState(false);

    const handleDropdownToggle = (nextShow: boolean, e?: any) => {
        //when the dropdown is a child of a component that has a click event
        //the dropdown will close when the click event is triggered

        if (shouldPreventClickEventPropagation && e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }

        props.onToggle?.(nextShow);

        setMenuVisible(nextShow);
    };

    const handleOnSelect = (id: string) => {
        if (singleSelect) {
            setMenuVisible(false);
        }

        props.onChange(id);
    };

    let wrapperClassName = "dropdown-button__wrapper";
    if (className) {
        wrapperClassName += " " + className;
    }

    let toggleClassName = "";
    if (label) {
        toggleClassName += "toggle-with-label";
    } else if (icon) {
        toggleClassName += "toggle-without-label icon-bordered";
    }

    if (background) {
        toggleClassName += " background-" + background;
    }

    if (buttonVariant) {
        toggleClassName += " variant-" + buttonVariant;
    }

    return (
        <div className="dropdown-button">
            <Dropdown
                onToggle={handleDropdownToggle}
                alignEnd={alignEnd ?? true}
                drop={drop}
            >
                <div className={wrapperClassName}>
                    <Toggle
                        id={id}
                        testId={buttonTestId}
                        disabled={disabled}
                        className={toggleClassName}
                        label={label}
                        icon={icon}
                    />

                    <DropdownMenu
                        isLoading={false}
                        isReset={!menuVisible}
                        isSearchable={!hideSearch}
                        options={options}
                        singleSelect={singleSelect}
                        onChange={handleOnSelect}
                    />
                </div>
            </Dropdown>
        </div>
    );
};

export default DropdownButton;
