import "./List.scss";

import { Context, PropsWithChildren, ReactNode, useContext } from "react";

import { ListedContentContextType } from "common/components/listed-content/ListedContentContext";

export interface IListInputProps<C extends ListedContentContextType> {
    context: Context<C>;
    title?: ReactNode;
    className?: string;
    testId?: string;
}

const List = <C extends ListedContentContextType>(
    props: PropsWithChildren<IListInputProps<C>>,
) => {
    const { title, context, className = "", testId, children } = props;

    const { mobileView } = useContext(context);

    if (mobileView) {
        return (
            <>
                {title && (
                    <div className="side-panel__list__title">{title}</div>
                )}
                {children}
            </>
        );
    }
    return (
        <div
            className={`listed-content__side-panel__list ${className}`}
            data-testid={testId}
        >
            {title && <div className="side-panel__list__title">{title}</div>}
            {children}
        </div>
    );
};

export default List;
