import { FC } from "react";

import { Trans, useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import classnames from "classnames";
import { SelectedDynamicValues } from "components/improvement-forms/components/ImprovementForm/components/Content/components/ConfigurationStep/configurationStep.hooks";
import k from "i18n/keys";

import { Button } from "../buttons";
import { WarningIcon } from "../icons";
import DynamicRow from "./components/DynamicRow";
import StaticRow from "./components/StaticRow";

import "./ConfigurationTable.scss";

interface ConfigurationTableProps {
    staticRows: string[];
    dynamicUserRows?: string[];
    tableHeader?: React.ReactNode;
    optionalColumns?: {
        name: string;
        type: "radio" | "checkbox";
        tooltip?: React.ReactNode;
    }[];
    selectedValues:
        | Record<
              string,
              Record<
                  string,
                  {
                      value?: boolean;
                      disabled?: boolean;
                  }
              >
          >
        | undefined;

    selectedDynamicValues?: SelectedDynamicValues;

    disabled?: boolean;
    errorMessage?: string | null;
    onClick?: (row: string, column: string, value: boolean) => void;
    onAddUserRow?: () => void;
    onClickNotifyUserSet?: (id: string, value: boolean) => void;
    onChangeUsers?: (id: string, users: string[]) => void;
    onDeleteRow?: (id: string) => void;
}
const ConfigurationTable: FC<ConfigurationTableProps> = (props) => {
    const {
        staticRows,
        dynamicUserRows,
        tableHeader,
        optionalColumns,
        selectedValues,
        selectedDynamicValues,
        disabled,
        errorMessage,
        onClick,
        onAddUserRow,
        onClickNotifyUserSet,
        onChangeUsers,
        onDeleteRow,
    } = props;

    const { t } = useTranslation();

    const classes = classnames("configuration-table", {
        "configuration-table--padding-20": staticRows.length > 0,
        "configuration-table--padding-10": staticRows.length == 0,
    });

    return (
        <table className={classes}>
            <thead>
                <tr>
                    {tableHeader && (
                        <th>
                            <div>
                                {tableHeader}
                                {errorMessage && (
                                    <WarningIcon
                                        active
                                        tooltip={{
                                            id: "configurationTableHeaderErrorId",
                                            message: t(errorMessage),
                                            placement: "bottom",
                                        }}
                                    />
                                )}
                            </div>
                        </th>
                    )}
                    {optionalColumns?.map((column) => (
                        <th key={column.name}>
                            {column.tooltip ? (
                                <div>
                                    <Trans i18nKey={column.name} />
                                    {column.tooltip}
                                </div>
                            ) : (
                                <Trans i18nKey={column.name} />
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {staticRows.map((description, index) => (
                    <StaticRow
                        key={description + index}
                        description={description}
                        index={index}
                        selectedRowValues={selectedValues}
                        optionalColumns={optionalColumns}
                        disabled={disabled}
                        onClick={onClick}
                    />
                ))}

                {dynamicUserRows?.map((id) => (
                    <DynamicRow
                        key={id}
                        id={id}
                        selectedRowValue={
                            selectedDynamicValues && selectedDynamicValues[id]
                        }
                        disabled={disabled}
                        onChangeUsers={onChangeUsers}
                        onClickNotify={onClickNotifyUserSet}
                        onDeleteRow={onDeleteRow}
                    />
                ))}

                {dynamicUserRows && (
                    <tr className="configuration-table--add-row-button-row">
                        <td colSpan={3}>
                            <Button
                                endIcon={<FiPlus />}
                                className="configuration-table--add-row-button"
                                transparent
                                onClick={onAddUserRow}
                            >
                                {t(k.ADD_NEW_ROW)}
                            </Button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default ConfigurationTable;
