import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import DangerBadge, { IDangerBadgeProps } from "../../base-badges/badge-danger/DangerBadge";

interface IStoppedBadgeProps extends IDangerBadgeProps {
    label?: string;
}

const StoppedBadge = (props: IStoppedBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <DangerBadge {...rest}>
            {label ?? t(k.STOPPED)}
        </DangerBadge>
    );
};

export default StoppedBadge;
