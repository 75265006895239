import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import DangerBadge, { IDangerBadgeProps } from "../../base-badges/badge-danger/DangerBadge";

interface IBlockingBadgeProps extends IDangerBadgeProps {
    label?: string;
}

const BlockingBadge = (props: IBlockingBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return <DangerBadge {...rest}>{label ?? t(k.BLOCKING)}</DangerBadge>;
};

export default BlockingBadge;
