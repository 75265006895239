import { useTranslation } from "react-i18next";
import k from "i18n/keys";
import SuccessBadge, { ISuccessBadgeProps } from "../../base-badges/badge-success/SuccessBadge";

interface ICompletedBadgeProps extends ISuccessBadgeProps {
    label?: string;
}

const CompletedBadge = (props: ICompletedBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <SuccessBadge {...rest}>
            {label ?? t(k.COMPLETED)}
        </SuccessBadge>
    );
};

export default CompletedBadge;
