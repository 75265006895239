import { QueryClient } from "@tanstack/react-query";
import { convertToDate } from "common/utils/time";
import { STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY } from "components/checklists/api/hooks";
import { STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY } from "components/improvements/api/hooks";
import IUserDefinedActivityInstanceRequest from "http/requests/IUserDefinedActivityInstanceRequest";
import omit from "lodash/omit";
import without from "lodash/without";
import IActivityInstance from "models/IActivityInstance";
import IActivityListDTO from "models/dto/IActivityListDTO";

function updateActivityInstances(
    activityInstance?: IActivityInstance,
    prevIsCreate?: boolean,
    activityInstanceIdsBySet?: string[],
    activityList?: IActivityListDTO,
): IActivityListDTO {
    if (activityInstance && activityList) {
        return {
            ...activityList,
            activityInstances: {
                ...activityList.activityInstances,
                [activityInstance.id]: activityInstance,
            },
            activityInstancesIdsByActivityId: {
                ...activityList.activityInstancesIdsByActivityId,
                [activityInstance.activityInstanceSetId]:
                    activityInstance.isCreate || prevIsCreate
                        ? [
                              ...(activityList.activityInstancesIdsByActivityId[
                                  activityInstance.activityInstanceSetId
                              ] || []),
                              activityInstance.id,
                          ]
                        : (activityInstanceIdsBySet ?? [
                              ...(activityList.activityInstancesIdsByActivityId[
                                  activityInstance.activityInstanceSetId
                              ] || []),
                              activityInstance.id,
                          ]),
            },
        };
    }

    return {
        activityInstanceSetIdsByStepInstanceId:
            activityList?.activityInstanceSetIdsByStepInstanceId ?? {},
        activityInstancesIdsByActivityId:
            activityList?.activityInstancesIdsByActivityId ?? {},
        activityInstances: activityList?.activityInstances ?? {},
        activityInstanceSets: activityList?.activityInstanceSets ?? {
            ids: [],
            values: {},
        },
    };
}

export async function onMutationActivityInstanceAddOrUpdateOnMutate(
    queryClient: QueryClient,
    instance: IActivityInstance | undefined,
    request: IUserDefinedActivityInstanceRequest,
) {
    const activitiesQuery = request.isImprovement
        ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
        : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

    await queryClient.invalidateQueries({
        queryKey: [
            activitiesQuery,
            request.parentId, // checklist id // improvement
        ],
    });

    const stepinstanceActivities = queryClient.getQueryData<IActivityListDTO>([
        activitiesQuery,
        request.parentId,
    ]);

    const existingActivityInstance = instance
        ? stepinstanceActivities?.activityInstances[instance.id]
        : undefined;

    const existingIdsOrder = instance
        ? stepinstanceActivities?.activityInstancesIdsByActivityId[
              instance.activityInstanceSetId
          ]
        : undefined;

    queryClient.setQueryData<IActivityListDTO>(
        [activitiesQuery, request.parentId],
        (prev) =>
            updateActivityInstances(
                instance,
                undefined,
                request.activityInstanceIdsBySet,
                prev,
            ),
    );

    return {
        prevActivityInstance: existingActivityInstance ?? instance,
        prevIdsOrder: existingIdsOrder,
    };
}

export function onMutationActivityInstanceAddOrUpdateOnSuccess(
    queryClient: QueryClient,
    isCreate: boolean,
    request: IUserDefinedActivityInstanceRequest,
    data: IActivityInstance | undefined,
    context:
        | {
              prevActivityInstance?: IActivityInstance;
              prevIdsOrder?: string[];
          }
        | undefined,
) {
    if (context) {
        const activitiesQuery = request.isImprovement
            ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
            : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

        queryClient.invalidateQueries({
            queryKey: [activitiesQuery, request.parentId],
        });

        if (isCreate) {
            queryClient.setQueryData<IActivityListDTO>(
                [activitiesQuery, request.parentId],
                (prev) =>
                    deleteActivityInstance(context.prevActivityInstance, prev),
            );
        }

        if (data) {
            if (data.updatedAt) {
                data.updatedAt = convertToDate(data.updatedAt);
            }

            queryClient.setQueryData<IActivityListDTO>(
                [activitiesQuery, request.parentId],

                (prev) =>
                    updateActivityInstances(
                        data,
                        isCreate,
                        request.activityInstanceIdsBySet,
                        prev,
                    ),
            );
        }
    }
}

export function onMutationActivityInstanceAddOrUpdateOnError(
    queryClient: QueryClient,
    isCreate: boolean,
    request: IUserDefinedActivityInstanceRequest,
    context:
        | {
              prevActivityInstance?: IActivityInstance;
              prevIdsOrder?: string[];
          }
        | undefined,
) {
    if (context) {
        const activitiesQuery = request.isImprovement
            ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
            : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

        queryClient.setQueryData<IActivityListDTO>(
            [activitiesQuery, request.parentId],

            (prev) =>
                updateActivityInstances(
                    context.prevActivityInstance,
                    context.prevActivityInstance?.isCreate ?? isCreate,
                    context.prevIdsOrder,
                    prev,
                ),
        );
    }
}

export async function onMutationActivityInstanceDeleteMutate(
    queryClient: QueryClient,
    id: string,
    isImprovement: boolean,
    parentId?: string,
) {
    const activitiesQuery = isImprovement
        ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
        : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

    await queryClient.invalidateQueries({
        queryKey: [activitiesQuery, parentId],
    });

    const stepinstanceActivities = queryClient.getQueryData<IActivityListDTO>([
        activitiesQuery,
        parentId,
    ]);

    const previousActivityInstance = id
        ? stepinstanceActivities?.activityInstances[id]
        : undefined;

    queryClient.setQueryData<IActivityListDTO>(
        [activitiesQuery, parentId],
        (prev) => deleteActivityInstance(previousActivityInstance, prev),
    );
    return {
        prevActivityInstance: previousActivityInstance,
    };
}

export function onMutationActivityInstanceDeleteError(
    queryClient: QueryClient,
    isImprovement: boolean,
    parentId: string | undefined,
    context:
        | {
              prevActivityInstance?: IActivityInstance;
          }
        | undefined,
) {
    if (context) {
        const activitiesQuery = isImprovement
            ? STEPINSTANCE_ACTIVITIES_BY_IMPROVEMENT_ID_KEY
            : STEPINSTACE_ACTIVITIES_BY_CHECKLIST_ID_KEY;

        queryClient.setQueryData<IActivityListDTO>(
            [activitiesQuery, parentId],

            (prev) =>
                updateActivityInstances(
                    context.prevActivityInstance,
                    undefined,
                    undefined,
                    prev,
                ),
        );
    }
}

function deleteActivityInstance(
    activityInstance?: IActivityInstance,
    activityList?: IActivityListDTO,
): IActivityListDTO {
    if (activityInstance && activityList) {
        return {
            ...activityList,
            activityInstances: omit(activityList.activityInstances, [
                activityInstance.id,
            ]),
            activityInstancesIdsByActivityId: {
                ...activityList.activityInstancesIdsByActivityId,

                [activityInstance.activityInstanceSetId]: without(
                    activityList.activityInstancesIdsByActivityId[
                        activityInstance.activityInstanceSetId
                    ],
                    activityInstance.id,
                ),
            },
        };
    }

    return {
        activityInstanceSetIdsByStepInstanceId:
            activityList?.activityInstanceSetIdsByStepInstanceId ?? {},
        activityInstancesIdsByActivityId:
            activityList?.activityInstancesIdsByActivityId ?? {},
        activityInstances: activityList?.activityInstances ?? {},
        activityInstanceSets: activityList?.activityInstanceSets ?? {
            ids: [],
            values: {},
        },
    };
}
