import React from "react";

import { useTranslation } from "react-i18next";

import { IMultiselectOption } from "../../../common/IMultiselectOption";
import k from "../../../i18n/keys";
import { IMultiselectDropdownProps } from "../../common/multiselect-dropdowns/IMultiselectDropdownProps";
import ValidationWrapper from "../../common/validation/ValidationWrapper";
import InputWrapper from "common/components/input-components/InputWrapper";
import {
    ESharedOptionsType,
    useSharedOptions,
} from "common/components/input-components/dropdown/api/hooks";
import SelectDropdown from "common/components/select-dropdown/SelectDropdown";

type IOwnProps = {
    required?: boolean;
    testId?: string;
};

type Props = IMultiselectDropdownProps & IOwnProps;

const EquipmentMultiselectWithWrapper: React.FC<
    React.PropsWithChildren<Props>
> = (props) => {
    const { values, label, uniqueId, isDisabled, required, error } = props;

    const { t } = useTranslation();

    const { data: allOptions = [] } = useSharedOptions(
        true,
        ESharedOptionsType.equipment,
        values,
    );

    const selectedOptions: IMultiselectOption[] = values
        ? allOptions.filter((x) => values.includes(x.value))
        : [];

    const handleOnChange = (options: any) => {
        if (options) {
            const selectedValues = options as IMultiselectOption[];

            props.onChange(
                selectedValues.map((x) => x.value),
                selectedValues,
            );
        } else {
            props.onChange([], []);
        }
    };

    const controlLabel = label || t(k.EQUIPMENT);

    const controlId = uniqueId || "form.selectEquipment";

    return (
        <InputWrapper wrapperLabel={controlLabel} required={required} boldLabel>
            <ValidationWrapper errors={error}>
                <SelectDropdown
                    id="equiptementsMultiselectDropdown"
                    testId={props.testId}
                    inputId={controlId}
                    value={selectedOptions}
                    isMulti
                    isSearchable
                    isDisabled={isDisabled}
                    placeholder={controlLabel}
                    options={allOptions}
                    closeMenuOnSelect={false}
                    onChange={handleOnChange}
                />
            </ValidationWrapper>
        </InputWrapper>
    );
};

export default EquipmentMultiselectWithWrapper;
