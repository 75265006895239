import "./CustomListItemForm.scss";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { v4 as uuidv4 } from "uuid";

import k from "i18n/keys";

import ICustomListItemRowDTO from "../api/ICustomListItemRowDTO";
import {
    useArchiveCustomListItemMutation,
    useCustomListItemById,
    useDeleteCustomListItemMutation,
    useSaveCustomListItemMutation,
} from "../api/hooks";
import { useCheckIfCanRunChecklists } from "../api/hooks/CheckIfCanRunChecklists";
import ArchiveMessage from "./messages/ArchiveMessage";
import DeleteMessage from "./messages/DeleteMessage";
import UnarchiveMessage from "./messages/UnarchiveMessage";
import Button from "common/components/buttons/Button";
import ButtonGroup from "common/components/buttons/ButtonGroup";
import ModalContent from "common/components/modal-content/ModalContent";
import { IShowModalPayload } from "common/components/modal-manager/api/IModalManager";
import NotAvailableModal from "common/components/modal-manager/no-access-modal/NotAvailableModal";
import IDictionary from "common/viewModels/IDictionary";
import IValidationMessages from "common/viewModels/IValidationMessages";
import SharedPropertyInput from "components/common/shared-properties/components/SharedPropertyInput";
import StartChecklistButton from "components/common/shared-properties/components/inputs/StartChecklistButton";
import AnimatedSpinner from "components/common/spinners/AnimatedSpinner";
import { scrollToTopErrorById } from "components/common/validation/ScrollToError";
import { CustomListPropertyEnum } from "components/custom-list-page/api/CustomListPropertyEnum";
import { ICustomListPropertyTemplateValue } from "components/custom-list-page/api/ICustomListPropertyTemplateValue";
import {
    useCustomListPermissions,
    usePropertiesByCustomListId,
} from "components/custom-list-page/api/hooks";
import { IAppState } from "store/IAppState";
import {
    IShowConfirmArgs,
    showConfirmDispatch,
    showConfirmNoThunk,
} from "store/confirms/actions";

interface IProps {
    id?: string;

    defaultCustomListId?: string;

    createNew?: boolean;

    haveChanges?: boolean;

    syncData: boolean;

    onShowModal: (payload: IShowModalPayload) => void;
    onHaveChanges: (haveChanges: boolean) => void;
    onClose: (onSave?: boolean) => void;
}

function filterValues<T>(
    savedValues: IDictionary<T[]>,
    allProperties: ICustomListPropertyTemplateValue[],
) {
    return Object.keys(savedValues).reduce<IDictionary<T[]>>(
        (map, parentId) => {
            if (allProperties.some((x) => x.parentId === parentId)) {
                map[parentId] = savedValues[parentId];
            }

            return map;
        },
        {},
    );
}

const CustomListItemForm: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { createNew = false, id: customListItemId, haveChanges } = props;

    const canArchive = useSelector(
        (appState: IAppState) =>
            appState.authViewState.roles.ADMINISTRATOR ||
            appState.authViewState.roles.MAPPER,
    );

    const canDelete = useSelector(
        (appState: IAppState) => appState.authViewState.roles.ADMINISTRATOR,
    );

    const userId = useSelector(
        (appState: IAppState) => appState.authViewState.profile?.id,
    );

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(createNew);

    const [errors, setErrors] = useState<IValidationMessages>({});

    const [savedValues, setSavedValues] = useState<IDictionary<string[]>>({});

    const [savedDateValues, setSavedDateValues] = useState<IDictionary<Date[]>>(
        {},
    );

    const { data: listItem, isLoading: isLoadingListItem } =
        useCustomListItemById(customListItemId);

    const customListId = createNew
        ? props.defaultCustomListId
        : listItem?.customListId;

    const { data: permissions, isLoading: isLoadingPermissions } =
        useCustomListPermissions(customListId);

    const canEdit =
        permissions?.isEditor ||
        permissions?.isOwner ||
        (permissions?.isUser &&
            (createNew
                ? true
                : permissions?.customListItemIdsUserCanAccess.includes(
                      customListItemId ?? "",
                  )));
    const cannotEditAccessLimited =
        !permissions?.isEditor && !permissions?.isOwner && permissions?.isUser;

    const { data: selectedProperties, isLoading: isLoadingProperties } =
        usePropertiesByCustomListId(customListId);

    const isLoading =
        isLoadingListItem || isLoadingProperties || isLoadingPermissions;

    useEffect(() => {
        if (selectedProperties && createNew) {
            const accessLimitedProperties = selectedProperties.ids.reduce<
                ICustomListPropertyTemplateValue[]
            >((map, id) => {
                const property = selectedProperties.values[id];

                if (property && property.userAccessLimited) {
                    map.push(property);
                }

                return map;
            }, []);

            if (cannotEditAccessLimited) {
                setSavedValues((prev) => {
                    const newValues = { ...prev };

                    accessLimitedProperties.forEach((property) => {
                        newValues[property.parentId] = [userId ?? ""];
                    });

                    return newValues;
                });
            }
        }
    }, [
        selectedProperties,
        listItem,
        permissions,
        cannotEditAccessLimited,
        isLoading,
    ]);

    const saveMutation = useSaveCustomListItemMutation();
    const archiveMutation = useArchiveCustomListItemMutation();
    const deleteMutation = useDeleteCustomListItemMutation();

    const isSaving = saveMutation.isPending;

    const values = { ...listItem?.values, ...savedValues };
    const dateValues = { ...listItem?.dateValues, ...savedDateValues };

    const { data: canRunChecklistsWithIds } = useCheckIfCanRunChecklists(
        customListItemId ? [customListItemId] : [],
    );

    const handleArchive = async () => {
        if (listItem) {
            const isArchive = listItem.isArchived ? false : true;

            const confirmOk = await showConfirmNoThunk(
                dispatch,
                isArchive ? <UnarchiveMessage /> : <ArchiveMessage />,
            );

            if (confirmOk && customListItemId) {
                archiveMutation.mutate({
                    request: { id: customListItemId, isArchive },
                });
            }
        }
    };

    const handleDelete = async () => {
        const confirmMsg: IShowConfirmArgs = {
            message: <DeleteMessage />,
            yesButtonVariant: "danger",
            yesLabel: t(k.DELETE),
        };

        const confirmOk = await showConfirmNoThunk(dispatch, confirmMsg);

        if (confirmOk && customListItemId) {
            const result = await deleteMutation.mutateAsync({
                id: customListItemId,
            });

            if (result.Succeeded) {
                props.onClose(true);
            } else {
                toast.dismiss();
                toast.error(result.Messages._error, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const handleChange = (parentId: string, values: string[]) => {
        props.onHaveChanges(true);

        setSavedValues((prev) => ({ ...prev, [parentId]: values }));
    };

    const handleChangeDate = (parentId: string, values: Date[]) => {
        props.onHaveChanges(true);

        setSavedDateValues((prev) => ({ ...prev, [parentId]: values }));
    };

    const handleCancel = async () => {
        if (createNew) {
            props.onClose();
        } else {
            if (haveChanges) {
                const args: IShowConfirmArgs = {
                    message: t(k.THERE_ARE_UNSAVED_CHANGES),
                };
                if (await showConfirmDispatch(dispatch, args)) {
                    resetEdits();
                }
            } else {
                resetEdits();
            }
        }
    };

    const resetEdits = () => {
        setEditMode(false);

        setErrors({});

        props.onHaveChanges(false);

        setSavedValues(listItem?.values ?? {});

        setSavedDateValues(listItem?.dateValues ?? {});
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const currentId = React.useRef(createNew ? uuidv4() : customListItemId);

    const handleSave = async () => {
        const allProperties = Object.values(
            selectedProperties?.values ?? {},
        ).filter((x) => x) as ICustomListPropertyTemplateValue[];

        const newValues: ICustomListItemRowDTO = {
            id: currentId.current ?? "",
            isArchived: false,
            customListId: customListId ?? "",
            values: filterValues(values, allProperties),
            dateValues: filterValues(dateValues, allProperties),
            notCustomListNames: {},
            tasklistOptionsByIds: {},
        };

        const result = await saveMutation.mutateAsync({
            isCreate: createNew,
            value: newValues,
        });

        if (result.Succeeded) {
            setErrors({});

            if (createNew) {
                props.onClose(true);
            } else {
                props.onHaveChanges(false);

                setEditMode(false);
            }
        } else {
            const errorKeys = Object.keys(result.Messages);

            if (errorKeys.length > 0) {
                setErrors(result.Messages);

                const ids = errorKeys.reduce<string[]>((map, key) => {
                    map.push(`form.${key}`);
                    return map;
                }, []);

                scrollToTopErrorById(ids);
            }
        }
    };

    const handleOnClose = () => {
        props.onClose();
    };

    const isSaveDisabled = listItem?.isArchived || isSaving;

    const noValueLoaded = createNew === false && listItem === undefined;

    const defaultEditTitle = createNew
        ? t(k.ADD_NEW)
        : editMode
          ? t(k.EDIT)
          : t(k.PREVIEW);

    const title = canEdit ? defaultEditTitle : t(k.PREVIEW);

    const isPreview = canEdit ? editMode === false : true;

    const disabled = isSaving || isPreview;

    const showLoadingWhenAccessLimited =
        createNew &&
        Object.keys(savedValues).length == 0 &&
        cannotEditAccessLimited;

    if (showLoadingWhenAccessLimited || !(permissions?.isViewer || canEdit)) {
        if (isLoading || showLoadingWhenAccessLimited) {
            return (
                <AnimatedSpinner
                    position="center"
                    aligned="center"
                    isVisible={true}
                />
            );
        }
        return (
            <NotAvailableModal
                onClose={handleOnClose}
                message={t(
                    k.NO_AVAILABLE_CUSTOMLISTITEM_FOUND_FOR_YOUR_USER_ROLE,
                )}
            />
        );
    }

    return (
        <ModalContent>
            <ModalContent.Header
                onClose={handleOnClose}
                title={title}
                actions={
                    listItem &&
                    customListItemId &&
                    isPreview &&
                    canRunChecklistsWithIds?.anyResult && (
                        <StartChecklistButton
                            isArchived={listItem.isArchived ?? false}
                            customListItemId={customListItemId}
                            templateVersionId={
                                canRunChecklistsWithIds.values[
                                    customListItemId
                                ][0]
                            }
                            noMinWidth
                            className="custom-list-item-form__start_checklist"
                        >
                            {t(k.START_CHECKLIST)}
                        </StartChecklistButton>
                    )
                }
            />

            <ModalContent.Body>
                <div className="custom-list-item-form--body">
                    <AnimatedSpinner
                        position="center"
                        isVisible={isLoading || showLoadingWhenAccessLimited}
                    />

                    {selectedProperties?.ids.map((id) => {
                        const property = selectedProperties.values[id];

                        if (!property) {
                            return;
                        }
                        const isDateTime =
                            property.type === CustomListPropertyEnum.DateTime;

                        return isDateTime ? (
                            <SharedPropertyInput.DateTime
                                key={id}
                                customListItemId={null}
                                showArchivedOrDeleted={null}
                                values={dateValues[property.parentId] ?? null}
                                property={property}
                                errors={errors}
                                disabled={disabled}
                                onChange={handleChangeDate}
                            />
                        ) : (
                            <SharedPropertyInput
                                key={id}
                                userId={userId}
                                cannotEditAccessLimited={
                                    cannotEditAccessLimited
                                }
                                customListItemId={currentId.current ?? null}
                                showArchivedOrDeleted={null}
                                preview={isPreview}
                                values={values[property.parentId] ?? null}
                                property={property}
                                errors={errors}
                                disabled={disabled}
                                setErrors={setErrors}
                                onChange={handleChange}
                            />
                        );
                    })}
                </div>
            </ModalContent.Body>

            <ModalContent.Footer>
                <ButtonGroup
                    className={
                        isPreview
                            ? "custom-list-item-form--footer custom-list-item-form--footer__preview"
                            : "custom-list-item-form--footer"
                    }
                >
                    {editMode && canEdit ? (
                        <React.Fragment>
                            <Button
                                variant="danger"
                                transparent
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                {t(k.CANCEL)}
                            </Button>

                            <Button
                                variant="success"
                                onClick={handleSave}
                                disabled={isSaveDisabled}
                                isBusy={isSaving}
                                testId="customListSaveBtn"
                            >
                                {t(k.SAVE)}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                variant="danger"
                                transparent
                                onClick={handleOnClose}
                                disabled={isSaving}
                            >
                                {t(k.CLOSE)}
                            </Button>

                            {listItem?.isDeleted
                                ? undefined
                                : canEdit && (
                                      <React.Fragment>
                                          {listItem?.isArchived ? (
                                              <React.Fragment>
                                                  {canArchive && (
                                                      <Button
                                                          variant="warning"
                                                          onClick={
                                                              handleArchive
                                                          }
                                                          disabled={
                                                              isSaving ||
                                                              noValueLoaded
                                                          }
                                                      >
                                                          {t(k.UN_ARCHIVE)}
                                                      </Button>
                                                  )}
                                                  {canDelete && (
                                                      <Button
                                                          variant="danger"
                                                          onClick={handleDelete}
                                                          disabled={
                                                              isSaving ||
                                                              noValueLoaded
                                                          }
                                                      >
                                                          {t(k.DELETE)}
                                                      </Button>
                                                  )}
                                              </React.Fragment>
                                          ) : (
                                              <React.Fragment>
                                                  {canArchive && (
                                                      <Button
                                                          variant="gray"
                                                          transparent
                                                          onClick={
                                                              handleArchive
                                                          }
                                                          disabled={
                                                              isSaving ||
                                                              noValueLoaded
                                                          }
                                                      >
                                                          {t(k.ARCHIVE)}
                                                      </Button>
                                                  )}
                                                  <Button
                                                      variant="blue"
                                                      transparent
                                                      onClick={handleEdit}
                                                      disabled={
                                                          isSaving ||
                                                          noValueLoaded
                                                      }
                                                  >
                                                      {t(k.EDIT)}
                                                  </Button>
                                              </React.Fragment>
                                          )}
                                      </React.Fragment>
                                  )}
                        </React.Fragment>
                    )}
                </ButtonGroup>
            </ModalContent.Footer>
        </ModalContent>
    );
};

export default CustomListItemForm;
