import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import DangerBadge, { IDangerBadgeProps } from "../../base-badges/badge-danger/DangerBadge";

interface IDeletedBadgeProps extends IDangerBadgeProps {
    label?: string;
}

const DeletedBadge = (props: IDeletedBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return <DangerBadge {...rest}>{label ?? t(k.DELETED)}</DangerBadge>;
};

export default DeletedBadge;
