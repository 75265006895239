import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import { TooltipProps } from "common/components/tooltip/Tooltip";
import { InfoIcon } from "common/components/icons";
import WarningBadge, { IWarningBadgeProps } from "../../base-badges/badge-warning/WarningBadge";

interface IBlockedDangerBadgeProps extends IWarningBadgeProps {
    label?: string;
    iconTooltip?: TooltipProps;
}

const AtRiskBadge = (props: IBlockedDangerBadgeProps) => {
    const { label, iconTooltip, ...rest } = props;
    const { t } = useTranslation();

    return (
        <WarningBadge {...rest}>
            {label ?? t(k.AT_RISK)}
            {iconTooltip && (
                <>
                    &nbsp;
                    <InfoIcon size="small" tooltip={iconTooltip} />
                </>
            )}
        </WarningBadge>
    );
};

export default AtRiskBadge;
