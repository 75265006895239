import axios, { AxiosResponse } from "axios";

import IDeviationPageFilter from "http/filters/IDeviationPageFilter";

export const downloadDeviationsAsync = async (
    filter: IDeviationPageFilter,
    defaultFileName: string = "unknown",
) => {
    const url: string = "/api/export/deviations";

    const response = await axios.post(url, filter, { responseType: "blob" });

    downloadFile(response, defaultFileName);
};

const getFileNameFromResponse = (
    response: AxiosResponse<any>,
    defaultFileName: string,
) => {
    let fileName: string = defaultFileName;

    const contentDisposition = response.headers["content-disposition"];

    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
        }
    }

    return fileName;
};

async function downloadFile(
    response: AxiosResponse<any>,
    defaultFileName: string,
) {
    const fileName = getFileNameFromResponse(response, defaultFileName);

    const blob = new Blob([response.data], {
        type: response.data.type,
    });

    await saveFileAsync(blob, fileName);
}

export async function saveFileAsync(blob: Blob, suggestedName: string) {
    try {
        const saveAsDialog = window.showSaveFilePicker;

        if (saveAsDialog) {
            const handle = await saveAsDialog({
                suggestedName,
            });

            const writable = await handle.createWritable();
            await writable.write(blob);
            await writable.close();

            return;
        }
    } catch (err: any) {
        if (err.name === "AbortError") {
            return;
        } else {
            console.log("save as error:", err);
        }
    }

    const link = document.createElement("a");
    link.style.setProperty("display", "none");
    link.href = window.URL.createObjectURL(blob);

    link.setAttribute("download", suggestedName);

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
    }, 0);
}
