import axios from "axios";

import { useMutation } from "@tanstack/react-query";
import IFinalizeStepInstanceRequest from "http/requests/IFinalizeStepInstanceRequest";
import IFinalizeStepInstanceDTO from "models/dto/IFinalizeStepInstanceDTO";

const baseUrl = "/api/stepinstance";

export const useFinalizeStepInstanceMutation = () => {
    return useMutation({
        mutationFn: async (value: IFinalizeStepInstanceRequest) => {
            const response = await axios.post<IFinalizeStepInstanceDTO>(
                `${baseUrl}/finalize`,
                value,
            );

            return response.data;
        },
    });
};
