import "./ListedContent.scss";

import { RefObject, useState } from "react";

import { Content, SidePanel } from "./components";

interface IListedProps {
    testId?: string;
    className?: string;
    listedContentRef?: RefObject<HTMLDivElement>;
    onScroll?: (event?: React.UIEvent<HTMLDivElement>) => void;
}

const ListedContent = (props: React.PropsWithChildren<IListedProps>) => {
    const { className = "" } = props;

    const [isScrolled, setIsScrolled] = useState(false);

    const onScroll = (e: any) => {
        setIsScrolled(e.target.scrollTop > 0);
    };

    return (
        <div
            data-testid={props.testId}
            className={`listed-content ${className} ${
                isScrolled ? "is-scrolled" : ""
            }`}
            onScroll={onScroll}
            ref={props.listedContentRef}
        >
            {props.children}
        </div>
    );
};

ListedContent.Content = Content;
ListedContent.SidePanel = SidePanel;

export default ListedContent;
