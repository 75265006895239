import i18n from "i18next";

import k from "i18n/keys";
import { IOption } from "common/IOption";

export enum WorkflowScheduleStatus {
    Draft,
    Published,

    Paused,
    Archived,
}
