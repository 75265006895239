export function isDevelopmentEnvironment() {
    return process.env.NODE_ENV === "development";
}

const testHosts: string[] = [
    "test-sso.ampliflow.com",
    "test-1.ampliflow.com",
    "test-2.ampliflow.com",
    "test-3.ampliflow.com",
    "test-4.ampliflow.com",
];

export function isOnTestAmpliflowHost() {
    return testHosts.some((x) => window.location.host.indexOf(x) > -1);
}

export function isOnLocalhost() {
    return window.location.host.indexOf("localhost") > -1;
}
