import { Reducer } from "redux";

import { IShowConfirmArgs } from "./actions";
import {
    ConfirmActionTypes,
    HIDE_CONFIRM_MODAL,
    SHOW_CONFIRM_MODAL,
} from "./types";

export interface IConfirmState {
    readonly isVisible: boolean;
    readonly isResolved: boolean;
    readonly confirm: IShowConfirmArgs;
    readonly result?: boolean;
}

const initialConfirmState: IShowConfirmArgs = {
    message: "",
};

const initialState: IConfirmState = {
    isVisible: false,
    isResolved: false,
    confirm: initialConfirmState,
    result: undefined,
};

export const ConfirmReducer = (
    state = initialState,
    action: ConfirmActionTypes,
): IConfirmState => {
    switch (action.type) {
        case SHOW_CONFIRM_MODAL: {
            return {
                ...state,
                confirm: action.payload,
                isVisible: true,
                isResolved: false,
                result: undefined,
            };
        }

        case HIDE_CONFIRM_MODAL: {
            return {
                ...state,
                confirm: initialConfirmState,
                isVisible: false,
                isResolved: true,
                result: action.payload,
            };
        }

        default:
            return state;
    }
};
