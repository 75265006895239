import "./ArrowLeftIcon.scss";

import Icon from "./assets/arrow-left-icon.svg";

interface IArrowLeftIconProps {
    className?: string;
}

const ArrowLeftIcon = ({ className }: IArrowLeftIconProps) => {
    return (
        <Icon
            className={`arrow-left-icon${className ? " " + className : ""}`}
        />
    );
};

export default ArrowLeftIcon;
