import axios from "axios";

import IChecklistReportDTO from "../api/IChecklistReportDTO";
import IChecklistReportFilter from "../api/IChecklistReportFilter";
import {
    ChecklistReportValueSumFilterResponseType,
    IChecklistReportValueSumFilter,
} from "../components/report-table-details/api/IChecklistReportValueSumFilter";
import { ViewMode } from "../components/report-table/ChecklistsTable";
import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    IInitialFetchOptions,
    useInitialFetch,
} from "common/utils/query/useInitialFetch";
import { convertToDate } from "common/utils/time";
import IDictionary from "common/viewModels/IDictionary";
import IChecklistReportTableMatrixResponse from "components/template-checklist-report/components/report-table-details/api/IChecklistReportResponse";
import IArchiveRequest from "http/requests/IArchiveRequest";
import IPauseRequest from "http/requests/IPauseRequest";
import IArchiveResponse from "http/responses/IArchiveResponse";
import IPauseResponse from "http/responses/IPauseResponse";

const baseUrl = "/api/workflowRun";

const SyncIntervalMs = 5000;

async function getSearchTemplateList(
    filter: IChecklistReportFilter,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/template-list`;

    const response = await axios.post<string[]>(url, filter, {
        signal,
    });

    return response.data;
}

export const useSearchTemplateListQuery = (
    { enabled, syncData }: IInitialFetchOptions,
    filter: IChecklistReportFilter,
) =>
    useInitialFetch(
        ["useSearchTemplateList", filter],
        ({ signal }) => getSearchTemplateList(filter, signal),
        { enabled, syncData },
    );

export const useOnlyStoppableChecklistsTotalCount = (
    parentFilter: IChecklistReportFilter,
    templateVersionIds?: string[],
    view?: ViewMode,
) => {
    const client = useQueryClient();

    return useQuery({
        queryKey: [
            "useTotalChecklistsCountToPause",
            parentFilter,
            templateVersionIds,
        ],
        queryFn: () => {
            let result: number | undefined;

            if (
                templateVersionIds === undefined ||
                templateVersionIds.length === 0
            ) {
                result = client.getQueryData<number>([
                    "report-totalOnlyStoppableChecklistsCount",
                    parentFilter,
                ]);
            } else if (templateVersionIds.length === 1) {
                result = client.getQueryData<number>([
                    "report-totalOnlyStoppableChecklistsCount",
                    parentFilter,
                    templateVersionIds[0],
                ]);
            } else {
                if (view?.endsWith("PerTemplate")) {
                    let sum: number = 0;

                    for (const id of templateVersionIds) {
                        const resultById = client.getQueryData<number>([
                            "report-totalOnlyStoppableChecklistsCount",
                            parentFilter,
                            id,
                        ]);

                        if (resultById !== undefined) {
                            sum += resultById;
                        }
                    }

                    result = sum;
                } else {
                    result = client.getQueryData<number>([
                        "report-totalOnlyStoppableChecklistsCount",
                        parentFilter,
                        templateVersionIds,
                    ]);
                }
            }

            return result ?? 0;
        },
    });
};

async function getChecklistReportItems(
    filter?: IChecklistReportFilter,
    signal?: AbortSignal,
) {
    if (filter) {
        const url = `${baseUrl}/report`;

        const response = await axios.post<IChecklistReportDTO>(url, filter, {
            signal,
        });

        return response.data;
    }
}

export async function getCalculateValueSums(
    filter: IChecklistReportValueSumFilter,
    signal?: AbortSignal,
) {
    const url = `${baseUrl}/calc-value-sum`;

    const response =
        await axios.post<ChecklistReportValueSumFilterResponseType>(
            url,
            filter,
            {
                signal,
            },
        );

    return response.data;
}

const onSettled = (
    queryClient: QueryClient,
    parentFilter: IChecklistReportFilter,
    filter?: IChecklistReportFilter,
    result?: IChecklistReportDTO,
) => {
    let invalidateQueries = false;
    if (
        filter?.templateVersionIds === undefined ||
        filter.templateVersionIds.length === 0
    ) {
        if (
            queryClient.getQueryData<number>([
                "report-totalOnlyStoppableChecklistsCount",
                parentFilter,
            ]) !== result?.totalOnlyStoppableChecklistsCount
        ) {
            invalidateQueries = true;
            queryClient.setQueryData(
                ["report-totalOnlyStoppableChecklistsCount", parentFilter],
                result?.totalOnlyStoppableChecklistsCount,
            );
        }
    } else if (filter.templateVersionIds.length === 1) {
        if (
            queryClient.getQueryData([
                "report-totalOnlyStoppableChecklistsCount",
                parentFilter,
                filter?.templateVersionIds[0],
            ]) !== result?.totalOnlyStoppableChecklistsCount
        ) {
            invalidateQueries = true;
            queryClient.setQueryData(
                [
                    "report-totalOnlyStoppableChecklistsCount",
                    parentFilter,
                    filter?.templateVersionIds[0],
                ],
                result?.totalOnlyStoppableChecklistsCount,
            );
        }
    } else {
        if (
            queryClient.getQueryData([
                "report-totalOnlyStoppableChecklistsCount",
                parentFilter,
                filter?.templateVersionIds,
            ]) !== result?.totalOnlyStoppableChecklistsCount
        ) {
            invalidateQueries = true;
            queryClient.setQueryData(
                [
                    "report-totalOnlyStoppableChecklistsCount",
                    parentFilter,
                    filter?.templateVersionIds,
                ],
                result?.totalOnlyStoppableChecklistsCount,
            );
        }
    }
    if (invalidateQueries) {
        queryClient.invalidateQueries({
            queryKey: ["useTotalChecklistsCountToPause", parentFilter],
        });
    }
};

export const useChecklistReportItemsQuery = (
    { enabled, syncData }: IInitialFetchOptions,
    parentFilter: IChecklistReportFilter,
    filter?: IChecklistReportFilter,
) => {
    const queryClient = useQueryClient();

    return useInitialFetch(
        ["useChecklistReportItems", filter],

        async ({ signal }) => {
            const result = await getChecklistReportItems(filter, signal);

            onSettled(queryClient, parentFilter, filter, result);

            return result;
        },

        { enabled, syncData },
        {
            refetchInterval: SyncIntervalMs,
        },
    );
};

async function pauseAll(filter: IChecklistReportFilter) {
    const url = `${baseUrl}/pause-all`;

    const response = await axios.post<number>(url, filter);

    return response.data;
}

export const usePauseAllMutation = () => {
    return useMutation({
        mutationFn: (filter: IChecklistReportFilter) => pauseAll(filter),
    });
};

export const useDeleteByIdChecklistMutation = () => {
    return useMutation({
        mutationFn: async (checklistId: string) => {
            try {
                await axios.delete(`${baseUrl}/${checklistId}`);

                return true;
            } catch (ex) {}

            return false;
        },
    });
};

export const useArchiveChecklistMutation = () => {
    return useMutation({
        mutationFn: async (variables: IArchiveRequest) => {
            try {
                const response = await axios.post<IArchiveResponse>(
                    `${baseUrl}/archive`,
                    variables,
                );

                const result = response.data;

                result.archivedAt = convertToDate(result.archivedAt);

                return result;
            } catch (error) {}
        },
    });
};

export const usePauseChecklistMutation = () => {
    return useMutation({
        mutationFn: async (variables: IPauseRequest) => {
            try {
                const response = await axios.post<IPauseResponse>(
                    `${baseUrl}/pause`,
                    variables,
                );

                const result = response.data;

                result.pausedAt = convertToDate(result.pausedAt);

                return result;
            } catch (error) {}
        },
    });
};

async function getChecklistReportDetailsByIds(
    checklistIds: string[],
    signal?: AbortSignal,
) {
    if (checklistIds.length > 0) {
        const url = `${baseUrl}/report-details`;

        const response = await axios.post<IChecklistReportTableMatrixResponse>(
            url,
            checklistIds,
            { signal },
        );

        return response.data;
    }
}

export const useChecklistReportDetailsQuery = (
    { syncData }: IInitialFetchOptions,
    uniqueKeys: IDictionary<string>,
    checklistIds: string[],
) =>
    useInitialFetch(
        ["useChecklistReportDetails", uniqueKeys],
        ({ signal }) => getChecklistReportDetailsByIds(checklistIds, signal),
        { enabled: checklistIds.length > 0, syncData },
        { refetchInterval: SyncIntervalMs },
    );
