import "./ClockIcon.scss";

import React from "react";

import Icon from "./assets/clock-icon.svg";

const ClockIcon: React.FC = (props) => {
    return <Icon />;
};

export default ClockIcon;
