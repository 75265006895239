import "./ArrowRightIcon.scss";

import Icon from "./assets/arrow-right-icon.svg";

interface IArrowRightIconProps {
    className?: string;
}

const ArrowRightIcon = ({ className }: IArrowRightIconProps) => {
    return (
        <Icon
            className={`arrow-right-icon${className ? " " + className : ""}`}
        />
    );
};

export default ArrowRightIcon;
