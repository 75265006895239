import "./UpgradeTemplateInfo.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import k from "i18n/keys";
import IWorkflowUpgradeResponse from "http/responses/IWorkflowUpgradeResponse";

import Button from "common/components/buttons/Button";

import FormattedDate from "common/components/formattedDate/FormattedDate";
import { getNotUpgradableChecklists } from "components/templates/api/hooks";
import WorksheetStatusBadge from "common/components/badges/worksheet-status-badge/WorksheetStatusBadge";

interface IProps {
    upgradeResponseInfo: IWorkflowUpgradeResponse;
}

const UpgradeTemplateInfo: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { upgradeResponseInfo } = props;

    const { t } = useTranslation();

    const [templateVersionId, setTemplateVersionId] = useState<string>();

    const { isLoading, data: runs } =
        getNotUpgradableChecklists(templateVersionId);

    const handleShowWorkflowRuns = () => {
        setTemplateVersionId(upgradeResponseInfo.workflowVersionId);
    };

    return (
        <>
            <p>
                <strong>
                    {upgradeResponseInfo.notStartedCount +
                        upgradeResponseInfo.startedCanUpgradeCount}
                </strong>{" "}
                {t(k.CHECKLISTS_WILL_BE_UPGRADED_AND)}{" "}
                <strong>{upgradeResponseInfo.startedCannotUpgradeCount}</strong>{" "}
                {t(k.CANT_BE_UPGRADED)}
            </p>
            {upgradeResponseInfo.startedCannotUpgradeCount > 0 && (
                <React.Fragment>
                    <div>
                        {t(k.A_CHECKLIST_CANT_BE_UPGRADED_IF_IT_HAS)}:
                        <ul>
                            <li>{t(k.COMPLETED_ACTIVITIES)}</li>
                            <li>{t(k.IMPROVEMENTS_REPORTED_TO_AN_ACTIVITY)}</li>
                            <li>{t(k.USERS_ASSIGNED_TO_AN_ACTIVITY)}</li>
                            <li>{t(k.CHANGED_TEAMS_IN_STEPS)}</li>
                            <li>{t(k.COMMENTS)}</li>
                        </ul>
                    </div>

                    <div>
                        <Button
                            isBusy={isLoading}
                            onClick={handleShowWorkflowRuns}
                        >
                            {t(k.VIEW_CHECKLISTS_THAT_CANT_BE_UPGRADED)}
                        </Button>

                        {runs && runs.length > 0 && (
                            <div className="not-upgradable-runs-list">
                                {runs.map((checklist) => (
                                    <div key={checklist.id}>
                                        <a
                                            href={`/?modal=checklist&id=${checklist.id}`}
                                            target="_blank"
                                        >
                                            <WorksheetStatusBadge
                                                status={checklist.status}
                                                completedAt={
                                                    checklist.completedAt
                                                }
                                            />

                                            <FormattedDate
                                                date={checklist.startDate}
                                            />

                                            <span className="mr-1">
                                                {checklist.startDate
                                                    ? ` - ${checklist.title}`
                                                    : checklist.title}
                                            </span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default UpgradeTemplateInfo;
