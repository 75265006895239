import { useTranslation } from "react-i18next";

import k from "i18n/keys";

import { IGradingBadgeBaseProps } from "../IGradingBadgeBaseProps";
import ScoreBadge from "common/components/badges/base-badges/badge-score/ScoreBadge";

type ILowGradeBadgeProps = IGradingBadgeBaseProps;

const LowGradeBadge = (props: ILowGradeBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <ScoreBadge
            score="sm"
            label={label ?? (props.short ? 2 : t(k.IMPACT_SCORE2_LABEL))}
            {...rest}
        />
    );
};

export default LowGradeBadge;
