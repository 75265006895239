export enum EAvailableAppFeatures {
    customers = "customers",
    bulkImport = "bulkImport",
    suppliers = "suppliers",
    userDefinedTasks = "userDefinedTasks",
    sharedSteps = "sharedSteps",
    itemCatalog = "itemCatalog",
    purchaseOrders = "purchaseOrders",
    barcodeActivity = "barcodeActivity",
    generateBarcodeActivity = "generateBarcodeActivity",
    stopMultipleChecklists = "stopMultipleChecklists",
    processChart = "processChart",
    cloudTemplates = "cloudTemplates",
    stakeholders = "stakeholders",
    legislations = "legislations",
    environmentalAspects = "environmentalAspects",
    customerRequirements = "customerRequirements",
    competencyMatrixes = "competencyMatrixes",
    trainingPlans = "trainingPlans",
    staffAppraisals = "staffAppraisals",
    impactGradings = "impactGradings",
    operationalRiskAnalysis = "operationalRiskAnalysis",
    tasks = "tasks",
    competencies = "competencies",
    customLists = "customLists",
    improvementDashboard = "improvementDashboard",
    assignImpactedProcessesToImprovement = "assignImpactedProcessesToImprovement",
    webhooks = "webhooks",
    kpi = "kpi",
    goals = "goals",
    checklistReport = "checklistReport",
    teams = "teams",
    equipment = "equipment",
    sso = "sso",
    allowUnfinalize = "allowUnfinalize",
    operationalRiskAnalysisGraph = "operationalRiskAnalysisGraph",
    ai = "ai",
    pages = "pages",
    positions = "positions",
    checklistReportTimelineView = "checklistReportTimelineView",
}
