import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import k from "i18n/keys";

import ChecklistDetailsContext from "../../../../../checklistContext";
import IconButton from "common/components/buttons/icon-button/IconButton";
import { ResumeIcon, StopIcon } from "common/components/icons";
import PauseWorksheetMessage from "common/components/messages/PauseWorksheetMessage";
import ResumeWorksheetMessage from "common/components/messages/ResumeWorksheetMessage";
import { usePauseChecklistMutation } from "components/checklists/api/hooks";
import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import { showConfirmNoThunk } from "store/confirms/actions";

interface IProps {}

const StopResumeButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { checklist, editMode, selectedChecklistId, roles } = useContext(
        ChecklistDetailsContext,
    );

    const canStop = roles?.ADMINISTRATOR || roles?.PLANNER;

    const isStopped = checklist?.status === ChecklistDynamicStatus.Stopped;
    const isArchived = checklist?.status === ChecklistDynamicStatus.Archived;
    const isFinalized = checklist?.status === ChecklistDynamicStatus.Finalized;

    const pauseChecklist = usePauseChecklistMutation();

    const onPause = async (isPause: boolean) => {
        if (selectedChecklistId) {
            const result = await pauseChecklist.mutateAsync({
                request: { id: selectedChecklistId, isPause: isPause },
            });
        }
    };

    const handleStopStep = async () => {
        if (
            (await showConfirmNoThunk(dispatch, <PauseWorksheetMessage />)) &&
            selectedChecklistId
        ) {
            await onPause(true);
        }
    };

    const handleResumeStep = async () => {
        if (
            (await showConfirmNoThunk(dispatch, <ResumeWorksheetMessage />)) &&
            selectedChecklistId
        ) {
            await onPause(false);
        }
    };

    return (
        <>
            {isStopped || isArchived ? (
                <IconButton
                    onClick={handleResumeStep}
                    background="dark"
                    tooltip={
                        editMode ? t(k.DISABLED_WHILE_EDITING) : t(k.RESUME)
                    }
                    disabled={isArchived || editMode || isFinalized}
                    testId={"side-panel--header--resume-button"}
                >
                    <ResumeIcon variant="white" />
                </IconButton>
            ) : (
                <IconButton
                    onClick={handleStopStep}
                    background="dark"
                    tooltip={editMode ? t(k.DISABLED_WHILE_EDITING) : t(k.STOP)}
                    disabled={!canStop || editMode || isFinalized}
                    testId={"side-panel--header--stop-button"}
                >
                    <StopIcon variant="white" />
                </IconButton>
            )}
        </>
    );
};

export default StopResumeButton;
