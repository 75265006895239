import axios from "axios";

import { MeasurementAccessRoleEnum } from "./EMeasurementAccessRole";
import { IMeasurementActionSetRequest } from "./IMeasurementActionSetRequest";
import { IMeasurementActionSetResponse } from "./IMeasurementActionSetResponse";
import { IMeasurementDTO } from "./IMeasurementDTO";
import { IMeasurementFilter } from "./IMeasurementFilter";
import { IMeasurementRequest } from "./IMeasurementRequest";
import { IMeasurementStatusCommentRequest } from "./IMeasurementStatusCommentRequest";
import { IMeasurementStatusRequest } from "./IMeasurementStatusRequest";
import {
    keepPreviousData,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import ExecutionResult from "common/viewModels/ExecutionResult";
import { IServerGoalPageFilter } from "components/goals-page/api/IGoalPageFilters";
import ITableSet from "http/ITableSet";
import IArchiveRequest from "http/requests/IArchiveRequest";
import { ISortRequest } from "http/requests/ISortRequest";

const baseUrl = "/api/measurement";

export const measurementKeys = {
    all: ["measurements"] as const,
    getList: () => [...measurementKeys.all] as const,
    getByIds: (filter?: IMeasurementFilter) =>
        [...measurementKeys.all, "measurements-byIds", filter] as const,
    measurementById: (id?: string) =>
        [...measurementKeys.all, "measurement-byId", id] as const,
    getAccessRole: (id?: string) =>
        [`measurement-access-role${id ? "-" + id : ""}`, id] as const,
    getViewAsAccessRole: (id?: string, filter?: IServerGoalPageFilter) =>
        [
            `measurement-view-as-access-role${id ? "-" + id : ""}`,
            JSON.stringify(filter),
        ] as const,
};

export const useMeasurementTableSetByIds = (
    filter: IMeasurementFilter,
    enabled?: boolean,
) => {
    const url = `${baseUrl}/table-set`;

    return useQuery({
        queryKey: measurementKeys.getByIds(filter),

        queryFn: async (context) => {
            const response = await axios.post<ITableSet<IMeasurementDTO>>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },
        enabled,
        placeholderData: keepPreviousData,
    });
};

export const useMeasurementAccessRole = (measurementId?: string) => {
    const url = `${baseUrl}/access-role/${measurementId}`;

    return useQuery({
        queryKey: measurementKeys.getAccessRole(measurementId),

        queryFn: async (context) => {
            const response = await axios.get<MeasurementAccessRoleEnum>(url, {
                signal: context.signal,
            });

            return response.data;
        },

        enabled: !!measurementId,
    });
};

export const useMeasurementViewAsAccessRole = (
    measurementId?: string,
    filter?: IServerGoalPageFilter,
) => {
    const url = measurementId
        ? `${baseUrl}/access-role/${measurementId}`
        : `${baseUrl}/access-role`;

    return useQuery({
        queryKey: measurementKeys.getViewAsAccessRole(measurementId, filter),

        queryFn: async (context) => {
            const response = await axios.post<MeasurementAccessRoleEnum>(
                url,
                filter,
                {
                    signal: context.signal,
                },
            );

            return response.data;
        },

        enabled:
            Object.values(filter ?? {}).some((value) => value !== undefined) &&
            filter?.viewAs !== undefined,
    });
};

export const useSaveMeasurementMutation = () => {
    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IMeasurementRequest;
        }) => {
            try {
                let result: string;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response = await axios.post<string>(baseUrl, value);

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${baseUrl}/${value.id}`,
                        value,
                    );

                    result = response.data;
                }

                return ExecutionResult.Result<string>(result);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useSortActionSetsMutation = () => {
    const url = `${baseUrl}/sort`;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: { request: ISortRequest }) => {
            try {
                const response = await axios.post<string>(
                    url,
                    variables.request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementKeys.measurementById(
                    variables.request.parentId,
                ),
            });
        },
    });
};

export const useMeasurementById = (id?: string, enabled = true) => {
    const url = `${baseUrl}/${id}`;

    return useQuery({
        queryKey: measurementKeys.measurementById(id),

        queryFn: async () => {
            const response = await axios.get<IMeasurementDTO>(url);

            return response.data;
        },

        enabled: enabled && id !== undefined,
    });
};

export const useDeleteMeasurementMutation = () => {
    return useMutation({
        mutationFn: async (id?: string) => {
            try {
                const response = await axios.delete<string>(`${baseUrl}/${id}`);

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useDeleteMeasurementActionSetMutation = () => {
    const url = `${baseUrl}/action-set`;

    return useMutation({
        mutationFn: async (id?: string) => {
            try {
                const response = await axios.delete<string>(`${url}/${id}`);

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useChangeMeasurementStatusMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: IMeasurementStatusRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/changeStatus`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementKeys.measurementById(data.Data),
            });
        },
    });
};

export const useChangeMeasurementStatusCommentMutation = () => {
    return useMutation({
        mutationFn: async (request: IMeasurementStatusCommentRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/changeStatusComment`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },
    });
};

export const useArchiveMeasurementMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: IArchiveRequest) => {
            try {
                const response = await axios.post<string>(
                    `${baseUrl}/archive`,
                    request,
                );

                return ExecutionResult.Result<string>(response.data);
            } catch (error) {
                return ExecutionResult.Failed<string>(error);
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementKeys.measurementById(data.Data),
            });
        },
    });
};

export const useSaveInputActionSetMutation = () => {
    const url = `${baseUrl}/action-set`;
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (variables: {
            isCreate: boolean;
            value: IMeasurementActionSetRequest;
        }) => {
            try {
                let result: IMeasurementActionSetResponse;
                const { isCreate, value } = variables;

                if (isCreate) {
                    const response =
                        await axios.post<IMeasurementActionSetResponse>(
                            url,
                            value,
                        );

                    result = response.data;
                } else {
                    const response = await axios.put<string>(
                        `${url}/${value.id}`,
                        value,
                    );

                    result = { id: response.data };
                }

                return ExecutionResult.Result<IMeasurementActionSetResponse>(
                    result,
                );
            } catch (error) {
                return ExecutionResult.Failed<IMeasurementActionSetResponse>(
                    error,
                );
            }
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({
                queryKey: measurementKeys.measurementById(
                    variables.value.measurementId,
                ),
            });
        },
    });
};
