import "./SidePanelDetails.scss";

import { useContext, useRef } from "react";

import ImprovementsDetailsContext from "../../../improvementContext";
import {
    DateRange,
    InvolvedUsers,
    ReportedBy,
    ReportedFrom,
    ResponsibleFor,
    Title,
} from "./components";
import ImpactBar from "./components/ImpactBar/ImpactBar";
import ImpactGradingBadges from "common/components/badges/impact-grading-badges/ImpactGradingBadges";
import ImprovementStatusBadge from "common/components/badges/improvement-status-badge/ImprovementStatusBadge";
import ListedContent from "common/components/listed-content/ListedContent";
import { DynamicImprovementStatus } from "models/enums/DynamicImprovementStatus";

const SidePanelDetails = () => {
    const {
        improvement,
        mobileView,
        sidePanelRef,
        listedContentRef,
        detailsRef,
    } = useContext(ImprovementsDetailsContext);

    const stickyEndRef = useRef<HTMLDivElement>(null);

    return (
        <ListedContent.SidePanel.Details
            context={ImprovementsDetailsContext}
            footRef={stickyEndRef}
            description={improvement?.templateDescription}
        >
            {improvement && detailsRef && (
                <>
                    <ImpactBar
                        impact={improvement.impact}
                        startRef={detailsRef}
                        endRef={stickyEndRef}
                        scrollContainerRef={
                            mobileView ? listedContentRef : sidePanelRef
                        }
                    />
                    <ImpactGradingBadges type={improvement.impact} />
                </>
            )}

            <Title />

            <DateRange />

            <div className="details-row">
                {improvement && (
                    <ImprovementStatusBadge
                        status={improvement.dynamicStatus}
                        closureReason={
                            improvement.dynamicStatus ===
                            DynamicImprovementStatus.Closed
                                ? improvement.closureReason
                                : undefined
                        }
                    />
                )}
            </div>
            <div className="details__content__sub-details">
                <ReportedBy />
                <ReportedFrom />
                <ResponsibleFor />
                <InvolvedUsers />
            </div>
        </ListedContent.SidePanel.Details>
    );
};

export default SidePanelDetails;
