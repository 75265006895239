import "./Selection.scss";

import React from "react";

import classNames from "classnames";

import { IValueLabelItem } from "common/IValueLabelItem";

interface ISelectionProps<
    V extends React.Key = string,
    L extends React.ReactNode = string,
    P = any,
> {
    options: IValueLabelItem<V, L, P>[];
    selectedValue?: V;
    disabled?: boolean;
    onSelect?: (value: V) => void;
    testId?: string;
    variant?: "horizontal" | "vertical";
}

interface ISelectionButtonProps<
    V extends React.Key = string,
    L extends React.ReactNode = string,
    P = string,
> {
    label: L;
    value: V;
    param?: P;
    isSelected: boolean;
    disabled: boolean;
    testId?: string;
    onSelect?: () => void;
}

const isCSSProperties = (style: any): style is React.CSSProperties => {
    return style && typeof style === "object" && !Array.isArray(style);
};

// SelectionButton component for reusability and readability
const SelectionButton = <V extends React.Key, L extends React.ReactNode, P>({
    label,
    value,
    isSelected,
    param,
    disabled,
    onSelect,
    testId,
}: ISelectionButtonProps<V, L, P>) => {
    const buttonClass = classNames("selection-button", `button-${value}`, {
        "button-active": isSelected,
        "button-disabled": disabled,
    });

    return (
        <button
            className={buttonClass}
            style={isCSSProperties(param) && isSelected ? param : undefined}
            onClick={!disabled ? onSelect : undefined}
            data-testid={testId}
            type="button"
        >
            {label}
        </button>
    );
};

// Selection component that can work with any array of ValueLabel options
const Selection = <V extends React.Key, L extends React.ReactNode, P>({
    options,
    selectedValue,
    disabled = false,
    testId = "selection-button",
    onSelect,
    variant = "vertical",
}: ISelectionProps<V, L, P>) => {
    const rowClass = classNames({
        selection: true,
        "selection--vertical": variant === "vertical",
        "selection--horizontal": variant === "horizontal",
    });

    return (
        <div className={rowClass}>
            {options.map((option) => (
                <SelectionButton<V, L, P>
                    key={option.value}
                    testId={`${testId}-${option.value}`}
                    label={option.label}
                    value={option.value}
                    isSelected={selectedValue === option.value}
                    param={option.param}
                    disabled={disabled}
                    onSelect={() => onSelect?.(option.value)}
                />
            ))}
        </div>
    );
};

export default Selection;
