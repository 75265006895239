import "./PrimaryBadge.scss";

import Badge, { IBaseBadgeProps } from "../Badge";
import { PropsWithChildren } from "react";
import classNames from "classnames";

export interface IPrimaryBadgeProps extends IBaseBadgeProps {
    outline?: boolean;
}

const PrimaryBadge = (props: PropsWithChildren<IPrimaryBadgeProps>) => {
    const { children, outline, ...rest } = props;

    const className = classNames("primary-badge", {
        "primary-badge--outline": outline,
    });

    return (
        <Badge className={className} {...rest}>
            {children}
        </Badge>
    );
};

export default PrimaryBadge;
