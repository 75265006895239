import { ChecklistDynamicStatus } from "models/enums/ChecklistDynamicStatus";
import BehindBadge from "../simple-badges/badge-behind/BehindBadge";
import OnTrackBadge from "../simple-badges/badge-on-track/OnTrackBadge";
import FinalizedBadge from "../simple-badges/badge-finalized/FinalizedBadge";
import BlockedBadge from "../simple-badges/badge-blocked/BlockedBadge";
import ScheduledBadge from "../simple-badges/badge-scheduled/ScheduledBadge";
import StoppedBadge from "../simple-badges/badge-stopped/StoppedBadge";
import ArchivedBadge from "../simple-badges/badge-archived/ArchivedBadge";

interface IChecklistStatusBadgeProps {
    status: ChecklistDynamicStatus;
    text?: string;
    testId?: string;
    title?: string;
}

const ChecklistStatusBadge = (props: IChecklistStatusBadgeProps) => {
    const { status, testId, text, title } = props;

    switch (status) {
        case ChecklistDynamicStatus.Behind:
            return <BehindBadge testId={testId} title={title} label={text} />;
        case ChecklistDynamicStatus.OnTrack:
            return <OnTrackBadge testId={testId} title={title} label={text} />;
        case ChecklistDynamicStatus.Finalized:
            return (
                <FinalizedBadge testId={testId} title={title} label={text} />
            );
        case ChecklistDynamicStatus.Blocked:
            return <BlockedBadge testId={testId} title={title} label={text} />;
        case ChecklistDynamicStatus.Scheduled:
            return (
                <ScheduledBadge testId={testId} title={title} label={text} />
            );
        case ChecklistDynamicStatus.Stopped:
            return <StoppedBadge testId={testId} title={title} label={text} />;
        case ChecklistDynamicStatus.Archived:
            return <ArchivedBadge testId={testId} title={title} label={text} />;
        default:
            return null;
    }
};

export default ChecklistStatusBadge;
