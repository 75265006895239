import axios from "axios";

import ITemplateConfigurationDTO from "./ITemplateConfigurationDTO";
import { useQuery } from "@tanstack/react-query";
import IValueLabelItemWithState from "common/IValueLabelItemWithState";

export const TEMPLATE_CONFIG_BY_VERSION_ID =
    "template-configuration-by-version-id";
export const TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID =
    "template-draft-configuration-by-version-id";

const keys = {
    options: "improvement-form-options-list" as const,
    templateConfigurationById: TEMPLATE_CONFIG_BY_VERSION_ID,
    templateDraftConfigurationById: TEMPLATE_DRAFT_CONFIG_BY_VERSION_ID,
};

const baseUrl = "/api/template";

export const useImprovementFormOptions = (enabled: boolean, ids?: string[]) => {
    return useQuery({
        queryKey: [keys.options],

        queryFn: async (context) => {
            let url = `${baseUrl}/improvement-form-options`;

            if (ids) {
                const params = ids
                    ?.map((id) => {
                        return `ids=${id}`;
                    })
                    .join("&");

                url = `${url}?${params}`;
            }

            const response = await axios.get<IValueLabelItemWithState[]>(url, {
                signal: context.signal,
            });

            return response.data;
        },

        enabled,
    });
};

export const useGetTemplateConfiguration = (templateVersionId?: string) => {
    const url = `${baseUrl}/configuration/${templateVersionId}`;

    return useQuery({
        queryKey: [keys.templateConfigurationById, templateVersionId],

        queryFn: async () => {
            const response = await axios.get<ITemplateConfigurationDTO>(url);

            return response.data;
        },

        enabled: !!templateVersionId,
    });
};

export const useGetTemplateDraftConfiguration = (
    templateVersionId?: string,
) => {
    const url = `${baseUrl}/draft/configuration/${templateVersionId}`;

    return useQuery({
        queryKey: [keys.templateDraftConfigurationById, templateVersionId],

        queryFn: async () => {
            const response = await axios.get<ITemplateConfigurationDTO>(url);

            return response.data;
        },

        enabled: !!templateVersionId,
    });
};
