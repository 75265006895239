import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import DangerBadge, { IDangerBadgeProps } from "../../base-badges/badge-danger/DangerBadge";
import { TooltipProps } from "common/components/tooltip/Tooltip";
import { InfoIcon } from "common/components/icons";

interface IBlockedDangerBadgeProps extends IDangerBadgeProps {
    label?: string;
    iconTooltip?: TooltipProps;
}

const BlockedDangerBadge = (props: IBlockedDangerBadgeProps) => {
    const { label, iconTooltip, ...rest } = props;
    const { t } = useTranslation();

    return (
        <DangerBadge {...rest}>
            {label ?? t(k.BLOCKED)}
            {iconTooltip && (
                <>
                    &nbsp;
                    <InfoIcon size="small" tooltip={iconTooltip} />
                </>
            )}
        </DangerBadge>
    );
};

export default BlockedDangerBadge;
