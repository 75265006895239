import React from "react";
import { EGoalGrading } from "components/goals-page/api/EGoalGrading";
import BehindBadge from "../simple-badges/badge-behind/BehindBadge";
import { TooltipProps } from "common/components/tooltip/Tooltip";
import BlockedDangerBadge from "../simple-badges/badge-blocked-danger/BlockedDangerBadge";
import AtRiskBadge from "../simple-badges/badge-at-risk/AtRiskBadge";
import CompletedBadge from "../simple-badges/badge-completed/CompletedBadge";
import DraftBadge from "../simple-badges/badge-draft/DraftBadge";
import OnTrackBadge from "../simple-badges/badge-on-track/OnTrackBadge";

interface IProps {
    status: EGoalGrading;
    hideOnTrack?: boolean;
    iconTooltip?: TooltipProps;
}

const GoalStatusBadge: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { status, hideOnTrack, iconTooltip } = props;

    switch (status) {
        case EGoalGrading.Behind:
            return <BehindBadge />;
        case EGoalGrading.Blocked:
            return <BlockedDangerBadge iconTooltip={iconTooltip} />;
        case EGoalGrading.AtRisk:
            return <AtRiskBadge iconTooltip={iconTooltip} />;
        case EGoalGrading.Completed:
            return <CompletedBadge />;
        case EGoalGrading.Draft:
            return <DraftBadge />;
        default:
            return hideOnTrack ? null : <OnTrackBadge />;
    }
};

export default GoalStatusBadge;
