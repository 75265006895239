import "./VerifyingBadge.scss";

import { useTranslation } from "react-i18next";
import k from "i18n/keys";

import Badge, { IBaseBadgeProps } from "../../base-badges/Badge";

interface IVerifyingBadgeProps extends IBaseBadgeProps {
    label?: string;
}

const VerifyingBadge = (props: IVerifyingBadgeProps) => {
    const { label, ...rest } = props;
    const { t } = useTranslation();

    return (
        <Badge className="verifying-badge" {...rest}>
            {label ?? t(k.VERIFYING)}
        </Badge>
    );
};

export default VerifyingBadge;
