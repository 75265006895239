import React from "react";

import IChecklistReportTableMatrixResponse from "../report-table-details/api/IChecklistReportResponse";
import SeparateTablesCell from "./SeparateTablesCell";
import { TableCell } from "common/components/table";
import { ChecklistReportValueSumFilterResponseType } from "components/template-checklist-report/components/report-table-details/api/IChecklistReportValueSumFilter";

interface IReportTableSumCellsProps {
    tableMatrix: IChecklistReportTableMatrixResponse;

    sumOfValues: ChecklistReportValueSumFilterResponseType | null;
}

export const ReportTableSumCells = (props: IReportTableSumCellsProps) => {
    const { tableMatrix, sumOfValues } = props;

    const showValueCellSums =
        sumOfValues?.hasValueColumn &&
        tableMatrix.valueColumns.length === sumOfValues.values.length;

    return (
        <React.Fragment>
            <SeparateTablesCell />

            {showValueCellSums ? (
                sumOfValues.values.map((x, index) => (
                    <TableCell key={index}>{x}</TableCell>
                ))
            ) : (
                <TableCell colSpan={tableMatrix.valueColumns.length}>
                    &nbsp;
                </TableCell>
            )}
        </React.Fragment>
    );
};
